import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import AuthBarCodeSVG from '../SVGs/Auth/AuthBarCodeSVG';
import { useForm } from "react-hook-form";
import FormInput from '../FormInput/FormInput';
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useNavigate } from 'react-router-dom';

const schema = yup
    .object({
        username: yup.string().required('Username is required'),
        password: yup.string().required('Password is required').min(3, 'Password must be atleast 3 characters')
    })
    .required()

const LoginForm = ({ onSuccess }) => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) })
    const [msg,setMsg]=useState(null)

    const onSubmit = async(data) => {
        // console.log('submit',data);
        const response = await fetch('/user/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: data.username,
                user_psw: data.password,
            }),
          });
        const result = await response.json();
        console.log(result);
        if (!result.error) {
          navigate('/admin');
        //   onSuccess();
        } else {
          setMsg(result.error);
        }
        
    };
    useEffect(() => {
        async function checkIfLogIn() {
            const response = await fetch('/user/me');
            const user = await response.json();
            if (!user.error) {
                navigate('/admin');
            }
          }
          checkIfLogIn();
    },[]);

    return (
        <Box sx={{
            marginLeft: 'auto',
            background: 'url("/assets/svgs/folder-primary.svg")',
            maxWidth: { xl: '488px', lg: '438px', md: '388px', sm: '388px', xs: '388px' },
            width: { xl: '488px', lg: '438px', md: '388px', sm: '388px', xs: '100%' },
            position: 'relative',
            zIndex: 2
        }}>
            <Box sx={{ paddingLeft: '10%', position: 'absolute', top: '2px', left: '0', width: '183.404px' }}>
                <AuthBarCodeSVG />
            </Box>
            <Box sx={{ padding: { md: '10%', xs: '5% 10%' } }}>
                <Box sx={{ marginTop: '16px', marginBottom: '16px' }}>
                    <Typography variant='form' color='#FFEDE6'>
                        Log in to your Account.
                    </Typography>
                </Box>
                <FormInput
                    sx={{ marginBottom: { md: '8px', xs: '4px' } }}
                    register={register}
                    name='username'
                    placeholder='Username'
                    error={errors.username}
                />
                <FormInput
                    sx={{ marginBottom: { md: '8px', xs: '4px' } }}
                    register={register}
                    name='password'
                    type='password'
                    placeholder='Password'
                    error={errors.password}
                />
                <Box sx={{ marginBottom: { md: '59px', xs: '39px' }, width: '100%', textAlign: 'end' }}>
                    <Typography variant='inter14Bold' color='#FFEDE6' onClick={() => navigate('/forgot-password')} sx={{ cursor: 'pointer' }}>
                        Forgot password?
                    </Typography>
                </Box>

                <Box sx={{ marginBottom: { md: '32px', xs: '16px' } }}>
                    <Button
                        variant="contained"
                        sx={{ width: '100%', paddingTop: { xs: '10px' }, paddingBottom: { xs: '10px' }, marginBottom: { md: '15px', xs: '10px' } }}
                        onClick={handleSubmit(onSubmit)}>
                        <Typography variant='inter14Bold' color='white'>
                            LOGIN
                        </Typography>
                    </Button>
                    {msg && <Typography color='primary' variant='inter12'>{msg}</Typography>}
                </Box>
                <Box sx={{ marginBottom: { md: '16px', xs: '10px' } }}>
                    <Typography variant='form2' color='#FFEDE6'>
                        Don’t have an account?&nbsp;
                    </Typography>
                    <Typography variant='inter14Bold' color='#FFEDE6' onClick={() => navigate('/signup')} sx={{ cursor: 'pointer' }}>
                        Sign Up
                    </Typography>
                </Box>
            </Box>
        </Box >
    );
}

export default LoginForm;