import React from 'react';
import Banner from './Banner/Banner';
import Mapping from './Mapping/Mapping';
import { Box } from '@mui/material';

const RoadMap = () => {

    return (
        <Box component='main' sx={{ backgroundColor: 'common.black' }}>
            <Banner />
            <Mapping />
        </Box>
    );
};

export default RoadMap;