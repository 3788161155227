import * as React from "react";

const SmallBarCodeSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={123}
        height={13}
        fill="none"
        {...props}
    >
        <path
            stroke="#000"
            strokeWidth={3}
            d="M1.5 0v12.569M121.18 12.568V0M33.502 0v12.569M89.176 12.568V0"
        />
        <path
            stroke="#000"
            d="M5.834 0v12.569M116.846 12.568V0M37.836 0v12.569M84.84 12.568V0M21.836 0v12.569M100.844 12.568V0M53.84 0v12.569M68.84 12.568V0M27.168 0v12.569M95.51 12.568V0M59.172 0v12.569M63.508 12.568V0"
        />
        <path
            stroke="#000"
            strokeWidth={9}
            d="M15.168 0v12.569M107.512 12.568V0M47.172 0v12.569"
        />
        <path stroke="#000" strokeWidth={6} d="M77.008 12.568V0" />
        <path
            stroke="#000"
            strokeWidth={3}
            d="M17.502 0v12.569M105.178 12.568V0M49.504 0v12.569M73.176 12.568V0"
        />
    </svg>
)
export default SmallBarCodeSVG
