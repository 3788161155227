
import { AppBar, Box, Button, Divider, Drawer, IconButton, Toolbar } from '@mui/material';
import React, { useEffect } from 'react';
import MenuIconSVG from '../../../components/SVGs/MenuIconSVG';
import CloseIconSVG from '../../../components/SVGs/CloseIconSVG';
import LogoComponent from '../../../components/LogoComponent/LogoComponent';
import getScreenBreakPoint from '../../../utils/getScreenBreakPoint';
import { useLocation, useNavigate } from 'react-router-dom';
import HomeHeaderMenuItems from '../HeaderMenuItems/HomeHeaderMenuItems/HomeHeaderMenuItems';
import AboutHeaderMenuItems from '../HeaderMenuItems/AboutHeaderMenuItems/AboutHeaderMenuItems';
const drawerWidth = '66.8%';

const PADDING_SIZE = {
    xl: '64px',
    lg: '64px',
    md: '48px',
    sm: '32px',
    xs: '32px'
}

const PADDING2_SIZE = {
    xl: 64,
    lg: 64,
    md: 48,
    sm: 32,
    xs: 32
}

const PADDING_SCROLL_SIZE = {
    xl: '20px',
    lg: '18px',
    md: '15px',
    sm: '12px',
    xs: '10px'
}

const Header = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isAboutPage = location.pathname === '/about';
    // const isHomePage = location.pathname === '/';

    const getMenu = () => {
        if (isAboutPage) return <AboutHeaderMenuItems navigate={navigate} />
        else return <HomeHeaderMenuItems navigate={navigate} />
    };

    useEffect(() => {
        window.removeEventListener('scroll', shrinkHeader);
        window.addEventListener("scroll", shrinkHeader);
        // eslint-disable-next-line
    }, [isAboutPage]);

    const shrinkHeader = () => {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const paddingSize = PADDING2_SIZE[getScreenBreakPoint(width)];
        const paddingScrolSize = PADDING_SCROLL_SIZE[getScreenBreakPoint(width)];

        const scrolled = document.body.scrollTop > paddingSize - 12 || document.documentElement.scrollTop > paddingSize - 12;
        const nav = document.getElementById("navbar");

        if (nav) {
            if (scrolled) {
                nav.style.position = 'fixed';
                nav.style.backgroundColor = isAboutPage ? '#F8E9CD' : "#000";
                nav.style.paddingTop = paddingScrolSize;
                nav.style.paddingBottom = paddingScrolSize;
            } else {
                nav.style.position = 'absolute';
                nav.style.paddingTop = paddingSize + "px";
                nav.style.paddingBottom = paddingSize + "px";
                nav.style.backgroundColor = "transparent";
            }
        }
    };

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ backgroundColor: isAboutPage ? 'secondary.main' : 'common.black', height: '100%', }}>
            <Box sx={{ textAlign: 'end', padding: '20px' }}>
                <Button >
                    <CloseIconSVG />
                </Button>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '20px', alignItems: 'flex-start', gap: '24px' }}>
                {getMenu()}
            </Box>
        </Box >
    );

    return (
        <Box component='header' sx={{ position: 'relative' }}>
            <AppBar
                id="navbar"
                component="nav"
                color='transparent'
                position='absolute'
                sx={{
                    width: '100%',
                    boxSizing: 'border-box',
                    paddingTop: PADDING_SIZE,
                    paddingLeft: PADDING_SIZE,
                    paddingRight: PADDING_SIZE,
                    boxShadow: 'none'
                }}>
                <Toolbar id="toolbar" sx={{ backgroundColor: 'transparent', paddingLeft: '0px !important', paddingRight: '0px !important', minHeight: '0px' }}>
                    <LogoComponent isBlackLogo={isAboutPage} />
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ display: { md: 'none' } }}
                    >
                        <Box sx={{ width: { sm: '32px', xs: '24px' }, height: { sm: '32px', xs: '24px' } }}>
                            <MenuIconSVG width='100%' height='100%' />
                        </Box>
                    </IconButton>
                    <Box sx={{
                        display: { xs: 'none', md: 'flex' }, gap: {
                            xl: '32px',
                            lg: '24px',
                            md: '16px',
                            sm: '8px'
                        }, boxSizing: 'border-box', paddingLeft: '32px', alignItems: 'center'
                    }}>
                        {getMenu()}
                    </Box>
                </Toolbar>
            </AppBar >
            <nav>
                <Drawer
                    anchor='right'
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box>
    )
};

export default Header;