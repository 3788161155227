import { Box, Button, ClickAwayListener, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import ToolTipInfoSVG from '../../SVGs/ToolTipInfoSVG';
import ReferralModal from '../ReferralModal/ReferralModal';
import dayjs from 'dayjs';
import { formatNumber } from '../../../common';
import ORGJSON from '../../../abis/Organization.json';
import { Contract,utils,providers } from 'ethers';
import {
    DAppProvider,
    Mainnet,
    Mumbai,
    useEthers,
    Polygon,
} from '@usedapp/core';
const { InfuraProvider,AlchemyProvider } = providers;
const ReferralItem = ({item}) => (
    <Box sx={{ display: 'flex', padding: '15px 0px', borderBottomColor: '#E7E7E7', borderBottomWidth: '1px', borderBottomStyle: 'solid' }}>
        <Box sx={{ width: { xl: '17%', lg: '20%', md: '25%', sm: '30%', xs: '33%' } }}>
            <Typography variant='inter14'>
                {dayjs(item.created).format('DD-MMM-YY')}
            </Typography>
        </Box>
        <Box sx={{ width: { xl: '17%', lg: '20%', md: '25%', sm: '30%', xs: '33%' } }}>
            <Box sx={{ border: '1px solid #0B8E4F', background: '#CFE2D9', width: 'fit-content', padding: '4px 8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant='inter12' color='#075F35'>
                    Success
                </Typography>
            </Box>
        </Box>
        <Box sx={{ width: { xl: '17%', lg: '20%', md: '25%', sm: '30%', xs: '33%' } }}>
            <Typography variant='inter14'>
                {item.user_id}
            </Typography>
        </Box>
    </Box>
);

const HasReferralComponent = ({config}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);
    const [referralAmt, setReferralAmt] = useState({});
    const [referralTotal, setReferralTotal] = useState(0);
    
    const {account,chainId}=useEthers();
    console.log('account',account,chainId);
    const [open, setOpen] = useState(false);
    const [refered, setRefered] = useState([]);
    const [chainConfig,setChainConfig]=useState({});
    const [provider,setProvider]=useState({});
    useEffect(()=>{
        const loadRefered=async()=>{
            const refered=await (await fetch('/user/get_refered')).json();
            console.log('referred',refered);
            if(!refered.error){
                setRefered(refered);
            }
        };
        loadRefered();
    
    },[]);
    useEffect(() => {
        if(!account||!chainId||!config){
            return;
        }
        const loadReferral = async () => {
            
            const projectId = config.default_alchemy?config.alchemyId:config.infuraId;
            const chainConfig = config?.chainConfigs?.find((chain) => chain.chainId === chainId);
            console.log(chainConfig,config,chainId);
            const provider=config.default_alchemy?new AlchemyProvider(chainId,  projectId ):new InfuraProvider(chainId, { projectId })
            setProvider(provider);
            setChainConfig(chainConfig);
            if(!(chainConfig?.orgAddress&&account)){
                console.log('no org address'   );
                // debugger;
                return;
            }
            const contract=new Contract(chainConfig?.orgAddress,ORGJSON.abi,provider);
            const referral={};
            const total=[0];
            const accountLoad=account;
            // const accountLoad='0x2D98c24DfD650323347C3a21df864C951241227a'
            Object.keys(chainConfig.tokens).forEach(async tokenName=>{
                const token=chainConfig.tokens[tokenName];
                const amount=await contract.getSalesCommission(accountLoad,token.addr);
                console.log('referal',tokenName,amount);
                if(amount.gt(0)){
                    const amt=Number(utils.formatUnits(amount,token.decimals));
                    referral[tokenName]={
                        balance:formatNumber(amt,token.displayDecimals),
                        addr:token.addr
                    };
                    const strike=token.strike||1;
                    total[0]+=amt*strike;
                    setReferralTotal(total[0]);
                    setReferralAmt({...referral});
                }
            });
        };
        loadReferral();
    },[config,chainId,account]);
    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };
    return (
        <>
            <Box sx={{ marginBottom: '15px' }}>
                <Typography variant='inter32Bold' color='black'>
                    Successful Referrals <Typography variant='inter32'>({refered.length})</Typography>
                </Typography>
            </Box>
            <Box sx={{ background: 'url("/assets/svgs/info-container.svg")', padding: '24px 30px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        <Box sx={{ display: 'flex', gap: '5px' }}>
                            <Typography variant='roboto14Bold' color='#888071'>
                                REFERRAL EARNINGS
                            </Typography>
                            <Tooltip
                                title="Rewards are in the form of USDC, BTC, and ETH."
                                placement="top"
                                arrow
                            >
                                <Button sx={{ padding: 0, margin: 0, outline: 0, display: { md: 'block', xs: 'none' } }} onClick={handleTooltipOpen} >
                                    <ToolTipInfoSVG />
                                </Button>
                            </Tooltip>
                            <ClickAwayListener onClickAway={handleTooltipClose} >
                                <Tooltip
                                    onClose={handleTooltipClose}
                                    open={open}
                                    title="Rewards are in the form of USDC, BTC, and ETH."
                                    placement="top"
                                    arrow
                                >
                                    <Button sx={{ padding: 0, margin: 0, outline: 0, display: { md: 'none', xs: 'block' } }} onClick={handleTooltipOpen} >
                                        <ToolTipInfoSVG />
                                    </Button>
                                </Tooltip>
                            </ClickAwayListener>
                            {/* <Tooltip disableFocusListener title="in the form of USDC, BTC, and ETH." placement="top" arrow>
                                <Button sx={{ padding: 0, margin: 0, outline: 0 }}>
                                    <ToolTipInfoSVG />
                                </Button>
                            </Tooltip> */}
                        </Box>
                        <Typography variant='roboto32'>
                            {formatNumber(referralTotal,2)} USDC
                        </Typography>
                    </Box>
                    <Button
                        variant='contained'
                        color='black'
                        sx={{ height: 'fit-content', padding: { xl: '10px 16px', lg: '6px 12px', md: '0px 6px' } }}
                        onClick={openModal}
                    >
                        <Typography variant='inter14Bold'>
                            CLAIM
                        </Typography>
                    </Button>
                </Box>
            </Box>
            <Box sx={{ flex: 1, minHeight: '250px', display: 'flex', flexDirection: 'column', padding: '25px' }}>
                <Box sx={{ display: 'flex', paddingBottom: '15px', borderBottomColor: '#E7E7E7', borderBottomWidth: '1px', borderBottomStyle: 'solid' }}>
                    <Box sx={{ width: { xl: '17%', lg: '20%', md: '25%', sm: '30%', xs: '33%' } }}>
                        <Typography variant='inter12' color='#808080'>
                            DATE
                        </Typography>
                    </Box>
                    <Box sx={{ width: { xl: '17%', lg: '20%', md: '25%', sm: '30%', xs: '33%' } }}>
                        <Typography variant='inter12' color='#808080'>
                            STATUS
                        </Typography>
                    </Box>
                    <Box sx={{ width: { xl: '17%', lg: '20%', md: '25%', sm: '30%', xs: '33%' } }}>
                        <Typography variant='inter12' color='#808080'>
                            USER
                        </Typography>
                    </Box>
                </Box>
                {
                    refered.map((item,index)=><ReferralItem key={item.user_id} item={item} />)
                }
            </Box>
            <ReferralModal open={modalIsOpen} handleClose={closeModal} referralAmt={referralAmt} referralTotal={referralTotal} chainConfig={chainConfig} provider={provider}/>
        </>
    );
};
const Wrapper = () => {
    const [config,setConfig]=useState({});
    useEffect(() => {
        const loadConfig=async()=>{
            const config=await (await fetch('/user/config')).json();
            setConfig(config);
        };
        loadConfig();
    },[]);
    const projectId = config.default_alchemy?config.alchemyId:config.infuraId;
    return <DAppProvider
            config={{
            networks: [Polygon, Mainnet],
            readOnlyChainId: Mainnet.chainId,
            readOnlyUrls: {
                [Mainnet.chainId]: config.default_alchemy?new AlchemyProvider(Mainnet.chainId, projectId):new InfuraProvider(Mainnet.chainId, {projectId}),
                [Polygon.chainId]: config.default_alchemy?new AlchemyProvider(Polygon.chainId, projectId):new InfuraProvider(Polygon.chainId, {projectId}),
            },
            }}
        >
            <HasReferralComponent config={config}/>
        </DAppProvider>
};
export default Wrapper;