import { Box, Container, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import BaljunaWatersSVG from '../../../components/SVGs/BaljunaWatersSVG';
import TigerSVG from '../../../components/SVGs/Auth/TigerSVG';
import AuthLoginTitleSVG from '../../../components/SVGs/Auth/AuthLoginTitleSVG';
import AuthHouseSVG from '../../../components/SVGs/Auth/AuthHouseSVG';
import LoginForm from '../../../components/LoginForm/LoginForm';
import Welcome from '../../Welcome/Welcome';
import ForgotPasswordForm from '../../../components/ForgotPasswordForm/ForgotPasswordForm';
import ForgotPasswordResetForm from '../../../components/ForgotPasswordResetForm/ForgotPasswordResetForm';
import ForgotPasswordSuccessForm from '../../../components/ForgotPasswordSuccessForm/ForgotPasswordSuccessForm';


const ForgotPassword = () => {
    const [successAuth, setSuccessAuth] = useState(false);
    const refSide = useRef();
    const refMain = useRef();
    const refForm = useRef();
    const [form, setForm] = useState(1);
    const [userId, setUserId] = useState(null);

    return (
        <Box sx={{ width: '100%', height: '100vh', position: 'relative', overflow: 'hidden' }}>
            <Box ref={refSide} sx={{ position: 'absolute', width: '75%', height: '100%', transition: '1.5s', top: '0', left: '0', backgroundColor: 'secondary.main', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ marginLeft: 'auto', width: { xl: '555px', lg: '495px', md: '435px' }, display: { md: 'block', xs: 'none' } }}>
                    <AuthHouseSVG />
                </Box>
            </Box>
            <Box ref={refMain} sx={{ position: 'absolute', width: '25%', transition: '1.5s', height: '100%', bottom: '0', right: '0', backgroundColor: 'common.black' }}>
                {successAuth && <Welcome />}
                {!successAuth &&
                    <Box sx={{ position: 'absolute', height: { md: '40%', xs: '25%' }, bottom: '0', right: '-250px', opacity: 0.5 }}>
                        <TigerSVG />
                    </Box>
                }
            </Box>
            <Container ref={refForm} maxWidth='xl' fixed sx={{ transition: '1.5s', position: 'relative', display: 'flex', height: '100%', alignItems: 'center', paddingTop: '4%', paddingBottom: '2%' }}>
                <Box sx={{ flex: 1, height: '100%', flexDirection: 'column', justifyContent: 'space-between', display: { md: 'flex', xs: 'none' } }}>
                    <Box sx={{ width: { xl: '482px', lg: '412px', md: '362px' } }}>
                        <AuthLoginTitleSVG />
                    </Box>
                    <Box sx={{ width: { xl: '206px', lg: '176px', md: '146px' } }}>
                        <BaljunaWatersSVG />
                    </Box>
                </Box>
                <Box sx={{
                    flex: 1,
                    height: { md: '100%', xs: '100vh' },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative'
                }}>
                    <Box sx={{ position: 'absolute', width: '100%', height: '100%', top: '0', right: '0', display: { md: 'none', xs: 'flex' }, alignItems: 'center', justifyContent: 'center' }}>
                        <AuthHouseSVG />
                    </Box>
                    <Box sx={{ position: 'absolute', top: '5%', left: '0', width: '75%', display: { md: 'none', xs: 'block', display: 'flex', justifyContent: 'center', zIndex: 1 } }}>
                        <Typography variant='authDescription' sx={{ display: 'block' }}>
                            LOG IN TO BALJUNA
                        </Typography>
                        <Typography variant='authTitle'>
                            ADAPT EVOLVE AND PROSPER
                        </Typography>
                    </Box>
                    {form === 1 && <ForgotPasswordForm onSuccess={(userId) => {setForm(2);setUserId(userId)}} />}
                    {form === 2 && <ForgotPasswordResetForm onSuccess={() => setForm(3)} userId={userId}/>}
                    {form === 3 && <ForgotPasswordSuccessForm />}
                    <Box sx={{ position: 'absolute', left: '0', bottom: '5%', height: { sm: '65px', xs: '50px' }, display: { md: 'none', xs: 'block', zIndex: 1 } }}>
                        <BaljunaWatersSVG />
                    </Box>
                </Box>
            </Container >
        </Box >
    );
};

export default ForgotPassword;