import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import TigerSVG from '../../components/SVGs/Auth/TigerSVG';
import ShortLogoSVG from '../../components/SVGs/ShortLogoSVG';
import HousesSVG from '../../components/SVGs/Auth/HousesSVG';
import BaljunaWatersSVG from '../../components/SVGs/BaljunaWatersSVG';
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ backgroundColor: 'common.black', overflow: 'hidden', height: '100%' }}>
            <Container maxWidth='xl' fixed sx={{ height: '100vh', position: 'relative', display: 'flex', paddingTop: '5%', flexDirection: { md: 'row', xs: 'column' } }}>

                <Box sx={{ position: 'absolute', height: { md: '40%', xs: '25%' }, bottom: '0', left: '0' }}>
                    <TigerSVG />
                </Box>

                <Box sx={{ position: 'absolute', height: { md: '50%', xs: '30%' }, top: '10%', right: '0' }}>
                    <HousesSVG />
                </Box>
                <Box sx={{ flex: 1, zIndex: 1 }}>
                    <Box sx={{ width: '76px', height: '74px', marginBottom: '10px' }}>
                        <ShortLogoSVG />
                    </Box>
                    <Box >
                        <Typography variant='h1Mb' color='secondary' >
                            WELCOME TO BALJUNA
                        </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '50px' }}>
                        <Typography variant='body1' color='common.white' >
                            You can now start trading in our platform. <br></br>
                            Launch the app to check out our suite of investment products.
                        </Typography>
                    </Box>
                    <Button variant='contained' sx={{ padding: '8px 24px' }} onClick={() => navigate('/admin')} >
                        <Typography variant='button1'>
                            LAUNCH APP
                        </Typography>
                    </Button>
                </Box>
                <Box sx={{ flex: 1, paddingBottom: '5%' }}>
                    <Box sx={{ height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <Box sx={{ width: { md: '207px', sm: '187px', xs: '167px' }, zIndex: 1 }}>
                            <BaljunaWatersSVG primary />
                        </Box>
                    </Box>
                </Box>
            </Container >
        </Box >
    );
};

export default Welcome;