import React, { useState } from 'react';
import { Box, Container } from '@mui/material';
import BarCodeVerticalSVG from '../../../components/SVGs/BarCodeVerticalSVG';
import DragonSVG from '../../../components/SVGs/DragonSVG';
import DragonTailSVG from '../../../components/SVGs/DragonTailSVG';
import DragonOtherSVG from '../../../components/SVGs/DragonOtherSVG';
import HomeHeader from '../../../components/HomeHeader/HomeHeader';
import { tokens } from './tokens.data';
import TokenBoxDesktop from './Desktop/TokenBoxDesktop/TokenBoxDesktop';
import TokenBigSVG from '../../../components/SVGs/Token/TokenBigSVG';
import TokenBoxMobile from './Mobile/TokenBoxMobile/TokenBoxMobile';
import BarCodeVerticalSmallSVG from '../../../components/SVGs/BarCodeVerticalSmallSVG';
import DragonSmallSVG from '../../../components/SVGs/DragonSmallSVG';
import DragonTailSmallSVG from '../../../components/SVGs/DragonTailSmallSVG';

const BPSToken = () => {

    const [activeToken, setActiveToken] = useState(0);

    return (
        <Box
            id="token"
            component="section"
            sx={{ backgroundColor: '#161887', position: 'relative' }}
        >
            <Box sx={{ position: 'absolute', left: '0px', top: '30px', display: { md: 'block', xs: 'none' } }}>
                <BarCodeVerticalSVG />
            </Box>
            <Box sx={{ position: 'absolute', left: '0px', top: '30px', display: { md: 'none', xs: 'block' } }}>
                <BarCodeVerticalSmallSVG />
            </Box>

            <Box sx={{ position: 'absolute', right: '0px', top: '0px', display: { md: 'block', xs: 'none' } }}>
                <DragonSVG />
            </Box>
            <Box sx={{ position: 'absolute', right: '0px', top: '0px', display: { md: 'none', xs: 'block' } }}>
                <DragonSmallSVG />
            </Box>

            <Box sx={{ position: 'absolute', right: '5%', bottom: '0px', display: { md: 'block', xs: 'none' } }}>
                <DragonTailSVG />
            </Box>
            <Box sx={{ position: 'absolute', left: '0', bottom: '30%', display: { md: 'none', xs: 'block' } }}>
                <DragonTailSmallSVG />
            </Box>


            <Box sx={{ position: 'absolute', left: '0px', bottom: '0px', display: { md: 'block', xs: 'none' } }}>
                <DragonOtherSVG />
            </Box>

            <Container maxWidth='xl' fixed>
                <HomeHeader
                    sx={{ display: { md: 'block', xs: 'none' }, maxWidth: '65%', paddingTop: '120px', paddingLeft: '5%', marginBottom: '30px' }}
                    title=" BALJUNA PROFIT SHARING (BPS) TOKEN."
                    titleColor='secondary.main'
                    description=" Receive rewards for participating and enjoy rewards from the buyback program."
                    descriptionColor='common.white'
                />

                <HomeHeader
                    sx={{ display: { md: 'none', xs: 'block' }, maxWidth: '85%', paddingTop: '40px', paddingLeft: '8%', position: 'relative', zIndex: 1 }}
                    title=" BALJUNA PROFIT SHARING (BPS) TOKEN."
                    titleColor='secondary.main'
                    description=" "
                    descriptionColor='common.white'
                />

                <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column-reverse' }, marginTop: '64px', overflow: 'hidden', paddingBottom: '50px', gap: '50px' }}>
                    <Box className='remove-scroll' sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '32px',
                        zIndex: 1,
                        height: '100%',
                        overflowY: 'scroll',
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        margin: 'auto',
                        maxHeight: { lg: '800px', md: '650px', sm: '525px', xs: '400px' },
                        width: { md: '35%', xs: '90%' },
                        maxWidth: { sm: '500px', xs: '400px' },
                    }}>
                        {tokens.map(({ key, icon, description }) => (
                            <TokenBoxDesktop
                                key={key}
                                icon={icon}
                                description={description}
                                isActive={key === activeToken}
                                onClick={() => setActiveToken(key)}
                                sx={{ display: { md: 'flex', xs: 'none' } }}
                            />
                        ))}
                        {tokens.map(({ key, icon, description }) => (
                            <TokenBoxMobile
                                key={key}
                                icon={icon}
                                description={description} i
                                sActive={key === activeToken}
                                onClick={() => setActiveToken(key)}
                                sx={{ display: { md: 'none', xs: 'flex' } }}
                            />
                        ))}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                        <Box sx={{
                            marginBottom: { md: '200px' },
                            width: { md: '100%', xs: '85%' },
                            height: { md: '100%', xs: 'auto' },
                            maxWidth: { lg: '600px', md: '500px', sm: '400px', xs: '300px' },
                            maxHeight: { lg: '600px', md: '500px' },
                            zIndex: 1
                        }}>
                            <TokenBigSVG triggerRotate={activeToken} width='100%' height='100%' />
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default BPSToken;
