import React, { useEffect } from 'react';
import BPSToken from './BPSToken/BPSToken';
import Documentation from './Documentation/Documentation';
import DiscerningInvestor from './DiscerningInvestor/DiscerningInvestor';
import InvestmentProducts from './InvestmentProducts/InvestmentProducts';
import Banner from './Banner/Banner';
import { ParallaxProvider } from 'react-scroll-parallax';
import { useSearchParams } from 'react-router-dom';
import { scroller } from 'react-scroll';


function Home() {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const scrollId = searchParams.get('scrollTo');
        if (scrollId) {
            scroller.scrollTo(scrollId, {
                smooth: true,
                spy: true,
                duration: 500
            });
        }
    }, [searchParams]);

    return (
        <main>
            <ParallaxProvider>
                <Banner />
                <InvestmentProducts />
                <DiscerningInvestor />
                <BPSToken />
                <Documentation />
            </ParallaxProvider>
        </main>
    );
}

export default Home;
