import React from 'react';
import FooterMarqueeDesktop from './Desktop/FooterMarqueeDesktop';
import FooterMarqueeMobile from './Mobile/FooterMarqueeMobile';

const FooterMarquee = () => (
    <section>
        <FooterMarqueeDesktop />
        <FooterMarqueeMobile />
    </section>
);

export default FooterMarquee;