import * as React from "react"
const ChildIconSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={20}
        fill="none"
        {...props}
    >
        <path
            stroke="#FF4C00"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M21 7a1.981 1.981 0 0 0-.5.07 8.99 8.99 0 0 0-17 0A2 2 0 1 0 3 11c.02 0 .039-.005.059-.006a9 9 0 0 0 17.883 0c.02 0 .038.006.058.006a2 2 0 1 0 0-4Z"
        />
        <path
            fill="#FF4C00"
            d="M8.5 10a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM15.5 10a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        />
        <path
            stroke="#FF4C00"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M13 4.732A2 2 0 1 1 12 1"
        />
        <path fill="#FF4C00" d="M12 16a3 3 0 0 0 3-3H9a3 3 0 0 0 3 3Z" />
    </svg>
)
export default ChildIconSVG
