import { Box, Typography } from '@mui/material';
import React from 'react';
import SmallBirdSVG from '../../SVGs/SmallBirdSVG';

const NoReferralComponent = () => {

    return (
        <>
            <Box sx={{ marginBottom: '15px' }}>
                <Typography variant='inter32Bold' color='black'>
                    Your Lieutenants <Typography variant='inter32'>(0)</Typography>
                </Typography>
            </Box>
            <Box sx={{ background: 'url("/assets/svgs/info-container.svg")', padding: '24px' }}>
                <Typography variant='inter16'>
                    Your referral earnings will appear here once your referral starts to trade.
                </Typography>
            </Box>
            <Box sx={{ flex: 1, width: '100%', minHeight: '250px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ heigt: '61px', marginBottom: '15px' }}>
                    <SmallBirdSVG />
                </Box>
                <Typography variant='inter16'>
                    Your referral earnings will appear here once your referral starts to trade.
                </Typography>
            </Box>
        </>
    );
};

export default NoReferralComponent;