import { Box, Typography } from '@mui/material';
import React from 'react';

const HomeHeader = ({ title, description, sx, titleColor = 'common.black', descriptionColor = 'common.black' }) => (
    <Box sx={sx}>
        <Typography variant='h1Mb' color={titleColor} sx={{ textTransform: 'uppercase' }}>
            {title}
        </Typography>
        <Typography color={descriptionColor} variant='subtitle1'>
            {description}
        </Typography>
    </Box>
);

export default HomeHeader;