import Token1SVG from "../../../components/SVGs/Token/Token1SVG";
import Token2SVG from "../../../components/SVGs/Token/Token2SVG";
import Token3SVG from "../../../components/SVGs/Token/Token3SVG";
import Token4SVG from "../../../components/SVGs/Token/Token4SVG";
import Token5SVG from "../../../components/SVGs/Token/Token5SVG";
import Token6SVG from "../../../components/SVGs/Token/Token6SVG";


export const tokens = [
    {
        key: 0,
        icon: <Token1SVG width='100%' height='100%' />,
        description: 'Earn BPS token when your trade is unprofitable.'
    }, {
        key: 1,
        icon: <Token2SVG width='100%' height='100%' />,
        description: 'Option to sell BPS token for USDT back to Baljuna every quarter.'
    }, {
        key: 2,
        icon: <Token3SVG width='100%' height='100%' />,
        description: 'Tokens bought back by the platform will be burned.'
    }, {
        key: 3,
        icon: <Token4SVG width='100%' height='100%' />,
        description: 'Token price increases the more people trade in the platform.'
    }, {
        key: 4,
        icon: <Token5SVG width='100%' height='100%' />,
        description: 'BPS token fixed supply is 20 million.'
    }, {
        key: 5,
        icon: <Token6SVG width='100%' height='100%' />,
        description: '20% of platform’s profits is allocated to buying back BPS tokens'
    },
]