import { Box, Container, Typography } from '@mui/material';
import React from 'react';

const Banner = () => {

    return (
        <Box component="section" sx={{ paddingTop: { md: '180px', xs: '130px' }, paddingBottom: '40px' }}>
            <Container maxWidth='xl' fixed
                sx={{
                    display: 'flex',
                    flexDirection: { md: 'flex', xs: 'column' }
                }}>
                <Box sx={{ flex: 1 }}>
                    <Typography variant='h1Mb' color='secondary'>
                        MILESTONES & ROADMAP
                    </Typography>
                </Box>

                <Box sx={{ flex: 1 }}>
                    <Typography variant='body1' color='secondary'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Banner;

