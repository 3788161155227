import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import DashboardIconSVG from '../../../components/SVGs/Admin/MenuIcons/DashboardIconSVG';
import CurrencyIconSVG from '../../../components/SVGs/Admin/MenuIcons/CurrencyIconSVG';
import VaultIconSVG from '../../../components/SVGs/Admin/MenuIcons/VaultIconSVG';
import RewardsIconSVG from '../../../components/SVGs/Admin/MenuIcons/RewardsIconSVG';
import TradingIconSVG from '../../../components/SVGs/Admin/MenuIcons/TradingIconSVG';
import StarIconSVG from '../../../components/SVGs/Admin/MenuIcons/StarIconSVG';
import DocumentationIconSVG from '../../../components/SVGs/Admin/MenuIcons/DocumentationIconSVG';
import FaqsIconSVG from '../../../components/SVGs/Admin/MenuIcons/FaqsIconSVG';
import TermsIconSVG from '../../../components/SVGs/Admin/MenuIcons/TermsIconSVG';
import ContactIconSVG from '../../../components/SVGs/Admin/MenuIcons/ContactIconSVG';
import ChildIconSVG from '../../../components/SVGs/Admin/MenuIcons/ChildIconSVG';
import MenuComponent from '../MenuComponent/MenuComponent';
import ContactUsModal from '../../../components/ContactUsModal/ContactUsModal';
import ContactUsDoneModal from '../../../components/ContactUsDoneModal/ContactUsDoneModal';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useEthers } from '@usedapp/core';
const AdminSidebar = () => {
    const [isAdmin,setAdmin]=useState(false);
    const { chainId } = useEthers();
    useEffect(()=>{
        const fetchUserData = async () => {
            const user= await (await fetch('/user/me')).json();
            if((user.roles||[]).includes('manager')){
                setAdmin(true);
            }
            if(user.upper_referral_code){
                localStorage.setItem('refcode',user.upper_referral_code);
            }
        };
        fetchUserData();
    },[])
    const navigate = useNavigate();
    const location = useLocation();
    const currentRoute = location.pathname; // location.pathname === '/admin/dashboard/my-transaction' ? '/admin' : location.pathname;
    const [showContactModal, setShowContactModal] = useState(false);
    const toggleShowContact = () => setShowContactModal(!showContactModal);

    const [showContactDoneModal, setShowContactDoneModal] = useState(false);
    const toggleShowContactDone = () => setShowContactDoneModal(!showContactDoneModal);

    const onSubmit = () => {
        setShowContactModal(false);
        setShowContactDoneModal(true);
    }

    const menuItems = [
        {
            route: '/admin',
            icon: <DashboardIconSVG />,
            label: 'Dashboard'
        },
        {
            route: '/admin/dual-currency',
            icon: <CurrencyIconSVG />,
            label: 'Dual Currency'
        }];
    if(chainId===137){
        // menuItems.push({
        //     route: '/admin/dci',
        //     icon: <CurrencyIconSVG />,
        //     label: 'Dual Currency (DeFi)'
        // });
    }
    [{
            route: '/admin/arb-yield-vault',
            icon: <VaultIconSVG />,
            label: 'Arb Yield Vaults'
        },
        {
            route: '/admin/bps-rewards',
            icon: <RewardsIconSVG />,
            label: 'BPS Rewards'
        },
        {
            icon: <TradingIconSVG />,
            label: 'Risk-free Trading',
            soon: true
        }
    ].forEach(item=>{
        menuItems.push(item);
    });
    if(isAdmin){
        menuItems.push({
            route: `${window.location.protocol}//${window.location.host}/dashboard`,
            icon: <CurrencyIconSVG />,
            label: 'Admin',
            target: true
        });
        menuItems.push({
            route: `${window.location.protocol}//${window.location.host}/instruments`,
            icon: <TermsIconSVG />,
            label: 'Instrument List',
            target: true
        })
    }

    const menu2Items = [
        {
            route: 'https://baljuna.gitbook.io/new-user-guide/',
            icon: <ChildIconSVG />,
            label: 'New User Guide',
            target: true
        },
        {
            route: 'https://baljuna.gitbook.io/new-user-guide/new-user-guide/trade-on-ethereum-vs-trade-on-polygon',
            label: 'Ethereum vs. Polygon',
            target: true
        },
        {
            route: 'https://baljuna.gitbook.io/new-user-guide/new-user-guide/how-to-change-metamask-network-to-polygon-chain',
            label: 'How to Switch to Polygon',
            target: true
        },
        {
            route: 'https://baljuna.gitbook.io/transfer-assets-to-polygon/',
            label: 'How to Transfer Assets to Polygon',
            target: true
        }
    ];

    const menu3Items = [
        {
            route: '/referral',
            icon: <StarIconSVG />,
            label: 'Earn Passive Income (Referral)'
        },
        {
            route: 'https://baljuna.gitbook.io/baljuna-waters-documentation/',
            icon: <DocumentationIconSVG />,
            label: 'Documentation',
            target: true
        },
        {
            icon: <FaqsIconSVG />,
            label: 'FAQs'
        },
        {
            route: 'https://baljuna.gitbook.io/terms-and-agreement/',
            icon: <TermsIconSVG />,
            label: 'Terms and Agreement',
            target: true
        },
        {
            onClick: toggleShowContact,
            icon: <ContactIconSVG />,
            label: 'Need help? Contact us.'
        },
    ];

    return (
        <Box sx={{ backgroundColor: 'common.black', padding: '24px 16px', flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Link to='/' style={{ textDecoration: 'none', display: 'block' }}>
                <Box component='img' sx={{ width: '164px', marginBottom: '40px', cursor: 'pointer' }} src={'/assets/images/logo.png'} />
            </Link>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
                <MenuComponent title='INVESTMENT PRODUCTS' items={menuItems} selectedRoute={currentRoute} />
                <MenuComponent title='GETTING STARTED' items={menu2Items} />
                <MenuComponent title='MORE' items={menu3Items} />
            </Box>
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', gap: '8px', minHeight: '100px' }}>
                    <Typography variant='roboto12Bold' color='common.grey'>
                        AUDITED BY
                    </Typography>
                    <Box component='img' src="/assets/images/peckshield-logo.png" alt="Peck Shield Logo"
                        sx={{
                            objectFit: 'contain',
                            maxWidth: '184px',
                            width: '80%',
                            height: 'auto',
                        }} />
                </Box>
            </Box>
            <ContactUsModal open={showContactModal} handleClose={toggleShowContact} onSubmit={onSubmit} />
            <ContactUsDoneModal open={showContactDoneModal} handleClose={toggleShowContactDone} />
        </Box>
    );

};

export default AdminSidebar;