import React, { useEffect,useState,useMemo } from 'react';
import { Outlet,useNavigate } from "react-router-dom";
import AdminSidebar from './Sidebar/AdminSidebar';
import { Box } from '@mui/material'
import HeaderMenu from './HeaderMenu/HeaderMenu';
import {
    DAppProvider,
    Mainnet,
    useEthers,
    Polygon,
} from '@usedapp/core';
import * as eth from 'ethers';
import { ConfigContext, UserContext } from '../../common';
import Token from '../../abis/OurToken.json';
import { Contract } from 'ethers';

const { InfuraProvider,AlchemyProvider } = eth.providers;
const tokenABI = Token.abi;
const fetchUserData = async () => {
return await (await fetch('/user/me')).json();
};

const fetchConfigData = async () => {
return await (await fetch('/user/config')).json();
};
const WrappedComponent = ({user,config}) => {
    const { chainId, account, library } = useEthers();
    const [balances,setBalances]=useState({});
    const projectId = config.default_alchemy?config.alchemyId:config.infuraId;
    const chainConfig = useMemo(()=>config?.chainConfigs?.find((chain) => chain.chainId === chainId),[config,chainId]);
    // console.log(chainConfig,config,chainId);
    const provider=useMemo(()=>config.default_alchemy?new AlchemyProvider(chainId,  projectId ):new InfuraProvider(chainId, { projectId }),[config,chainId])
    useEffect(() => {
        const loadBalances=async()=>{
            const balances={};
            Object.keys(chainConfig.tokens).forEach(async tokenName=>{
                const token=chainConfig.tokens[tokenName];
                const tokenContract = new Contract(
                    token.addr,
                    tokenABI,
                    provider
                  );
                const balance = await tokenContract.balanceOf(account);
                if(token.native){
                    const nativeBalance = await provider.getBalance(account);
                    balances[tokenName]=Number(eth.utils.formatUnits(balance.add(nativeBalance),token.decimals));
                    setBalances({...balances});
                }else{
                    balances[tokenName]=Number(eth.utils.formatUnits(balance,token.decimals));
                    setBalances({...balances});
                }
                
            })
            if(chainConfig.investTokenAddress){
                const tokenContract = new Contract(
                    chainConfig.investTokenAddress,
                    tokenABI,
                    provider
                  );
                const balance = await tokenContract.balanceOf(account);
                balances['iUSD']=Number(eth.utils.formatUnits(balance,6));
                setBalances({...balances});
            }
            if(chainConfig.rewardTokenAddress){
                const tokenContract = new Contract(
                    chainConfig.rewardTokenAddress,
                    tokenABI,
                    provider
                  );
                const balance = await tokenContract.balanceOf(account);
                balances['BPS']=Number(eth.utils.formatUnits(balance,6));
                setBalances({...balances});
            }
        };
        if (account&&chainConfig) {
            loadBalances();
        }
    },[chainId,account,chainConfig]);
    return <UserContext.Provider value={{...user,chainConfig,provider,balances}}>
        <ConfigContext.Provider value={config}>
            <Box sx={{ flex: 1 }}>
                <HeaderMenu />
                <Outlet />
            </Box>
        </ConfigContext.Provider>
    </UserContext.Provider>
}
const Admin = () => {
    const [config,setConfig]=useState({});
    const [user,setUser]=useState({});
    const [tvl,setTvl]=useState(0);
    const [ttv, setTtv] = useState(0);
    const navigate = useNavigate();
    useEffect(() => {
        const loadConfig=async()=>{
            const [user, config,tvlData] = await Promise.all([
                fetchUserData(),
                fetchConfigData(),
                await (await fetch('/get_tvl_ttv')).json()
            ]);
            if (user.error) {
                navigate('/login');
            }else{
                setConfig(config);
                setUser(user);
                const ttv=tvlData.reduce((acc,cur)=>cur.TTV,0);
                const tvl=tvlData.reduce((acc,cur)=>acc+cur.TVL,0);
                setTtv(ttv.toLocaleString('en-US', { style: 'currency', currency: 'USD' }));
                setTvl(tvl.toLocaleString('en-US', { style: 'currency', currency: 'USD' }));
            }
        };
        loadConfig();
    },[]);
    const projectId = config.default_alchemy?config.alchemyId:config.infuraId;
    if(!projectId){
        return <div>...</div>;
    }
    return <Box sx={{ display: 'flex' }}>
        
        <DAppProvider
            config={{
            networks: [Polygon, Mainnet],
            readOnlyChainId: Mainnet.chainId,
            readOnlyUrls: {
                [Mainnet.chainId]: config.default_alchemy?new AlchemyProvider(Mainnet.chainId, projectId):new InfuraProvider(Mainnet.chainId, {projectId}),
                [Polygon.chainId]: config.default_alchemy?new AlchemyProvider(Polygon.chainId, projectId):new InfuraProvider(Polygon.chainId, {projectId}),
            },
            providerOverrides:{
                [Mainnet.chainId]: config.default_alchemy?new AlchemyProvider(Mainnet.chainId, projectId):new InfuraProvider(Mainnet.chainId, {projectId}),
                [Polygon.chainId]: config.default_alchemy?new AlchemyProvider(Polygon.chainId, projectId):new InfuraProvider(Polygon.chainId, {projectId}),
            },
            pollingInterval: 150000,
            notifications: {
                checkInterval: 150000,
                expirationPeriod: 150000
              },
            refresh: 'never'
            }}
        >
            <Box sx={{ minWidth: { lg: '20%', md: '23%' }, display: { md: 'flex', xs: 'none' }, minHeight: '100vh' }}>
                <AdminSidebar />
            </Box>
           <WrappedComponent user={{...user,tvl,ttv}} config={config}> 
                <Outlet />
           </WrappedComponent>
        </DAppProvider>
    </Box>
};

export default Admin;