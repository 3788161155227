import { Box, Container, Typography } from '@mui/material';
import React, { useState } from 'react';
import CoreValuesSVG from '../../../components/SVGs/CoreValuesSVG';

const values = [
    {
        title: 'HONOR',
        description: 'We value integrity. Our products are made in such a way that would address issues that have surfaced in DeFi in the past.'
    },
    {
        title: 'TRANSPARENCY',
        description: 'To remain honorable in our mission, we made sure you can check your transactions on-chain.'
    },
    {
        title: 'INNOVATION',
        description: 'We aim to explore the uncharted waters of DeFi and bridge the best of traditional finance to crypto. With our team\'s experience in banking, we understand what it takes to create sophisticated solutions and to modernize the DeFi experience.'
    },
    {
        title: 'VICTORY',
        description: 'We value our brothers and sisters in DeFi space and we put your interest first.'
    },
    {
        title: 'BROTHERHOOD',
        description: 'We believe that there is a way to make everyone win. A portion of our profits will benefit holders of BPS token and as seen in our referral program, we prioritize rewarding everyone for their contribution.'
    }
    , {
        title: 'PROFESSIONALISM',
        description: 'We believe that DeFi is the next natural revolution to money and we remain our thesis that crypto will be the biggest wealth distributor in this century. We will always find ways to bring the best in CeFi to DeFi.'
    }
];

const CoreValues = () => {
    const [selectedValue, setSelectedValue] = useState(0);

    return (
        <Box component='section' sx={{ backgroundColor: 'common.black' }}>

            <Container maxWidth='xl' fixed sx={{
                position: 'relative',
                overflowX: { sm: 'visible', xs: 'scroll' },
                marginTop: '-1px'
            }}>
                <Box sx={{ position: 'absolute', width: '99%', height: { xl: '1205px', lg: '942px', md: '706px', sm: '540px', xs: '500px' }, top: '0.5%', bottom: '0.5%', left: '0.5%', right: '0.5%' }}>
                    <Box component='img' src='/assets/svgs/folder.svg' width='100%' height='100%' />
                </Box>
                <Box sx={{ position: 'absolute', left: { lg: '3%', md: '1%', sm: '2%', xs: '3%' }, top: { md: '2%', sm: '5%', xs: '5%' } }}>
                    <Typography variant='subject' color="primary">
                        {'// OUR CORE VALUES'}
                    </Typography>
                </Box>
                <Box sx={{ width: '100%', height: { xl: '1230px', lg: '961px', md: '700px', sm: '550px', xs: '510px' }, display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10, position: 'relative' }}>
                    <Box sx={{ width: { lg: '698px', md: '550px', sm: '380px', xs: '380px' }, height: { lg: '762px', md: '600.429px', sm: '414.842px', xs: '414.842px' }, zIndex: 1 }}>
                        <CoreValuesSVG selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
                    </Box>
                    <Box sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: { lg: '30%', sm: '40%', xs: '55%' } }}>
                            <Typography color='secondary' variant='coreValues'>
                                {values[selectedValue].title}
                            </Typography>
                            <Typography color='secondary' variant='coreValuesDesc'>
                                {values[selectedValue].description}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box >
    );

};

export default CoreValues;