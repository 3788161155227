import * as React from "react"
const VaultIconSVG = ({ fill = '#FF4C00' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={'100%'}
        height={'100%'}
        viewBox="0 0 26 24"
        fill="none"
    >
        <g>
            <path
                fill={fill}
                d="M17.83 1H7.28C3.27 1 0 4.27 0 8.28v14.86c0 .41.34.75.75.75h23.6c.41 0 .75-.34.75-.75V8.28C25.1 4.27 21.84 1 17.83 1Zm1.67 21.38H5.72a4.901 4.901 0 0 0-4.22-4.11v-5.72H9.56v3.34c0 .41.34.75.75.75h4.24c.41 0 .75-.34.75-.75v-3.34H23.59v5.74c-2.1.32-3.76 1.98-4.09 4.09Zm-8.43-7.24V9.33h2.74v5.81h-2.74ZM1.5 8.28c0-3.19 2.59-5.78 5.78-5.78h10.55c3.19 0 5.78 2.59 5.78 5.78v2.77H15.32V8.58c0-.41-.34-.75-.75-.75h-4.24c-.41 0-.75.34-.75.75v2.47H1.5V8.28Zm0 11.49c1.34.25 2.41 1.29 2.71 2.61H1.5v-2.61Zm19.52 2.61c.29-1.29 1.3-2.3 2.59-2.59v2.59h-2.59Z"
            />
        </g>
    </svg>
)
export default VaultIconSVG
