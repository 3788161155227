import * as React from "react"

const BitCoinTokenSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#000"
            d="M12 24.908c-6.618 0-12-5.383-12-12 0-6.618 5.382-12 12-12 6.617 0 12 5.382 12 12-.002 6.617-5.385 12-12 12Zm0-23.001c-6.066 0-11 4.935-11 11C1 18.974 5.933 23.91 12 23.91s11-4.936 11-11.001c-.002-6.066-4.934-11.001-11-11.001Z"
        />
        <path
            fill="#000"
            d="M11.999 22.666c-5.38 0-9.759-4.378-9.759-9.758 0-5.38 4.379-9.759 9.759-9.759 5.38 0 9.758 4.379 9.758 9.759 0 5.38-4.378 9.758-9.758 9.758Zm0-18.517c-4.83 0-8.76 3.928-8.76 8.759 0 4.83 3.93 8.76 8.76 8.76s8.76-3.93 8.76-8.76-3.932-8.76-8.76-8.76Z"
        />
        <path
            fill="#000"
            d="M13.296 13.609H8.908V8.302h4.388a2.656 2.656 0 0 1 2.653 2.652 2.658 2.658 0 0 1-2.653 2.655Zm-3.386-1h3.389c.912 0 1.653-.741 1.653-1.653 0-.911-.742-1.653-1.653-1.653h-3.39v3.306Z"
        />
        <path
            fill="#000"
            d="M14.04 17.914H8.908V12.61h5.132a2.654 2.654 0 0 1 2.653 2.653 2.654 2.654 0 0 1-2.653 2.652Zm-4.13-1h4.133c.911 0 1.653-.74 1.653-1.652 0-.912-.742-1.653-1.653-1.653H9.91v3.306Z"
        />
        <path
            fill="#000"
            d="M10.921 16.915H7.606v.999h3.316v-1ZM10.921 8.301H7.606v1h3.316v-1Z"
        />
        <path
            fill="#000"
            d="M11.22 17.413h-1v2.11h1v-2.11ZM12.929 17.413h-1v2.11h1v-2.11ZM11.22 6.692h-1v2.11h1v-2.11ZM12.929 6.692h-1v2.11h1v-2.11Z"
        />
        <path
            fill="#000"
            d="M11.999 22.666c-5.38 0-9.759-4.378-9.759-9.758 0-5.38 4.379-9.759 9.759-9.759 5.38 0 9.758 4.379 9.758 9.759 0 5.38-4.378 9.758-9.758 9.758Zm0-18.517c-4.83 0-8.76 3.928-8.76 8.759 0 4.83 3.93 8.76 8.76 8.76s8.76-3.93 8.76-8.76-3.932-8.76-8.76-8.76Z"
        />
        <path
            fill="#000"
            d="M12.597 1.409h-1.199V3.65h1.199V1.41ZM12.597 21.993h-1.199v2.241h1.199v-2.241ZM23.413 12.22h-2.241v1.198h2.241v-1.199ZM2.83 12.22H.587v1.198h2.241v-1.199ZM18.902 18.88l-.847.848 1.585 1.584.847-.847-1.585-1.585ZM4.346 4.324l-.848.847 1.585 1.585.847-.847-1.584-1.585ZM19.645 4.327l-1.584 1.585.847.848 1.585-1.585-.848-.848ZM5.089 18.882l-1.585 1.585.848.848 1.584-1.585-.847-.848Z"
        />
    </svg>
)
export default BitCoinTokenSVG;
