import * as React from "react";

const PlayIconSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill="#fff"
            d="m16.53 11.152-8-5A.998.998 0 0 0 7 7v10a1 1 0 0 0 1.53.848l8-5a1 1 0 0 0 0-1.696Z"
        />
    </svg>
)
export default PlayIconSVG;
