import { Box, Typography } from '@mui/material';
import React from 'react';
import Marquee from 'react-fast-marquee';

const BaljunaMarquee = ({ borderColor = 'secondary.main', color = 'secondary' }) => (

    <Box sx={{
        borderColor,
        borderTopWidth: '0.75px',
        borderTopStyle: 'solid',
        paddingTop: {
            lg: '18px',
            md: '14px',
            sm: '12px',
            xs: '12px'
        },
        paddingBottom: {
            lg: '18px',
            md: '14px',
            sm: '12px',
            xs: '12px'
        }
    }}>
        <Marquee>
            <Typography color={color} variant='marquee'>
                PRINCIPAL-PROTECTED • FULL TRANSPARENCY • NO CREDIT RISK • PROFIT SHARING • DEVELOPED BY BEST BANKERS OF ASIA • INSTANT TRADING • SECURED ACCESS TO YOUR ACCOUNT • UUKHAI!! •&nbsp;
            </Typography>
        </Marquee>
    </Box>

);

export default BaljunaMarquee;