import { Box } from '@mui/material';
import React from 'react';
import Banner from './Banner/Banner';
import Overview from './Overview/Overview';
import CoreValues from './CoreValues/CoreValues';
import OpenEcoSystem from './OpenEcoSystem/OpenEcoSystem';

const About = () => {

    return (
        <Box component='main' sx={{ backgroundColor: 'common.black' }}>
            <Banner />
            <Overview />
            <CoreValues />
            <OpenEcoSystem />
        </Box>
    )
}

export default About;