import * as React from "react"
const DashboardIconSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            stroke="#FF4C00"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M2 10.964h8.687V22H2zM22 13.035h-8.687V2H22zM2 2h8.687v6.289H2zM22 22h-8.687v-6.289H22z"
        />
    </svg>
)
export default DashboardIconSVG;
