import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import { ThemeProvider } from "@emotion/react";
import CustomTheme from "./theme/CustomTheme";
import ScrollToTop from "./utils/ScrollToTop";
import RoadMap from "./pages/RoadMap/RoadMap";
import Team from "./pages/Team/Team";
import Public from "./layout/Public/Public";
import Auth from "./layout/Auth/Auth";
import Registration from "./pages/Auth/Registration/Registration";
import Login from "./pages/Auth/Login/Login";
import Welcome from "./pages/Welcome/Welcome";
import NotFound from "./pages/NotFound/NotFound";
import Referral from "./pages/Referral/Referral";
import Admin from "./layout/Admin/Admin";
import Dashboard from "./pages/Admin/Dashboard/Dashboard";
import BPSRewards from "./pages/Admin/BPSRewards/BPSRewards";
import DualCurrency from "./pages/Admin/DualCurrency/DualCurrency";
import DualCurrencyWrapper from "./pages/Admin/DualCurrency/DualCurrencyWrapper";
import DualCurrencyNew from "./pages/Admin/DualCurrency/DualCurrencyNew";
import ArbYieldVault from "./pages/Admin/ArbYieldVault/ArbYieldVault";
import MyTransaction from "./pages/Admin/MyTransaction/MyTransaction";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import { setGlobalAlert } from "./common";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { useEffect, useState } from "react";
const App = () => {
  const [msg, setMsg] = useState('');
  const [level, setLevel] = useState('success');
  useEffect(() => {
    setGlobalAlert((level,msg) => {
      setLevel(level);
      setMsg(msg);
    });
    return () => {
      setGlobalAlert(null);
    }
  },[]);
  return (
    <ThemeProvider theme={CustomTheme}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Public />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="roadmap" element={<RoadMap />} />
            <Route path="team" element={<Team />} />
          </Route>

          <Route path="/signup" element={<Registration />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          <Route path="welcome" element={<Welcome />} />
          <Route path="referral" element={<Referral />} />

          <Route path="/admin" element={<Admin />}>
            <Route index element={<Dashboard />} />
            <Route path="dashboard/my-transaction" element={<MyTransaction />} />
            <Route path="bps-rewards" element={<BPSRewards />} />
            <Route path="dual-currency" element={<DualCurrencyWrapper />} />
            <Route path="dci" element={<DualCurrency />} />
            <Route path="dci2" element={<DualCurrencyNew />} />
            <Route path="arb-yield-vault" element={<ArbYieldVault />} />
          </Route>
          <Route path="coming-soon" element={<ComingSoon />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <Snackbar
        open={msg !== ''}
        autoHideDuration={6000}
        onClose={() => setMsg('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" severity={level} onClose={() => setMsg('')}>{msg}</MuiAlert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default App;
