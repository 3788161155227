import React from 'react';

const BarCodeVertical2SmallSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="173" viewBox="0 0 17 173" fill="none">
        <line x1="16.0254" y1="1.10574" x2="-7.97394" y2="1.10574" stroke="black" strokeWidth="2.21148" />
        <line x1="-7.97363" y1="171.122" x2="16.0257" y2="171.122" stroke="black" strokeWidth="2.21148" />
        <line x1="16.0254" y1="46.0335" x2="-7.97394" y2="46.0335" stroke="black" strokeWidth="2.21148" />
        <line x1="-7.97363" y1="126.192" x2="16.0257" y2="126.192" stroke="black" strokeWidth="2.21148" />
        <line x1="16.0254" y1="7.85735" x2="-7.97394" y2="7.85735" stroke="black" strokeWidth="0.737159" />
        <line x1="-7.97363" y1="164.37" x2="16.0257" y2="164.37" stroke="black" strokeWidth="0.737159" />
        <line x1="16.0254" y1="52.7851" x2="-7.97394" y2="52.7851" stroke="black" strokeWidth="0.737159" />
        <line x1="-7.97363" y1="119.44" x2="16.0257" y2="119.44" stroke="black" strokeWidth="0.737159" />
        <line x1="16.0254" y1="30.3217" x2="-7.97394" y2="30.3217" stroke="black" strokeWidth="0.737159" />
        <line x1="-7.97363" y1="141.905" x2="16.0257" y2="141.905" stroke="black" strokeWidth="0.737159" />
        <line x1="16.0254" y1="75.2494" x2="-7.97394" y2="75.2494" stroke="black" strokeWidth="0.737159" />
        <line x1="-7.97363" y1="96.9771" x2="16.0257" y2="96.9771" stroke="black" strokeWidth="0.737159" />
        <line x1="16.0254" y1="37.808" x2="-7.97394" y2="37.808" stroke="black" strokeWidth="0.737159" />
        <line x1="-7.97363" y1="134.417" x2="16.0257" y2="134.417" stroke="black" strokeWidth="0.737159" />
        <line x1="16.0254" y1="82.7382" x2="-7.97394" y2="82.7382" stroke="black" strokeWidth="0.737159" />
        <line x1="-7.97363" y1="89.4884" x2="16.0257" y2="89.4884" stroke="black" strokeWidth="0.737159" />
        <line x1="16.0254" y1="18.2943" x2="-7.97394" y2="18.2943" stroke="black" strokeWidth="6.63443" />
        <line x1="-7.97363" y1="153.933" x2="16.0257" y2="153.933" stroke="black" strokeWidth="6.63443" />
        <line x1="16.0254" y1="63.222" x2="-7.97394" y2="63.222" stroke="black" strokeWidth="6.63443" />
        <line x1="-7.97363" y1="110.11" x2="16.0257" y2="110.11" stroke="black" strokeWidth="4.42296" />
        <line x1="16.0254" y1="23.5706" x2="-7.97394" y2="23.5706" stroke="black" strokeWidth="2.21148" />
        <line x1="-7.97363" y1="148.656" x2="16.0257" y2="148.656" stroke="black" strokeWidth="2.21148" />
        <line x1="16.0254" y1="68.4978" x2="-7.97394" y2="68.4978" stroke="black" strokeWidth="2.21148" />
        <line x1="-7.97363" y1="103.729" x2="16.0257" y2="103.729" stroke="black" strokeWidth="2.21148" />
    </svg>
);

export default BarCodeVertical2SmallSVG;