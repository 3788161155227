import * as React from "react"
const TermsIconSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={24}
        fill="none"
        {...props}
    >
        <g
            stroke="#FF4C00"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
        >
            <path d="M17 5H1v18h16V5Z" />
            <path d="M4 1h17v20M5 10h8M5 14h8M5 18h4" />
        </g>
    </svg>
)
export default TermsIconSVG
