import React from 'react';
import { Outlet } from "react-router-dom";
import Header from './Header/Header';
import FooterMarquee from './FooterMarquee/FooterMarquee';
import Footer from './Footer/Footer';

const Public = () => (
    <>
        <Header />
        <Outlet />
        <FooterMarquee />
        <Footer />
    </>
);

export default Public;