
import React from 'react';

const Token2SVG = ({ width = 88, height = 89 }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 88 89" fill="none">
        <path d="M44 88.7549C19.6881 88.7549 -2.10792e-07 68.9922 -1.35921e-07 44.7549C-6.10491e-08 20.5176 19.7627 0.754883 44 0.754883C68.2373 0.754883 88 20.5176 88 44.7549C88 68.9922 68.3119 88.7549 44 88.7549ZM44 2.91759C20.9559 2.91759 2.23729 21.6362 2.23729 44.6803C2.23729 67.7244 20.9559 86.443 44 86.443C67.0441 86.443 85.7627 67.7244 85.7627 44.6803C85.7627 21.6362 67.0441 2.91759 44 2.91759Z" fill="#FCF5E8" />
        <path d="M39.897 55.2706C31.4699 55.2706 24.6089 48.4096 24.6089 39.9825C24.6089 31.5554 31.4699 24.6943 39.897 24.6943C48.3241 24.6943 55.1852 31.5554 55.1852 39.9825C55.1852 48.4096 48.3241 55.2706 39.897 55.2706ZM39.897 26.5587C32.514 26.5587 26.4733 32.5994 26.4733 39.9825C26.4733 47.3655 32.514 53.4062 39.897 53.4062C47.2801 53.4062 53.3208 47.3655 53.3208 39.9825C53.3208 32.5994 47.3547 26.5587 39.897 26.5587Z" fill="#FCF5E8" />
        <path d="M46.7559 65.7115C38.3288 65.7115 31.4678 58.8505 31.4678 50.4234C31.4678 41.9963 38.3288 35.1353 46.7559 35.1353C55.183 35.1353 62.044 41.9963 62.044 50.4234C62.044 58.8505 55.183 65.7115 46.7559 65.7115ZM46.7559 36.9251C39.3729 36.9251 33.3322 42.9658 33.3322 50.3488C33.3322 57.7319 39.3729 63.7725 46.7559 63.7725C54.139 63.7725 60.1796 57.7319 60.1796 50.3488C60.1796 42.9658 54.139 36.9251 46.7559 36.9251Z" fill="#FCF5E8" />
        <path d="M46.7561 13.2839L40.5662 9.70423C40.3425 9.55507 40.1934 9.33135 40.1934 9.10762C40.1934 8.88389 40.3425 8.58558 40.5662 8.43643L46.7561 4.48389L47.5764 5.75168L42.4306 9.03304L47.5018 11.9415L46.7561 13.2839Z" fill="#FCF5E8" />
        <path d="M56.3029 15.7445L51.53 10.4496C51.3808 10.2259 51.3062 10.0021 51.3808 9.70382C51.4554 9.40552 51.6791 9.25637 51.9029 9.18179L58.9876 7.31738L59.3605 8.73433L53.4689 10.3004L57.4215 14.7004L56.3029 15.7445Z" fill="#FCF5E8" />
        <path d="M64.5829 20.9653L61.6745 14.4772C61.5999 14.2534 61.5999 13.9551 61.7491 13.7314C61.8982 13.5077 62.1219 13.4331 62.4202 13.4331L69.7287 13.8806L69.6541 15.3721L63.5389 14.9992L65.9253 20.3687L64.5829 20.9653Z" fill="#FCF5E8" />
        <path d="M70.923 28.572L70.1772 21.4872C70.1772 21.2635 70.2518 20.9652 70.4756 20.816C70.6993 20.6669 70.923 20.5923 71.2213 20.7415L78.0078 23.4262L77.4857 24.8432L71.8179 22.6059L72.4145 28.4974L70.923 28.572Z" fill="#FCF5E8" />
        <path d="M75.9915 38.0417L74.5 37.7434L75.9915 30.8078C76.0661 30.5841 76.2153 30.3604 76.439 30.2858C76.6627 30.2112 76.961 30.2112 77.1848 30.435L82.8525 35.0587L81.883 36.1773L77.1848 32.2994L75.9915 38.0417Z" fill="#FCF5E8" />
        <path d="M82.6998 48.3337L79.4185 43.188L76.51 48.2592L75.2422 47.5134L78.8218 41.3236C78.971 41.0998 79.1947 40.9507 79.4185 40.9507C79.7168 40.9507 79.9405 41.0998 80.0896 41.3236L84.0422 47.5134L82.6998 48.3337Z" fill="#FCF5E8" />
        <path d="M79.6455 60.1178L78.0794 54.2263L73.6794 58.1788L72.71 57.0602L78.0049 52.2873C78.2286 52.1381 78.4523 52.0636 78.7506 52.1381C78.9743 52.2127 79.1981 52.4364 79.2727 52.6602L81.1371 59.7449L79.6455 60.1178Z" fill="#FCF5E8" />
        <path d="M74.6507 70.5573L73.1591 70.4827L73.532 64.3675L68.1625 66.7539L67.5659 65.4116L74.0541 62.5031C74.2778 62.4285 74.5761 62.4285 74.7998 62.5777C75.0236 62.7268 75.1727 62.9505 75.0981 63.2488L74.6507 70.5573Z" fill="#FCF5E8" />
        <path d="M65.1066 78.8359L63.6896 78.3138L65.9269 72.6461L60.0354 73.2427L59.8862 71.7511L66.971 71.0054C67.1947 71.0054 67.493 71.0799 67.6422 71.3037C67.7913 71.5274 67.8659 71.7511 67.7167 72.0494L65.1066 78.8359Z" fill="#FCF5E8" />
        <path d="M53.5441 83.6841L52.4254 82.7146L56.3034 78.0163L50.561 76.8231L50.8593 75.3315L57.7949 76.8231C58.0187 76.8976 58.2424 77.0468 58.317 77.2705C58.3915 77.4943 58.3916 77.7926 58.1678 78.0163L53.5441 83.6841Z" fill="#FCF5E8" />
        <path d="M41.0137 84.8026L40.1934 83.5348L45.3391 80.2534L40.2679 77.3449L41.0137 76.0771L47.2035 79.6568C47.4273 79.806 47.5764 80.0297 47.5764 80.2534C47.5764 80.4771 47.4273 80.7755 47.2035 80.9246L41.0137 84.8026Z" fill="#FCF5E8" />
        <path d="M28.7093 81.9683L28.3364 80.5513L34.228 78.9852L30.2754 74.5852L31.3941 73.6157L36.1669 78.9106C36.3161 79.1344 36.3907 79.3581 36.3161 79.6564C36.2415 79.9547 36.0178 80.1039 35.7941 80.1784L28.7093 81.9683Z" fill="#FCF5E8" />
        <path d="M25.2795 75.8535L17.8965 75.406L17.9711 73.9145L24.0863 74.2874L21.6999 68.9179L23.0423 68.3213L25.9507 74.8094C26.0253 75.0332 26.0253 75.3315 25.8761 75.5552C25.8016 75.7789 25.5033 75.8535 25.2795 75.8535Z" fill="#FCF5E8" />
        <path d="M16.7776 68.6935C16.703 68.6935 16.6285 68.6935 16.4793 68.619L9.69287 65.9342L10.2149 64.5173L15.8827 66.7545L15.2861 60.863L16.7776 60.7139L17.5234 67.7986C17.5234 68.0223 17.4488 68.3206 17.2251 68.4698C17.0759 68.6189 16.9268 68.6935 16.7776 68.6935Z" fill="#FCF5E8" />
        <path d="M10.8848 59.1475C10.7356 59.1475 10.5119 59.073 10.4373 58.9984L4.76953 54.3747L5.73901 53.256L10.4373 57.134L11.6305 51.3916L13.1221 51.6899L11.6305 58.6255C11.556 58.8492 11.4068 59.073 11.1831 59.1475C11.1085 59.1475 11.0339 59.1475 10.8848 59.1475Z" fill="#FCF5E8" />
        <path d="M8.2767 48.3337C8.05297 48.3337 7.75466 48.1846 7.68008 47.9609L3.72754 41.771L4.99533 40.9507L8.2767 46.0964L11.1852 41.0253L12.453 41.771L8.87331 47.9609C8.79873 48.1846 8.575 48.3337 8.2767 48.3337Z" fill="#FCF5E8" />
        <path d="M9.09601 37.1476C9.02143 37.1476 8.94687 37.1476 8.87229 37.1476C8.64856 37.0731 8.42482 36.8493 8.35025 36.6256L6.48584 29.5409L7.9028 29.168L9.46889 35.0595L13.8689 31.107L14.8384 32.2256L9.54347 36.9985C9.4689 37.1476 9.31974 37.1476 9.09601 37.1476Z" fill="#FCF5E8" />
        <path d="M13.3472 26.8568C13.198 26.8568 13.0489 26.7823 12.8997 26.7077C12.676 26.5585 12.5269 26.3348 12.6014 26.0365L13.0489 18.728L14.5404 18.8026L14.1675 24.9179L19.537 22.5314L20.1336 23.8738L13.6455 26.7823C13.5709 26.8568 13.4218 26.8568 13.3472 26.8568Z" fill="#FCF5E8" />
        <path d="M20.5811 18.3544C20.3574 18.3544 20.1336 18.2799 19.9845 18.0561C19.8353 17.8324 19.7608 17.6087 19.9099 17.3104L22.5947 10.5239L24.0116 11.046L21.7743 16.7138L27.6659 16.1171L27.815 17.6087L20.7303 18.3544C20.6557 18.3544 20.5811 18.3544 20.5811 18.3544Z" fill="#FCF5E8" />
        <path d="M36.9143 13.9556L29.9787 12.464C29.755 12.3895 29.5313 12.2403 29.4567 12.0166C29.3821 11.7929 29.3821 11.4946 29.6059 11.2708L34.2296 5.60303L35.3482 6.57252L31.4703 11.2708L37.2127 12.464L36.9143 13.9556Z" fill="#FCF5E8" />
        <path d="M46.7561 13.2839L40.5662 9.70423C40.3425 9.55507 40.1934 9.33135 40.1934 9.10762C40.1934 8.88389 40.3425 8.58558 40.5662 8.43643L46.7561 4.48389L47.5764 5.75168L42.4306 9.03304L47.5018 11.9415L46.7561 13.2839Z" fill="#FCF5E8" />
        <path d="M43.9999 72.8709C28.488 72.8709 15.8101 60.2675 15.8101 44.681C15.8101 29.1692 28.4135 16.4912 43.9999 16.4912C59.5118 16.4912 72.1897 29.0946 72.1897 44.681C72.1897 60.2675 59.5863 72.8709 43.9999 72.8709ZM43.9999 18.8031C29.6812 18.8031 18.0473 30.437 18.0473 44.7556C18.0473 59.0743 29.6812 70.7081 43.9999 70.7081C58.3185 70.7081 69.9524 59.0743 69.9524 44.7556C69.9524 30.437 58.3185 18.8031 43.9999 18.8031Z" fill="#FCF5E8" />
    </svg>
);

export default Token2SVG;