
import React from 'react';

const Token5SVG = ({ width = 88, height = 88 }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 88 88" fill="none">
        <path d="M44 88.7549C19.6881 88.7549 -2.10792e-07 68.9922 -1.35921e-07 44.7549C-6.10491e-08 20.5176 19.7627 0.754883 44 0.754883C68.2373 0.754883 88 20.5176 88 44.7549C88 68.9922 68.3119 88.7549 44 88.7549ZM44 2.91759C20.9559 2.91759 2.23729 21.6362 2.23729 44.6803C2.23729 67.7244 20.9559 86.443 44 86.443C67.0441 86.443 85.7627 67.7244 85.7627 44.6803C85.7627 21.6362 67.0441 2.91759 44 2.91759Z" fill="#FCF5E8" />
        <path d="M43.9999 51.5428C32.5898 51.5428 23.3423 42.2953 23.3423 30.8852C23.3423 19.475 32.5898 10.2275 43.9999 10.2275C55.4101 10.2275 64.6576 19.475 64.6576 30.8852C64.6576 42.2953 55.4101 51.5428 43.9999 51.5428ZM43.9999 12.3903C33.8575 12.3903 25.5796 20.6682 25.5796 30.8106C25.5796 40.953 33.8575 49.2309 43.9999 49.2309C54.1423 49.2309 62.4203 40.953 62.4203 30.8106C62.4203 20.6682 54.2169 12.3903 43.9999 12.3903Z" fill="#FCF5E8" />
        <path d="M43.9994 78.763C32.5893 78.763 23.3418 69.5155 23.3418 58.1054C23.3418 46.6952 32.5893 37.4478 43.9994 37.4478C55.4096 37.4478 64.6571 46.6952 64.6571 58.1054C64.6571 69.5155 55.4096 78.763 43.9994 78.763ZM43.9994 39.6105C33.8571 39.6105 25.5791 47.8884 25.5791 58.0308C25.5791 68.1732 33.8571 76.4511 43.9994 76.4511C54.1418 76.4511 62.4198 68.1732 62.4198 58.0308C62.4198 47.8884 54.2164 39.6105 43.9994 39.6105Z" fill="#FCF5E8" />
        <path d="M30.4236 65.1155C19.0134 65.1155 9.69141 55.8681 9.69141 44.4579C9.69141 33.0478 19.0134 23.8003 30.4236 23.8003C41.8338 23.8003 51.0812 33.0478 51.0812 44.4579C51.0812 55.8681 41.8338 65.1155 30.4236 65.1155ZM30.4236 26.0376C20.2067 26.0376 11.9287 34.3155 11.9287 44.4579C11.9287 54.6003 20.2067 62.8783 30.349 62.8783C40.4914 62.8783 48.7694 54.6003 48.7694 44.4579C48.7694 34.3155 40.566 26.0376 30.4236 26.0376Z" fill="#FCF5E8" />
        <path d="M57.5702 65.1146C46.1601 65.1146 36.9126 55.8671 36.9126 44.4569C36.9126 33.0468 46.2346 23.7993 57.5702 23.7993C68.9058 23.7993 78.3024 33.0468 78.3024 44.4569C78.3024 55.8671 68.9804 65.1146 57.5702 65.1146ZM57.5702 26.0366C47.4279 26.0366 39.1499 34.3146 39.1499 44.4569C39.1499 54.5993 47.4279 62.8773 57.5702 62.8773C67.7126 62.8773 76.0651 54.5993 76.0651 44.4569C76.0651 34.3146 67.7872 26.0366 57.5702 26.0366Z" fill="#FCF5E8" />
    </svg>
);

export default Token5SVG;