import * as React from "react"
const CurrencyIconSVG = ({ fill = '#FF4C00' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={'100%'}
        height={'100%'}
        viewBox="0 0 18 24"
        fill="none"
    >
        <g fill={fill}>
            <path d="M16.376 12.217a13.464 13.464 0 0 0-2.504-3.103h.642c.362 0 .662-.3.662-.662 0-.362-.3-.662-.662-.662h-1.149l3.466-4.324a.712.712 0 0 0 .082-.766.733.733 0 0 0-.651-.414h-3.145l.486-1.303A.718.718 0 0 0 13.52.32a.71.71 0 0 0-.6-.321h-8.1a.738.738 0 0 0-.62.352.737.737 0 0 0-.021.703l.631 1.22H1.48a.715.715 0 0 0-.653.415.735.735 0 0 0 .093.765L4.386 7.78H2.731c-.362 0-.662.3-.662.662 0 .362.3.662.662.662h1.148a13.298 13.298 0 0 0-2.41 3C-.031 14.66-.393 17.69.455 20.421c.827 2.658 2.276 3.32 3.352 3.393 1.665.124 3.424.186 5.182.186 1.728 0 3.456-.062 5.1-.176 1.076-.083 2.535-.734 3.352-3.414.807-2.69.424-5.679-1.065-8.193ZM5.555 3.724l1.2 2.328a.723.723 0 0 0 1.283-.662L6.01 1.448h5.866l-1.51 4.024a.723.723 0 0 0 .423.931.714.714 0 0 0 .259.042.73.73 0 0 0 .683-.476l.838-2.245h2.182l-3.196 3.993h-5.38L2.99 3.724h2.566Zm10.49 16.252c-.466 1.52-1.16 2.327-2.07 2.39a69.566 69.566 0 0 1-10.065-.01c-.91-.073-1.603-.87-2.08-2.38-.734-2.338-.403-4.945.89-7.159A11.854 11.854 0 0 1 5.927 9.26l.145-.104h5.597l.279.207a12.235 12.235 0 0 1 3.186 3.569c1.273 2.193 1.604 4.748.91 7.045Z" />
            <path d="M8.877 11.814a4.362 4.362 0 0 0-4.356 4.355c0 2.4 1.956 4.355 4.356 4.355s4.355-1.955 4.355-4.355a4.355 4.355 0 0 0-4.355-4.355Zm0 7.386a3.035 3.035 0 0 1-3.031-3.031 3.035 3.035 0 0 1 3.03-3.031 3.029 3.029 0 0 1 3.032 3.03A3.035 3.035 0 0 1 8.877 19.2Z" />
        </g>
    </svg>
)
export default CurrencyIconSVG
