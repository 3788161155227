import { Box, Button, Popover, Typography,Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ToolTipInfoSVG from '../../../components/SVGs/ToolTipInfoSVG';
import PlayIconSVG from '../../../components/SVGs/PlayIconSVG';
import SmallBarCodeBlackSVG from '../../../components/SVGs/SmallBarCodeBlackSVG';
import SolanaTokenSVG from '../../../components/SVGs/SolanaTokenSVG';
import BitCoinToken40SVG from '../../../components/SVGs/BitCoinToken40SVG';
import ETHToken40SVG from '../../../components/SVGs/ETHToken40SVG';
import DipIconSVG from '../../../components/SVGs/DipIconSVG';
import DipRocketIconSVG from '../../../components/SVGs/DipRocketIconSVG';
import DropdownIconSVG from '../../../components/SVGs/DropdownIconSVG';
import BuyTheDipModal from '../../../components/BuyTheDipModal/BuyTheDipModal';
import SellTheRipModal from '../../../components/SellTheRipModal/SellTheRipModal';
import SubscribeSuccessModal from '../../../components/SubscribeSuccessModal/SubscribeSuccessModal';
import DualCurrencyReminderModal from '../../../components/DualCurrencyReminderModal/DualCurrencyReminderModal';
import { UserContext,formatNumber,setConnection,subscribeWS,tokenMapping } from '../../../common';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useEthers } from '@usedapp/core';
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
//fixme: Select a token to trade: should be clickable, and highlight active token

const Item = ({ onClick,dciItem }) => (
    <Box sx={{ border: '1px solid #FF7033', paddingLeft: '24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px', minWidth: '700px' }}>
        <Box sx={{ minWidth: '40px' }}>
            {tokenMapping[dciItem?.base_ccy]?.icon}
        </Box>

        <Box sx={{ width: '150px' }}>
            <Typography variant='roboto16Bold'>
                {dciItem.strike}
            </Typography>
        </Box>
        <Box sx={{ width: '170px' }}>
            <Box sx={{ border: '1px solid #037A41', padding: '0px 8px', height: 'fit-content', width: 'fit-content  ' }}>
                <Typography variant='inter16Bold' color='#037A41'>
                    {dciItem.apy} APY
                </Typography>
            </Box>
        </Box>
        <Box sx={{ width: '250.234px' }}>
            <Typography variant='inter16' color='#343434'>
                {dayjs.unix(dciItem.expiry).format('DD-MMM-YY')}  <Typography variant='inter16' color='#808080'> ({dayjs.unix(dciItem.expiry).fromNow()}) </Typography>
            </Typography>
        </Box>
        <Box sx={{ padding: '20px 24px', borderLeftWidth: '1px', borderLeftColor: '#FF7033', borderLeftStyle: 'dashed' }}>
            <Button variant='contained' color='black' onClick={()=>onClick(dciItem)}>
                <Typography variant='inter14Bold'>
                    SUBSCRIBE
                </Typography>
            </Button>
        </Box>
    </Box>
);

const BUY = 'BUY';
const SELL = 'SELL';
const CALL_PUT_MAP={
    'BUY':'PUT',
    'SELL':'CALL'
}
const formatYield = (yieldRate) => {
    if (!isNaN(yieldRate)) {
      return (yieldRate * 100).toFixed(0) + '%';
    }
    return yieldRate;
  };

const BITCOIN = 'BTC';
const ETHEREUM = 'ETH';
const SOLANA = 'SOLANA';
const showReminder=(new Date().getTime()/1000-7*24*3600)>Number(localStorage.getItem('lastReminderTS')||'0');
const DualCurrency = () => {
    const user=React.useContext(UserContext);
    const {ttv,chainConfig}=user;

    const [dciList,setDciList]=useState([]);
    const [filters,setFilters]=useState([{
        label:'All Settlement Dates',
        from: 0,
        until: Infinity
    }]);
    const [selectedFilter,setSelectedFilter]=useState({
        label:'All Settlement Dates',
        from: 0,
        until: Infinity
    });
    const {account}=useEthers();
    const [liveRate,setLiveRate]=useState({});
    const [openVideo, setOpenVideo] = React.useState(false);
    const url='https://www.youtube.com/embed/cTvcRU776Ho?&autoplay=1';
    const title='Baljuna Waters - Dual Yield Currency'
    
    useEffect(()=>{
        const startOfNextWeek=dayjs().add(1,'week').set('day',0).set('hour',0).set('minute',0).set('second',0).set('millisecond',0);
        const startOfNextMonth=dayjs().add(1,'month').set('date',1).set('hour',0).set('minute',0).set('second',0).set('millisecond',0);
        const startOfNext2Month=dayjs().add(2,'month').set('date',1).set('hour',0).set('minute',0).set('second',0).set('millisecond',0);
        const startOfNext6Month=dayjs().add(6,'month').set('date',1).set('hour',0).set('minute',0).set('second',0).set('millisecond',0);
        setFilters([
            {
                label:'All Settlement Dates',
                from: 0,
                until: Infinity
            },
            {
                label:'This Week',
                from: 0,
                until: startOfNextWeek.unix()
            },
            {
                label:'This Month',
                from: Math.min(startOfNextMonth.unix(),startOfNextWeek.unix()),
                until: startOfNextMonth.unix()
            },
            {
                label:'Next Month',
                from: startOfNextMonth.unix(),
                until: startOfNext2Month.unix()
            },
            {
                label:'In 6 Months',
                from: startOfNext2Month.unix(),
                until: startOfNext6Month.unix()
            }
        ])
        const unsubs=[];
        const getDciList=async()=>{
            const config = await fetch('/instrument_list');
            const list = await config.json();
            setDciList(list);
            list.forEach(dciItem => {
                unsubs.push(subscribeWS(dciItem.instrument, (data) => {
                    dciItem.apy = formatYield(data.yield_rate);
                    setDciList([...list]);
                }));
            });
            const liveRate={};
            ['BTC','ETH','SOL'].forEach(tokenName=>{
                unsubs.push(subscribeWS(`${tokenName}-PERPETUAL`, (data) => {
                    liveRate[tokenName]=data.price;
                    setLiveRate({...liveRate});
                }))
            });
        }
        getDciList();
        setConnection(true);

        return ()=>{
            unsubs.forEach(unsub=>unsub());
            setConnection(false);
        }
    },[]);
    const [dciItem, setDciItem] = useState(null);
    const navigate = useNavigate();
    const [buyModal, setBuyModal] = useState(false);
    const toggleBuyModal = () => setBuyModal(!buyModal);

    const [sellModal, setSellModal] = useState(false);
    const toggleSellModal = () => setSellModal(!sellModal);

    const [successModal, setSuccessModal] = useState(false);
    const [subscribeData, setSubscribeData] = useState({});
    const toggleSuccessModal = () => setSuccessModal(!successModal);

    const [iWantTo, setIWantTo] = useState(BUY);

    const [showCurrencyReminderModal, setShowCurrencyReminderModal] = useState(showReminder);

    const subscribe = (dciItem) => {
        if (iWantTo === BUY) setBuyModal(true);
        if (iWantTo === SELL) setSellModal(true);
        setDciItem(dciItem);
    }

    const success = (dciItem,amount,yield_rate) => {
        setBuyModal(false);
        setSellModal(false);
        setSuccessModal(true);
        setSubscribeData({dciItem,amount,yield_rate});
    };

    const [filterEl, setFilterEl] = React.useState(null);

    const handleFilterClick = (event) => {
        setFilterEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setFilterEl(null);
    };

    const openFilter = Boolean(filterEl);
    const open = Boolean(filterEl);
    const filterId = open ? 'simple-popover' : undefined;
    const [selectedToken, setSelectedToken] = useState(BITCOIN);
    const dciListSelected=dciList.filter(dciItem=>dciItem.call_put === CALL_PUT_MAP[iWantTo])
        .filter(dciItem=>dciItem.expiry>=selectedFilter.from&&dciItem.expiry<selectedFilter.until)
        .filter(dciItem=>dciItem.base_ccy===selectedToken);
    const [pageNo,setPageNo]=useState(1);
    const start=(pageNo-1)*10;
    const end=Math.max(0,Math.min(pageNo*10,dciListSelected.length));
    const total=dciListSelected.length;
    const listToDisplay=dciListSelected.slice(start,end);
    const [tempPagination, setTempPagination] = useState(false);

    

    return (
        <>
            {user?.chainConfig?.rewardTokenAddress && <Box sx={{ padding: '16px 24px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '24px', borderBottomWidth: '1px', borderBottomColor: '#000', borderBottomStyle: 'solid' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Typography variant='inter16'>
                        BPS Rewards: <Typography variant='inter16Bold'>{formatNumber(user?.balances?.BPS,2)} BPS</Typography>
                    </Typography>
                    <ToolTipInfoSVG />
                </Box>
                <Button variant='outlined' color='black' sx={{ padding: '6px 12px' }} onClick={() => navigate('/admin/bps-rewards')}>
                    <Typography variant='inter14Bold'>
                        MANAGE
                    </Typography>
                </Button>
            </Box>
            }
            <Box sx={{ padding: '24px' }}>
                <Box sx={{ padding: '24px', backgroundColor: 'common.black', display: 'flex', gap: '24px', alignItems: 'center', flexDirection: { sm: 'row', xs: 'column' }, marginBottom: '24px' }}>
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ marginBottom: '8px' }}>
                            <Typography variant='GoshanSans24Bold' color='secondary.main'>
                                DUAL CURRENCY
                            </Typography>
                        </Box>
                        <Box sx={{ marginBottom: '16px' }}>
                            <Typography variant='inter16' color='#CCCCCC'>
                                Earn steady yield whichever way the market goes.
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '8px' }}>
                            <Box sx={{ backgroundColor: '#D9D9D9', width: '24px', height: '24px', borderRadius: '24px' }} />
                            <Typography variant='inter14' color='#CCCCCC'>
                                Earn up to 25% APY. No trading fees.
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ flex: 1, display: 'flex', gap: '24px', flexDirection: { lg: 'row', xs: 'column' } }}>
                        <Box sx={{ flex: 1, backgroundColor: '#1A1A1A', padding: '24px' }}>
                            <Box sx={{ marginBottom: '8px' }}>
                                <Typography variant='roboto14Bold' color='#CCCCCC'>
                                    TOTAL VOLUME TRADED
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='roboto32' color='primary.main'>
                                    {ttv}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ flex: 1, backgroundColor: '#1A1A1A', padding: '24px' }}>
                            <Box sx={{ marginBottom: '8px' }}>
                                <Typography variant='inter16Bold' color='primary.main'>
                                    Don’t know how to start?
                                </Typography>
                            </Box>
                            <Box sx={{ marginBottom: '18px' }}>
                                <Typography variant='inter12' color='common.white'>
                                    Watch our tutorial video to help you get started.
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '8px', minWidth: '220px' }}>
                                <Button  onClick={()=>setOpenVideo(true)} size='small'>
                                <PlayIconSVG />
                                <Box>
                                    <Typography variant='inter14Bold' color='common.white'>
                                        WATCH TUTORIAL
                                    </Typography>

                                    <Typography variant='inter14' color='common.white' sx={{ fontStyle: 'italic' }}>
                                        &nbsp; (3 mins)
                                    </Typography>
                                    <Modal open={openVideo} onClose={()=>setOpenVideo(false)}>
                                        <div className='video-container' onClick={()=>setOpenVideo(false)}>
                                            <iframe width="800" height="560" src={url} title={title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen autoplay></iframe>
                                        </div>
                                    </Modal>
                                </Box>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ marginBottom: '16px' }}>
                    <Typography variant='roboto14'>
                        Select a token to trade:
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '24px', flexDirection: { lg: 'row', xs: 'column' } }}>
                    <Box onClick={() => setSelectedToken(BITCOIN)} sx={{ padding: '20px 16px', border: selectedToken === BITCOIN ? '2px solid #FF4C00' : '1px solid #B0A592', display: 'flex', alignItems: 'flex-start', gap: '12px', position: 'relative', cursor: 'pointer' }}>
                        <Box sx={{ position: 'absolute', bottom: '0px', right: '5%', height: '15px' }}>
                            <SmallBarCodeBlackSVG />
                        </Box>
                        <Box sx={{ minWidth: '40px' }}>
                            <BitCoinToken40SVG color={selectedToken === BITCOIN ? '#FF4C00' : '#000'} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                            <Box sx={{ display: 'flex', gap: '8px' }}>
                                <Typography variant='inter16SemiBold' color={selectedToken==='BTC'?'white':'black'}>
                                    Bitcoin
                                </Typography>
                            </Box>
                            <Typography variant='inter14' color={selectedToken==='BTC'?'white':'black'}>
                                ${formatNumber(liveRate['BTC'],0)}
                            </Typography>
                        </Box>

                        <Box sx={{ border: selectedToken==='BTC'?'1px solid white':'1px solid #037A41', padding: '0px 8px' }}>
                            <Typography variant='inter14Bold' color={selectedToken==='BTC'?'white':'#037A41'}>
                                Earn 4-25% APY
                            </Typography>
                        </Box>
                    </Box>
                    <Box onClick={() => setSelectedToken(ETHEREUM)} sx={{ padding: '20px 16px', border: selectedToken === ETHEREUM ? '2px solid #FF4C00' : '1px solid #B0A592', display: 'flex', alignItems: 'flex-start', gap: '12px', position: 'relative', cursor: 'pointer' }}>
                        <Box sx={{ position: 'absolute', bottom: '0px', right: '5%', height: '15px' }}>
                            <SmallBarCodeBlackSVG />
                        </Box>
                        <Box sx={{ minWidth: '40px' }}>
                            <ETHToken40SVG color={selectedToken === ETHEREUM ? '#FF4C00' : '#000'} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', gap: '8px' }}>
                                <Typography variant='inter16SemiBold' color={selectedToken==='ETH'?'white':'black'}>
                                    Ethereum
                                </Typography>
                            </Box>
                            <Typography variant='inter14' color={selectedToken==='ETH'?'white':'black'}>
                                ${formatNumber(liveRate['ETH'],2)}
                            </Typography>
                        </Box>

                        <Box sx={{ border: '1px solid #037A41', padding: '0px 8px' }}>
                            <Typography variant='inter14Bold' color='#037A41'>
                                Earn 4-25% APY
                            </Typography>
                        </Box>
                    </Box>
                    {/* <Box onClick={() => setSelectedToken(SOLANA)} sx={{ padding: '20px 16px', border: selectedToken === SOLANA ? '2px solid #FF4C00' : '1px solid #B0A592', display: 'flex', alignItems: 'flex-start', gap: '12px', position: 'relative', cursor: 'pointer' }}>
                        <Box sx={{ position: 'absolute', bottom: '0px', right: '5%', height: '15px' }}>
                            <SmallBarCodeBlackSVG />
                        </Box>
                        <Box sx={{ minWidth: '40px' }}>
                            <SolanaTokenSVG color={selectedToken === SOLANA ? '#FF4C00' : '#000'} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', gap: '8px' }}>
                                <Typography variant='inter16SemiBold'>
                                    Solana
                                </Typography>
                            </Box>
                            <Typography variant='inter14'>
                                $25.21
                            </Typography>
                        </Box>

                        <Box sx={{ border: '1px solid #037A41', padding: '0px 8px' }}>
                            <Typography variant='inter14Bold' color='#037A41'>
                                Earn 4-25% APY
                            </Typography>
                        </Box> */}
                    {/* </Box> */}
                    
                </Box>

                <Box sx={{ marginTop: '24px', paddingTop: '24px', borderTopColor: '#B0A592', borderTopWidth: '1px', borderTopStyle: 'solid', marginBottom: '32px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: { lg: 'row', xs: 'column-reverse', gap: '16px' } }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                            <Typography variant='roboto14'>
                                I want to:
                            </Typography>
                            <Box sx={{ border: '1px solid #B0A592', padding: '8px', display: 'flex', gap: '8px' }}>
                                {iWantTo === BUY && (
                                    <>
                                        <Button variant='contained' color='success' sx={{ display: 'flex', gap: '8px' }} onClick={() => setIWantTo(BUY)}>
                                            <DipIconSVG />
                                            <Typography variant='inter14Bold'>
                                                BUY the DIP!
                                            </Typography>
                                        </Button>
                                        <Button color='black' sx={{ display: 'flex', gap: '8px' }} onClick={() => setIWantTo(SELL)}>
                                            <DipRocketIconSVG />
                                            <Typography variant='inter16Bold' color='common.black'>
                                                SELL the RIP!
                                            </Typography>
                                        </Button>
                                    </>
                                )}
                                {iWantTo === SELL && (
                                    <>
                                        <Button color='black' sx={{ display: 'flex', gap: '8px' }} onClick={() => setIWantTo(BUY)}>
                                            <DipIconSVG fill='#000' />
                                            <Typography variant='inter14Bold' color='common.black'>
                                                BUY the DIP!
                                            </Typography>
                                        </Button>
                                        <Button variant='contained' color='red' sx={{ display: 'flex', gap: '8px', backgroundColor: '#DC3C3C' }} onClick={() => setIWantTo(SELL)}>
                                            <DipRocketIconSVG fill='#fff' />
                                            <Typography variant='inter16Bold'>
                                                SELL the RIP!
                                            </Typography>
                                        </Button>
                                    </>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                            <Typography variant='roboto14'>
                                Settlement Date:
                            </Typography>

                            <Button variant='outlined' color='black' sx={{ display: 'flex', gap: '8px', borderColor: '#B0A592' }} onClick={handleFilterClick} aria-describedby={filterId}>
                                <Typography variant='inter14' color='common.black' sx={{ textTransform: 'none' }}>
                                    {selectedFilter.label}
                                </Typography>
                                <DropdownIconSVG />
                            </Button>
                            <Popover
                                id={filterId}
                                open={openFilter}
                                anchorEl={filterEl}
                                onClose={handleFilterClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >

                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', padding: '16px', border: '1px solid #000', borderRadius: 0 }}>
                                    {
                                        filters.filter(filter=>filter.label!==selectedFilter.label).map((filter) => (<Box key={filter.label} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Button color='black' sx={{ display: 'flex', gap: '8px' }} onClick={()=>{setSelectedFilter(filter);handleFilterClose()}}>
                                            <Typography variant='inter14'>
                                                {filter.label}
                                            </Typography>
                                        </Button>
                                        </Box>))
                                    }
                                </Box>
                            </Popover>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', marginBottom: '24px', justifyContent: 'space-between', flexDirection: { md: 'row', xs: 'column' } }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }} color='black'>
                        <Typography variant='inter14' sx={{ margin: '0px 8px' }}>
                            Showing {start+1}-{end} out of {total} transactions
                        </Typography>
                        <Button size="small" disabled={start===0} onClick={() => {setPageNo(pageNo-1)}} variant='outlined' color='black' sx={{ padding: 0, margin: 0, borderRadius: 0, minWidth: 'fit-content', marginLeft: '8px' }}>
                            <NavigateBeforeIcon size="small" />
                        </Button>
                        <Button size="small" disabled={total<=start+10} onClick={() => {setPageNo(pageNo+1)}} variant='outlined' color='black' sx={{ padding: 0, margin: 0, borderRadius: 0, minWidth: 'fit-content', marginLeft: '8px' }}>
                            <NavigateNextIcon size="small" />
                        </Button>
                    </Box>
                </Box>

                <Box sx={{ border: '1px solid #B0A592', padding: '14px 16px', backgroundColor: '#FCF5E8', marginBottom: '44px' }}>
                    {iWantTo === BUY &&<Typography variant='inter14'>
                        You selected <Typography variant='inter14Bold'>BUY the DIP</Typography>. This means you are spending USDC to purchase your desired future {selectedToken} price.
                    </Typography>}
                    {iWantTo === SELL &&<Typography variant='inter14'>
                        You selected <Typography variant='inter14Bold'>SELL the RIP</Typography>. This means you are selling {selectedToken} for USDC at your desired future {selectedToken} price.
                    </Typography>}
                </Box>
                <Box sx={{
                    overflowX: 'scroll',
                    maxWidth: { lg: '100%', md: 'calc(calc(100vw * 0.72) - 50px)', sm: 'calc(100vw - 50px)', xs: 'calc(100vw - 50px)' },
                }}>
                    <Box sx={{ paddingLeft: '24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', minWidth: '700px' }}>
                        <Box sx={{ minWidth: '40px', visibility: 'hidden' }}>
                            <BitCoinToken40SVG />
                        </Box>
                        <Box sx={{ width: '150px' }}>
                            <Typography variant='roboto14Bold' color='#888071'>
                                TARGET BUY PRICE
                            </Typography>
                        </Box>
                        <Box sx={{ border: '0', padding: '0px 8px', height: 'fit-content', width: '170px' }}>
                            <Typography variant='roboto14Bold' color='#888071'>
                                ESTIMATED % APY
                            </Typography>
                        </Box>
                        <Box sx={{ width: '250.234px' }}>
                            <Typography variant='roboto14Bold' color='#888071'>
                                SETTLEMENT DATE
                            </Typography>
                        </Box>
                        <Box sx={{ padding: '0px 24px', borderLeftWidth: '1px', borderLeftColor: '#FF7033', borderLeftStyle: 'dashed', visibility: 'hidden' }}>
                            <Button variant='contained' color='black'>
                                <Typography variant='inter14Bold'>
                                    SUBSCRIBE
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                    {
                        listToDisplay.map((dciItem, index) => (
                            <Item key={dciItem.instrument} onClick={subscribe} dciItem={dciItem}/>
                        ))
                    }
                </Box>
            </Box>
            <BuyTheDipModal open={buyModal} handleClose={toggleBuyModal} onClickSuccess={success} dciItem={dciItem} account={account} chainConfig={chainConfig} user={user}/>
            <SellTheRipModal open={sellModal} handleClose={toggleSellModal} onClickSuccess={success} dciItem={dciItem} account={account} chainConfig={chainConfig} user={user}/>
            <SubscribeSuccessModal subscribeData={subscribeData} chainConfig={chainConfig} open={successModal} onClickSA={toggleSuccessModal} onClickVT={toggleSuccessModal} />

            <DualCurrencyReminderModal open={showCurrencyReminderModal} handleClose={() => {
                setShowCurrencyReminderModal(false);
                localStorage.setItem('lastReminderTS',new Date().getTime()/1000);
            }} />
        </>
    );
};

export default DualCurrency;