import * as React from "react"
const GiftCircleSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        fill="none"
        {...props}
    >
        <circle cx={20} cy={20} r={20} fill="#FFEDE6" />
        <path
            stroke="#FF4C00"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M11.971 18.101h16.057v11.9H11.971z"
        />
        <path
            stroke="#FF4C00"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M17.709 13.881h4.585V30h-4.585z"
        />
        <path
            stroke="#FF4C00"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M10.365 18.1h19.268v-4.22H10.365zM13.438 10 20 13.88h-6.564V10Z"
        />
        <path
            stroke="#FF4C00"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="m26.424 10-6.564 3.88h6.564V10Z"
        />
    </svg>
)
export default GiftCircleSVG
