import React, { useState } from 'react'
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import BitCoinTokenSVG from '../../../components/SVGs/BitCoinTokenSVG';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SubscriptionDetailsModal from '../../../components/SubscriptionDetailsModal/SubscriptionDetailsModal';

const MyTransaction = () => {
    const [selectedTab, setSelectedTab] = useState('currency');
    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);

    const [tempPagination, setTempPagination] = useState(false);

    return (
        <Box sx={{ flex: 1, padding: { lg: '40p', md: '25px', sm: '20px', xs: '15px' }, overflow: 'hidden' }} >
            <Box sx={{ marginBottom: '16px' }}>
                <Typography variant='roboto16Bold'>
                    ACTIVE TRANSACTIONS
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', marginBottom: '24px', justifyContent: 'space-between', flexDirection: { md: 'row', xs: 'column' } }}>
                <Box sx={{ display: 'flex' }}>
                    <Button color='black' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} onClick={() => setSelectedTab('currency')}>
                        <Typography variant='roboto14500' >
                            Dual Currency
                        </Typography>
                        <svg style={{ visibility: selectedTab === 'currency' ? 'visible' : 'hidden' }} xmlns="http://www.w3.org/2000/svg" width="90" height="14" viewBox="0 0 90 14" fill="none">
                            <path d="M2 14C2 12.3431 3.34315 11 5 11H85C86.6569 11 88 12.3431 88 14V14H2V14Z" fill="black" />
                        </svg>
                    </Button>
                    <Button color='black' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} onClick={() => setSelectedTab('vault')}>
                        <Typography variant='roboto14500'>
                            Arb Yield Vault
                        </Typography>
                        <svg style={{ visibility: selectedTab === 'vault' ? 'visible' : 'hidden' }} xmlns="http://www.w3.org/2000/svg" width="90" height="14" viewBox="0 0 90 14" fill="none">
                            <path d="M2 14C2 12.3431 3.34315 11 5 11H85C86.6569 11 88 12.3431 88 14V14H2V14Z" fill="black" />
                        </svg>
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }} color='black'>
                    <Typography variant='inter14' sx={{ margin: '0px 8px' }}>
                        Showing 1-10 out of 400 transactions
                    </Typography>
                    <Button size="small" disabled={!tempPagination} onClick={() => setTempPagination(false)} variant='outlined' color='black' sx={{ padding: 0, margin: 0, borderRadius: 0, minWidth: 'fit-content', marginLeft: '8px' }}>
                        <NavigateBeforeIcon size="small" />
                    </Button>
                    <Button size="small" disabled={tempPagination} onClick={() => setTempPagination(true)} variant='outlined' color='black' sx={{ padding: 0, margin: 0, borderRadius: 0, minWidth: 'fit-content', marginLeft: '8px' }}>
                        <NavigateNextIcon size="small" />
                    </Button>
                </Box>
            </Box>
            {selectedTab === 'currency' && (
                <TableContainer component={Paper}
                    sx={{
                        borderRadius: 0,
                        border: '1px solid #FF7033',
                        overflowX: 'scroll',
                        paddingBottom: '5px',
                        // minWidth: { lg: '900px', md: 'calc(calc(100vw * 0.72) - 50px)', sm: 'calc(100vw - 60px)', xs: 'calc(100vw - 40px)' },
                        maxWidth: { lg: '100%', md: 'calc(calc(100vw * 0.72) - 50px)', sm: 'calc(100vw - 40px)', xs: 'calc(100vw - 30px)' }
                    }}>
                    <Table sx={{ minWidth: 900 }} aria-label="simple table">
                        <TableHead sx={{ borderBottomStyle: 'dotted', borderBottomWidth: '2px', borderBottomColor: '#FF7033' }}>
                            <TableRow>
                                <TableCell>
                                    <Typography variant='roboto12Bold' color='#888071'>
                                        TOKEN
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='roboto12Bold' color='#888071'>
                                        INVESTMENT
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='roboto12Bold' color='#888071'>
                                        TARGET PRICE
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='roboto12Bold' color='#888071'>
                                        YIELD
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='roboto12Bold' color='#888071'>
                                        SETTLEMENT DATE
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='roboto12Bold' color='#888071'>
                                        STATUS
                                    </Typography>
                                </TableCell>
                                {/* <TableCell>
                                    <Typography variant='roboto12Bold' color='#888071'>
                                        ACTIONS
                                    </Typography>
                                </TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[1, 2, 3].map((row) => (
                                <TableRow
                                    key={row}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell scope="row">
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                            <BitCoinTokenSVG />
                                            <Typography variant='inter14'>
                                                Bitcoin
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='roboto14500'>
                                            30,000 USDC
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='roboto14500'>
                                            $30,500
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ border: '1px solid #037A41', background: '#CFE2D9', width: 'fit-content', padding: '4px 8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Typography variant='inter12' color='#037A41'>
                                                25%
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='inter14'>
                                            07-July-23 (3 days)
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ width: 'fit-content', border: '1px solid #CCC', background: '#E7E7E7', padding: '0px 8px' }}>
                                            <Typography variant='inter12'>
                                                Open
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    {/* <TableCell>
                                        <Button sx={{ width: 'fit-content', border: '1px solid #E7E7E7', padding: '7px 12px' }} color='black' onClick={toggleModal}>
                                            <Typography variant='inter12Bold'>
                                                DETAILS
                                            </Typography>
                                        </Button>
                                    </TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {selectedTab === 'vault' && (
                <TableContainer component={Paper}
                    sx={{
                        borderRadius: 0,
                        border: '1px solid #FF7033',
                        overflowX: 'scroll',
                        paddingBottom: '5px',
                        // minWidth: { lg: '900px', md: 'calc(calc(100vw * 0.72) - 50px)', sm: 'calc(100vw - 60px)', xs: 'calc(100vw - 40px)' },
                        maxWidth: { lg: '100%', md: 'calc(calc(100vw * 0.72) - 50px)', sm: 'calc(100vw - 40px)', xs: 'calc(100vw - 30px)' }
                    }}>
                    <Table sx={{ minWidth: 900 }} aria-label="simple table">
                        <TableHead sx={{ borderBottomStyle: 'dotted', borderBottomWidth: '2px', borderBottomColor: '#FF7033' }}>
                            <TableRow>
                                <TableCell>
                                    <Typography variant='roboto12Bold' color='#888071'>
                                        TOKEN
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='roboto12Bold' color='#888071'>
                                        TOTAL SUBSCRIBED AMOUNT
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell scope="row" style={{ width: '15%' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <BitCoinTokenSVG />
                                        <Typography variant='inter14'>
                                            Tether
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='roboto14Bold'>
                                        30,000 USDT
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <SubscriptionDetailsModal open={modal} toggleModal={toggleModal} />
        </Box>
    )
}

export default MyTransaction