
import React from 'react';

const BarCodeSVG = ({ width = 184, height = 27 }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 184 27" fill="none">
        <line x1="181.819" y1="26.1333" x2="181.819" y2="0.576477" stroke="black" strokeWidth="4.35046" />
        <line x1="2.76458" y1="0.576538" x2="2.76458" y2="26.1334" stroke="black" strokeWidth="4.35046" />
        <line x1="133.976" y1="26.1333" x2="133.976" y2="0.576477" stroke="black" strokeWidth="4.35046" />
        <line x1="50.6108" y1="0.576538" x2="50.6108" y2="26.1334" stroke="black" strokeWidth="4.35046" />
        <line x1="175.294" y1="26.1333" x2="175.294" y2="0.576477" stroke="black" strokeWidth="1.45015" />
        <line x1="9.28904" y1="0.576538" x2="9.28903" y2="26.1334" stroke="black" strokeWidth="1.45015" />
        <line x1="127.451" y1="26.1333" x2="127.451" y2="0.576477" stroke="black" strokeWidth="1.45015" />
        <line x1="57.1352" y1="0.576538" x2="57.1352" y2="26.1334" stroke="black" strokeWidth="1.45015" />
        <line x1="151.372" y1="26.1333" x2="151.372" y2="0.576477" stroke="black" strokeWidth="1.45015" />
        <line x1="33.2124" y1="0.576416" x2="33.2124" y2="26.1332" stroke="black" strokeWidth="1.45015" />
        <line x1="103.528" y1="26.1333" x2="103.528" y2="0.576477" stroke="black" strokeWidth="1.45015" />
        <line x1="81.0556" y1="0.576538" x2="81.0556" y2="26.1334" stroke="black" strokeWidth="1.45015" />
        <line x1="143.4" y1="26.1333" x2="143.4" y2="0.576477" stroke="black" strokeWidth="1.45015" />
        <line x1="41.1865" y1="0.576538" x2="41.1865" y2="26.1334" stroke="black" strokeWidth="1.45015" />
        <line x1="95.5537" y1="26.1333" x2="95.5537" y2="0.576477" stroke="black" strokeWidth="1.45015" />
        <line x1="89.0307" y1="0.576538" x2="89.0307" y2="26.1334" stroke="black" strokeWidth="1.45015" />
        <line x1="161.52" y1="26.1333" x2="161.52" y2="0.576478" stroke="black" strokeWidth="13.0514" />
        <line x1="23.0638" y1="0.576538" x2="23.0638" y2="26.1334" stroke="black" strokeWidth="13.0514" />
        <line x1="113.676" y1="26.1333" x2="113.676" y2="0.576478" stroke="black" strokeWidth="13.0514" />
        <line x1="68.7347" y1="0.576538" x2="68.7347" y2="26.1334" stroke="black" strokeWidth="8.70092" />
        <line x1="157.896" y1="26.1333" x2="157.896" y2="0.576477" stroke="black" strokeWidth="4.35046" />
        <line x1="26.6879" y1="0.576538" x2="26.6879" y2="26.1334" stroke="black" strokeWidth="4.35046" />
        <line x1="110.053" y1="26.1333" x2="110.053" y2="0.576477" stroke="black" strokeWidth="4.35046" />
        <line x1="74.5317" y1="0.576538" x2="74.5317" y2="26.1334" stroke="black" strokeWidth="4.35046" />
    </svg>
);

export default BarCodeSVG;