import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';
import ArrowIllustrationSVG from '../SVGs/ArrowIllustrationSVG';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const ArbYieldVaultModal = ({ width = '345px', open, handleClose }) => {

    return (
        <Modal
            open={open}
            handleClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ backdropFilter: 'blur(12px)' }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Box sx={{ width: { sm: width, xs: '90%' }, maxWidth: { sm: width, xs: '90%' }, backgroundColor: 'common.white', padding: '32px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '24px' }}>
                        <ArrowIllustrationSVG />
                    </Box>
                    <Box sx={{ marginBottom: '16px', textJustify: 'center' }}>
                        <Typography variant='inter16Bold' sx={{ textJustify: 'center' }}>
                            Hold on, this feature is limited to whitelisted users at the moment.
                        </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '10px' }}>
                        <Typography variant='inter14'>
                            If you are interested to access this feature and invest bigger amounts, our team will require you to submit valid documents to verify your identity. Kindly reach out to our email { } for further instructions. Thank you!
                            <br></br><br></br>
                            If you just want minimal exposure in our Arb Yield Vault, you may just <Typography variant='inter14Bold' sx={{ textDecorationLine: 'underline' }}>buy iUSD directly via Uniswap</Typography>.
                        </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '32px', display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel control={<Checkbox color='black' />} label="Never show this reminder again" componentsProps={{ typography: { variant: 'inter14' } }} />
                    </Box>
                    <Button variant='contained' color='black' onClick={handleClose} sx={{ width: '100%' }}>
                        I UNDERSTAND
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ArbYieldVaultModal;