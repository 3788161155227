import { OutlinedInput } from '@mui/material';
import React from 'react';

const FormTextField = ({ placeholder, sx, ...props }) => (
    <OutlinedInput
        placeholder={placeholder}
        sx={{ borderRadius: '0px', ...sx }}
        inputProps={{
            sx: {
                fontSize: '16px',
                fontFamily: 'Inter',
                lineHeight: '24px'
            }
        }}
        color='black'
        {...props}
    />
)

export default FormTextField;