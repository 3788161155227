import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import AboutBigBagSVG from '../../../components/SVGs/AboutBigBagSVG';
import AboutBigHorseSVG from '../../../components/SVGs/AboutBigHorseSVG';

const Overview = () => (
    <Box component='section' sx={{
        backgroundColor: 'common.black',
        paddingBottom: '5%'
    }}>
        <Container maxWidth='xl' fixed>
            <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column-reverse' }, padding: { xs: '2%' }, position: 'relative' }}>
                <Box sx={{ flex: 1, padding: '1%' }}>
                    <Box sx={{ marginBottom: '15px', marginTop: { md: '10%', sx: '2%' } }}>
                        <Typography variant='subject' color="primary">
                            {'// OVERVIEW'}
                        </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '20px' }}>
                        <Typography variant='sectionTitle' color="secondary">
                            Baljuna is a DeFi platform that gives users easy, transparent and safe access to professional crypto trading and yield generation products.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant='sectionBody' color="secondary">
                            In addition, Baljuna changes the "zero-sum" nature of financial trading by partially compensating users with losses through our innovative tokenomics. Baljuna aims to be a one-stop DeFi solution for users to manage their DeFi portfolio and earn through trading, even if their trades lose money.
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <AboutBigBagSVG />
                </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, padding: '2%', gap: '1%', position: 'relative', marginTop: '2%' }}>
                {/* <Box sx={{ position: 'absolute', width: '40%', left: 0, bottom: 0 }}>
                    <AboutBigHorseSVG />
                </Box> */}
                <Box sx={{ display: 'flex', alignItems: 'flex-end', flex: 1 }}>
                    <Box sx={{ padding: '2%', width: '88%' }}>
                        <AboutBigHorseSVG />
                    </Box>
                </Box>
                <Box sx={{ flex: 1, padding: '1%' }}>
                    <Box sx={{ marginBottom: '15px' }}>
                        <Typography variant='subject' color="primary">
                            {'// NAME ORIGIN'}
                        </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '20px' }}>
                        <Typography variant='sectionTitle' color="secondary">
                            The Baljuna Covenant
                        </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '12px' }}>
                        <Typography variant='sectionBody' color="secondary">
                            In 1201, Genghis Khan built an army and began his revenge. by 1203, he defeated 5 powerful clans to take control of the affluent Hulunbuir Grassland.It was such a brilliant victory that made Ong Khan, the leader of the Kled clan, also Genghis Khan’s God father and main ally, very jealous.
                        </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '12px' }}>
                        <Typography variant='sectionBody' color="secondary">
                            Ong Khan’s calvary suddenly attacked Genghis Khan’s troops.Genghis khan suffered bad defeat and fled. when he fled to the muddy Baljuna water, he had only 19 exhausted followers. At rock bottom of his life, to everyone’s surprice, genghis khan suddenly drank the muddy water and swore “anyone drnking tis water is my brother. when we achieve the big cause, we share all joys and sorrows together and same for generations”. the follwers were moved.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant='sectionBody' color="secondary">
                            All drank the water and helped Genghis Khan strike back.Baljuna Oath is a turning point for Genghis Khan. After this, he is almost invincible, unified Mongolian Steppe in 3 years, and went on to conquer the world.Baljuna symbolizes the brotherhood, courage, and bravery even during the worst crisis. as well as the invincible road afterwards. We hope every team has its Baljuna Water spirits.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Container>
    </Box >
);

export default Overview;