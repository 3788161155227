import { Box, Container } from '@mui/material';
import React from 'react';
import BaljunaMarquee from '../../../../components/BaljunaMarquee/BaljunaMarquee';
import HomeHeaderTitle from '../../../../components/HomeHeaderTitle/HomeHeaderTitle';
import NavButton from '../../../../components/NavButton/NavButton';

const FooterMarqueeDesktop = () => {

    return (
        <Box sx={{ backgroundColor: 'primary.main', textAlign: 'center', display: { sm: 'block', xs: 'none' } }}>
            <Container maxWidth='xl' fixed>
                <HomeHeaderTitle
                    sx={{ paddingTop: '60px', paddingBottom: '20px', paddingLeft: '20px', paddingRight: '20px' }}
                    description='Passive Income: Invite someone to join and receive 30% of their rewards, forever.'
                >
                    TRADE INSTANTLY.<br></br>Join Baljuna.
                </HomeHeaderTitle>
                <NavButton
                    variant='contained'
                    color='black'
                    textColor='secondary.main'
                    textVariant='button1'
                    label='Sign Up'
                    href='/signup'
                    sx={{ marginBottom: '50px', textTransform: 'initial' }}
                    hoverBackgroundColor='black'
                />
            </Container>
            <BaljunaMarquee color="black" borderColor='common.black' />
        </Box >
    );
};

export default FooterMarqueeDesktop;