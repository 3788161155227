import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import CommonModal from '../CommonComponents/CommonModal/CommonModal';
import FormTextField from '../FormTextField/FormTextField';

const ContactUsModal = ({ open, handleClose, onSubmit }) => (
    <CommonModal title={'// CONTACT US'} open={open} handleClose={handleClose} width={'624px'}>
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '32px' }}>
            <Box sx={{ marginBottom: '24px' }}>
                <Typography variant='inter24Bold'>
                    Tell us about your concern.
                </Typography>
            </Box>
            <FormTextField
                placeholder='Title'
                sx={{ marginBottom: '16px' }}
            />
            <FormTextField
                sx={{ marginBottom: '24px' }}
                placeholder='Your message to us'
                multiline
                rows={4}
            />
            <Button variant='contained' color='black' sx={{ width: 'fit-content' }} onClick={onSubmit}>
                SEND MESSAGE
            </Button>
        </Box>
    </CommonModal>
);

export default ContactUsModal;