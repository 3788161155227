import { Box } from '@mui/material';
import React, { useState } from 'react';
import BorderTopLeftSVG from '../../SVGs/BorderTopLeftSVG';
import BorderBottomRightSVG from '../../SVGs/BorderBottomRightSVG';

const CommonContainer = ({ sx, children }) => {
    const [isHover, setIsHover] = useState(false);

    return (
        <Box onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} sx={{ border: '1px solid #000', padding: '24px', position: 'relative', ...sx }}>
            <Box sx={{ position: 'absolute', top: '-3px', left: '-3px' }}>
                <BorderTopLeftSVG fill={isHover ? '#FF4C00' : '#000'} />
            </Box>
            <Box sx={{ position: 'absolute', bottom: '-7px', right: '-3px' }}>
                <BorderBottomRightSVG fill={isHover ? '#FF4C00' : '#000'} />
            </Box>
            {children}
        </Box>
    );
};

export default CommonContainer;