import React from 'react';
import NavButton from '../../../../components/NavButton/NavButton';

const HomeHeaderMenuItems = () => (
    <>
        <NavButton
            href="/about"
            label='ABOUT'
        />
        <NavButton
            href="/?scrollTo=investment"
            label='INVESTMENT PRODUCTS'
        />
        <NavButton
            href="/?scrollTo=token"
            label=' BPS TOKEN'
        />
        <NavButton
            href="https://baljuna.gitbook.io/baljuna-waters-documentation/"
            label='DOCUMENTATION'
            newTab={true}
        />
        <NavButton
            href="/login"
            label='LOGIN'
        />
        <NavButton
            href="/signup"
            variant='outlined'
            color='primary'
            textColor='primary'
            label='GET STARTED'
            sx={{ padding: { xl: '10px 16px', lg: '6px 12px', md: '0px 6px', } }}
        />
    </>
);

export default HomeHeaderMenuItems