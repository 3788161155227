import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';

const ContactUsDoneModal = ({ width = '420px', open, handleClose }) => {

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Box sx={{ width: { sm: width, xs: '90%' }, maxWidth: { sm: width, xs: '90%' }, backgroundColor: 'common.white', padding: '32px' }}>
                    <Box sx={{ marginBottom: '8px' }}>
                        <Typography variant='inter24Bold'>
                            Thanks for letting us know!
                        </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '24px' }}>
                        <Typography variant='inter16'>
                            Please give us time to review your concern. Our team will quickly get back at you.
                        </Typography>
                    </Box>
                    <Button variant='contained' color='black' sx={{ width: 'fit-content' }} onClick={handleClose}>
                        DONE
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ContactUsDoneModal;