import * as React from "react"
const StarIconSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        {...props}
    >
        <path
            stroke="#FF4C00"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="m10 1 2.234 7H19l-5.385 4.125L15.849 19 10 14.751 4.151 19l2.234-6.875L1 8h6.766L10 1Z"
        />
    </svg>
)
export default StarIconSVG
