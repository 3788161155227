import * as React from "react";

const DashboardBarCodeSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={'100%'}
        height={'100%'}
        viewBox="0 0 133 24"
        fill="none"
        {...props}
    >
        <path
            stroke="gray"
            strokeWidth={3}
            d="M1.668 0v24M130.883 24V0M36.158 0v24M96.391 24V0"
        />
        <path
            stroke="gray"
            d="M6.416 0v24M126.135 24V0M40.906 0v24M91.643 24V0M23.662 0v24M108.889 24V0M58.152 0v24M74.398 24V0M29.41 0v24M103.141 24V0M63.902 0v24M68.65 24V0"
        />
        <path
            stroke="gray"
            strokeWidth={9}
            d="M16.166 0v24M116.387 24V0M50.656 0v24"
        />
        <path stroke="gray" strokeWidth={6} d="M83.394 24V0" />
        <path
            stroke="gray"
            strokeWidth={3}
            d="M18.914 0v24M113.639 24V0M53.404 0v24M79.147 24V0"
        />
    </svg>
)
export default DashboardBarCodeSVG;
