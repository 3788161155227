
import React from 'react';

const Token4SVG = ({ width = 88, height = 88 }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 88 88" fill="none">
        <path d="M44 88.7549C19.6881 88.7549 -2.10792e-07 68.9922 -1.35921e-07 44.7549C-6.10491e-08 20.5176 19.7627 0.754883 44 0.754883C68.2373 0.754883 88 20.5176 88 44.7549C88 68.9922 68.2373 88.7549 44 88.7549ZM44 2.91759C20.9559 2.91759 2.23729 21.6362 2.23729 44.6803C2.23729 67.7244 20.9559 86.443 44 86.443C67.0441 86.443 85.7627 67.7244 85.7627 44.6803C85.7627 21.6362 67.0441 2.91759 44 2.91759Z" fill="#FCF5E8" />
        <path d="M44.0004 65.4124C32.5902 65.4124 23.3428 56.165 23.3428 44.7548C23.3428 33.3446 32.5902 24.0972 44.0004 24.0972C55.4106 24.0972 64.658 33.3446 64.658 44.7548C64.658 56.165 55.336 65.4124 44.0004 65.4124ZM44.0004 26.2599C33.858 26.2599 25.5801 34.5378 25.5801 44.6802C25.5801 54.8226 33.858 63.1006 44.0004 63.1006C54.1428 63.1006 62.4208 54.8226 62.4208 44.6802C62.4208 34.5378 54.1428 26.2599 44.0004 26.2599Z" fill="#FCF5E8" />
        <path d="M11.0408 36.6258L9.25098 36.1783C13.2035 20.1444 27.4476 8.95801 44.0035 8.95801C59.068 8.95801 72.6408 18.4292 77.712 32.5987L75.9222 33.1953C71.1493 19.7716 58.2476 10.8224 44.0035 10.8224C28.268 10.8224 14.7696 21.4122 11.0408 36.6258Z" fill="#FCF5E8" />
        <path d="M9.17406 38.7141C9.0249 38.7141 8.95033 38.7141 8.80117 38.6395C8.42829 38.4904 8.20457 38.1921 8.20457 37.8192L7.90625 29.4667L9.77066 29.3921L9.99438 35.7311L14.6181 31.3311L15.8859 32.6734L9.77066 38.4904C9.6215 38.565 9.39778 38.7141 9.17406 38.7141Z" fill="#FCF5E8" />
        <path d="M74.4271 38.267L72.9355 37.1483L76.5898 32.0771L82.3321 34.3144L81.661 36.1043L77.261 34.3144L74.4271 38.267Z" fill="#FCF5E8" />
        <path d="M72.5622 33.3448L70.9961 32.3754L74.2029 27.0059L80.169 28.8703L79.5724 30.5855L75.0978 29.2431L72.5622 33.3448Z" fill="#FCF5E8" />
        <path d="M70.4013 29.3919L68.686 28.5716L71.5199 22.9038L77.5606 24.3208L77.1877 26.1106L72.564 25.0665L70.4013 29.3919Z" fill="#FCF5E8" />
        <path d="M43.9994 61.1621C34.9011 61.1621 27.5181 53.779 27.5181 44.6807C27.5181 35.5824 34.9011 28.2739 43.9994 28.2739C53.0978 28.2739 60.4808 35.657 60.4808 44.7553C60.4808 53.8536 53.0232 61.1621 43.9994 61.1621ZM43.9994 30.5112C36.1689 30.5112 29.7554 36.9248 29.7554 44.7553C29.7554 52.5858 36.1689 58.9993 43.9994 58.9993C51.83 58.9993 58.2435 52.5858 58.2435 44.7553C58.2435 36.9248 51.83 30.5112 43.9994 30.5112Z" fill="#FCF5E8" />
        <path d="M43.9301 80.4774C28.8657 80.4774 15.2929 71.0062 10.2217 56.8367L12.0115 56.2401C16.7844 69.6638 29.6861 78.613 43.9301 78.613C59.5911 78.613 73.164 68.0231 76.8928 52.8096L78.6827 53.257C74.7301 69.2909 60.4861 80.4774 43.9301 80.4774Z" fill="#FCF5E8" />
        <path d="M78.084 60.0434L77.8602 53.7044L73.2366 58.1044L71.9687 56.762L78.084 50.9451C78.3823 50.7213 78.7552 50.6467 79.0535 50.7213C79.4264 50.8705 79.6501 51.1688 79.6501 51.5417L79.9484 59.8942L78.084 60.0434Z" fill="#FCF5E8" />
        <path d="M11.339 57.3583L5.52197 55.0464L6.26774 53.3312L10.6678 55.121L13.4271 51.1685L14.9186 52.2871L11.339 57.3583Z" fill="#FCF5E8" />
        <path d="M13.6508 62.4288L7.75928 60.5644L8.28132 58.7746L12.8305 60.1915L15.2915 56.0898L16.8576 57.0593L13.6508 62.4288Z" fill="#FCF5E8" />
        <path d="M16.3366 66.4565L10.2959 65.1142L10.7433 63.2498L15.3671 64.3684L17.5298 60.043L19.1705 60.8633L16.3366 66.4565Z" fill="#FCF5E8" />
    </svg>
);

export default Token4SVG;