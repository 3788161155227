
import React from 'react';

const BarCodeVerticalSmallSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={161}
        fill="none"
        {...props}
    >
        <path
            fill="#000"
            fillRule="evenodd"
            d="M18.38 161H-5.85v-.897H18.38V161ZM-5.849.378h24.228v.897H-5.85V.378ZM18.38 119.723H-5.85v-1.795H18.38v1.795ZM-5.849 42.553h24.228v.897H-5.85v-.897ZM18.38 154.719H-5.85v-.898H18.38v.898ZM-5.849 6.66h24.228v1.794H-5.85V6.66ZM18.38 112.544H-5.85v-.897H18.38v.897ZM-5.849 48.834h24.228v.897H-5.85v-.897ZM18.38 134.08H-5.85v-1.795H18.38v1.795ZM-5.849 28.195h24.228v.897H-5.85v-.897ZM18.38 91.906H-5.85v-.898H18.38v.898ZM-5.849 69.472h24.228v1.795H-5.85v-1.795ZM18.38 146.643H-5.85v-2.692H18.38v2.692ZM-5.849 14.735h24.228v3.59H-5.85v-3.59ZM18.38 105.365H-5.85v-3.589H18.38v3.589ZM-5.849 56.91h24.228v1.795H-5.85V56.91ZM18.38 140.361H-5.85v-.897H18.38v.897ZM-5.849 21.017h24.228v1.794H-5.85v-1.794ZM18.38 98.187H-5.85v-.897H18.38v.897ZM-5.849 63.191h24.228v.897H-5.85v-.897Z"
            clipRule="evenodd"
        />
    </svg>
);

export default BarCodeVerticalSmallSVG;