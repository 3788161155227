import * as React from "react"
const ETHToken40SVG = ({ color = '#000' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        fill="none"
    >
        <g fill={color} clipPath="url(#a)">
            <path d="m20.686 33.596 8.438-14.435v-.05c0-.051.05-.102.05-.153V18.552c0-.051 0-.051-.05-.102v-.051c0-.05-.051-.05-.051-.102 0 0 0-.05-.051-.05L20.636 6.963c-.153-.203-.407-.356-.661-.356-.254 0-.508.102-.66.356l-8.489 11.233s0 .05-.05.05c0 .051-.051.051-.051.102v.051c0 .05 0 .05-.051.102V18.907c0 .051.05.102.05.153v.05l8.438 14.435a.811.811 0 0 0 .711.407c.305 0 .661-.102.813-.356Zm.102-16.061 5.744 1.525-5.744 4.168v-5.693Zm-1.677 5.642-5.743-4.168 5.743-1.525v5.693Zm1.677 2.084 4.93-3.558-4.93 8.386v-4.828Zm0-9.454V9.962l5.54 7.319-5.54-1.474Zm-1.677 0-5.49 1.474 5.54-7.32v5.846h-.05Zm0 9.454v4.828l-4.88-8.386 4.88 3.558Z" />
            <path d="M19.975 0C8.945 0 0 8.945 0 19.975c0 11.029 8.945 19.974 19.975 19.974C31.004 39.95 40 31.004 40 19.975 40 8.945 31.004 0 19.975 0Zm12.249 30.699a16.18 16.18 0 0 0 4.015-9.81h2.084a18.258 18.258 0 0 1-4.575 11.284l-1.524-1.474ZM6.302 32.122c-2.693-3.05-4.421-6.963-4.574-11.283h2.084a16.055 16.055 0 0 0 4.015 9.809l-1.525 1.474ZM7.878 9.149a16.176 16.176 0 0 0-4.117 9.707H1.728A18.136 18.136 0 0 1 6.455 7.675l1.423 1.474Zm-2.49 10.826c0-8.03 6.556-14.587 14.587-14.587 8.03 0 14.587 6.556 14.587 14.587 0 8.03-6.506 14.637-14.587 14.637-8.082 0-14.587-6.556-14.587-14.637Zm30.8-1.119c-.254-3.71-1.779-7.064-4.117-9.707l1.474-1.474c2.694 2.998 4.473 6.861 4.727 11.181h-2.084ZM32.122 6.302l-1.474 1.474a16.216 16.216 0 0 0-10.673-4.015A16.216 16.216 0 0 0 9.3 7.776L7.827 6.302a18.121 18.121 0 0 1 12.148-4.625c4.676 0 8.945 1.728 12.147 4.625ZM7.675 33.545l1.474-1.474c2.897 2.592 6.658 4.168 10.826 4.168 4.167 0 7.98-1.576 10.825-4.168l1.475 1.474c-3.253 2.948-7.574 4.778-12.3 4.778-4.727 0-9.047-1.83-12.3-4.778Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h40v40H0z" />
            </clipPath>
        </defs>
    </svg>
)
export default ETHToken40SVG
