import React, { useContext, useEffect, useState } from 'react'
import CommonModal from '../CommonComponents/CommonModal/CommonModal';
import { Typography, Box, Button } from '@mui/material';
import IERC20JSON from '../../abis/OurToken.json';
import InvestVaultJSON from '../../abis/InvestVault.json';
import { UserContext,connectContractToSigner,tryF } from '../../common';
import { Contract,utils } from 'ethers';
import {useEthers} from '@usedapp/core';
import _ from 'lodash';

const DepositUSDModal = ({ open, handleClose, onClickSuccess }) => {
    const [investAmount, setInvestAmount] = useState('');
    const { account, library } = useEthers();
    const user=useContext(UserContext);
    const {balances,chainConfig,provider}=user||{};
    useEffect(() => {
        setInvestAmount('')
    }, [open]);
    const ensureBalance=async(tokenName,amount,chainConfig)=>{
        const token=chainConfig.tokens[tokenName];
        const tokenContract = new Contract(
            token.addr,
            IERC20JSON.abi,
            user?.provider
        );
        const balance = await tokenContract.balanceOf(account);
        if(balance.lt(amount)){
            //fixme error notification
            throw new Error('Insufficient balance '+tokenName);
        }
    }
    const ensureAllowance=async(spender,tokenName,amount,chainConfig)=>{
        await ensureBalance(tokenName,amount,chainConfig);
        const token=chainConfig.tokens[tokenName];
        const tokenContract = new Contract(
            token.addr,
            IERC20JSON.abi,
            user?.provider
        );
        const allowance = await tokenContract.allowance(account,spender);
        console.log('allowance',allowance,'amount',amount);
        if(allowance.lt(amount)){
            const tokenSigned = connectContractToSigner(
                tokenContract,
                {
                  transactionName: `Approval Spending of ${tokenName}`,
                },
                library
            );
            if(allowance.gt(0)){
                const transaction1=await tokenSigned.approve(spender,0);
                await transaction1.wait(1);
            }
            const transaction2=await tokenSigned.approve(spender,amount);
            await transaction2.wait(1);
        }
    }
    const onClickDeposit = async () => {
        const web3Amount=utils.parseUnits(investAmount,6);
        console.log('web3 amount',web3Amount);
        await ensureAllowance(chainConfig.investVaultAddress,'USDT',web3Amount,chainConfig);
        const vaultContract = new Contract(
            chainConfig.investVaultAddress,
            InvestVaultJSON.abi,
            user?.provider
        );
        const contractSigned = connectContractToSigner(
            vaultContract,
            {
              transactionName: 'Invest USDT to vault',
            },
            library
          );
        const transaction=await contractSigned.invest(web3Amount);
        await transaction.wait(1);
        onClickSuccess(investAmount);
    }
    const tryDeposit=tryF('Deposit USDT',onClickDeposit);
    return (
        <CommonModal minWidth='400px' title='// DEPOSIT USDT' open={open} handleClose={handleClose}>
            <Box sx={{ padding: '24px' }}>
                <Typography variant='inter14'>
                    How much do you want to deposit? No minimum deposit required.
                </Typography>
                <Box sx={{ border: '1px solid #B0A592', padding: '20px 16px', backgroundColor: '#FEFDFA', marginTop: '8px', marginBottom: '16px' }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingBottom: '8px',
                        borderBottomColor: '#CCCCCC',
                        borderBottomWidth: '1px',
                        borderBottomStyle: 'solid',
                        marginBottom: '8px',
                        gap: '16px'
                    }}>
                        <Box sx={{ flex: 1 }}>
                            <Box
                                component={'input'}
                                placeholder='Input USDT amount'
                                sx={{
                                    background: 'transparent',
                                    width: '100%',
                                    fontFamily: 'Inter',
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    outline: 0,
                                    border: 0
                                }}
                                value={investAmount}
                                onChange={(e) => setInvestAmount(e.target.value)} />
                        </Box>
                        <Box>
                            <Typography variant='inter14'>
                                USDT | <Typography variant='inter14Bold' sx={{ cursor: 'pointer' }} onClick={() => setInvestAmount(balances?.USDT)}>Max</Typography>
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Typography variant='inter12'>
                            Wallet Balance: {balances?.USDT} USDT
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ marginBottom: '24px' }}>
                    <Typography variant='inter14'>
                        Please note there is a one off charge of <Typography variant='inter14Bold'>{chainConfig?.fee} USDT</Typography> for each investment made to cover all fees incurred. There is no charge for holding or redemption.
                    </Typography>
                </Box>
                <Box sx={{ marginBottom: '16px' }}>
                    <Typography variant='inter14' color='#808080'>
                        Please review and make sure all details are correct before you confirm.
                    </Typography>
                </Box>

                <Button variant='contained' color='black' disabled={_.isEmpty(investAmount.toString())} sx={{ width: '100%' }} onClick={tryDeposit}>
                    <Typography variant='inter14Bold'>
                        CONFIRM DEPOSIT
                    </Typography>
                </Button>
            </Box>
        </CommonModal>
    )
}

export default DepositUSDModal