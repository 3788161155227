
import React from 'react';

const BarCodeVerticalSVG = ({ width = 184, height = 27 }) => (
    <svg width="31" height="293" viewBox="0 0 31 293" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M30.2695 292.865L-13.8486 292.865L-13.8486 291.231L30.2695 291.231L30.2695 292.865Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M-13.8486 0.377713L30.2695 0.377728L30.2695 2.01172L-13.8486 2.0117L-13.8486 0.377713Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M30.2695 217.701L-13.8486 217.701L-13.8486 214.433L30.2695 214.433L30.2695 217.701Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M-13.8486 77.1765L30.2695 77.1766L30.2695 78.8105L-13.8486 78.8105L-13.8486 77.1765Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M30.2695 281.427L-13.8486 281.427L-13.8486 279.793L30.2695 279.793L30.2695 281.427Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M-13.8486 11.8162L30.2695 11.8162L30.2695 15.0842L-13.8486 15.0842L-13.8486 11.8162Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M30.2695 204.629L-13.8486 204.629L-13.8486 202.995L30.2695 202.995L30.2695 204.629Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M-13.8486 88.614L30.2695 88.6141L30.2695 90.248L-13.8486 90.248L-13.8486 88.614Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M30.2695 243.845L-13.8486 243.845L-13.8486 240.577L30.2695 240.577L30.2695 243.845Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M-13.8486 51.032L30.2695 51.032L30.2695 52.666L-13.8486 52.666L-13.8486 51.032Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M30.2695 167.047L-13.8486 167.047L-13.8486 165.413L30.2695 165.413L30.2695 167.047Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M-13.8486 126.197L30.2695 126.197L30.2695 129.465L-13.8486 129.465L-13.8486 126.197Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M30.2695 266.721L-13.8486 266.721L-13.8486 261.819L30.2695 261.819L30.2695 266.721Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M-13.8486 26.5226L30.2695 26.5226L30.2695 33.0586L-13.8486 33.0586L-13.8486 26.5226Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M30.2695 191.557L-13.8486 191.557L-13.8486 185.021L30.2695 185.021L30.2695 191.557Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M-13.8486 103.321L30.2695 103.321L30.2695 106.589L-13.8486 106.589L-13.8486 103.321Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M30.2695 255.283L-13.8486 255.283L-13.8486 253.649L30.2695 253.649L30.2695 255.283Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M-13.8486 37.9603L30.2695 37.9603L30.2695 41.2283L-13.8486 41.2283L-13.8486 37.9603Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M30.2695 178.485L-13.8486 178.485L-13.8486 176.851L30.2695 176.851L30.2695 178.485Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M-13.8486 114.759L30.2695 114.759L30.2695 116.393L-13.8486 116.393L-13.8486 114.759Z" fill="black" />
    </svg>

);

export default BarCodeVerticalSVG;