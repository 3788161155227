import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import WalkingSVG from '../../../components/SVGs/Auth/WalkingSVG';
import AuthTitleSVG from '../../../components/SVGs/Auth/AuthTitleSVG';
import BaljunaWatersSVG from '../../../components/SVGs/BaljunaWatersSVG';
import TigerSVG from '../../../components/SVGs/Auth/TigerSVG';
import RegistrationForm from '../../../components/RegistrationForm/RegistrationForm';



const Registration = () => {

    return (
        <Box sx={{ width: '100%', height: '100vh', position: 'relative', overflow: 'hidden' }}>
            <Box sx={{ position: 'absolute', width: '75%', height: '100%', transition: '1.5s', top: '0', left: '0', backgroundColor: 'secondary.main', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ width: { xl: '720px', lg: '660px', md: '600px' }, display: { md: 'block', xs: 'none' } }}>
                    <WalkingSVG />
                </Box>
            </Box>
            <Box sx={{ position: 'absolute', width: '25%', transition: '1.5s', height: '100%', bottom: '0', right: '0', backgroundColor: 'common.black' }}>
                {/* <Welcome isShowInRegistration={refWidth.ref2 === 100} /> */}
                <Box sx={{ position: 'absolute', height: { md: '40%', xs: '25%' }, bottom: '0', right: '-250px', opacity: 0.5 }}>
                    <TigerSVG />
                </Box>
            </Box>

            {/* <Box sx={{ position: 'absolute', left: '5%', bottom: '5%', width: '23%' }}>
                <BaljunaWatersSVG />
            </Box> */}
            <Container maxWidth='xl' fixed sx={{ position: 'relative', display: 'flex', height: '100%', alignItems: 'center', paddingTop: '4%', paddingBottom: '2%' }}>
                <Box sx={{ flex: 1, height: '100%', flexDirection: 'column', justifyContent: 'space-between', display: { md: 'flex', xs: 'none' } }}>
                    <Box sx={{ width: { xl: '482px', lg: '412px', md: '362px' } }}>
                        <AuthTitleSVG />
                    </Box>
                    <Box sx={{ width: { xl: '206px', lg: '176px', md: '146px' } }}>
                        <BaljunaWatersSVG />
                    </Box>
                </Box>
                <Box sx={{
                    flex: 1,
                    height: { md: '100%', xs: '100vh' },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative'
                }}>
                    <Box sx={{ position: 'absolute', width: '100%', height: '100%', display: { md: 'none', xs: 'block' } }}>
                        <WalkingSVG />
                    </Box>
                    <Box sx={{ position: 'absolute', top: '5%', left: '0', width: '75%', display: { md: 'none', xs: 'block', display: 'flex', justifyContent: 'center', zIndex: 1 } }}>
                        <Typography variant='authDescription' sx={{ display: 'block' }}>
                            GET STARTED WITH BALJUNA
                        </Typography>
                        <Typography variant='authTitle'>
                            WANDER TRADE AND CONQUER
                        </Typography>
                    </Box>
                    <RegistrationForm />
                    <Box sx={{ position: 'absolute', left: '0', bottom: '5%', height: { sm: '65px', xs: '50px' }, display: { md: 'none', xs: 'block', zIndex: 1 } }}>
                        <BaljunaWatersSVG />
                    </Box>
                </Box>
            </Container >
        </Box >
    );
};

export default Registration;