const isXl = (num) => num >= 1536;
const isLg = (num) => num >= 1200 && num < 1536;
const isMd = (num) => num >= 900 && num < 1200;
const isSm = (num) => num >= 600 && num < 900;
const isXs = (num) => num < 600;

const getScreenBreakPoint = (num) => {
    if (isXl(num)) return 'xl';
    if (isLg(num)) return 'lg';
    if (isMd(num)) return 'md';
    if (isSm(num)) return 'sm';
    if (isXs(num)) return 'xs';
    return "";
}

export default getScreenBreakPoint;