import * as React from "react"
const ETHTokenSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#000"
            d="M12 24.97c-6.617 0-12-5.383-12-12 0-6.618 5.383-12 12-12s12 5.384 12 12c0 6.617-5.383 12-12 12Zm0-23.002c-6.066 0-11 4.936-11 11.001C1 19.035 5.933 23.97 12 23.97s11-4.935 11-11c0-6.066-4.934-11.002-11-11.002Z"
        />
        <path
            fill="#000"
            d="M11.999 22.728c-5.38 0-9.759-4.379-9.759-9.759C2.24 7.59 6.62 3.211 12 3.211c5.38 0 9.758 4.378 9.758 9.758 0 5.383-4.378 9.759-9.758 9.759Zm0-18.518c-4.83 0-8.76 3.929-8.76 8.76 0 4.83 3.93 8.759 8.76 8.759s8.76-3.929 8.76-8.76c0-4.83-3.93-8.759-8.76-8.759Z"
        />
        <path
            fill="#000"
            d="M11.999 22.728c-5.38 0-9.759-4.379-9.759-9.759C2.24 7.59 6.62 3.211 12 3.211c5.38 0 9.758 4.378 9.758 9.758 0 5.383-4.378 9.759-9.758 9.759Zm0-18.518c-4.83 0-8.76 3.929-8.76 8.76 0 4.83 3.93 8.759 8.76 8.759s8.76-3.929 8.76-8.76c0-4.83-3.93-8.759-8.76-8.759Z"
        />
        <path
            fill="#000"
            d="M11.978 16.237a.364.364 0 0 1-.214-.07l-5.08-3.682a.362.362 0 0 1-.075-.515l5.08-6.741a.38.38 0 0 1 .583 0l5.081 6.741a.364.364 0 0 1-.075.515l-5.081 3.681a.372.372 0 0 1-.219.07Zm-4.565-4.123 4.565 3.308 4.566-3.309-4.566-6.058-4.565 6.059ZM19.647 4.388l-1.584 1.585.847.847 1.585-1.585-.848-.847ZM5.09 18.944 3.507 20.53l.847.848 1.585-1.585-.847-.848Z"
        />
        <path
            fill="#000"
            d="M11.978 21.232a.36.36 0 0 1-.314-.18l-5.08-8.676a.368.368 0 0 1 .221-.537l5.08-1.345a.348.348 0 0 1 .188 0l5.08 1.344c.11.03.2.107.244.214a.369.369 0 0 1-.022.324l-5.081 8.676a.367.367 0 0 1-.316.18ZM7.456 12.42l4.522 7.726L16.5 12.42l-4.522-1.196-4.522 1.196Z"
        />
        <path fill="#000" d="M12.34 5.45h-.729v15.418h.73V5.45Z" />
        <path
            fill="#000"
            d="M12 24.97c-6.617 0-12-5.383-12-12 0-6.618 5.383-12 12-12s12 5.384 12 12c0 6.617-5.383 12-12 12Zm0-23.002c-6.066 0-11 4.936-11 11.001C1 19.035 5.933 23.97 12 23.97s11-4.935 11-11c0-6.066-4.934-11.002-11-11.002Z"
        />
        <path
            fill="#000"
            d="M11.999 22.728c-5.38 0-9.759-4.379-9.759-9.759C2.24 7.59 6.62 3.211 12 3.211c5.38 0 9.758 4.378 9.758 9.758 0 5.383-4.378 9.759-9.758 9.759Zm0-18.518c-4.83 0-8.76 3.929-8.76 8.76 0 4.83 3.93 8.759 8.76 8.759s8.76-3.929 8.76-8.76c0-4.83-3.93-8.759-8.76-8.759Z"
        />
        <path
            fill="#000"
            d="M11.999 22.728c-5.38 0-9.759-4.379-9.759-9.759C2.24 7.59 6.62 3.211 12 3.211c5.38 0 9.758 4.378 9.758 9.758 0 5.383-4.378 9.759-9.758 9.759Zm0-18.518c-4.83 0-8.76 3.929-8.76 8.76 0 4.83 3.93 8.759 8.76 8.759s8.76-3.929 8.76-8.76c0-4.83-3.93-8.759-8.76-8.759Z"
        />
        <path
            fill="#000"
            d="m4.351 4.386-.847.847 1.585 1.585.847-.847-1.585-1.585ZM18.906 18.94l-.847.848 1.585 1.584.847-.847-1.585-1.585Z"
        />
        <path
            fill="#EAEAFE"
            d="M23.413 12.284h-2.241v1.198h2.241v-1.198ZM2.825 12.284H.584v1.198h2.241v-1.198Z"
        />
    </svg>
)
export default ETHTokenSVG
