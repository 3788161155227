import * as React from "react"
const BitCoinToken40SVG = ({ color = '#000' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        fill="none"
    >
        <g fill={color} clipPath="url(#a)">
            <path d="M19.975 0C8.945 0 0 8.945 0 19.975c0 11.029 8.945 19.974 19.975 19.974C31.004 39.95 40 31.004 40 19.975 40 8.945 31.004 0 19.975 0Zm0 34.612c-8.03 0-14.587-6.556-14.587-14.587 0-8.03 6.556-14.587 14.587-14.587 8.03 0 14.587 6.557 14.587 14.587s-6.506 14.587-14.587 14.587ZM30.699 7.776C28.056 5.49 24.7 4.016 20.99 3.812V1.677a18.429 18.429 0 0 1 11.131 4.575l-1.423 1.524Zm-11.69-4.015c-3.71.203-7.065 1.677-9.708 3.965L7.827 6.252c2.999-2.643 6.862-4.371 11.131-4.575v2.084h.05ZM7.878 9.15a16.176 16.176 0 0 0-4.117 9.707H1.728A18.136 18.136 0 0 1 6.455 7.675l1.423 1.474Zm-4.117 11.69a16.055 16.055 0 0 0 4.015 9.809l-1.474 1.474c-2.693-3.05-4.421-6.963-4.574-11.283h2.033ZM9.15 32.122c2.643 2.389 6.048 3.914 9.86 4.117v2.084c-4.32-.254-8.285-1.983-11.283-4.727l1.423-1.474Zm11.842 4.117c3.761-.254 7.217-1.728 9.86-4.117l1.474 1.474a18.413 18.413 0 0 1-11.283 4.727v-2.084h-.05Zm11.233-5.54a16.18 16.18 0 0 0 4.015-9.81h2.084a18.258 18.258 0 0 1-4.575 11.284l-1.524-1.474Zm3.964-11.843c-.254-3.71-1.779-7.064-4.117-9.707l1.474-1.474c2.694 2.998 4.473 6.861 4.727 11.181h-2.084Z" />
            <path d="M25.261 19.924a4.46 4.46 0 0 0 1.322-3.151 4.425 4.425 0 0 0-4.422-4.422h-.61V9.657h-1.677v2.694h-1.17V9.657h-1.677v2.694h-4.371v1.677h2.186V26.684h-2.186v1.677h4.371v2.643h1.677V28.31h1.17v2.694h1.677V28.31h1.83a4.425 4.425 0 0 0 4.421-4.421 4.352 4.352 0 0 0-2.54-3.965Zm-8.742-5.947H22.161a2.733 2.733 0 0 1 2.744 2.745 2.733 2.733 0 0 1-2.744 2.745h-5.642v-5.49Zm6.862 12.707h-6.862v-5.54h6.862a2.733 2.733 0 0 1 2.744 2.745c0 1.524-1.22 2.795-2.744 2.795Z" />
        </g>
        <defs>
            <clipPath id="a">
                <rect width={40} height={40} fill="#fff" rx={15} />
            </clipPath>
        </defs>
    </svg>
)
export default BitCoinToken40SVG;
