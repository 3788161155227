import React from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';

const BeforeSwitchModal = ({ width = '345px', open, handleClose }) => {

    return (
        <Modal
            open={open}
            handleClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ backdropFilter: 'blur(12px)' }}
        >

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Box sx={{ width: { sm: width, xs: '90%' }, maxWidth: { sm: width, xs: '90%' }, backgroundColor: 'common.white', padding: '32px', paddingTop: '16px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button sx={{ padding: 0, minWidth: 0 }} color='black' onClick={handleClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path d="M12.6673 4.24109L3.33398 13.5744" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12.6673 13.5744L3.33398 4.24109" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Button>
                    </Box>
                    <Box sx={{ marginBottom: '16px' }}>
                        <Typography variant='inter16Bold'>
                            Before you switch to Polygon
                        </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '32px' }}>
                        <Typography variant='inter14'>
                            Please note that Polygon uses USDC.e for transactions.
                        </Typography>
                    </Box>
                    <Button variant='contained' color='black' onClick={handleClose} sx={{ width: '100%' }}>
                        PROCEED
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default BeforeSwitchModal;