
import React from 'react';

const Token1SVG = ({ width = 88, height = 88 }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 88 88" fill="none">
        <path d="M44 88.7546C19.6881 88.7546 -2.10792e-07 68.9919 -1.35921e-07 44.7546C-6.10491e-08 20.5174 19.7627 0.754639 44 0.754639C68.2373 0.754639 88 20.5174 88 44.7546C88 68.9919 68.3119 88.7546 44 88.7546ZM44 2.91735C20.9559 2.91735 2.23729 21.636 2.23729 44.6801C2.23729 67.7241 20.9559 86.4428 44 86.4428C67.0441 86.4428 85.7627 67.7241 85.7627 44.6801C85.7627 21.636 67.1186 2.91735 44 2.91735Z" fill="#FCF5E8" />
        <path d="M84.8648 30.8845L58.9868 30.8845C58.3902 30.8845 57.8682 30.3625 57.8682 29.7659L57.8682 3.88794L60.1055 3.88794L60.1055 28.7218L84.9394 28.7218L84.9394 30.8845L84.8648 30.8845Z" fill="#FCF5E8" />
        <path d="M29.0844 30.8842L3.72852 30.8842L3.72852 28.6469L27.9658 28.6469L27.9658 4.78247L30.2031 4.78247L30.2031 29.7655C30.2031 30.4367 29.7556 30.8842 29.0844 30.8842Z" fill="#FCF5E8" />
        <path d="M60.1055 85.5486L57.8682 85.5486L57.8682 59.6706C57.8682 59.074 58.3902 58.552 58.9868 58.552L84.9394 58.552L84.9394 60.7893L60.1055 60.7893L60.1055 85.5486Z" fill="#FCF5E8" />
        <path d="M30.2018 84.952L27.9645 84.952L27.9645 60.7893L3.80176 60.7893L3.80176 58.552L29.1577 58.552C29.7543 58.552 30.2763 59.074 30.2763 59.6706L30.2763 84.952L30.2018 84.952Z" fill="#FCF5E8" />
        <path d="M45.1181 24.3955L42.8809 24.3955L42.8809 66.0836L45.1181 66.0836L45.1181 24.3955Z" fill="#FCF5E8" />
        <path d="M64.8805 44.084L23.1924 44.084L23.1924 46.3213L64.8805 46.3213L64.8805 44.084Z" fill="#FCF5E8" />
        <path d="M78.6787 36.7756L76.4414 36.7756L76.4414 53.779L78.6787 53.779L78.6787 36.7756Z" fill="#FCF5E8" />
        <path d="M86.0595 44.0837L69.0562 44.0837L69.0562 46.321L86.0595 46.321L86.0595 44.0837Z" fill="#FCF5E8" />
        <path d="M45.1181 1.79907L42.8809 1.79907L42.8809 18.8025L45.1181 18.8025L45.1181 1.79907Z" fill="#FCF5E8" />
        <path d="M52.5771 9.18188L35.5737 9.18188L35.5737 11.4192L52.5771 11.4192L52.5771 9.18188Z" fill="#FCF5E8" />
        <path d="M45.1172 70.634L42.8799 70.634L42.8799 87.6374L45.1172 87.6374L45.1172 70.634Z" fill="#FCF5E8" />
        <path d="M52.5771 78.0171L35.5737 78.0171L35.5737 80.2544L52.5771 80.2544L52.5771 78.0171Z" fill="#FCF5E8" />
        <path d="M12.0073 36.7756L9.77002 36.7756L9.77002 53.779L12.0073 53.779L12.0073 36.7756Z" fill="#FCF5E8" />
        <path d="M19.4677 44.0837L2.46436 44.0837L2.46436 46.321L19.4677 46.321L19.4677 44.0837Z" fill="#FCF5E8" />
        <path d="M66.5239 82.1927L64.2866 82.1927L64.2866 66.0842C64.2866 65.4876 64.8087 64.9656 65.4053 64.9656L81.0663 64.9656L81.0663 67.2029L66.5239 67.2029L66.5239 82.1927Z" fill="#FCF5E8" />
        <path d="M81.29 24.4701L65.4053 24.4701C64.8087 24.4701 64.2866 23.9481 64.2866 23.3515L64.2866 8.13794L66.5239 8.13794L66.5239 22.2329L81.29 22.2329L81.29 24.4701Z" fill="#FCF5E8" />
        <path d="M22.6719 24.4704L6.78711 24.4704L6.78711 22.2331L21.4786 22.2331L21.4786 8.13818L23.7159 8.13818L23.7159 23.3517C23.7905 23.9484 23.2685 24.4704 22.6719 24.4704Z" fill="#FCF5E8" />
        <path d="M23.7905 82.1927L21.5532 82.1927L21.5532 67.2029L6.78711 67.2029L6.78711 64.9656L22.5973 64.9656C23.1939 64.9656 23.7159 65.4876 23.7159 66.0842L23.7159 82.1927L23.7905 82.1927Z" fill="#FCF5E8" />
        <path d="M45.1181 24.3958L42.8809 24.3958L42.8809 66.0839L45.1181 66.0839L45.1181 24.3958Z" fill="#FCF5E8" />
        <path d="M64.8805 44.084L23.1924 44.084L23.1924 46.3213L64.8805 46.3213L64.8805 44.084Z" fill="#FCF5E8" />
    </svg>
);

export default Token1SVG;