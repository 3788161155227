import { Typography, Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const NavButton = ({
    href = "",
    label = 'Button',
    variant = 'text',
    color = 'white',
    textColor = 'common.white',
    textVariant = 'inter14SemiBold',
    hoverBackgroundColor = 'rgba(255, 255, 255, 0.2)',
    sx,
    newTab,
    startIcon
}) => {
    hoverBackgroundColor = hoverBackgroundColor === 'primary' ? 'rgba(255, 112, 51, 1)' : hoverBackgroundColor
    hoverBackgroundColor = hoverBackgroundColor === 'black' ? 'rgba(85, 85, 85, 1)' : hoverBackgroundColor
    return (
        <Link to={href} target={newTab ? '_blank' : '_self'} style={{ textDecoration: 'none', display: 'block' }}>
            <Button
                variant={variant}
                color={color}
                startIcon={startIcon}
                sx={{
                    ...sx,
                    height: '100%',
                    '&:hover': {
                        backgroundColor: hoverBackgroundColor
                    }
                }}>
                <Typography variant={textVariant} color={textColor}>
                    {label}
                </Typography>
            </Button>
        </Link>
    );
}

export default NavButton;