import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import AuthBarCodeSVG from '../SVGs/Auth/AuthBarCodeSVG';
import { useForm } from "react-hook-form";
import FormInput from '../FormInput/FormInput';
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useNavigate } from 'react-router-dom';
import UukhaiFlagPrimarySVG from '../SVGs/UukhaiFlagPrimarySVG';

const schema = yup
    .object({
        username: yup.string().required('Username is required'),
        password: yup.string().required('Password is required').min(3, 'Password must be atleast 3 characters')
    })
    .required()

const ForgotPasswordSuccessForm = ({ }) => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) })

    return (
        <Box sx={{
            marginLeft: 'auto',
            background: 'url("/assets/svgs/folder-primary.svg")',
            maxWidth: { xl: '488px', lg: '438px', md: '388px', sm: '388px', xs: '388px' },
            width: { xl: '488px', lg: '438px', md: '388px', sm: '388px', xs: '100%' },
            position: 'relative',
            zIndex: 2
        }}>
            <Box sx={{ paddingLeft: '10%', position: 'absolute', top: '2px', left: '0', width: '183.404px' }}>
                <AuthBarCodeSVG />
            </Box>
            <Box sx={{ padding: { md: '10%', xs: '5% 10%' }, minHeight: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {/* <Box sx={{ display: 'flex', justifyContent: 'center' }}> */}
                <UukhaiFlagPrimarySVG />
                {/* </Box> */}
                <Box sx={{ marginTop: '16px', marginBottom: '8px' }}>
                    <Typography variant='inter20' color='common.white'>
                        Password reset successful!
                    </Typography>
                </Box>
                <Box sx={{}}>
                    <Typography variant='inter14' color='common.white'>
                        You may login using your new password now.
                    </Typography>
                </Box>
                <Box sx={{ width: '100%', marginTop: 'auto' }}>
                    <Button
                        variant="contained"
                        sx={{ width: '100%', paddingTop: { xs: '10px' }, paddingBottom: { xs: '10px' }, marginBottom: { md: '15px', xs: '10px' } }}
                        onClick={() => navigate('/login')}>
                        <Typography variant='inter14Bold' color='white'>
                            LOGIN
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Box >
    );
}

export default ForgotPasswordSuccessForm;