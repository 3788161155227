import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import OpenEcosystemSVG from '../../../components/SVGs/OpenEcosystemSVG';

const OpenEcoSystem = () => (

    <Box component='section' sx={{
        backgroundColor: 'common.black', overflowX: 'scroll', marginTop: { xl: '-70px', lg: '-53px', md: '-20px', sm: '-44px', xs: '-36px' }
    }}>

        < Container maxWidth='xl' fixed sx={{ position: 'relative' }}>
            <Box sx={{ position: 'absolute', width: { md: '99%', sm: '1100px', xs: '900px' }, height: { xl: '880px', lg: '730px', md: '624px', sm: '624px', xs: '520px' }, top: '0.5%', bottom: '0.5%', left: '0.5%', right: '0.5%' }}>
                <Box component='img' src='/assets/svgs/folder-long.svg' width='100%' height='100%' />
            </Box>
            <Box sx={{ position: 'absolute', left: '3%', top: { md: '3%', xs: '2%' } }}>
                <Typography variant='subject' color="primary">
                    {'// BALJUNA OPEN ECOSYSTEM'}
                </Typography>
            </Box>
            <Box sx={{ width: { md: '100%', sm: '1100px', xs: '900px' }, height: { xl: '900px', lg: '750px', md: '600px', sm: '632px', xs: '528px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ width: '90%', height: 'auto', zIndex: 1, margin: 'auto' }}>
                    <OpenEcosystemSVG />
                    {/* <Box component='img' src='/assets/svgs/open-ecosystem.svg' width='100%' height='100%' /> */}
                </Box>
            </Box>
        </Container >
    </Box >
);

export default OpenEcoSystem;