import React from 'react';
import { Box } from '@mui/material';
import HomeHeader from '../../../components/HomeHeader/HomeHeader';
import ProductsCarousel from './ProductsCarousel/ProductsCarousel';

const DiscerningInvestor = () => (
    <Box id="investment" component='section' sx={{
        boxSizing: 'border-box', backgroundColor: 'common.black',
        paddingTop: {
            xl: '44px',
            lg: '32px',
            md: '26px',
            xs: '20px'
        },
        paddingBottom: {
            xl: '32px',
            lg: '24px'
        },
        paddingLeft: {
            xl: '32px',
            lg: '24px'
        },
        paddingRight: {
            xl: '32px',
            lg: '24px'
        },
        overflow: 'hidden'
    }}>
        <Box sx={{ borderWidth: '0.75px', borderStyle: 'solid', borderColor: 'secondary.main', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <HomeHeader
                sx={{ marginTop: '5%', marginBottom: '50px', display: { md: 'block', xs: 'none' } }}
                title="FOR THE DISCERNING INVESTOR"
                titleColor='secondary.main'
                description="On-chain protocol that is made  to bring peace of mind"
                descriptionColor='common.white'
            />
            <HomeHeader
                sx={{ marginTop: '5%', marginBottom: '50px', padding: '5%', display: { md: 'none', xs: 'block' } }}
                title=" BUILT FOR THE DISCERNING INVESTOR."
                titleColor='secondary.main'
                description=" Made to bring peace of mind through easy execution"
                descriptionColor='common.white'
            />
            <Box sx={{ maxWidth: '1500px', width: { md: '100%', sm: '130%', xs: '180%' }, height: { lg: '580px', md: '450px', xs: '400px' }, marginBottom: '30px' }} >
                <ProductsCarousel />
            </Box>
        </Box>
    </Box >
);

export default DiscerningInvestor;