import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import DiscordIconSVG from '../../../components/SVGs/SocialMedia/DiscordIconSVG';
import TwitterIconSVG from '../../../components/SVGs/SocialMedia/TwitterIconSVG';
import NavButton from '../../../components/NavButton/NavButton';
import LogoComponent from '../../../components/LogoComponent/LogoComponent';

const MenuItems = [
    {
        label: 'MEET THE TEAM',
        href: '/team'
    },
    {
        label: 'DOCUMENTATION',
        href: 'https://baljuna.gitbook.io/baljuna-waters-documentation/',
        newTab: true
    },
    {
        label: 'TOKENOMICS',
        href: 'https://baljuna.gitbook.io/baljuna-waters-documentation/group-3/baljuna-profit-sharing-token-bps-tokenomics',
        newTab: true
    },
    {
        label: 'ROADMAP',
        href: '/roadmap'
    },
    {
        label: 'TERMS OF SERVICE',
        href: 'https://baljuna.gitbook.io/terms-and-agreement/'
    },
];

const Footer = () => (
    <Box component={'footer'} sx={{ backgroundColor: 'common.black' }}>
        <Box sx={{ padding: { lg: '48px', md: '24px', sm: '48px', xs: '24px' } }}>
            <Box
                sx={{
                    display: 'flex',
                    backgroundColor: 'common.black',
                    paddingLeft: '0px !important',
                    paddingRight: '0px !important',
                    minHeight: '0px',
                    alignItems: 'flex-start',
                    flexDirection: { md: 'row', xs: 'column' }
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <LogoComponent />
                    <Typography variant='footer' color='common.white' sx={{ marginTop: '16px', marginBottom: '24px' }}>
                        Superior solutions to modern DeFi.
                    </Typography>

                    <Typography variant='footer' color='common.white' sx={{ opacity: 0.5 }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g clipPath="url(#clip0_717_17299)">
                                <path d="M8 9C9.10457 9 10 8.10457 10 7C10 5.89543 9.10457 5 8 5C6.89543 5 6 5.89543 6 7C6 8.10457 6.89543 9 8 9Z" fill="#808080" />
                                <path d="M7.3 15.7C7.2 15.6 3.1 12 3.1 11.9C1.7 10.7 1 8.9 1 7C1 3.1 4.1 0 8 0C11.9 0 15 3.1 15 7C15 8.9 14.3 10.7 12.9 12C12.8 12.1 8.8 15.7 8.7 15.8C8.3 16.1 7.7 16.1 7.3 15.7ZM4.6 10.7L8 13.7L11.4 10.7C12.4 9.7 13 8.5 13 7.1C13 4.3 10.8 2.1 8 2.1C5.2 2.1 3 4.2 3 7C3 8.4 3.6 9.7 4.6 10.7C4.6 10.6 4.6 10.6 4.6 10.7Z" fill="#808080" />
                            </g>
                            <defs>
                                <clipPath id="clip0_717_17299">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        &nbsp; 30F Watten rise, Singapore 287403
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { md: 'flex-end', xs: 'flex-start' }, marginBottom: { md: '0px', xs: '30px' } }}>
                    <Box sx={{
                        alignSelf: 'flex-start',
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                        gap: {
                            xl: '32px',
                            lg: '24px',
                            md: '16px',
                            sm: '8px'
                        }, boxSizing: 'border-box', paddingLeft: { md: '32px' }, alignItems: { md: 'center', xs: 'flex-start' },
                        marginBottom: '48px'
                    }}>
                        {MenuItems.map(({ label, href, newTab }, index) => (
                            <NavButton
                                key={index}
                                label={label}
                                href={href}
                                newTab={newTab}
                            />
                        ))}
                        <NavButton
                            href="/signup"
                            variant='outlined'
                            color='primary'
                            textColor='primary'
                            label='GET STARTED'
                            sx={{ padding: { xl: '10px 16px', lg: '6px 12px', md: '0px 6px', } }}
                        />
                    </Box>
                    <Box>
                        <Button color='black' sx={{ borderRadius: '50px', marigin: 0, minWidth: 0 }}>
                            <TwitterIconSVG />
                        </Button>
                        <Button color='black' sx={{ borderRadius: '50px', marigin: 0, minWidth: 0 }}>
                            <DiscordIconSVG />
                        </Button>
                        <Button color='black' sx={{ borderRadius: '50px', marigin: 0, minWidth: 0 }}>
                            <Box component='img' src='/assets/icons/telegram.png' />
                        </Button>
                    </Box>
                    <Typography variant='footer' color='common.white' sx={{ opacity: 0.5, marginTop: '16px' }}>
                        Partnerships: sales@baljuna.io
                    </Typography>
                    <Typography variant='footer' color='common.white' sx={{ opacity: 0.5, marginTop: '16px' }}>
                        Technical Support: george@baljuna.io
                    </Typography>
                    <Typography variant='footer' color='common.white' sx={{ opacity: 0.5, marginTop: '16px' }}>
                        Complaints and Suggestions: george@baljuna.io
                    </Typography>
                </Box>
            </Box>
        </Box>
        <Box sx={{
            paddingTop: '14px',
            paddingBottom: '14px',
            borderTopColor: 'rgba(255, 255, 255, 0.20)',
            borderTopWidth: '1px',
            borderTopStyle: 'solid',
            textAlign: 'center'
        }}>
            <Typography variant="footer" color="common.white" sx={{ opacity: 0.5 }}>
                © 2023 BALJUNA. All rights reserved.
            </Typography>
        </Box>
    </Box>

);

export default Footer;