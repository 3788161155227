
import React from 'react';

const LogoTableSVG = ({ width = 592, height = 34 }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
        <g clipPath="url(#clip0_717_17174)">
            <path d="M19.3341 7.68735H9.66699V4.83544C9.66699 3.5605 10.1699 2.33705 11.0645 1.43235C12.9454 -0.468932 16.0099 -0.477283 17.9019 1.41425L17.9172 1.42956C18.8243 2.33705 19.3327 3.56746 19.3327 4.85075V7.68735H19.3341Z" fill="black" />
            <path d="M21.8072 17.372H29.0002L19.3345 7.6875V14.8945L21.8072 17.372Z" fill="black" />
            <path d="M7.19166 17.374H0L9.66574 7.68945V14.8951L7.19166 17.374Z" fill="black" />
            <path d="M19.3327 14.894H9.66699V18.3152H19.3327V14.894Z" fill="black" />
            <path d="M21.8072 28H29.0002L19.3345 18.3154V25.5211L21.8072 28Z" fill="black" />
            <path d="M7.19451 28H0.00146484L9.6672 18.3154V25.5211L7.19451 28Z" fill="black" />
        </g>
        <defs>
            <clipPath id="clip0_717_17174">
                <rect width="29" height="28" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default LogoTableSVG;