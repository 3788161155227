import { Box, Card, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';

const InvestorCard = ({ id, currentSlide, setCurrentSlide, setIsClickSide, title, description, icon, ...props }) => {
    const iconBoxRef = useRef();

    useEffect(() => {
        if (id === (currentSlide + 1)) {
            iconBoxRef.current.style.height = '100%';
            iconBoxRef.current.style.width = '100%';
            iconBoxRef.current.style.right = '0%';
        } else {
            iconBoxRef.current.style.height = '75%'
            iconBoxRef.current.style.width = '75%';
            iconBoxRef.current.style.right = '15%';
        }
        // eslint-disable-next-line
    }, [currentSlide]);

    const [touchStart, setTouchStart] = React.useState(0);
    const [touchEnd, setTouchEnd] = React.useState(true);

    function handleTouchStart(e) {
        setTouchStart(e.targetTouches[0].clientX);
    }

    function handleTouchMove(e) {
        const touchMove = e.targetTouches[0].clientX;
        if (touchEnd) {
            if (touchStart - touchMove > 100) {
                // do your stuff here for left swipe
                setIsClickSide(true);

                setTouchEnd(false);
                setTouchStart(0);
                currentSlide >= 5 ? setCurrentSlide(0) : setCurrentSlide(prev => prev + 1);

                const myTimeout = setTimeout(() => {
                    setIsClickSide(false);
                    clearTimeout(myTimeout);
                }, 500);

            } else if (touchStart - touchMove < -100) {
                // do your stuff here for right swipe
                setIsClickSide(true);

                setTouchEnd(false);
                setTouchStart(0);
                currentSlide <= 0 ? setCurrentSlide(5) : setCurrentSlide(prev => prev - 1);

                const myTimeout = setTimeout(() => {
                    setIsClickSide(false);
                    clearTimeout(myTimeout);
                }, 500);
            }
        }
    }

    const handleTouchEnd = () => {
        setTouchEnd(true);
    }

    return (
        <Card sx={{
            minHeight: { xl: '580px', lg: '500px', md: '500px', sm: '450px', xs: '400px' },
            maxWidth: { xl: '430px', lg: '400px', md: '300px', sm: '275px', xs: '250px' },
            width: { lg: '400px', xs: 'calc(100vw * 0.7)' },
            background: 'linear-gradient(31deg, #1A1A1A 29.03%, rgba(26, 26, 26, 0.00) 84.77%)',
            // borderWidth: '2.408px',
            // borderColor: '#343434',
            // borderStyle: 'solid',
            backgroundImage: "url('data:image/svg+xml,%3Csvg%20width%3D%22432%22%20height%3D%22580%22%20viewBox%3D%220%200%20432%20580%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M179.417%2050.8885L179.777%2051.2993H180.323H430.286V578.586H1.71459V1.69947L136.347%201.69946L179.417%2050.8885Z%22%20fill%3D%22url(%23paint0_linear_436_9028)%22%20stroke%3D%22%23343434%22%20strokeWidth%3D%222.4077%22%2F%3E%0A%3Cdefs%3E%0A%3ClinearGradient%20id%3D%22paint0_linear_436_9028%22%20x1%3D%22138.51%22%20y1%3D%22422.154%22%20x2%3D%22392.78%22%20y2%3D%22110.673%22%20gradientUnits%3D%22userSpaceOnUse%22%3E%0A%3Cstop%20stopColor%3D%22%231A1A1A%22%2F%3E%0A%3Cstop%20offset%3D%221%22%20stopColor%3D%22%231A1A1A%22%20stop-opacity%3D%220%22%2F%3E%0A%3C%2FlinearGradient%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E%0A')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            position: 'relative',
            justifyContent: 'flex-start',
            overflow: 'visible',
            paddingLeft: '5%'
        }} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
            <Box sx={{ position: 'relative', marginBottom: { lg: '30px', xs: '20px' } }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '3%' }}>
                    <Typography sx={{
                        color: '#808080',
                        fontFamily: 'Roboto Mono',
                        fontSize: '26.982px',
                        fontWeight: 700,
                        lineHeight: '40.472px',
                        textAlign: 'start'
                    }}>{'// 0' + id}</Typography>
                    <Box sx={{
                        marginTop: '20px', width: '0px', borderLeftStyle: 'dashed', borderLeftColor: 'primary.main', borderLeftWidth: '2px',
                        height: { lg: '270px', xs: '160px' }
                    }} />
                </Box>
                <Box ref={iconBoxRef} sx={{ transition: '0.3s', position: 'absolute', bottom: 0, right: '5%', height: '80%', width: '80%', display: 'flex', justifyContent: 'center' }}>
                    {icon}
                </Box>
            </Box>

            <Box sx={{ paddingRight: { lg: '5%' } }}>
                <Typography variant='h4' color="secondary" align='left'>
                    {title}
                </Typography>
                <Typography variant='body2' color="#B0A592" align='left' sx={{ marginBottom: { lg: '50px', xs: '20px' } }}>
                    {description}
                </Typography>
            </Box>
        </Card>
    );
}

export default InvestorCard;