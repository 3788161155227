import React, { useState } from 'react';
import { Box, Button, ButtonGroup, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import BarCodeVertical2SVG from '../../../components/SVGs/BarCodeVertical2SVG';
import HeaderLine00SVG from '../../../components/SVGs/HeaderLine00SVG';
import VictoriousIllustrationSVG from '../../../components/SVGs/VictoriousIllustrationSVG';
import HomeHeader from '../../../components/HomeHeader/HomeHeader';
import LogoTableSVG from '../../../components/SVGs/LogoTableSVG';
import CheckOrageSVG from '../../../components/SVGs/CheckOrageSVG';
import BarCodeVertical2SmallSVG from '../../../components/SVGs/BarCodeVertical2SmallSVG';
import HeaderLine00SmallSVG from '../../../components/SVGs/HeaderLine00SmallSVG';
import VictoriousIllustration2SVG from '../../../components/SVGs/VictoriousIllustration2SVG';
import { useParallax } from 'react-scroll-parallax';

const Documentation = () => {

    const [tableToggle, setTableToggle] = useState(true);
    const { ref } = useParallax({
        translateX: [100, -100]
    });
    const { ref: ref2 } = useParallax({
        translateX: [100, -100]
    });

    return (
        <Box
            id="documentation"
            component="section"
            sx={{ position: 'relative', backgroundColor: 'secondary.main', paddingBottom: { md: '150px' }, overflow: 'hidden' }}
        >
            <Box sx={{ position: 'absolute', left: '0px', top: '25%', display: { md: 'block', xs: 'none' } }}>
                <BarCodeVertical2SVG />
            </Box>
            <Box sx={{ position: 'absolute', left: '0px', top: '5%', display: { md: 'none', xs: 'block' } }}>
                <BarCodeVertical2SmallSVG />
            </Box>

            <Box sx={{ position: 'absolute', right: '0px', top: '40px', display: { md: 'block', xs: 'none' } }}>
                <HeaderLine00SVG />
            </Box>

            <Box sx={{ position: 'absolute', right: '0px', top: '25px', display: { md: 'none', xs: 'block' } }}>
                <HeaderLine00SmallSVG />
            </Box>


            <Box ref={ref} sx={{ position: 'absolute', bottom: { lg: '10%', md: '5%' }, right: { lg: '5%', md: '3%' }, display: { md: 'block', xs: 'none' } }}>
                <VictoriousIllustrationSVG />
            </Box>

            <Box ref={ref2} sx={{ position: 'absolute', bottom: '2%', right: '0%', zIndex: 1, display: { md: 'none', xs: 'block' } }}>
                <VictoriousIllustration2SVG />
            </Box>

            <Container maxWidth='xl' fixed>
                <HomeHeader
                    sx={{ textAlign: 'center', paddingTop: '10%', marginBottom: '50px', display: { md: 'block', xs: 'none' } }}
                    title="No drama with Baljuna"
                    description="Experience true DeFi evolution with our superior solutions"
                />
                <HomeHeader
                    sx={{ textAlign: 'center', paddingTop: '70px', marginBottom: '30px', display: { md: 'none', xs: 'block' } }}
                    title="WHY CHOOSE BALJUNA SMART TRADING"
                    description="Baljuna Trading is better than existing DeFi and CeFi alternatives."
                />

                <ButtonGroup sx={{ width: '100%', marginBottom: '20px', display: { md: 'none', xs: 'block' } }}>
                    <Button
                        variant={tableToggle ? 'contained' : 'outlined'}
                        color='black'
                        sx={{
                            padding: { sm: '8px 24px', xs: '6px 18px' },
                            width: '50%'
                        }}

                        onClick={() => setTableToggle(true)}>
                        <Typography variant='button1'>
                            DeFi
                        </Typography>
                    </Button>
                    <Button
                        variant={!tableToggle ? 'contained' : 'outlined'}
                        color='black'
                        sx={{
                            padding: { sm: '8px 24px', xs: '6px 18px' },
                            width: '50%'
                        }}
                        onClick={() => setTableToggle(false)}>
                        <Typography variant='button1'>
                            CeFi
                        </Typography>
                    </Button>
                </ButtonGroup>

                <Box sx={{ width: '90%', margin: 'auto', display: { md: 'block', xs: 'none' } }}>
                    <TableContainer component={Paper} sx={{ backgroundColor: 'transparent', }}>
                        <Table sx={{ border: 1 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ borderBottomColor: 'common.black', borderRightWidth: '1px', borderRightStyle: 'solid' }}>
                                        <Typography variant='tableHeader'>
                                            Platform
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={4} align='center' sx={{ borderBottomColor: 'common.black', borderRightWidth: '1px', borderRightStyle: 'solid' }}>
                                        <Typography variant='tableHeader'>
                                            DeFi
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={2} align='center' sx={{ borderBottomColor: 'common.black' }}>
                                        <Typography variant='tableHeader'>
                                            CeFi
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ border: 1 }}>
                                    <TableCell sx={{ borderBottomColor: 'common.black', borderRightWidth: '1px', borderRightStyle: 'solid' }}>
                                        <Typography variant='tableSubHeader'>
                                            Type
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderColor: 'primary.main',
                                        borderWidth: '2px',
                                        borderStyle: 'solid',
                                        borderBottomWidth: '1px',
                                        borderBottomColor: 'common.black',
                                        backgroundColor: '#FFC8B0',
                                    }}>
                                        <LogoTableSVG />
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomColor: 'common.black',
                                        borderLeftWidth: '1px',
                                        borderRightWidth: '1px',
                                        borderLeftStyle: 'dashed',
                                        borderLeftColor: 'rgba(0,0,0,0.2)',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                        <Typography variant='tableSubHeader'>
                                            AMM
                                        </Typography>

                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomColor: 'common.black',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                        <Typography variant='tableSubHeader'>
                                            DOV
                                        </Typography>

                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        sx={{
                                            borderBottomColor: 'common.black',
                                            borderRightWidth: '1px',
                                            borderRightStyle: 'solid'
                                        }}>
                                        <Typography variant='tableSubHeader'>
                                            Others
                                        </Typography>

                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomColor: 'common.black',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                        <Typography variant='tableSubHeader'>
                                            Exchange
                                        </Typography>

                                    </TableCell>
                                    <TableCell align='center' sx={{ borderBottomColor: 'common.black' }}>
                                        <Typography variant='tableSubHeader'>
                                            Asset Management
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                    }}>
                                        <Typography variant='tableItem'>
                                            Physical Settlement <br></br>
                                        </Typography>
                                        <Typography variant='tableSubItem'>
                                            (Buy/Sell coin)
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderColor: 'primary.main',
                                        borderWidth: '2px',
                                        borderStyle: 'solid',
                                        backgroundColor: '#FFC8B0',
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderBottomWidth: '1px',
                                        borderTopStyle: 'none',
                                    }}>
                                        <CheckOrageSVG />
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px', borderRightStyle: 'solid'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                        <CheckOrageSVG />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                    }}>
                                        <Typography variant='tableItem'>
                                            Cross Asset Margin <br></br>
                                        </Typography>
                                        <Typography variant='tableSubItem'>
                                            (BTC & USDT Acceptable)
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderColor: 'primary.main',
                                        borderWidth: '2px',
                                        borderStyle: 'solid',
                                        backgroundColor: '#FFC8B0',
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderBottomWidth: '1px',
                                        borderTopStyle: 'none',
                                    }}>
                                        <CheckOrageSVG />
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'solid'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                    }}>
                                        <CheckOrageSVG />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                    }}>
                                        <Typography variant='tableItem'>
                                            Instant Trading
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderColor: 'primary.main',
                                        borderWidth: '2px',
                                        borderStyle: 'solid',
                                        backgroundColor: '#FFC8B0',
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderBottomWidth: '1px',
                                        borderTopStyle: 'none',
                                    }}>
                                        <CheckOrageSVG />
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px', borderRightStyle: 'solid'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                        <CheckOrageSVG />
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                    }}>
                                        <CheckOrageSVG />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                    }}>
                                        <Typography variant='tableItem'>
                                            No Access to Client Asset
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderColor: 'primary.main',
                                        borderWidth: '2px',
                                        borderStyle: 'solid',
                                        backgroundColor: '#FFC8B0',
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderBottomWidth: '1px',
                                        borderTopStyle: 'none',
                                    }}>
                                        <CheckOrageSVG />
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                        <CheckOrageSVG />
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                        <CheckOrageSVG />
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px', borderRightStyle: 'solid'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                    }}>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                    }}>
                                        <Typography variant='tableItem'>
                                            Transparency of Execution
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderColor: 'primary.main',
                                        borderWidth: '2px',
                                        borderStyle: 'solid',
                                        backgroundColor: '#FFC8B0',
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderBottomWidth: '1px',
                                        borderTopStyle: 'none',
                                    }}>
                                        <CheckOrageSVG />
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                        <CheckOrageSVG />
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                        <CheckOrageSVG />
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px', borderRightStyle: 'solid'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                        <CheckOrageSVG />
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                    }}>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                    }}>
                                        <Typography variant='tableItem'>
                                            Cannot Restrict Client Account
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderColor: 'primary.main',
                                        borderWidth: '2px',
                                        borderStyle: 'solid',
                                        backgroundColor: '#FFC8B0',
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderBottomWidth: '1px',
                                        borderTopStyle: 'none',
                                    }}>
                                        <CheckOrageSVG />
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                        <CheckOrageSVG />
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                        <CheckOrageSVG />
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px', borderRightStyle: 'solid'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'dashed',
                                        borderBottomColor: 'rgba(0,0,0,0.2)',
                                    }}>
                                    </TableCell>
                                </TableRow>

                                <TableRow >
                                    <TableCell sx={{ borderBottomStyle: 'none' }}>
                                        <Typography variant='tableItem'>
                                            Cannot Restrict Client Account
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderColor: 'primary.main',
                                        borderWidth: '2px',
                                        borderStyle: 'solid',
                                        backgroundColor: '#FFC8B0',
                                        borderTopStyle: 'none',
                                    }}>
                                        <CheckOrageSVG />
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'none',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'none',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'solid',
                                        borderBottomStyle: 'none'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{
                                        borderBottomStyle: 'none',
                                        borderRightWidth: '1px',
                                        borderRightStyle: 'dashed',
                                        borderRightColor: 'rgba(0,0,0,0.2)'
                                    }}>
                                    </TableCell>
                                    <TableCell align='center' sx={{ borderBottomStyle: 'none' }}>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                    {tableToggle === true ? (
                        <Box sx={{ textAlign: 'center', width: '100%', margin: 'auto', paddingBottom: '170px', position: 'relative' }}>
                            <TableContainer component={Paper} sx={{ backgroundColor: 'transparent', }}>
                                <Table sx={{ border: 1, borderColor: '#000', minWidth: '500px' }}>
                                    <TableHead sx={{ border: 1 }}>
                                        <TableRow>
                                            <TableCell width="30%" variant='head' sx={{ borderBottomColor: 'common.black', borderRightWidth: '1px', borderRightStyle: 'solid' }}>
                                                <Typography variant='tableHeader'>
                                                    Platform
                                                </Typography>
                                            </TableCell>
                                            <TableCell variant='head' colSpan={4} align='center' sx={{ borderBottomColor: 'common.black', borderRightWidth: '1px', borderRightStyle: 'solid' }}>
                                                <Typography variant='tableHeader'>
                                                    DeFi
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow sx={{ border: 1 }}>
                                            <TableCell width="25%" sx={{ borderBottomColor: 'common.black', borderRightWidth: '1px', borderRightStyle: 'solid' }}>
                                                <Typography variant='tableSubHeader'>
                                                    Type
                                                </Typography>
                                            </TableCell>
                                            <TableCell width="25%" align='center' sx={{
                                                borderColor: 'primary.main',
                                                borderWidth: '2px',
                                                borderStyle: 'solid',
                                                borderBottomWidth: '1px',
                                                borderBottomColor: 'common.black',
                                                backgroundColor: '#FFC8B0',
                                            }}>
                                                <LogoTableSVG />
                                            </TableCell>
                                            <TableCell width="25%" align='center' sx={{
                                                borderBottomColor: 'common.black',
                                                borderLeftWidth: '1px',
                                                borderRightWidth: '1px',
                                                borderLeftStyle: 'dashed',
                                                borderLeftColor: 'rgba(0,0,0,0.2)',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                                <Typography variant='tableSubHeader'>
                                                    AMM
                                                </Typography>

                                            </TableCell>
                                            <TableCell width="25%" align='center' sx={{
                                                borderBottomColor: 'common.black',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                                <Typography variant='tableSubHeader'>
                                                    DOV
                                                </Typography>

                                            </TableCell>
                                            <TableCell width="25%" align='center' sx={{ borderBottomColor: 'common.black', borderRightWidth: '1px', borderRightStyle: 'solid' }}>
                                                <Typography variant='tableSubHeader'>
                                                    Others
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                            }}>
                                                <Typography variant='tableItem'>
                                                    Physical Settlement <br></br>
                                                </Typography>
                                                <Typography variant='tableSubItem'>
                                                    (Buy/Sell coin)
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderColor: 'primary.main',
                                                borderWidth: '2px',
                                                borderStyle: 'solid',
                                                backgroundColor: '#FFC8B0',
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderBottomWidth: '1px',
                                                borderTopStyle: 'none',
                                            }}>
                                                <CheckOrageSVG />
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px', borderRightStyle: 'solid'
                                            }}>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                            }}>
                                                <Typography variant='tableItem'>
                                                    Cross Asset Margin <br></br>
                                                </Typography>
                                                <Typography variant='tableSubItem'>
                                                    (BTC & USDT Acceptable)
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderColor: 'primary.main',
                                                borderWidth: '2px',
                                                borderStyle: 'solid',
                                                backgroundColor: '#FFC8B0',
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderBottomWidth: '1px',
                                                borderTopStyle: 'none',
                                            }}>
                                                <CheckOrageSVG />
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'solid'
                                            }}>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                            }}>
                                                <Typography variant='tableItem'>
                                                    Instant Trading
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderColor: 'primary.main',
                                                borderWidth: '2px',
                                                borderStyle: 'solid',
                                                backgroundColor: '#FFC8B0',
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderBottomWidth: '1px',
                                                borderTopStyle: 'none',
                                            }}>
                                                <CheckOrageSVG />
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px', borderRightStyle: 'solid'
                                            }}>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                            }}>
                                                <Typography variant='tableItem'>
                                                    No Access to Client Asset
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderColor: 'primary.main',
                                                borderWidth: '2px',
                                                borderStyle: 'solid',
                                                backgroundColor: '#FFC8B0',
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderBottomWidth: '1px',
                                                borderTopStyle: 'none',
                                            }}>
                                                <CheckOrageSVG />
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                                <CheckOrageSVG />
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                                <CheckOrageSVG />
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px', borderRightStyle: 'solid'
                                            }}>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                            }}>
                                                <Typography variant='tableItem'>
                                                    Transparency of Execution
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderColor: 'primary.main',
                                                borderWidth: '2px',
                                                borderStyle: 'solid',
                                                backgroundColor: '#FFC8B0',
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderBottomWidth: '1px',
                                                borderTopStyle: 'none',
                                            }}>
                                                <CheckOrageSVG />
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                                <CheckOrageSVG />
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                                <CheckOrageSVG />
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px', borderRightStyle: 'solid'
                                            }}>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                            }}>
                                                <Typography variant='tableItem'>
                                                    Cannot Restrict Client Account
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderColor: 'primary.main',
                                                borderWidth: '2px',
                                                borderStyle: 'solid',
                                                backgroundColor: '#FFC8B0',
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderBottomWidth: '1px',
                                                borderTopStyle: 'none',
                                            }}>
                                                <CheckOrageSVG />
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                                <CheckOrageSVG />
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                                <CheckOrageSVG />
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px', borderRightStyle: 'solid'
                                            }}>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow >
                                            <TableCell sx={{ borderBottomStyle: 'none' }}>
                                                <Typography variant='tableItem'>
                                                    Cannot Restrict Client Account
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderColor: 'primary.main',
                                                borderWidth: '2px',
                                                borderStyle: 'solid',
                                                backgroundColor: '#FFC8B0',
                                                borderTopStyle: 'none',
                                            }}>
                                                <CheckOrageSVG />
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'none',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'none',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'solid',
                                                borderBottomStyle: 'none'
                                            }}>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    ) : (
                        <Box sx={{ textAlign: 'center', width: '100%', margin: 'auto', paddingBottom: '150px', position: 'relative' }}>
                            <TableContainer component={Paper} sx={{ backgroundColor: 'transparent', }}>
                                <Table sx={{ border: 1, borderColor: '#000' }}>
                                    <TableHead sx={{ border: 1 }}>
                                        <TableRow>
                                            <TableCell sx={{ borderBottomColor: 'common.black', borderRightWidth: '1px', borderRightStyle: 'solid' }}>
                                                <Typography variant='tableHeader'>
                                                    Platform
                                                </Typography>
                                            </TableCell>
                                            <TableCell colSpan={6} align='center' sx={{ borderBottomColor: 'common.black' }}>
                                                <Typography variant='tableHeader'>
                                                    CeFi
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow sx={{ border: 1 }}>
                                            <TableCell sx={{ borderBottomColor: 'common.black', borderRightWidth: '1px', borderRightStyle: 'solid' }}>
                                                <Typography variant='tableSubHeader'>
                                                    Type
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomColor: 'common.black',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                                <Typography variant='tableSubHeader'>
                                                    Exchange
                                                </Typography>

                                            </TableCell>
                                            <TableCell align='center' sx={{ borderBottomColor: 'common.black' }}>
                                                <Typography variant='tableSubHeader'>
                                                    Asset Management
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                            }}>
                                                <Typography variant='tableItem'>
                                                    Physical Settlement <br></br>
                                                </Typography>
                                                <Typography variant='tableSubItem'>
                                                    (Buy/Sell coin)
                                                </Typography>
                                            </TableCell>

                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)',
                                                borderLeftWidth: '1px',
                                                borderLeftStyle: 'solid',
                                            }}>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                                <CheckOrageSVG />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                            }}>
                                                <Typography variant='tableItem'>
                                                    Cross Asset Margin <br></br>
                                                </Typography>
                                                <Typography variant='tableSubItem'>
                                                    (BTC & USDT Acceptable)
                                                </Typography>
                                            </TableCell>

                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)',
                                                borderLeftWidth: '1px',
                                                borderLeftStyle: 'solid',
                                            }}>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                            }}>
                                                <CheckOrageSVG />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                            }}>
                                                <Typography variant='tableItem'>
                                                    Instant Trading
                                                </Typography>
                                            </TableCell>

                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)',
                                                borderLeftWidth: '1px',
                                                borderLeftStyle: 'solid',
                                            }}>
                                                <CheckOrageSVG />
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                            }}>
                                                <CheckOrageSVG />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                            }}>
                                                <Typography variant='tableItem'>
                                                    No Access to Client Asset
                                                </Typography>
                                            </TableCell>

                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)',
                                                borderLeftWidth: '1px',
                                                borderLeftStyle: 'solid',
                                            }}>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                            }}>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                            }}>
                                                <Typography variant='tableItem'>
                                                    Transparency of Execution
                                                </Typography>
                                            </TableCell>

                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)',
                                                borderLeftWidth: '1px',
                                                borderLeftStyle: 'solid',
                                            }}>
                                                <CheckOrageSVG />
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                            }}>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                            }}>
                                                <Typography variant='tableItem'>
                                                    Cannot Restrict Client Account
                                                </Typography>
                                            </TableCell>

                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)',
                                                borderLeftWidth: '1px',
                                                borderLeftStyle: 'solid',
                                            }}>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: 'rgba(0,0,0,0.2)',
                                            }}>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow >
                                            <TableCell sx={{ borderBottomStyle: 'none' }}>
                                                <Typography variant='tableItem'>
                                                    Cannot Restrict Client Account
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)',
                                                borderBottomStyle: 'none',
                                                borderLeftWidth: '1px',
                                                borderLeftStyle: 'solid',
                                            }}>
                                            </TableCell>
                                            <TableCell align='center' sx={{
                                                borderBottomStyle: 'none',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'dashed',
                                                borderRightColor: 'rgba(0,0,0,0.2)'
                                            }}>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    )}
                </Box>
            </Container >
        </Box >
    );
};

export default Documentation;