import * as React from "react"
const TradingIconSVG = ({ fill = '#FF4C00' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={'100%'}
        height={'100%'}
        viewBox="0 0 22 22"
        fill="none"
    >
        <g stroke={fill} strokeLinejoin="round" strokeWidth={1.5}>
            <path strokeLinecap="round" d="M5.263.85 1.5 4.006 5.263 7.16" />
            <path d="M2.504 4.08h16.675v6.562" />
            <path strokeLinecap="round" d="m16.67 21.16 3.764-3.155-3.763-3.156" />
            <path d="M19.43 17.93H2.754v-6.561" />
        </g>
    </svg>
)
export default TradingIconSVG
