import * as React from "react"

const AuthBarCodeSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={'100%'}
        height={'100%'}
        viewBox="0 0 184 24"
        preserveAspectRatio="xMinYMin slice"
        fill="none"
        {...props}
    >
        <path
            stroke="#1A1A1A"
            strokeWidth={4.35}
            d="M181.229 23.243V.315M2.175.315v22.928M133.385 23.243V.315M50.021.315v22.928"
        />
        <path
            stroke="#1A1A1A"
            strokeWidth={1.45}
            d="M174.705 23.243V.315M8.7.315v22.928M126.861 23.243V.315M56.545.315v22.928M150.781 23.243V.315M32.622.315v22.928M102.939 23.243V.315M80.465.315v22.928M142.81 23.243V.315M40.596.315v22.928M94.964 23.243V.315M88.44.315v22.928"
        />
        <path
            stroke="#1A1A1A"
            strokeWidth={13.051}
            d="M160.929 23.243V.315M22.475.315v22.928M113.088 23.243V.315"
        />
        <path stroke="#1A1A1A" strokeWidth={8.701} d="M68.145.315v22.928" />
        <path
            stroke="#1A1A1A"
            strokeWidth={4.35}
            d="M157.305 23.243V.315M26.097.315v22.928M109.463 23.243V.315M73.941.315v22.928"
        />
    </svg>
)
export default AuthBarCodeSVG;
