import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';
import ArrowIllustrationSVG from '../SVGs/ArrowIllustrationSVG';
import { Link } from 'react-router-dom';

const DualCurrencyReminderModal = ({ width = '345px', open, handleClose }) => (
    <Modal
        open={open}
        handleClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ backdropFilter: 'blur(12px)' }}
    >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Box sx={{ width: { sm: width, xs: '90%' }, maxWidth: { sm: width, xs: '90%' }, backgroundColor: 'common.white', padding: '32px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '24px' }}>
                    <ArrowIllustrationSVG />
                </Box>
                <Box sx={{ marginBottom: '16px' }}>
                    <Typography variant='inter16Bold'>
                        Just a friendly reminder before you begin.
                    </Typography>
                </Box>
                <Box sx={{ marginBottom: '32px' }}>
                    <Typography variant='inter14'>
                        Subscribing to Dual Currency options could lead to loss of principal capital due to the nature of crypto market volatility. Subscribed assets are also subject to lock periods depending on your chosen settlement date.
                        <br></br><br></br>
                        Please be mindful of your trades as Baljuna Waters will not assume liability of any losses incurred from price fluctuations.
                        <br></br><br></br>
                        For more information, you may check our&nbsp;
                        <Link to="https://baljuna.gitbook.io/terms-and-agreement/" target='_blank'><Typography variant='inter14Bold' color='common.black' sx={{ textDecorationLine: 'underline' }}>Terms and Agreement</Typography></Link> for using our platform.
                    </Typography>
                </Box>
                <Button variant='contained' color='black' onClick={handleClose} sx={{ width: '100%' }}>
                    I UNDERSTAND
                </Button>
            </Box>
        </Box>
    </Modal>
);

export default DualCurrencyReminderModal;