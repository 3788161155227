import * as React from "react"

const TwitterIconSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={48}
        height={48}
        viewBox="0 0 48 48"
        fill="none"
        {...props}
    >
        <circle cx={24} cy={24} r={24} fill="#1A1A1A" />
        <path
            fill="#1DA1F2"
            d="M19.547 33.75c9.057 0 14.01-7.503 14.01-14.01 0-.213 0-.425-.014-.636A10.019 10.019 0 0 0 36 16.555a9.817 9.817 0 0 1-2.828.775 4.94 4.94 0 0 0 2.165-2.724 9.863 9.863 0 0 1-3.127 1.196 4.929 4.929 0 0 0-8.391 4.49 13.979 13.979 0 0 1-10.149-5.144 4.928 4.928 0 0 0 1.525 6.573 4.881 4.881 0 0 1-2.235-.616v.062a4.926 4.926 0 0 0 3.95 4.827 4.915 4.915 0 0 1-2.223.084 4.93 4.93 0 0 0 4.6 3.42A9.88 9.88 0 0 1 12 31.538a13.941 13.941 0 0 0 7.547 2.209"
        />
    </svg>
)
export default TwitterIconSVG;
