import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';
import UukhaiFlagSVG from '../SVGs/UukhaiFlagSVG';
import BitCoinTokenSVG from '../SVGs/BitCoinTokenSVG';
import { formatNumber,tokenMapping } from '../../common';
import dayjs from 'dayjs';

const SubscribeSuccessModal = ({ open, onClickSA, onClickVT, subscribeData,chainConfig }) => {
    const {dciItem,amount,yield_rate}=subscribeData;
    const isCall=dciItem?.call_put==='CALL';
    const baseToken=chainConfig?.tokens?.[dciItem?.base_ccy];
    const contraToken=chainConfig?.tokens?.[dciItem?.contra_ccy];
    const investToken=isCall?baseToken:contraToken;
    const investTokenMapping=tokenMapping[dciItem?.base_ccy];
    const amountDisplay=formatNumber(amount,investToken?.decimals);
    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Box sx={{ width: { sm: '400px', xs: '90%' }, maxWidth: '400px', backgroundColor: 'common.white' }}>
                    <Box sx={{ marginBottom: '24px' }}>
                        <Box sx={{ position: 'relative' }}>
                            <Box sx={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <UukhaiFlagSVG />
                            </Box>
                            <Box sx={{ height: '60px', backgroundColor: '#E7E7E7' }}>

                            </Box>
                            <Box sx={{ height: '40px', backgroundColor: '#fff' }}>

                            </Box>
                        </Box>
                        <Box sx={{ textAlign: 'center', marginBottom: '16px' }}>
                            <Typography variant='inter16Bold'>
                                Successfully subscribed to {`${isCall?'Sell the Rip':'Buy the Dip'}`}
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant='roboto32'>
                                {amountDisplay} {isCall? dciItem?.base_ccy: dciItem?.contra_ccy}
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant='inter14' color='#808080'>
                                Amount invested
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ padding: '15px' }}>
                        <Box sx={{
                            borderColor: 'common.black',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            padding: '20px',
                            marginBottom: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '12px'
                        }}>
                            <Typography variant='roboto12Bold'>
                                EARNINGS BREAKDOWN
                            </Typography>
                            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                {investTokenMapping?.logo}
                                <Typography variant='inter16'>
                                    {investTokenMapping?.name}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant='inter14'>
                                    Target {isCall?'Sell':'Buy'} Price
                                </Typography>
                                <Typography variant='inter14Bold'>
                                    ${formatNumber(dciItem?.strike,0)}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant='inter14'>
                                    Settlement Date
                                </Typography>
                                <Typography variant='inter14Bold'>
                                    {dayjs.unix(dciItem?.expiry).format('DD-MMM-YY')} <Typography variant='inter14' color='#808080'>(({dayjs.unix(dciItem?.expiry).fromNow()}))</Typography>
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant='inter14'>
                                    % APY
                                </Typography>
                                <Box sx={{ border: '1px solid #037A41', padding: '0px 8px' }}>
                                    <Typography variant='inter14Bold' color='#037A41'>
                                        {(yield_rate*100)?.toFixed(0)}%
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ textAlign: 'center', marginBottom: '24px' }}>
                            <Typography variant='inter14'>
                                This transaction can be viewable under your
                                “My Transactions” tab.
                            </Typography>
                        </Box>
                        <Button variant='contained' color='black' sx={{ width: '100%', marginBottom: '10px' }} onClick={onClickSA}>
                            <Typography variant='inter14Bold'>
                                SUBSCRIBE AGAIN
                            </Typography>
                        </Button>
                        <Button variant='contained' color='white' sx={{ width: '100%', border: '1px solid #000' }} onClick={onClickVT}>
                            <Typography variant='inter14Bold'>
                                VIEW TRANSACTIONS
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal >
    );
};

export default SubscribeSuccessModal;