import * as React from "react"

const BorderTopLeftSVG = ({ fill = '#000' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        fill="none"
    >
        <path fill={fill} d="M0 0h32v6H0z" />
        <path fill={fill} d="M6 0v32H0V0z" />
    </svg>
)
export default BorderTopLeftSVG
