import * as React from "react"
const BorderBottomRightSVG = ({ fill = '#000' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        fill="none"
    >
        <path fill={fill} d="M32 32H0v-6h32z" />
        <path fill={fill} d="M26 32V0h6v32z" />
    </svg>
)
export default BorderBottomRightSVG
