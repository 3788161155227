import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import AboutUsBackgroundSVG from '../../../components/SVGs/AboutUsBackgroundSVG';

const Banner = () => (
    <Box component='section' sx={{
        height: '100vh',
        boxSizing: 'border-box', backgroundColor: 'secondary.main',
        overflow: 'hidden',
        position: 'relative'
    }}>
        <Box sx={{ position: 'absolute', height: '100%', width: '100%', marginTop: '9%' }}>
            <AboutUsBackgroundSVG />
        </Box>
        <Container maxWidth='xl' fixed sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='h1Mb' color='common.black' sx={{ textTransform: 'uppercase', textAlign: 'center', zIndex: 1 }}>
                DeFi For the <br></br> Discerning Investor
                {/* COPY<br></br>TAGLINE OR<br></br>STATEMENT<br></br>PIECE */}
            </Typography>
        </Container>
    </Box>
);

export default Banner;