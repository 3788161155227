import React, { useState,useEffect, useContext } from 'react'
import { Box, Button, ClickAwayListener, Drawer, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import MenuIconSVG from '../../../components/SVGs/MenuIconSVG'
import AdminSidebar from '../Sidebar/AdminSidebar';
import LogoutIconSVG from '../../../components/SVGs/LogoutIconSVG';
import TranslationIconSVG from '../../../components/SVGs/TranslationIconSVG';
import { useLocation, Link,useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import { ChainId, useEthers } from '@usedapp/core';
import { UserContext,ConfigContext, formatNumber } from '../../../common';
import BeforeSwitchModal from '../../../components/BeforeSwitchModal/BeforeSwitchModal';

const balance = [
    {
        label: 'BTC',
        amount: '3.2'
    },
    {
        label: 'ETH',
        amount: '1.3'
    },
    {
        label: 'USDT',
        amount: '500,000'
    },
    {
        label: 'USDC',
        amount: '500,000'
    },
    {
        label: 'iUSD',
        amount: '5,000'
    },
];

const HeaderMenu = () => {
    const location = useLocation();
    const isMyTransactionRoute = location.pathname === '/admin/dashboard/my-transaction';

    const { account, activateBrowserWallet, deactivate,chainId,switchNetwork } = useEthers();

    const [showMetaMask, setShowMetaMask] = useState(false);
    const connectedToMetaMask=account !== undefined;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [metaMaskEl, setMetaMaskEl] = useState(null);
    const openMetaMask = Boolean(metaMaskEl);
    const navigate=useNavigate();
    const user=useContext(UserContext);
    const chainName=user?.chainConfig?.name;
    console.log('chainName',chainName);
    const switchChain=()=>{
        switchNetwork(chainId===ChainId.Mainnet?ChainId.Polygon:ChainId.Mainnet);
        setMetaMaskEl(null);
    }
    

    const [openPolyMainnet, setOpenPolyMainnet] = useState(false);
    const toggleOpenPolyMainnet = () => {
        if(openPolyMainnet){
            switchNetwork(chainId===ChainId.Mainnet?ChainId.Polygon:ChainId.Mainnet);
        }
        setOpenPolyMainnet(!openPolyMainnet);
    }

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout= async()=>{
        handleClose();
        await fetch('/user/logout');
        navigate('/');
    }

    const handleMetaMaskClick = (event) => {
        setMetaMaskEl(event.currentTarget);
    };

    const handleMetaMaskClose = () => {
        setMetaMaskEl(null);
    };

    const connect = (event) => {
        setShowMetaMask(false);
        console.log('connect');
        activateBrowserWallet(event);
    };

    const handleMetaMaskDisconnect = () => {
        setMetaMaskEl(null);
        deactivate();
    };

    const [walletEl, setWalletEl] = React.useState(null);

    const handleWalletClick = (event) => {
        setWalletEl(event.currentTarget);
    };

    const handleSwitchMainnet = () => {
        if(chainId===ChainId.Polygon){
            switchNetwork(chainId===ChainId.Mainnet?ChainId.Polygon:ChainId.Mainnet);
        }
        setOpenPolyMainnet(chainId===ChainId.Mainnet);
        setWalletEl(null);
    };

    const handleWalletClose = () => {
        setWalletEl(null);
    };

    const openWallet = Boolean(walletEl);
    const walletId = open ? 'simple-popover' : undefined;

    return (
        <Box component='header'>
            <Box sx={{
                backgroundColor: '#fcf5e8',
                padding: { lg: '16px 24px', md: '8px 8px' },
                borderBottom: '1px solid #000',
                display: 'flex',
                flexDirection: { md: 'row', xs: 'column' },
                justifyContent: 'flex-end',
                gap: { lg: '24px', md: '16px', xs: '12px' }
            }}>
                <Box sx={{ flex: 1, justifyContent: 'space-between', padding: { sm: '0px 24px', xs: '0px 20px' }, display: { md: 'none', xs: 'flex' } }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                    >
                        <Box sx={{ width: { sm: '24px', xs: '20px' }, height: { sm: '24px', xs: '20px', transform: 'rotate(180deg)' } }}>
                            <MenuIconSVG width='100%' height='100%' fill='#000' />
                        </Box>
                    </IconButton>
                    <Button
                        sx={{ display: 'flex' }}
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <Typography variant='inter12' color='common.black'>
                            Hello, <Typography variant='inter12Bold' color='common.black'>{user.user}!</Typography>
                        </Typography>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M16 10L12 14L8 10" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', gap: { lg: '24px', md: '16px' }, padding: { sm: '0px 24px', xs: '0px 20px' }, marginBottom: { md: '0px', sm: '15px', marginBottom: '10px' }, justifyContent: { sm: 'space-between' } }}>
                    <Link to="/admin#my-transactions" style={{ textDecoration: 'none', padding: 0, height: '100%', display: 'block' }}>
                        <Button sx={{ padding: { md: '10px 16px', sm: '8px 14px', xs: '6px 12px' }, display: 'flex', flexDirection: 'column', height: '100%' }} color='black'>
                            <Typography variant='inter12' color='common.black'>
                                MY TRANSACTIONS
                            </Typography>
                            <svg style={{ visibility: isMyTransactionRoute ? 'visible' : 'hidden' }} width="24" height="2" viewBox="0 0 24 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="-8.74228e-08" y1="1" x2="24" y2="0.999998" stroke="black" stroke-width="2" />
                            </svg>
                        </Button>
                    </Link>
                    <Button aria-describedby={walletId} sx={{ padding: { md: '10px 16px', sm: '8px 14px', xs: '6px 12px' } }} color='black' onClick={handleWalletClick}>
                        <Typography variant='inter12' color='common.black'>
                            WALLET BALLANCE
                        </Typography>
                    </Button>
                    <Popover
                        id={walletId}
                        open={openWallet}
                        anchorEl={walletEl}
                        onClose={handleWalletClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >

                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', padding: '16px', border: '1px solid #000', borderRadius: 0, minWidth: '158px' }}>
                            <Typography variant='inter12Bold' color='#808080'>
                                MY PORTFOLIO
                            </Typography>
                            {
                                Object.keys(user?.balances||{}).map((token) => {
                                const tokenConfig=user?.chainConfig?.tokens?.[token];
                                return <Box sx={{ display: 'flex', justifyContent: 'space-between' }} key={token}>
                                    <Box sx={{ display: 'flex', gap: '8px' }}>
                                        <Box sx={{ width: '16px', height: '16px', borderRadius: '16px', backgroundColor: '#D9D9D9' }} />
                                        <Typography variant='inter14'>
                                            {token}
                                        </Typography>
                                    </Box>
                                    <Typography variant='roboto14Bold'>
                                        {formatNumber(user?.balances?.[token],tokenConfig?.displayDecimals)}
                                    </Typography>
                                </Box>
                                }
                                
                            )}
                        </Box>
                    </Popover>
                    {connectedToMetaMask && (
                        <>
                            <Button
                                sx={{ padding: { md: '10px 12px', sm: '8px 10px', xs: '6px 8px' } }}
                                id="meta-mask-button"
                                aria-controls={openMetaMask ? 'meta-mask-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openMetaMask ? 'true' : undefined}
                                onClick={handleMetaMaskClick}
                                variant='outlined'
                                color='black'
                            >
                                <Typography variant='inter12bold' color='common.black'>
                                 {account ? account.substring(0, 6) + '...' + account.slice(-4) : ''} |  <Typography variant='inter14' color='common.black'>{chainName}</Typography>
                                </Typography>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M16 10L12 14L8 10" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </Button>
                            <Menu
                                id="meta-mask-menu"
                                anchorEl={metaMaskEl}
                                open={openMetaMask}
                                onClose={handleMetaMaskClose}
                                MenuListProps={{
                                    'aria-labelledby': 'meta-mask-button',
                                    dense: true,
                                    sx: {
                                        border: '1px solid #000'
                                    }
                                }}
                            >
                                <MenuItem onClick={handleSwitchMainnet}>
                                    <Typography variant='inter14'>
                                        Switch to {chainId===ChainId.Polygon?'ETH Mainnet':'Polygon'}
                                    </Typography>
                                </MenuItem>
                                <MenuItem onClick={handleMetaMaskDisconnect}>
                                    <Typography variant='inter14'>
                                        Disconnect
                                    </Typography>
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                    {!connectedToMetaMask && (
                        <Button variant='contained' color='black' sx={{ padding: { md: '10px 16px', sm: '8px 14px', xs: '6px 12px' } }} onClick={() => setShowMetaMask(true)}>
                            <Typography variant='inter14SemiBold' color='common.white'>
                                CONNECT WALLET
                            </Typography>
                        </Button>

                    )}
                </Box>
                <Button
                    color='black'
                    sx={{ display: { md: 'flex', xs: 'none' } }}
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <Typography variant='inter12' color='common.black'>
                        Hello, <Typography variant='inter12Bold' color='common.black'>{user.user}!</Typography>
                    </Typography>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M16 10L12 14L8 10" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        dense: true,
                        sx: {
                            paddingTop: '0px'
                        }
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'primary.main', padding: '16px', minWidth: '200px' }}>
                        <Box>
                            <Typography variant='inter16Bold'>
                                Welcome, {user.user}!
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant='roboto12' color='common.white'>
                                Roles: {(user.roles||[]).join(', ')}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant='roboto12' color='common.white'>
                                {chainName}
                            </Typography>
                        </Box>
                    </Box>
                    <MenuItem onClick={handleClose} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <TranslationIconSVG />
                        <Typography variant='inter14'>
                            English
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleLogout} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <LogoutIconSVG />
                        <Typography variant='inter14'>
                            Logout
                        </Typography>
                    </MenuItem>
                </Menu>
            </Box>
            <nav>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        overflow: 'hidden',
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { sm: '50%', xs: '80%' } }
                    }}
                >
                    <AdminSidebar />
                </Drawer>
            </nav>

            {showMetaMask === true && (
                <ClickAwayListener onClickAway={() => setShowMetaMask(false)}>
                    <Box sx={{
                        position: 'absolute',
                        top: '0px', right: '0px',
                        width: '286px', height: '480px',
                        backgroundColor: '#fff',
                        border: '1px solid #000', zIndex: 10,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}>
                        <Typography variant='inter24Bold' align='center'>
                            Connect with Metamask...
                        </Typography>
                        <Button onClick={connect}>
                            Connect
                        </Button>
                    </Box>
                </ClickAwayListener>
            )}
            <BeforeSwitchModal open={openPolyMainnet} handleClose={toggleOpenPolyMainnet} />
        </Box>
    )
}

export default HeaderMenu