import { Box, Button, Modal, Typography } from '@mui/material';
import React, { useState,useContext } from 'react';
import UukhaiFlagSVG from '../../SVGs/UukhaiFlagSVG';
import { tokenMapping,formatNumber,connectContractToSigner,tryF } from '../../../common';
import ORGJSON from '../../../abis/Organization.json';
import { Contract,utils,providers } from 'ethers';
import { useEthers } from '@usedapp/core';

const ReferralModal = ({ open, handleClose,referralTotal,referralAmt,chainConfig,provider }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    
    const {library}=useEthers();
    const handleClaim=async()=>{
        const orgContract = new Contract(
            chainConfig.orgAddress,
            ORGJSON.abi,
            provider
        );
        const txs=Object.keys(referralAmt).map(async tokenName=>{
            const contractSigned=connectContractToSigner(orgContract,{
                    transactionName: `Claim Rewards ${tokenName}`,
                },
            library);
            const tx=await contractSigned.claimSalesCommission(referralAmt[tokenName].addr);
            await tx.wait();
        });
        await Promise.all(txs);
        setIsSuccess(true);
    }
    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Box sx={{ width: { sm: '400px', xs: '90%' }, maxWidth: '400px', backgroundColor: 'common.white' }}>
                    {isSuccess && (
                        <Box>
                            <Box sx={{ position: 'relative' }}>
                                <Box sx={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <UukhaiFlagSVG />
                                </Box>
                                <Box sx={{ height: '60px', backgroundColor: '#E7E7E7' }}>

                                </Box>
                                <Box sx={{ height: '40px', backgroundColor: '#fff' }}>

                                </Box>
                            </Box>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography variant='inter16Bold'>
                                    Successfully Claimed Referral Earnings
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    {!isSuccess && (
                        <Box sx={{ display: 'flex', alignItems: 'center', borderBottomColor: 'common.black', borderBottomWidth: '1px', borderBottomStyle: 'solid' }}>
                            <Box sx={{ flex: 1, padding: '0px 10px' }}>
                                <Typography variant='GoshanSans16Bold'>
                                    {'// CLAIM REFERRAL EARNINGS'}
                                </Typography>
                            </Box>

                            <Button sx={{ borderLeftColor: 'common.black', borderLeftWidth: '1px', borderLeftStyle: 'solid' }} color='black' onClick={handleClose}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                    <path d="M12.6673 4.24109L3.33398 13.5744" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12.6673 13.5744L3.33398 4.24109" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </Button>
                        </Box>
                    )}
                    <Box sx={{ padding: '15px' }}>
                        <Box sx={{
                            backgroundColor: 'common.black',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '20px 10px',
                            position: 'relative'
                        }}>
                            <Box sx={{
                                top: -1,
                                right: -1,
                                position: 'absolute',
                                width: 0,
                                height: 0,
                                borderLeft: { md: '20px solid transparent', xs: '15px solid transparent' },
                                borderTop: { md: '20px solid #fff', xs: '15px solid #fff' }
                            }} />
                            <Box>
                                <Typography variant='roboto32' color='primary'>
                                    {formatNumber(referralTotal,2)} USDC
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='inter14' color='#CCCCCC'>
                                    {isSuccess ? 'Amount Invested' : 'Total unclaimed earnings'}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{
                            borderColor: 'common.black',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            padding: '20px',
                            marginBottom: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '12px'
                        }}>
                            <Typography variant='roboto12Bold'>
                                EARNINGS BREAKDOWN
                            </Typography>
                            {
                                Object.keys(referralAmt).map((key, index) => {
                                    return <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }} key={key}>
                                        {tokenMapping[key].logo}
                                        <Typography variant='inter16'>
                                            {referralAmt[key].balance} {key}
                                        </Typography>
                                    </Box>
                                })
                            }
                        </Box>
                        {!isSuccess && (
                            <Button variant='contained' color='black' sx={{ width: '100%' }} onClick={tryF('Claiming Reward',handleClaim)}>
                                <Typography variant='inter14Bold'>
                                    CLAIM
                                </Typography>
                            </Button>
                        )}

                        {isSuccess && (
                            <>
                                {/* <Button variant='contained' color='black' sx={{ width: '100%', marginBottom: '10px' }} onClick={() => setIsSuccess(false)}>
                                    <Typography variant='inter14Bold'>
                                        VIEW TRANSACTION HASH
                                    </Typography>
                                </Button> */}
                                <Button variant='contained' color='white' sx={{ width: '100%', border: '1px solid #000' }} onClick={handleClose}>
                                    <Typography variant='inter14Bold'>
                                        DONE
                                    </Typography>
                                </Button>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default ReferralModal;