import { Box, Typography } from '@mui/material';
import React from 'react';

const TokenBoxDesktop = ({ icon, description, isActive, onClick, sx }) => {

    return (
        //onClick={onClick} 
        <Box onMouseOver={onClick} sx={{
            cursor: 'pointer',
            borderWidth: '1.44px',
            borderStyle: 'solid',
            borderColor: isActive ? 'primary.main' : '#6F71F9',
            background: '#282BF6',
            // width: { lg: '480px', md: '400px' },
            padding: { lg: '24px 32px', md: '20px 26px' },
            display: 'flex',
            gap: '24px',
            alignItems: 'center',
            ...sx
        }}>
            <Box sx={{ width: '88px', height: '88px' }}>
                {icon}
            </Box>
            <Box sx={{ flex: 1 }}>
                <Typography variant='inter16' color='#FCF5E8'>
                    {description}
                </Typography>
            </Box>
        </Box>
    );
};

export default TokenBoxDesktop;