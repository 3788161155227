
import React from 'react';

const LaptopBar1SVG = ({ width = 703, height = 27 }) => (
    <svg width={width} height={height} viewBox="0 0 703 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="700.393" y1="26.541" x2="700.393" y2="0.984131" stroke="black" strokeWidth="4.35046" />
        <line x1="521.339" y1="0.984131" x2="521.339" y2="26.541" stroke="black" strokeWidth="4.35046" />
        <line x1="652.55" y1="26.541" x2="652.55" y2="0.984131" stroke="black" strokeWidth="4.35046" />
        <line x1="569.185" y1="0.984131" x2="569.185" y2="26.541" stroke="black" strokeWidth="4.35046" />
        <line x1="693.868" y1="26.541" x2="693.868" y2="0.984131" stroke="black" strokeWidth="1.45015" />
        <line x1="527.863" y1="0.984131" x2="527.863" y2="26.541" stroke="black" strokeWidth="1.45015" />
        <line x1="646.025" y1="26.541" x2="646.025" y2="0.984131" stroke="black" strokeWidth="1.45015" />
        <line x1="575.709" y1="0.984131" x2="575.709" y2="26.541" stroke="black" strokeWidth="1.45015" />
        <line x1="669.946" y1="26.541" x2="669.946" y2="0.984131" stroke="black" strokeWidth="1.45015" />
        <line x1="551.787" y1="0.984131" x2="551.787" y2="26.541" stroke="black" strokeWidth="1.45015" />
        <line x1="622.103" y1="26.541" x2="622.103" y2="0.984131" stroke="black" strokeWidth="1.45015" />
        <line x1="599.63" y1="0.984131" x2="599.63" y2="26.541" stroke="black" strokeWidth="1.45015" />
        <line x1="661.974" y1="26.541" x2="661.974" y2="0.984131" stroke="black" strokeWidth="1.45015" />
        <line x1="559.761" y1="0.984131" x2="559.761" y2="26.541" stroke="black" strokeWidth="1.45015" />
        <line x1="614.128" y1="26.541" x2="614.128" y2="0.984131" stroke="black" strokeWidth="1.45015" />
        <line x1="607.605" y1="0.984131" x2="607.605" y2="26.541" stroke="black" strokeWidth="1.45015" />
        <line x1="680.094" y1="26.541" x2="680.094" y2="0.984131" stroke="black" strokeWidth="13.0514" />
        <line x1="541.638" y1="0.98413" x2="541.638" y2="26.541" stroke="black" strokeWidth="13.0514" />
        <line x1="632.251" y1="26.541" x2="632.251" y2="0.984131" stroke="black" strokeWidth="13.0514" />
        <line x1="587.309" y1="0.984131" x2="587.309" y2="26.541" stroke="black" strokeWidth="8.70092" />
        <line x1="676.47" y1="26.541" x2="676.47" y2="0.984131" stroke="black" strokeWidth="4.35046" />
        <line x1="545.262" y1="0.984131" x2="545.262" y2="26.541" stroke="black" strokeWidth="4.35046" />
        <line x1="628.628" y1="26.541" x2="628.628" y2="0.984131" stroke="black" strokeWidth="4.35046" />
        <line x1="593.106" y1="0.984131" x2="593.106" y2="26.541" stroke="black" strokeWidth="4.35046" />
        <line x1="222.544" y1="15.041" x2="519.164" y2="15.041" stroke="black" />
        <path d="M251.429 14.3496L0.82022 14.3235" stroke="black" strokeWidth="15" />
    </svg>
);

export default LaptopBar1SVG;