import React from 'react';

const BarCodeVertical2SVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="227" viewBox="0 0 32 227" fill="none">
        <line x1="31.5244" y1="2.17523" x2="4.37739e-05" y2="2.17523" stroke="black" strokeWidth="4.35046" />
        <line x1="0.000488415" y1="224.055" x2="31.5249" y2="224.055" stroke="black" strokeWidth="4.35046" />
        <line x1="31.5244" y1="61.1899" x2="4.37739e-05" y2="61.1899" stroke="black" strokeWidth="4.35046" />
        <line x1="0.000488415" y1="165.036" x2="31.5249" y2="165.036" stroke="black" strokeWidth="4.35046" />
        <line x1="31.5244" y1="10.5625" x2="4.38373e-05" y2="10.5625" stroke="black" strokeWidth="1.45015" />
        <line x1="0.000488326" y1="215.668" x2="31.5249" y2="215.668" stroke="black" strokeWidth="1.45015" />
        <line x1="31.5244" y1="69.5766" x2="4.38373e-05" y2="69.5766" stroke="black" strokeWidth="1.45015" />
        <line x1="0.000488326" y1="156.65" x2="31.5249" y2="156.65" stroke="black" strokeWidth="1.45015" />
        <line x1="31.5244" y1="40.0708" x2="4.38373e-05" y2="40.0708" stroke="black" strokeWidth="1.45015" />
        <line x1="0.000488326" y1="186.159" x2="31.5249" y2="186.159" stroke="black" strokeWidth="1.45015" />
        <line x1="31.5244" y1="99.0854" x2="4.38373e-05" y2="99.0854" stroke="black" strokeWidth="1.45015" />
        <line x1="0.000488326" y1="127.144" x2="31.5249" y2="127.144" stroke="black" strokeWidth="1.45015" />
        <line x1="31.5244" y1="49.9038" x2="4.38373e-05" y2="49.9038" stroke="black" strokeWidth="1.45015" />
        <line x1="0.000488326" y1="176.323" x2="31.5249" y2="176.323" stroke="black" strokeWidth="1.45015" />
        <line x1="31.5244" y1="108.922" x2="4.38373e-05" y2="108.922" stroke="black" strokeWidth="1.45015" />
        <line x1="0.000488326" y1="117.307" x2="31.5249" y2="117.307" stroke="black" strokeWidth="1.45015" />
        <line x1="31.5244" y1="26.1985" x2="4.35838e-05" y2="26.1985" stroke="black" strokeWidth="13.0514" />
        <line x1="0.000488683" y1="200.031" x2="31.5249" y2="200.031" stroke="black" strokeWidth="13.0514" />
        <line x1="31.5244" y1="85.2132" x2="4.35838e-05" y2="85.2132" stroke="black" strokeWidth="13.0514" />
        <line x1="0.000488549" y1="143.189" x2="31.5249" y2="143.189" stroke="black" strokeWidth="8.70092" />
        <line x1="31.5244" y1="31.6845" x2="4.37739e-05" y2="31.6845" stroke="black" strokeWidth="4.35046" />
        <line x1="0.000488415" y1="194.545" x2="31.5249" y2="194.545" stroke="black" strokeWidth="4.35046" />
        <line x1="31.5244" y1="90.6982" x2="4.37739e-05" y2="90.6982" stroke="black" strokeWidth="4.35046" />
        <line x1="0.000488415" y1="135.53" x2="31.5249" y2="135.53" stroke="black" strokeWidth="4.35046" />
    </svg>
);

export default BarCodeVertical2SVG;