import React, { useEffect, useState } from 'react'
import { Box, Button, Typography,Modal } from '@mui/material';
import DashboardBarCodeSVG from '../../../components/SVGs/Admin/DashboardBarCodeSVG';
import BitCoinTokenSVG from '../../../components/SVGs/BitCoinTokenSVG';
import ETHTokenSVG from '../../../components/SVGs/ETHTokenSVG';
import USDTokenSVG from '../../../components/SVGs/USDTokenSVG';
import ToolTipInfoSVG from '../../../components/SVGs/ToolTipInfoSVG';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ArrowRightPrimaryIconSVG from '../../../components/SVGs/ArrowRightPrimaryIconSVG';
import SellBPSModal from '../../../components/SellBPSModal/SellBPSModal';
import SellBPSSuccessModal from '../../../components/SellBPSSuccessModal/SellBPSSuccessModal';
import WithdrawBPSSuccessModal from '../../../components/WithdrawBPSSuccessModal/WithdrawBPSSuccessModal';
import { UserContext,formatNumber, tokenMapping,connectContractToSigner, tryF,convertW3Data } from '../../../common';
import AuctionVaultJSON from '../../../abis/AuctionVault.json';
import { Contract,utils,BigNumber } from 'ethers';
import { useEthers } from '@usedapp/core';
import dayjs from 'dayjs';
const ZERO=BigNumber.from(0);
const BPSRewards = () => {
    const { account, library } = useEthers();
    const [sellModal, setSellModal] = useState(false);
    const toggleSellModal = () => setSellModal(!sellModal);

    const [sellSuccessModal, setSellSuccessModal] = useState(false);
    const toggleSellSuccessModal = () => setSellSuccessModal(!sellSuccessModal);
    const [openVideo,setOpenVideo]=useState(false);
    const url='https://www.youtube.com/embed/FLZHTO48pks?autoplay=1';
    const title='Baljuna Waters - Baljuna Profit Sharing (BPS) Token';

    const [withdrawSuccessModal, setWithdrawSuccessModal] = useState(false);
    const toggleWithdrawSuccessModal = () => setWithdrawSuccessModal(!withdrawSuccessModal);
    const user=React.useContext(UserContext);
    const {chainConfig}=user||{};
    const onSuccess = () => {
        setSellModal(false);
        setSellSuccessModal(true);
    }
    const [amount,setAmount]=useState('');
    const [auctionData, setAuctionData] = useState({});
    const [auctionStatus, setAuctionStatus] = useState({});
    useEffect(() => {
        if(!chainConfig?.auctionAddress) return;
        const load = async () => {
            const auctionContract = new Contract(
                chainConfig.auctionAddress,
                AuctionVaultJSON.abi,
                user?.provider
            );
            const auctionData = await auctionContract.getAuctionStatus();
            console.log('auction status',auctionData);
            setAuctionData(auctionData);
            const convertToUSD = (value, strike, decimals) => {
                return Number(utils.formatUnits(value, decimals)) * strike;
              };
            const {
                currentAuctionId,
                isAuctionActive,
                rewardRatio,
                rewardBalance,
                poolStake,
                auctionStart,
                auctionEnd,
                cDeposits,
                tDeposits,
                tBalances,
                } = auctionData;
            const calculateDollarValue = () => {
                let lastDoneAuctionId = currentAuctionId;
                if (isAuctionActive) {
                    lastDoneAuctionId = currentAuctionId - 1;
                }
            
                // Convert array to map
                const cDepositMap = cDeposits.reduce((acc, deposit) => {
                    acc[deposit.auctionId] = deposit.deposit;
                    return acc;
                }, {});
                const currentEpochBalance=formatNumber(convertW3Data(cDepositMap?.[currentAuctionId]||ZERO,6,2),2);
                const tDepositMap = tDeposits.reduce((acc, deposit) => {
                    acc[deposit.auctionId] = deposit.deposit;
                    return acc;
                }, {});
                const tBalanceMap = tBalances.reduce((acc, bal) => {
                    const { auctionId, token, balance } = bal;
                    if (!acc[auctionId]) {
                    acc[auctionId] = {};
                    }
                    acc[auctionId][token] = balance;
                    return acc;
                }, {});
            
                // Calculate `withdrawAuctionIds` and `withdrawBalance`
                let withdrawBalance = 0;
                const withdrawAuctionIds = [];
                for (
                    let tempAuctionId = 0;
                    tempAuctionId < lastDoneAuctionId;
                    tempAuctionId++
                ) {
                    const clientAmount = cDepositMap[tempAuctionId];
                    const totalAmount = tDepositMap[tempAuctionId];
                    if (clientAmount > 0) {
                    withdrawAuctionIds.push(tempAuctionId);
                    const tokens = Object.values(chainConfig.tokens);
                    for (let j = 0; j < tokens.length; j++) {
                        const { addr, decimals, strike = 1 } = tokens[j];
                        const value =
                        (tBalanceMap[tempAuctionId]?.[addr] * clientAmount) / totalAmount ||
                        0;
                        withdrawBalance += convertToUSD(value.toFixed(), strike, decimals);
                    }
                }
            }
        
            // Calculate `poolValue`
            let poolValue = 0;
            const poolTokens={};
            Object.keys(chainConfig.tokens).forEach(tokenName=>{
                const token=chainConfig.tokens[tokenName];
                const { addr, decimals, strike = 1,displayDecimals } = token;
                const value = tBalanceMap[lastDoneAuctionId]?.[addr] || 0;
                const usdValue= convertToUSD(value, strike, decimals);
                poolValue += usdValue;
                if(usdValue>0.1){
                    poolTokens[tokenName]=formatNumber(Number(utils.formatUnits(value,decimals)),displayDecimals);
                }
            });
            
        
            return {
                    withdrawAuctionIds,
                    withdrawBalance,
                    poolValue,
                    poolTokens,
                    currentEpochBalance
                };
            };
            const { withdrawBalance, withdrawAuctionIds, poolValue,poolTokens,currentEpochBalance } = calculateDollarValue();
            console.log('withdrawBalance',withdrawBalance,'withdrawAuctionIds',withdrawAuctionIds,'poolValue',poolValue);
            setAuctionStatus({withdrawBalance, withdrawAuctionIds, poolValue,poolTokens,currentEpochBalance});
        };
        load();
    }, [chainConfig]);
    const withdrawAuction=async()=>{
        const vaultContract = new Contract(
            user?.chainConfig?.auctionAddress,
            AuctionVaultJSON.abi,
            user?.provider
        );
        const contractSigned = connectContractToSigner(vaultContract, { transactionName: 'Claim Auction Payout' }, library);
        const transaction=await contractSigned.withDraw(auctionStatus?.withdrawAuctionIds);
        await transaction.wait(1);
        setAmount(auctionStatus?.withdrawBalance);
        toggleWithdrawSuccessModal();
    }

    const tryWithdraw=tryF('Withdraw Auction Payout',withdrawAuction);
    return (
        <Box sx={{ flex: 1, padding: { lg: '40p', md: '25px', sm: '20px', xs: '15px' }, overflow: 'hidden' }} >
            <Box sx={{ marginBottom: '24px', display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Typography variant='inter24Bold'>
                    Manage BPS Rewards
                </Typography>
                <HelpOutlineIcon color='red' sx={{cursor:'pointer'}} onClick={()=>setOpenVideo(true)}/>
                <Modal open={openVideo} onClose={()=>setOpenVideo(false)}>
                    <div className='video-container' onClick={()=>setOpenVideo(false)}>
                        <iframe width="800" height="560" src={url} title={title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen autoplay></iframe>
                    </div>
                </Modal>
            </Box>
            <Box sx={{ display: 'flex', gap: '24px' }}>
                <Box sx={{ flex: 1, display: 'flex', gap: '24px', marginBottom: '24px', flexDirection: { sm: 'row', xs: 'column' } }}>
                    <Box
                        sx={{
                            backgroundColor: 'common.black',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding: { lg: '24px', md: '21px', sm: '18px', xs: '12px' },
                            position: 'relative',
                            flex: 1
                        }}>
                        <Box sx={{
                            top: -1,
                            right: -1,
                            position: 'absolute',
                            width: 0,
                            height: 0,
                            borderLeft: { md: '20px solid transparent', xs: '15px solid transparent' },
                            borderTop: { md: '20px solid #fff', xs: '15px solid #fff' }
                        }} />
                        <Box sx={{ position: 'absolute', bottom: '0px', right: '10%', height: { md: '24px', sm: '20px', xs: '16px' } }}>
                            <DashboardBarCodeSVG />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <Box sx={{ marginBottom: '8px', display: 'flex', gap: '8px' }}>
                                    <Typography variant='roboto14Bold' color='common.white'>
                                        YOUR BPS REWARDS
                                    </Typography>
                                </Box>
                                <Box sx={{ marginBottom: { lg: '24px', md: '20px', sm: '16px', xs: '12px' } }}>
                                    <Typography variant='roboto40' color='primary'>
                                        {formatNumber(user?.balances?.BPS,2)} BPS
                                    </Typography>
                                </Box>
                                <Button color='black' sx={{ padding: '0px 0px', display: 'flex', gap: '8px' }} onClick={toggleSellModal}>
                                    <Typography variant='inter14Bold' color='common.white'>
                                        SELL
                                    </Typography>
                                    <ArrowRightPrimaryIconSVG />
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ flex: 1, display: 'flex', gap: '24px', marginBottom: '24px', flexDirection: { sm: 'row', xs: 'column' } }}>
                    <Box
                        sx={{
                            backgroundColor: 'common.black',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding: { lg: '24px', md: '21px', sm: '18px', xs: '12px' },
                            position: 'relative',
                            flex: 1
                        }}>
                        <Box sx={{
                            top: -1,
                            right: -1,
                            position: 'absolute',
                            width: 0,
                            height: 0,
                            borderLeft: { md: '20px solid transparent', xs: '15px solid transparent' },
                            borderTop: { md: '20px solid #fff', xs: '15px solid #fff' }
                        }} />
                        <Box sx={{ position: 'absolute', bottom: '0px', right: '10%', height: { md: '24px', sm: '20px', xs: '16px' } }}>
                            <DashboardBarCodeSVG />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <Box sx={{ marginBottom: '8px', display: 'flex', gap: '8px' }}>
                                    <Typography variant='roboto14Bold' color='common.white'>
                                        WITHDRAWABLE AMOUNT
                                    </Typography>
                                </Box>
                                <Box sx={{ marginBottom: { lg: '24px', md: '20px', sm: '16px', xs: '12px' } }}>
                                    <Typography variant='roboto40' color='primary'>
                                        ${formatNumber(auctionStatus?.withdrawBalance,2)}
                                    </Typography>
                                </Box>
                                <Button color='black' sx={{ padding: '0px 0px', display: 'flex', gap: '8px' }} onClick={tryWithdraw}>
                                    <Typography variant='inter14Bold' color='common.white'>
                                        WITHDRAW
                                    </Typography>
                                    <ArrowRightPrimaryIconSVG />
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: '24px' }}>
                <Box sx={{ border: '2px solid #000', padding: { xl: '32px', lg: '28px', md: '24px', sm: '20px', xs: '16px' }, flex: 1 }}>
                    <Box sx={{ marginBottom: '8px' }}>
                        <Typography variant='roboto14Bold' color='#888071'>
                            TOTAL BPS TOKENS BEING AUCTIONED
                        </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '16px' }}>
                        <Typography variant='roboto40'>
                            {formatNumber(auctionData?.poolStake,2)} BPS
                        </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '24px' }}>
                        <Typography variant='inter14' sx={{ fontStyle: 'italic' }}>
                            Auction ends: <Typography variant='inter16Bold'>{dayjs.unix(auctionData?.auctionEnd).format('DD-MMM-YY')}</Typography>
                        </Typography>
                    </Box>
                    <Box sx={{ border: '1px solid #F8E9CD', backgroundColor: '#FDF8F0', padding: '16px 20px' }}>
                        <Typography variant='inter16'>
                            Pending for auction: <Typography variant='inter16Bold'>{auctionData?.currentEpochBalance} BPS</Typography>
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ border: '2px solid #000', padding: { xl: '32px', lg: '28px', md: '24px', sm: '20px', xs: '16px' }, flex: 1 }}>
                    <Box sx={{ marginBottom: '8px' }}>
                        <Typography variant='roboto14Bold' color='#888071'>
                            TOTAL POOL REWARD
                        </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '16px' }}>
                        <Typography variant='roboto40'>
                            ${formatNumber(auctionStatus?.poolValue,2)}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: { md: '16px', xs: '8px' } }}>
                        {
                            auctionStatus?.poolTokens&&Object.keys(auctionStatus?.poolTokens).map(tokenName=>{
                                return <Box key={tokenName} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: { lg: 'row', xs: 'column' } }}>
                                    {tokenMapping[tokenName].icon}
                                    <Typography variant='roboto16' align='center'>
                                        {auctionStatus?.poolTokens[tokenName]} {tokenName}
                                    </Typography>
                                </Box>
                            })
                        }
                    </Box>
                </Box>
            </Box>
            <SellBPSModal open={sellModal} handleClose={toggleSellModal} onClickSuccess={onSuccess} />
            <SellBPSSuccessModal open={sellSuccessModal} handleClose={toggleSellSuccessModal} />
            <WithdrawBPSSuccessModal open={withdrawSuccessModal} handleClose={toggleWithdrawSuccessModal} amount={amount}/>
        </Box>
    )
}

export default BPSRewards;