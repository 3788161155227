import * as React from "react"
const ArrowRightPrimaryIconSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        {...props}
    >
        <path
            stroke="#FF4C00"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M1.334 8h13.333M10 3.333 14.667 8 10 12.667"
        />
    </svg>
)
export default ArrowRightPrimaryIconSVG
