import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';
import BitCoinTokenSVG from '../SVGs/BitCoinTokenSVG';

const SubscriptionDetailsModal = ({ open, toggleModal }) => {
    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Box sx={{ width: { sm: '400px', xs: '90%' }, maxWidth: '400px', backgroundColor: 'common.white' }}>
                    <Box sx={{ marginBottom: '24px', paddingTop: '40px' }}>
                        <Box sx={{ textAlign: 'center', marginBottom: '16px' }}>
                            <Typography variant='inter16Bold'>
                                Subscription Details
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant='roboto32'>
                                30,000 USDC
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant='inter14' color='#808080'>
                                Amount invested
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ padding: '15px' }}>
                        <Box sx={{
                            borderColor: 'common.black',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            padding: '20px',
                            marginBottom: '32px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px'
                        }}>
                            <Typography variant='roboto12Bold'>
                                Subscription Summary
                            </Typography>
                            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                <BitCoinTokenSVG />
                                <Typography variant='inter16'>
                                    Bitcoin
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant='inter14'>
                                    Date Subscribed
                                </Typography>
                                <Typography variant='inter14Bold'>
                                    05-July-23
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant='inter14'>
                                    Target Buy Price
                                </Typography>
                                <Typography variant='inter14Bold'>
                                    $29,500
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant='inter14'>
                                    Settlement Date
                                </Typography>
                                <Typography variant='inter14Bold'>
                                    07-July-23 <Typography variant='inter14' color='#808080'>(3 days)</Typography>
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant='inter14'>
                                    % APY
                                </Typography>
                                <Box sx={{ border: '1px solid #037A41', padding: '0px 8px' }}>
                                    <Typography variant='inter14Bold' color='#037A41'>
                                        25%
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant='inter14'>
                                    Status
                                </Typography>
                                <Box sx={{ border: '1px solid #037A41', padding: '0px 8px', backgroundColor: '#E0EBE6' }}>
                                    <Typography variant='inter14Bold' color='#037A41'>
                                        Filled
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant='inter14'>
                                    You Gained
                                </Typography>
                                <Typography variant='inter14Bold'>
                                    123 USDC
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant='inter14'>
                                    Trading Rewards
                                </Typography>
                                <Typography variant='inter14Bold'>
                                    10,000 BPS
                                </Typography>
                            </Box>
                        </Box>
                        <Button variant='contained' color='black' sx={{ width: '100%', marginBottom: '10px' }} onClick={toggleModal}>
                            <Typography variant='inter14Bold'>
                                BACK TO TRANSACTIONS
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal >
    );
};

export default SubscriptionDetailsModal;