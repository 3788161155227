import { Box, Button, Typography } from '@mui/material';
import React,{ useEffect,useState} from 'react';
import AboutBigHorseSVG from '../../components/SVGs/AboutBigHorseSVG';
import ShortLogoSVG from '../../components/SVGs/ShortLogoSVG';
import ReferralBarCodeSVG from '../../components/SVGs/BarCodes/ReferralBarCodeSVG';
import ReferralBagSVG from '../../components/SVGs/Bags/ReferralBagSVG';
import HasReferralComponent from '../../components/Referral/HasReferralComponent.jsx/HasReferralComponent';
import NoReferralComponent from '../../components/Referral/NoReferralComponent/NoReferralComponent';
import DoubleBackArrowSVG from '../../components/SVGs/DoubleBackArrowSVG';
import { Link } from 'react-router-dom';

const Referral = () => {
    const [referral_code, setReferralCode] = React.useState('');
    const [refered, setRefered] = useState([]);
    const copyToClipboard = () => {
        const referralLink = `${window.location.origin}/signup?referral_code=${referral_code}`;
        navigator.clipboard.writeText(referralLink);
    }
    useEffect(() => {
        const fetchUserData = async () => {
            const user= await (await fetch('/user/me')).json();
            if (user.error) {
                // navigate('/login');
            }else{
                setReferralCode(user.referral_code);
            }
        };
        const loadRefered=async()=>{
            const refered=await (await fetch('/user/get_refered')).json();
            console.log('referred',refered);
            if(!refered.error){
                setRefered(refered);
            }
            
        };
        loadRefered();
        fetchUserData();
    },[]);
    return (
        <Box component='main' sx={{ width: '100%', minHeight: '100vh', display: 'flex', flexDirection: { md: 'row', xs: 'column' } }}>
            <Box sx={{ background: 'linear-gradient(180deg, #212121 0%, #000 100%)', width: { md: '25%', xs: '100%' }, position: 'relative' }}>
                <Box sx={{ position: 'absolute', left: '0', bottom: '0', width: '95%' }}>
                    <AboutBigHorseSVG />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { md: 'column', xs: 'row' }, padding: { md: '10% 5%', xs: '5% 5%' }, gap: { md: '50px', xs: '30px' } }}>
                    <Box sx={{ width: { md: '76px', xs: '96px' }, zIndex: 1 }}>
                        <ShortLogoSVG fill='#F8E9CD' />
                    </Box>
                    <Box sx={{ zIndex: 1 }}>
                        <Box>
                            <Typography variant='roboto5' color='secondary' >
                                STRATEGY
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant='referralTitle' color='secondary'>
                                REFERRAL PROGRAM
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ backgroundColor: '#FEFDFA', flex: 1, padding: '3%', display: 'flex', flexDirection: 'column' }}>
                <Link to={-1} style={{ textDecoration: 'none' }} >
                    <Box sx={{ marginBottom: '16px', display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <DoubleBackArrowSVG />
                        <Typography variant='inter14' color={'common.black'}>
                            Go Back
                        </Typography>
                    </Box>
                </Link>
                <Box sx={{ display: 'flex', marginBottom: '15px' }}>
                    <Box sx={{ flex: 1 }}>
                        <Box sx={{ marginBottom: '8px' }}>
                            <Typography variant='inter32Bold'>
                                Generate Passive Income
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant='inter16'>
                                Build an empire with your feet up! Recruit someone and receive 30% of their rewards, forever.
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Button variant='outlined' color='black' sx={{ padding: '10px 24px' }}>
                            <Typography variant='inter14SemiBold' color='black'>
                                FAQs
                            </Typography>
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ padding: '30px', backgroundColor: 'common.black', position: 'relative', marginBottom: '40px' }}>
                    <Box sx={{ position: 'absolute', bottom: 0, height: '16px' }}>
                        <ReferralBarCodeSVG />
                    </Box>
                    <Box sx={{ position: 'absolute', top: 0, right: 0, height: '100%' }}>
                        <ReferralBagSVG />
                    </Box>
                    <Box sx={{ zIndex: 1 }}>
                        <Typography variant='roboto14Bold' color='#FFEDE6' sx={{ zIndex: 1 }}>
                            YOUR REFERRAL CODE
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '30px', marginBottom: '10px', justifyContent: { md: 'flex-start', xs: 'space-between' } }}>
                        <Box sx={{ zIndex: 1 }}>
                            <Typography variant='h2' color='primary'>
                                {referral_code}
                            </Typography>
                        </Box>
                        <Box>
                            <Button variant='contained' sx={{ padding: '8px 16px' }}>
                                <Typography variant='inter14Bold' onClick={copyToClipboard}>
                                    COPY LINK
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
                {refered.length===0&& <NoReferralComponent /> }
                {refered.length>0&&<HasReferralComponent refered={refered}/>}
            </Box>
        </Box >
    );
};

export default Referral;