import * as React from "react"

const DiscordIconSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={48}
        height={48}
        viewBox="0 0 48 48"
        fill="none"
        {...props}
    >
        <circle cx={24} cy={24} r={24} fill="#1A1A1A" />
        <path
            fill="#7289DA"
            d="M21.888 22.068c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332.012-.732-.54-1.332-1.224-1.332Zm4.38 0c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332 0-.732-.54-1.332-1.224-1.332Z"
        />
        <path
            fill="#7289DA"
            d="M32.1 12H16.02a2.466 2.466 0 0 0-2.46 2.472v16.224a2.466 2.466 0 0 0 2.46 2.472h13.608l-.636-2.22 1.536 1.428 1.452 1.344L34.56 36V14.472A2.466 2.466 0 0 0 32.1 12Zm-4.632 15.672s-.432-.516-.792-.972c1.572-.444 2.172-1.428 2.172-1.428-.492.324-.96.552-1.38.708-.6.252-1.176.42-1.74.516a8.406 8.406 0 0 1-3.108-.012 10.073 10.073 0 0 1-1.764-.516 7.032 7.032 0 0 1-.876-.408c-.036-.024-.072-.036-.108-.06a.166.166 0 0 1-.048-.036c-.216-.12-.336-.204-.336-.204s.576.96 2.1 1.416c-.36.456-.804.996-.804.996-2.652-.084-3.66-1.824-3.66-1.824 0-3.864 1.728-6.996 1.728-6.996 1.728-1.296 3.372-1.26 3.372-1.26l.12.144c-2.16.624-3.156 1.572-3.156 1.572s.264-.144.708-.348c1.284-.564 2.304-.72 2.724-.756.072-.012.132-.024.204-.024a9.782 9.782 0 0 1 6.036 1.128s-.948-.9-2.988-1.524l.168-.192s1.644-.036 3.372 1.26c0 0 1.728 3.132 1.728 6.996 0 0-1.02 1.74-3.672 1.824Z"
        />
    </svg>
)
export default DiscordIconSVG;
