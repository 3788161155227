import { Box, Typography } from '@mui/material';
import React from 'react';

const TokenBoxMobile = ({ icon, description, isActive, onClick, sx }) => {

    return (
        <Box onClick={onClick} sx={{
            cursor: 'pointer',
            borderWidth: '1.44px',
            borderStyle: 'solid',
            borderColor: isActive ? 'primary.main' : '#6F71F9',
            background: '#282BF6',
            padding: { sm: '18px 24px', xs: '14px 16px' },
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
            zIndex: 1,
            ...sx
        }}>
            <Box sx={{ width: '64px', height: '64px' }}>
                {icon}
            </Box>
            <Box sx={{ flex: 1 }}>
                <Typography variant='inter16' color='#FCF5E8'>
                    {description}
                </Typography>
            </Box>
        </Box>
    );
};

export default TokenBoxMobile;