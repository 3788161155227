import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import ShortLogoSVG from '../../components/SVGs/ShortLogoSVG';
import NotFoundSVG from '../../components/SVGs/NotFoundSVG';
import { useNavigate } from 'react-router-dom';
import ComingSoonSVG from '../../components/SVGs/ComingSoonSVG';

const ComingSoon = () => {
    const navigate = useNavigate();
    return (
        <Box compontent='main' sx={{ height: '100vh', backgroundColor: 'secondary.main', display: 'flex' }}>
            <Box sx={{ flex: 1, background: 'url("/assets/svgs/folder-404.svg")', margin: '1%', position: 'relative' }}>
                <Box sx={{ position: 'absolute', left: '3%', top: '5%' }}>
                    <Box sx={{ width: '76px', height: '74px' }}>
                        <ShortLogoSVG />
                    </Box>
                </Box>
                <Container maxWidth='xl' fixed sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ width: '368.875px' }}>
                        <ComingSoonSVG />
                    </Box>
                    <Typography variant='h1' color='primary' sx={{ marginBottom: '16px' }}>
                        COMING SOON.
                    </Typography>
                    <Typography variant='inter15' sx={{ opacity: 0.8, marginBottom: '30px' }}>
                        The page or feature you are looking for is about to go live soon. Stay tuned for updates!
                    </Typography>
                    <Button variant='contained' sx={{ padding: '8px 24px' }} onClick={() => navigate('/')}>
                        <Typography variant='button1'>
                            GO HOME
                        </Typography>
                    </Button>
                </Container>
            </Box>
        </Box>
    );

};

export default ComingSoon;