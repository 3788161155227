
import React from 'react';

const CloseIconSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <rect x="6.10059" y="3.27417" width="32" height="4" transform="rotate(45 6.10059 3.27417)" fill="#FF4C00" />
        <rect x="3.27246" y="25.9016" width="32" height="4" transform="rotate(-45 3.27246 25.9016)" fill="#FF4C00" />
    </svg>
);

export default CloseIconSVG;