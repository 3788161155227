import * as React from "react";

const TranslationIconSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        {...props}
    >
        <g
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            clipPath="url(#a)"
        >
            <path d="M1.5 2.5h8M5.5.5v2M3.5 2.5A6.106 6.106 0 0 0 8 8.184" />
            <path d="M7.5 2.5c-.4 5.625-6 6-6 6M8 14.5l3.5-8 3.5 8M9.313 11.5h4.374" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h16v16H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default TranslationIconSVG;
