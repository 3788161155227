import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';
import UukhaiFlagSVG from '../SVGs/UukhaiFlagSVG';


const SellBPSSuccessModal = ({ open, handleClose }) => {

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ backdropFilter: 'blur(12px)' }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Box sx={{ width: { sm: '400px', xs: '90%' }, maxWidth: '400px', backgroundColor: 'common.white' }}>
                    <Box>
                        <Box sx={{ position: 'relative' }}>
                            <Box sx={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <UukhaiFlagSVG />
                            </Box>
                            <Box sx={{ height: '60px', backgroundColor: '#E7E7E7' }}>

                            </Box>
                            <Box sx={{ height: '40px', backgroundColor: '#fff' }}>

                            </Box>
                        </Box>
                        <Box sx={{ textAlign: 'center', marginBottom: '16px' }}>
                            <Typography variant='inter16Bold'>
                                Sell BPS Rewards
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant='roboto32'>
                                5,000 BPS
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant='inter14' color='#808080'>
                                Amount to sell
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ padding: '15px', textAlign: 'center' }}>
                        <Typography variant='inter14'>
                            Note that your BPS tokens will be up for auction. You will only receive the equivalent amount after the auction ends.
                        </Typography>
                        <br></br>
                        <br></br>
                        <Box sx={{ marginBottom: '24px' }}>
                            <Typography variant='inter14' >
                                This transaction can be viewable under your
                                “My Transactions” tab.
                            </Typography>
                        </Box>

                        <Button variant='contained' color='black' sx={{ width: '100%', marginBottom: '10px' }} onClick={handleClose}>
                            <Typography variant='inter14Bold'>
                                SELL AGAIN
                            </Typography>
                        </Button>
                        <Button variant='contained' color='white' sx={{ width: '100%', border: '1px solid #000' }} onClick={handleClose}>
                            <Typography variant='inter14Bold'>
                                VIEW TRANSACTIONS
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default SellBPSSuccessModal