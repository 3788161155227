import { Box } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const LogoComponent = ({ isBlackLogo = false }) => {

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Link to={'/'} style={{ textDecoration: 'none', display: 'block' }}>
                <Box sx={{
                    width: {
                        xl: '270px',
                        lg: '245px',
                        md: '200px',
                        sm: '180px',
                        xs: '160px'
                    },
                    height: 'auto',
                    padding: 0, margin: 0, outline: 0, border: 0
                }}>
                    <Box component='img' id="logo" src={isBlackLogo ? '/assets/images/logo-black.png' : '/assets/images/logo.png'} alt='Logo' sx={{ width: '100%', height: '100%', flexShrink: 0, transition: '0.4s' }} />
                </Box>
            </Link>
        </Box>
    );
};

export default LogoComponent;