import React,{useState} from 'react';
import { Box, Button, Typography } from '@mui/material';
import AuthBarCodeSVG from '../SVGs/Auth/AuthBarCodeSVG';
import { useForm } from "react-hook-form";
import FormInput from '../FormInput/FormInput';
import VerifiedCodeSVG from '../SVGs/VerifiedCodeSVG';
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

const schema = yup
    .object({
        username: yup.string().required('Username is required'),
        email: yup.string().email('Email is not valid').required('Email is required'),
        password: yup.string().required('Password is required').min(3, 'Password must be atleast 3 characters'),
        confirmPassword: yup.string().required('Confirm password is required').test('password-match', 'Passwords do not match', function (value) {
            const { password } = this.parent;
            return password === value;
        }).min(3, 'Confirm password must be atleast 3 characters')
    })
    .required()
const searchParams = new URLSearchParams(window.location.search);
const referral_code = searchParams.get('referral_code');

const RegistrationForm = () => {
    const navigate = useNavigate();
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) })
    const [msg,setMsg]=useState(null)
    const code = watch('code');

    const onSubmit =async (data) => {
        console.log(data);
        const response = await fetch('/user/register', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: data.username,
                user_psw: data.password,
                user_email: data.email,
                referral_code: data.code
            }),
          });
        const result = await response.json();
        console.log(result);
        if (!result.error) {
          navigate('/admin');
        } else {
          setMsg(result.error);
        }
    }

    return (
        <Box sx={{
            marginLeft: 'auto',
            background: 'url("/assets/svgs/folder-primary.svg")',
            maxWidth: { xl: '488px', lg: '438px', md: '388px', sm: '388px', xs: '388px' },
            width: { xl: '488px', lg: '438px', md: '388px', sm: '388px', xs: '100%' },
            position: 'relative',
            zIndex: 2
        }}>
            <Box sx={{ paddingLeft: '10%', position: 'absolute', top: '2px', left: '0', width: '183.404px' }}>
                <AuthBarCodeSVG />
            </Box>
            <Box sx={{ padding: { md: '10%', xs: '5% 10%' } }}>
                <Box sx={{ marginTop: '16px', marginBottom: '16px' }}>
                    <Typography variant='form' color='#FFEDE6'>
                        Account Information
                    </Typography>
                </Box>
                <FormInput
                    sx={{ marginBottom: { md: '8px', xs: '4px' } }}
                    register={register}
                    name='username'
                    placeholder='Enter your desired username'
                    error={errors.username}
                />
                <FormInput
                    sx={{ marginBottom: { md: '8px', xs: '4px' } }}
                    register={register}
                    name='email'
                    placeholder='Enter your email'
                    error={errors.email}
                />
                <FormInput
                    sx={{ marginBottom: { md: '8px', xs: '4px' } }}
                    register={register}
                    name='password'
                    type='password'
                    placeholder='Set your password'
                    error={errors.password}
                />
                <FormInput
                    sx={{ marginBottom: { md: '8px', xs: '4px' } }}
                    register={register}
                    name='confirmPassword'
                    type='password'
                    placeholder='Confirm your password'
                    error={errors.confirmPassword}
                />

                <Box sx={{ marginBottom: '16px' }}>
                    <Typography variant='form' color='#FFEDE6'>
                        Were you referred by someone? Enter your code and both of you will earn rewards!
                    </Typography>
                </Box>

                <FormInput
                    sx={{ marginBottom: { md: '42px', xs: '22px' } }}
                    register={register}
                    name='code'
                    placeholder='Enter your referral code'
                    defaultValue={referral_code}
                    error={errors.code}
                    endIcon={!_.isEmpty(code) && <VerifiedCodeSVG />}
                />

                <Box sx={{ marginBottom: { md: '32px', xs: '16px' } }}>
                    <Button variant="contained" sx={{ width: '100%', paddingTop: { xs: '10px' }, paddingBottom: { xs: '10px' } }} onClick={handleSubmit(onSubmit)}>
                        <Typography variant='inter14Bold' color='common.white'>
                            CREATE ACCOUNT
                        </Typography>
                    </Button>
                    {msg && <Typography color='primary' variant='inter12'>{msg}</Typography>}
                </Box>
                <Box>
                    <Typography variant='form2' color='#FFEDE6'>
                        Already have an account?&nbsp;
                    </Typography>
                    <Typography variant='inter14Bold' color='#FFEDE6' onClick={() => navigate('/login')} sx={{ cursor: 'pointer' }}>
                        Sign in
                    </Typography>
                </Box>
            </Box>
        </Box >
    );
}

export default RegistrationForm;