import * as React from "react";

const DipRocketIconSVG = ({ fill = '#000' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
    >
        <path
            fill={fill}
            d="M17.888 20.607H7.823a.562.562 0 0 1-.555-.56v-6.38c0-4.707 1.805-9.138 5.084-12.485.214-.216.6-.254.814-.037 3.352 3.317 5.277 7.934 5.277 12.678v6.224c0 .306-.252.56-.555.56Zm-9.51-1.12h8.955v-5.664c0-4.25-1.65-8.397-4.552-11.475a16.612 16.612 0 0 0-4.403 11.319v5.82Z"
        />
        <path
            fill={fill}
            d="M20.198 25h-.037a.545.545 0 0 1-.444-.291l-2.309-4.259a.557.557 0 0 1-.066-.269v-5.155c0-.201.11-.388.281-.485a.56.56 0 0 1 .563.007l2.76 1.696a4.482 4.482 0 0 1 2.043 2.966 4.469 4.469 0 0 1-.807 3.511l-1.532 2.055a.592.592 0 0 1-.451.224Zm-1.753-4.96 1.828 3.369 1.013-1.36c.555-.747.777-1.71.6-2.63A3.373 3.373 0 0 0 20.36 17.2l-1.916-1.18v4.02ZM5.508 25a.555.555 0 0 1-.444-.224l-1.532-2.055a4.498 4.498 0 0 1-.807-3.51 4.474 4.474 0 0 1 2.043-2.967l2.76-1.696a.543.543 0 0 1 .563-.007.548.548 0 0 1 .281.485v5.155a.557.557 0 0 1-.067.27l-2.309 4.258a.562.562 0 0 1-.444.291h-.044Zm1.761-8.98L5.353 17.2a3.357 3.357 0 0 0-.925 4.849l1.006 1.36 1.828-3.37v-4.02h.007ZM12.856 14.093c-1.658 0-3.004-1.36-3.004-3.033 0-1.674 1.346-3.034 3.004-3.034s3.005 1.36 3.005 3.034c0 1.673-1.347 3.033-3.005 3.033Zm0-4.946c-1.043 0-1.894.86-1.894 1.913s.85 1.912 1.894 1.912 1.895-.859 1.895-1.912a1.907 1.907 0 0 0-1.895-1.913Z"
        />
    </svg>
)
export default DipRocketIconSVG;
