import React, { useEffect, useState } from 'react';
import CommonModal from '../CommonComponents/CommonModal/CommonModal';
import { Box, Typography, Button } from '@mui/material';
import {formatNumber,postData,connectContractToSigner,tokenMapping,formatNumberRoundDown, tryF,parseW3Price,convertPercent} from '../../common';
import OppositeIconSVG from '../SVGs/OppositeIconSVG';
import GiftCircleSVG from '../SVGs/GiftCircleSVG';
import dayjs from 'dayjs';
import _ from 'lodash';
import DualCurrencyJSON from '../../abis/DCI_New.json';
import IERC20JSON from '../../abis/OurToken.json';
import WETHABI from '../../abis/WETH.json';
import { Contract } from 'ethers';
import * as ethers from 'ethers';
import { useEthers,ChainId } from '@usedapp/core';


const SellTheRipModal = ({ open, handleClose, onClickSuccess,account,dciItem,chainConfig,user }) => {
    const {library,switchNetwork}=useEthers();
    const [investAmount, setInvestAmount] = useState('');
    const [tradeableQuote, setTradeableQuote] = useState();
    const { dci, tokens, chainId, name: chainName } = chainConfig || {};
    const baseToken=tokens?.[dciItem?.base_ccy];
    const contraToken=tokens?.[dciItem?.contra_ccy];
    const minSize=(dci?.[dciItem?.base_ccy]?.minSize)?.toFixed(baseToken?.displayDecimals);
    const maxSize=(dci?.[dciItem?.base_ccy]?.maxSize)?.toFixed(baseToken?.displayDecimals);
    const token=tokenMapping[dciItem?.base_ccy];
    let effectiveAmount=minSize;
    const investParsed=parseFloat(investAmount);
    if(!isNaN(investParsed) && investParsed!==0){
        effectiveAmount=investParsed;
    }
    if(maxSize){
        effectiveAmount=Math.min(effectiveAmount,maxSize);
    }
    const payoff = effectiveAmount * (1+tradeableQuote?.unitInterest);
    const payOffExercised = payoff * dciItem?.strike;
    const updateInvestAmount=(text)=>{
        const value = parseFloat(text);
        if(isNaN(value) || text.endsWith('.') || value===0){
            setInvestAmount(text);
        }else{
            const scale=Math.pow(10, tokens?.[dciItem?.base_ccy]?.displayDecimals);
            const roundedValue=Math.floor(value * scale)/scale;
            let finalValue=Math.max(roundedValue,minSize);
            if(maxSize){
                finalValue=Math.min(finalValue,maxSize);
            }
            setInvestAmount(finalValue);
        }
    }
    const ensureBalance=async(tokenName,amount,chainConfig)=>{
        const token=chainConfig.tokens[tokenName];
        const tokenContract = new Contract(
            token.addr,
            IERC20JSON.abi,
            user?.provider
        );
        const balance = await tokenContract.balanceOf(account);
        if(balance.lt(amount)){
            if(token.native){
                //convert ETH to WETH
                const tokenContract = new Contract(token.addr,
                    WETHABI,
                    user?.provider);
                const tokenSigned = connectContractToSigner(
                    tokenContract,
                    {
                    transactionName: `Tokenize ${token}`,
                    },
                    library
                );
                const transaction=await tokenSigned.deposit({value:amount.sub(balance)});
                await transaction.wait(1);
            }else{
                //fixme error notification
                throw new Error('Insufficient balance '+tokenName);
            }
        }
    }
    const switchChain=()=>{
        switchNetwork(chainId===ChainId.Mainnet?ChainId.Polygon:ChainId.Mainnet);
    }
    const ensureAllowance=async(spender,tokenName,amount,chainConfig)=>{
        await ensureBalance(tokenName,amount,chainConfig);
        const token=chainConfig.tokens[tokenName];
        const tokenContract = new Contract(
            token.addr,
            IERC20JSON.abi,
            user?.provider
        );
        const allowance = await tokenContract.allowance(account,spender);
        if(allowance.lt(amount)){
            const tokenSigned = connectContractToSigner(
                tokenContract,
                {
                  transactionName: `Approval Spending of ${tokenName}`,
                },
                library
              );
            const transaction=await tokenSigned.approve(spender,amount);
            await transaction.wait(1);
        }
    }
    const subscribeDCI=async()=>{
        
        const dciAddr=chainConfig?.dci2;
        const dciContract = new Contract(
            dciAddr,
            DualCurrencyJSON.abi,
            user?.provider
        );
        
        const web3Amount=ethers.utils.parseUnits(effectiveAmount.toString(),baseToken?.decimals);
        await ensureAllowance(dciAddr,dciItem?.base_ccy,web3Amount,chainConfig);
        const signed = connectContractToSigner(
            dciContract,
            {
            transactionName: `Subscribe to sell ${dciItem?.base_ccy}`,
            },
            library
        );
        
        const expiry=dciItem.expiry;
        const K=parseW3Price(dciItem.strike,dciItem,chainConfig.tokens2);
        const refCode=localStorage.getItem('refcode')||'';
        
        //for testing only, expires in 5 minutes
        // const expiry=Math.round((new Date()).getTime()/1000)+300;
        
        console.log(
            'subscribing, params ',
            dciItem.id,
            web3Amount,
            expiry,
            dciItem.isBase,
            K,
            refCode,
            {
                gasLimit: 1500000,
            }
        );
        const subscribe = await signed.subscribe(
            dciItem.id,
            web3Amount,
            expiry,
            dciItem.isBase,
            K,
            refCode,
            {
                gasLimit: 1500000,
            }
        );
        console.log('subscribing', subscribe);
        const update = postData('/client_trade_ws', {
            chainId: chainId,
            chainName: chainName,
            contractAddress:dciAddr,
            id: dciItem.id,
            base_ccy: dciItem.base_ccy,
            contra_ccy: dciItem.contra_ccy,
            investToken: dciItem['base_ccy'],
            tokenExercised: dciItem['contra_ccy'],
            group: dciItem['group'],
            amount: effectiveAmount,
            strike: dciItem.strike,
            expiry: dciItem.expiry,
            call_put: dciItem['call_put'],
            yield_rate: tradeableQuote['yield_rate'],
            status: 'processing',
            });
        await subscribe.wait(1);
        await update;
        onClickSuccess(dciItem,effectiveAmount,tradeableQuote['yield_rate'],subscribe);

    }
    const trySubscribe=tryF('Subscribing',subscribeDCI);
    
    useEffect(() => {
        if(!(dciItem && account)){
            return;
        }
        const loadTradeableQuote=async()=>{
            console.log('chainConfig',chainConfig?.dci2,
            DualCurrencyJSON.abi,
            user?.provider);
            const dciContract = new Contract(
                chainConfig?.dci2,
                DualCurrencyJSON.abi,
                user?.provider
            );
            const instrumentId=dciItem.id;
            const investInBase=dciItem.isBase;
            const K=parseW3Price(dciItem.strike,dciItem,chainConfig.tokens2);
            const refCode=localStorage.getItem('refcode')||'';
            const result=await dciContract.quote(instrumentId,dciItem.expiry,investInBase,K,refCode);
            console.log('result',result);
            const apy=convertPercent(result[0])/10000;
            const unitInterest= convertPercent(result[1]);
            console.log('apy',apy,'unitInterest',unitInterest);
            setTradeableQuote({
                yield_rate:apy,
                unitInterest
            });
        }
        loadTradeableQuote();
    }, [dciItem,account]);
    const strikeText=formatNumber(dciItem?.strike,0);
    console.log('user balances',user?.balances);
    return (
        <CommonModal width={{ md: '801px', xs: 'calc(100vw - 50px)' }} minWidth='400px' title='// SUBSCRIBE TO SELL THE RIP' open={open} handleClose={handleClose}>
            <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column-reverse' } }}>
                <Box sx={{ flex: 1, borderRightColor: '#000', borderRightWidth: '1px', borderRightStyle: 'solid', padding: '24px', minWidth: '400px', boxSizing: 'border-box' }}>
                    <Box sx={{ display: 'flex', gap: '8px', marginBottom: '24px' }}>
                    {token?.icon}
                        <Typography variant='inter16'>
                            {token?.name}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '12px' }}>
                        <Typography variant='inter14'>
                            Target Sell Price
                        </Typography>
                        <Typography variant='inter14Bold'>
                            {strikeText}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '12px' }}>
                        <Typography variant='inter14'>
                            Settlement Date
                        </Typography>
                        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>

                            <Typography variant='inter14Bold'>
                                {dayjs.unix(dciItem?.expiry).format('DD-MMM-YY')}
                            </Typography>
                            <Typography variant='inter14' color='#808080'>
                                ({dayjs.unix(dciItem?.expiry).fromNow()})
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '24px' }}>
                        <Typography variant='inter14'>
                            % APY
                        </Typography>
                        <Box sx={{ display: 'flex', padding: '0px 8px', border: '1px solid #037A41' }}>
                            <Typography variant='inter14Bold' color='#037A41'>
                            {(tradeableQuote?.yield_rate*100).toFixed(0)}%
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ marginBottom: '8px' }}>
                        <Typography variant='inter14'>
                            How much do you want to invest?
                        </Typography>
                    </Box>
                    <Box sx={{ border: '1px solid #B0A592', padding: '20px 16px', backgroundColor: '#FEFDFA', marginBottom: '8px' }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingBottom: '8px',
                            borderBottomColor: '#CCCCCC',
                            borderBottomWidth: '1px',
                            borderBottomStyle: 'solid',
                            marginBottom: '8px',
                            gap: '16px'
                        }}>
                            <Box sx={{ flex: 1 }}>
                                <Box
                                    component={'input'}
                                    placeholder={`Minimum ${minSize} ${dciItem?.base_ccy}`}
                                    sx={{
                                        background: 'transparent',
                                        width: '100%',
                                        fontFamily: 'Inter',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        outline: 0,
                                        border: 0
                                    }}
                                    value={investAmount}
                                    onChange={(e) => setInvestAmount(e.target.value)} onBlur={(e)=>updateInvestAmount(e.target.value)}/>
                            </Box>
                            <Box>
                                <Typography variant='inter14'>
                                    {dciItem?.base_ccy} | <Typography variant='inter14Bold' sx={{ cursor: 'pointer' }} onClick={() => setInvestAmount(formatNumberRoundDown(user?.balances?.[dciItem?.base_ccy],baseToken?.displayDecimals))}>Max</Typography>
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography variant='inter12'>
                                Wallet Balance: {formatNumber(user?.balances?.[dciItem?.base_ccy],baseToken?.displayDecimals)} {dciItem?.base_ccy}
                            </Typography>
                        </Box>
                        {
                            user?.balances?.[dciItem?.base_ccy] < effectiveAmount &&
                                <Box sx={{ marginBottom: '8px' }}>
                                    <Typography variant='inter12' color='#DC3C3C'>
                                    You don’t have enough balance.
                                    </Typography>
                                </Box>
                        }
                    </Box>
                    {
                        !chainName?.includes('Polygon') && <Box sx={{ backgroundColor: '#FAFAFA', padding: '16px', display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }} onClick={switchChain}>
                            <Typography variant='inter14'>
                                Want a lower gas or minimum investment option?
                            </Typography>
                            <Typography variant='inter14Bold'>
                                SWITCH TO POLYGON NETWORK
                            </Typography>
                        </Box>
                        <OppositeIconSVG />
                    </Box>
                    }
                    
                    <Box sx={{ marginBottom: '16px' }}>
                        <Typography variant='inter14'>
                            Please review and make sure all details are correct before you subscribe. Subscribed amount is locked until the settlement date.
                        </Typography>
                    </Box>
                    <Button variant='contained' color='black' disabled={_.isEmpty(investAmount.toString())} sx={{ width: '100%' }} onClick={trySubscribe}>
                        <Typography variant='inter14Bold'>
                            SUBSCRIBE
                        </Typography>
                    </Button>
                </Box>
                <Box sx={{ flex: 1, padding: '24px', minWidth: '400px', boxSizing: 'border-box' }}>
                    <Box sx={{ marginBottom: '8px' }}>
                        <Typography variant='roboto14Bold'>
                            SETTLEMENT PROJECTION
                        </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '16px' }}>
                        <Typography variant='inter14'>
                            With an investment of <Typography variant='inter14Bold'>{formatNumber(effectiveAmount,baseToken?.displayDecimals)} {dciItem?.base_ccy}</Typography>:
                        </Typography>
                    </Box>
                    <Box sx={{ backgroundColor: '#F0DBDB', padding: '16px', display: 'flex', flexDirection: 'column', marginBottom: '8px' }}>
                        <Typography variant='inter14'>
                            If price at settlement <Typography variant='inter14Bold'>{`<=$${strikeText}`}   </Typography>,
                        </Typography>
                        <Typography variant='inter14'>
                            you will receive <Typography variant='inter14Bold'>{formatNumber(payoff,baseToken?.displayDecimals)} {dciItem?.base_ccy}</Typography>.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
                        <Box sx={{ padding: '0px 8px', backgroundColor: '#0B8E4F' }}>
                            <Typography variant='inter14Bold' color='common.white'>
                                TARGET SELL PRICE: ${strikeText}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ backgroundColor: '#FAFAFA', padding: '16px', display: 'flex', flexDirection: 'column', marginBottom: '24px' }}>
                        <Typography variant='inter14'>
                            If price at settlement <Typography variant='inter14Bold'>{`>$${strikeText}`}</Typography>,
                        </Typography>
                        <Typography variant='inter14'>
                            you will receive <Typography variant='inter14Bold'>{formatNumber(payOffExercised,contraToken?.displayDecimals)} {dciItem?.contra_ccy}</Typography>.
                        </Typography>
                    </Box>
                    <Box sx={{ padding: '16px', border: '1px solid #000' }}>
                        <Box sx={{ marginBottom: '8px' }}>
                            <Typography variant='roboto14Bold'>
                                TRADING REWARDS
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '12px' }}>
                            <Box sx={{ width: '40px' }}>
                                <GiftCircleSVG />
                            </Box>
                            <Box>
                                <Typography variant='inter14'>
                                    Earn extra BPS tokens when the settlement price is above the target sell price.<br></br><br></br>
                                    <Typography variant='inter14Bold'>
                                        Learn More
                                    </Typography> on how it works.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </CommonModal>
    );
};

export default SellTheRipModal;