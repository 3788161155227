import { Box, Input, Typography } from '@mui/material';
import React from 'react';
import _ from 'lodash';

const FormInput = ({ sx, sxInput, register, name, error, subText, startIcon, endIcon, ...props }) => {
    const hasError = !_.isEmpty(error);
    return (
        <Box sx={{ minHeight: { md: '56px', xs: '52px' }, ...sx }}>
            <Input
                sx={sxInput}
                {...register(name)}
                error={hasError}
                startAdornment={startIcon}
                endAdornment={endIcon}
                {...props}
            />
            {subText && (
                <Typography color='#808080' variant='inter12' sx={{ display: 'block' }}>
                    {subText}
                </Typography>
            )}
            {hasError && (
                <Typography color='primary' variant='inter12'>
                    {error.message}
                </Typography>
            )}
        </Box>
    )
};

export default FormInput;