import * as React from "react"
import { motion, useAnimate } from "framer-motion";
import { useEffect } from "react";

const CoreValuesSVG = ({ selectedValue, setSelectedValue }) => {

    const [scope0, animate0] = useAnimate();
    const [scopeCircle0, animateCircle0] = useAnimate();

    const [scope1, animate1] = useAnimate();
    const [scopeCircle1, animateCircle1] = useAnimate();

    const [scope2, animate2] = useAnimate();
    const [scopeCircle2, animateCircle2] = useAnimate();

    const [scope3, animate3] = useAnimate();
    const [scopeCircle3, animateCircle3] = useAnimate();

    const [scope4, animate4] = useAnimate();
    const [scopeCircle4, animateCircle4] = useAnimate();

    const [scope5, animate5] = useAnimate();
    const [scopeCircle5, animateCircle5] = useAnimate();

    const onClickValues = (selected) => {
        setSelectedValue(selected);
    };

    useEffect(() => {
        toggleValue1(selectedValue === 0);
        toggleValue2(selectedValue === 1);
        toggleValue3(selectedValue === 2);
        toggleValue4(selectedValue === 3);
        toggleValue5(selectedValue === 4);
        toggleValue6(selectedValue === 5);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue]);

    const toggleValue1 = (isSelected = false) => {
        animate0(scope0.current, { fill: isSelected ? '#000' : '#F8E9CD' }, { duration: 0.5 });
        animateCircle0(scopeCircle0.current, { fill: isSelected ? '#F8E9CD' : '#000' }, { duration: 0.5 });
    };

    const toggleValue2 = (isSelected = false) => {
        animate1(scope1.current, { fill: isSelected ? '#000' : '#F8E9CD' }, { duration: 0.5 });
        animateCircle1(scopeCircle1.current, { fill: isSelected ? '#F8E9CD' : '#000' }, { duration: 0.5 });
    };

    const toggleValue3 = (isSelected = false) => {
        animate2(scope2.current, { fill: isSelected ? '#000' : '#F8E9CD' }, { duration: 0.5 });
        animateCircle2(scopeCircle2.current, { fill: isSelected ? '#F8E9CD' : '#000' }, { duration: 0.5 });
    };

    const toggleValue4 = (isSelected = false) => {
        animate3(scope3.current, { fill: isSelected ? '#000' : '#F8E9CD' }, { duration: 0.5 });
        animateCircle3(scopeCircle3.current, { fill: isSelected ? '#F8E9CD' : '#000' }, { duration: 0.5 });
    };

    const toggleValue5 = (isSelected = false) => {
        animate4(scope4.current, { fill: isSelected ? '#000' : '#F8E9CD' }, { duration: 0.5 });
        animateCircle4(scopeCircle4.current, { fill: isSelected ? '#F8E9CD' : '#000' }, { duration: 0.5 });
    };


    const toggleValue6 = (isSelected = false) => {
        animate5(scope5.current, { fill: isSelected ? '#000' : '#F8E9CD' }, { duration: 0.5 });
        animateCircle5(scopeCircle5.current, { fill: isSelected ? '#F8E9CD' : '#000' }, { duration: 0.5 });
    };

    return (
        <svg width="100%" height="100%" viewBox="0 0 698 762" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="352.049" cy="380.989" r="318.88" stroke="#F8E9CD" strokeDasharray="5 5" />
            <motion.g ref={scope0} onClick={() => onClickValues(0)} fill="black" style={{ cursor: 'pointer' }}>
                <motion.circle ref={scopeCircle0} cx="350.945" cy="61.6089" r="60.7144" fill="#F8E9CD" stroke="#F8E9CD" strokeWidth="0.702146" />
                <path d="M350.692 109.877C324.092 109.877 302.392 88.1769 302.392 61.5769C302.392 34.9769 324.092 13.2769 350.692 13.2769C377.292 13.2769 398.992 34.9769 398.992 61.5769C398.992 88.1769 377.292 109.877 350.692 109.877ZM350.692 15.2769C325.192 15.2769 304.392 36.0769 304.392 61.5769C304.392 87.0769 325.192 107.877 350.692 107.877C376.192 107.877 396.992 87.0769 396.992 61.5769C396.992 36.0769 376.192 15.2769 350.692 15.2769Z" />
                <path d="M350.692 100.677C329.092 100.677 311.592 83.1768 311.592 61.5768C311.592 39.9768 329.192 22.4768 350.692 22.4768C372.192 22.4768 389.792 39.9768 389.792 61.5768C389.792 83.1768 372.292 100.677 350.692 100.677ZM350.692 24.4768C330.292 24.4768 313.592 41.0768 313.592 61.5768C313.592 82.0768 330.192 98.6768 350.692 98.6768C371.092 98.6768 387.792 82.0768 387.792 61.5768C387.792 41.0768 371.192 24.4768 350.692 24.4768Z" />
                <path d="M350.692 100.677C329.092 100.677 311.592 83.1768 311.592 61.5768C311.592 39.9768 329.192 22.4768 350.692 22.4768C372.192 22.4768 389.792 39.9768 389.792 61.5768C389.792 83.1768 372.292 100.677 350.692 100.677ZM350.692 24.4768C330.292 24.4768 313.592 41.0768 313.592 61.5768C313.592 82.0768 330.192 98.6768 350.692 98.6768C371.092 98.6768 387.792 82.0768 387.792 61.5768C387.792 41.0768 371.192 24.4768 350.692 24.4768Z" />
                <path d="M351.691 14.2769H349.691V23.4769H351.691V14.2769Z" />
                <path d="M351.691 98.9768H349.691V108.177H351.691V98.9768Z" />
                <path d="M397.592 60.1768H388.392V62.1768H397.592V60.1768Z" />
                <path d="M312.992 60.1768H303.792V62.1768H312.992V60.1768Z" />
                <path d="M378.079 87.2381L376.665 88.6523L383.17 95.1586L384.585 93.7444L378.079 87.2381Z" />
                <path d="M318.231 27.2962L316.816 28.7104L323.322 35.2158L324.736 33.8016L318.231 27.2962Z" />
                <path d="M383.143 27.3482L376.638 33.8535L378.052 35.2677L384.557 28.7624L383.143 27.3482Z" />
                <path d="M323.342 87.1885L316.837 93.6938L318.251 95.108L324.756 88.6027L323.342 87.1885Z" />
                <path d="M359.192 60.5769L357.792 59.1769L368.092 48.7769L369.092 41.3769L361.692 42.3769L351.592 52.4769L350.092 51.0769L360.792 40.4769L371.392 39.0769L369.992 49.6769L359.192 60.5769Z" />
                <path d="M339.792 79.877L330.592 70.677L341.892 59.377L343.292 60.777L333.392 70.677L339.792 77.077L349.992 66.977L351.392 68.377L339.792 79.877Z" />
                <path d="M328.417 65.7203L327.003 67.1345L343.478 83.61L344.893 82.1958L328.417 65.7203Z" />
                <path d="M335.449 73.8358L325.125 84.1594L326.539 85.5736L336.863 75.25L335.449 73.8358Z" />
                <path d="M361.591 79.977L331.591 49.977L330.191 39.377L340.791 40.777L370.791 70.777L361.591 79.977ZM333.491 49.177L361.591 77.277L367.991 70.877L339.891 42.677L332.491 41.777L333.491 49.177Z" />
                <path d="M373.155 65.6154L356.68 82.0908L358.094 83.505L374.569 67.0296L373.155 65.6154Z" />
                <path d="M366.139 73.7186L364.725 75.1328L375.048 85.4565L376.462 84.0423L366.139 73.7186Z" />
            </motion.g>
            <motion.g ref={scope1} onClick={() => onClickValues(1)} style={{ cursor: 'pointer' }} fill="#F8E9CD">
                <motion.circle ref={scopeCircle1} cx="630.073" cy="233.319" r="60.7144" fill="black" stroke="#F8E9CD" strokeWidth="0.702146" />
                <path d="M630.073 281.619C603.473 281.619 581.773 259.919 581.773 233.319C581.773 206.719 603.473 185.019 630.073 185.019C656.673 185.019 678.373 206.719 678.373 233.319C678.373 259.919 656.673 281.619 630.073 281.619ZM630.073 187.019C604.573 187.019 583.773 207.819 583.773 233.319C583.773 258.819 604.573 279.619 630.073 279.619C655.573 279.619 676.373 258.819 676.373 233.319C676.373 207.819 655.573 187.019 630.073 187.019Z" />
                <path d="M630.074 272.419C608.474 272.419 590.974 254.919 590.974 233.319C590.974 211.719 608.574 194.219 630.074 194.219C651.574 194.219 669.174 211.719 669.174 233.319C669.174 254.919 651.674 272.419 630.074 272.419ZM630.074 196.219C609.674 196.219 592.974 212.819 592.974 233.319C592.974 253.819 609.574 270.419 630.074 270.419C650.474 270.419 667.174 253.819 667.174 233.319C667.174 212.819 650.574 196.219 630.074 196.219Z" />
                <path d="M630.074 272.419C608.474 272.419 590.974 254.919 590.974 233.319C590.974 211.719 608.574 194.219 630.074 194.219C651.574 194.219 669.174 211.719 669.174 233.319C669.174 254.919 651.674 272.419 630.074 272.419ZM630.074 196.219C609.674 196.219 592.974 212.819 592.974 233.319C592.974 253.819 609.574 270.419 630.074 270.419C650.474 270.419 667.174 253.819 667.174 233.319C667.174 212.819 650.574 196.219 630.074 196.219Z" />
                <path d="M631.073 186.019H629.073V195.219H631.073V186.019Z" />
                <path d="M631.073 270.719H629.073V279.919H631.073V270.719Z" />
                <path d="M676.973 231.919H667.773V233.919H676.973V231.919Z" />
                <path d="M592.374 231.919H583.174V233.919H592.374V231.919Z" />
                <path d="M630.073 247.319C611.773 247.319 597.273 234.619 596.573 234.119L595.773 233.319L596.573 232.619C597.273 232.019 611.773 219.319 630.073 219.319C648.373 219.319 662.873 232.019 663.573 232.619L664.373 233.319L663.573 234.019C662.873 234.619 648.373 247.319 630.073 247.319ZM598.873 233.319C602.273 236.019 615.073 245.319 630.073 245.319C645.073 245.319 657.873 236.019 661.273 233.319C657.873 230.619 645.073 221.319 630.073 221.319C615.073 221.319 602.273 230.619 598.873 233.319Z" />
                <path d="M630.074 246.419C622.874 246.419 616.974 240.519 616.974 233.319C616.974 226.119 622.874 220.219 630.074 220.219C637.274 220.219 643.174 226.119 643.174 233.319C643.174 240.519 637.274 246.419 630.074 246.419ZM630.074 222.219C623.974 222.219 618.974 227.219 618.974 233.319C618.974 239.419 623.974 244.419 630.074 244.419C636.174 244.419 641.174 239.419 641.174 233.319C641.174 227.219 636.174 222.219 630.074 222.219Z" />
                <path d="M630.074 240.219C626.274 240.219 623.174 237.119 623.174 233.319C623.174 229.519 626.274 226.419 630.074 226.419C633.874 226.419 636.974 229.519 636.974 233.319C636.974 237.119 633.874 240.219 630.074 240.219ZM630.074 228.419C627.374 228.419 625.174 230.619 625.174 233.319C625.174 236.019 627.374 238.219 630.074 238.219C632.774 238.219 634.974 236.019 634.974 233.319C634.974 230.619 632.774 228.419 630.074 228.419Z" />
                <path d="M630.174 222.119L624.474 214.419L626.074 213.219L629.974 218.519L633.274 213.319L634.974 214.419L630.174 222.119Z" />
                <path d="M633.273 253.419L629.373 248.119L626.073 253.319L624.373 252.319L629.173 244.519L634.873 252.219L633.273 253.419Z" />
                <path d="M630.074 264.619C619.374 264.619 608.574 257.619 602.674 246.819L604.474 245.819C609.974 256.019 620.074 262.619 630.074 262.619C641.374 262.619 651.674 256.019 656.574 245.919L658.374 246.819C653.174 257.619 642.074 264.619 630.074 264.619Z" />
                <path d="M656.573 220.119C650.973 209.919 640.873 203.319 630.873 203.319C619.573 203.319 609.273 209.919 604.373 220.019L602.573 219.119C607.773 208.319 618.873 201.319 630.873 201.319C641.573 201.319 652.373 208.319 658.273 219.119L656.573 220.119Z" />
            </motion.g>
            <motion.g ref={scope2} onClick={() => onClickValues(2)} style={{ cursor: 'pointer' }} fill="#F8E9CD">
                <motion.circle ref={scopeCircle2} cx="636.073" cy="517.023" r="60.7144" fill="black" stroke="#F8E9CD" strokeWidth="0.702146" />
                <path d="M636.073 565.291C609.473 565.291 587.773 543.591 587.773 516.991C587.773 490.391 609.473 468.691 636.073 468.691C662.673 468.691 684.373 490.391 684.373 516.991C684.373 543.591 662.673 565.291 636.073 565.291ZM636.073 470.691C610.573 470.691 589.773 491.491 589.773 516.991C589.773 542.491 610.573 563.291 636.073 563.291C661.573 563.291 682.373 542.491 682.373 516.991C682.373 491.491 661.573 470.691 636.073 470.691Z" />
                <path d="M636.074 556.091C614.474 556.091 596.974 538.591 596.974 516.991C596.974 495.391 614.574 477.891 636.074 477.891C657.574 477.891 675.174 495.391 675.174 516.991C675.174 538.591 657.674 556.091 636.074 556.091ZM636.074 479.891C615.674 479.891 598.974 496.491 598.974 516.991C598.974 537.491 615.574 554.091 636.074 554.091C656.474 554.091 673.174 537.491 673.174 516.991C673.174 496.491 656.574 479.891 636.074 479.891Z" />
                <path d="M636.074 556.091C614.474 556.091 596.974 538.591 596.974 516.991C596.974 495.391 614.574 477.891 636.074 477.891C657.574 477.891 675.174 495.391 675.174 516.991C675.174 538.591 657.674 556.091 636.074 556.091ZM636.074 479.891C615.674 479.891 598.974 496.491 598.974 516.991C598.974 537.491 615.574 554.091 636.074 554.091C656.474 554.091 673.174 537.491 673.174 516.991C673.174 496.491 656.574 479.891 636.074 479.891Z" />
                <path d="M668.525 482.762L662.02 489.268L663.434 490.682L669.939 484.177L668.525 482.762Z" />
                <path d="M608.724 542.603L602.219 549.108L603.633 550.522L610.138 544.017L608.724 542.603Z" />
                <path d="M636.073 565.291C609.473 565.291 587.773 543.591 587.773 516.991C587.773 490.391 609.473 468.691 636.073 468.691C662.673 468.691 684.373 490.391 684.373 516.991C684.373 543.591 662.673 565.291 636.073 565.291ZM636.073 470.691C610.573 470.691 589.773 491.491 589.773 516.991C589.773 542.491 610.573 563.291 636.073 563.291C661.573 563.291 682.373 542.491 682.373 516.991C682.373 491.491 661.573 470.691 636.073 470.691Z" />
                <path d="M636.074 556.091C614.474 556.091 596.974 538.591 596.974 516.991C596.974 495.391 614.574 477.891 636.074 477.891C657.574 477.891 675.174 495.391 675.174 516.991C675.174 538.591 657.674 556.091 636.074 556.091ZM636.074 479.891C615.674 479.891 598.974 496.491 598.974 516.991C598.974 537.491 615.574 554.091 636.074 554.091C656.474 554.091 673.174 537.491 673.174 516.991C673.174 496.491 656.574 479.891 636.074 479.891Z" />
                <path d="M636.074 556.091C614.474 556.091 596.974 538.591 596.974 516.991C596.974 495.391 614.574 477.891 636.074 477.891C657.574 477.891 675.174 495.391 675.174 516.991C675.174 538.591 657.674 556.091 636.074 556.091ZM636.074 479.891C615.674 479.891 598.974 496.491 598.974 516.991C598.974 537.491 615.574 554.091 636.074 554.091C656.474 554.091 673.174 537.491 673.174 516.991C673.174 496.491 656.574 479.891 636.074 479.891Z" />
                <path d="M616.373 537.592C610.673 532.192 607.573 524.792 607.573 516.992C607.573 509.392 610.573 502.192 615.973 496.792L617.373 498.192C612.373 503.192 609.573 509.892 609.573 516.992C609.573 524.292 612.473 531.092 617.773 536.192L616.373 537.592Z" />
                <path d="M655.774 537.592L654.374 536.192C659.374 531.192 662.174 524.492 662.174 517.392C662.174 510.092 659.274 503.292 653.974 498.192L655.374 496.792C661.074 502.192 664.174 509.592 664.174 517.392C664.074 525.092 661.174 532.192 655.774 537.592Z" />
                <path d="M653.573 531.092L652.173 529.692C655.473 526.392 657.273 521.992 657.273 517.292C657.273 512.492 655.373 507.992 651.873 504.692L653.273 503.292C657.173 506.992 659.273 511.992 659.273 517.392C659.273 522.592 657.273 527.392 653.573 531.092Z" />
                <path d="M619.174 531.091C615.274 527.391 613.174 522.391 613.174 516.991C613.174 511.791 615.174 506.891 618.874 503.191L620.274 504.591C616.974 507.891 615.174 512.291 615.174 516.991C615.174 521.791 617.074 526.291 620.574 529.591L619.174 531.091Z" />
                <path d="M603.615 482.711L602.2 484.125L608.706 490.631L610.12 489.217L603.615 482.711Z" />
                <path d="M663.453 542.65L662.039 544.064L668.544 550.569L669.959 549.155L663.453 542.65Z" />
                <path d="M682.973 515.591H673.773V517.591H682.973V515.591Z" />
                <path d="M598.374 515.591H589.174V517.591H598.374V515.591Z" />
                <path d="M635.874 519.391L623.174 507.791L623.274 493.191L648.974 493.491L648.874 508.091L635.874 519.391ZM625.174 506.891L635.874 516.691L646.774 507.191L646.874 495.491L625.174 495.291V506.891Z" />
                <path d="M648.473 542.791L622.773 542.591L622.873 527.991L635.873 516.691L648.573 528.191L648.473 542.791ZM624.773 540.591L646.473 540.791L646.573 529.091L635.873 519.391L624.973 528.891L624.773 540.591Z" />
                <path d="M627.674 509.891L625.674 509.491C625.674 509.291 626.874 504.091 635.774 505.191C643.274 506.091 646.974 500.891 647.074 500.691L648.674 501.791C648.474 502.091 644.174 508.191 635.474 507.091C628.574 506.291 627.674 509.691 627.674 509.891Z" />
                <path d="M641.974 535.591C639.974 535.591 637.674 535.191 634.974 533.891C627.974 530.491 623.974 530.391 623.974 530.391V528.391C624.174 528.391 628.374 528.491 635.874 532.091C642.574 535.291 646.874 532.391 647.074 532.291L648.174 533.891C647.974 533.991 645.674 535.591 641.974 535.591Z" />
                <path d="M622.373 493.206L622.353 495.206L649.45 495.483L649.47 493.483L622.373 493.206Z" />
                <path d="M619.297 490.07L619.28 492.07L652.38 492.346L652.397 490.346L619.297 490.07Z" />
                <path d="M618.83 543.475L618.813 545.475L651.913 545.751L651.93 543.751L618.83 543.475Z" />
                <path d="M621.81 540.598L621.789 542.598L648.886 542.875L648.907 540.875L621.81 540.598Z" />
            </motion.g>
            <motion.g ref={scope3} onClick={() => onClickValues(3)} style={{ cursor: 'pointer' }} fill="#F8E9CD">
                <motion.circle ref={scopeCircle3} cx="352.691" cy="700.369" r="60.7144" fill="black" stroke="#F8E9CD" strokeWidth="0.702146" />
                <path d="M352.692 748.637C326.092 748.637 304.392 726.937 304.392 700.337C304.392 673.737 326.092 652.037 352.692 652.037C379.292 652.037 400.992 673.737 400.992 700.337C400.992 726.937 379.292 748.637 352.692 748.637ZM352.692 654.037C327.192 654.037 306.392 674.837 306.392 700.337C306.392 725.837 327.192 746.637 352.692 746.637C378.192 746.637 398.992 725.837 398.992 700.337C398.992 674.837 378.192 654.037 352.692 654.037Z" />
                <path d="M352.692 739.437C331.092 739.437 313.592 721.937 313.592 700.337C313.592 678.737 331.092 661.237 352.692 661.237C374.292 661.237 391.792 678.737 391.792 700.337C391.792 721.937 374.292 739.437 352.692 739.437ZM352.692 663.237C332.292 663.237 315.592 679.837 315.592 700.337C315.592 720.837 332.192 737.437 352.692 737.437C373.092 737.437 389.792 720.837 389.792 700.337C389.792 679.937 373.192 663.237 352.692 663.237Z" />
                <path d="M352.692 739.437C331.092 739.437 313.592 721.937 313.592 700.337C313.592 678.737 331.092 661.237 352.692 661.237C374.292 661.237 391.792 678.737 391.792 700.337C391.792 721.937 374.292 739.437 352.692 739.437ZM352.692 663.237C332.292 663.237 315.592 679.837 315.592 700.337C315.592 720.837 332.192 737.437 352.692 737.437C373.092 737.437 389.792 720.837 389.792 700.337C389.792 679.937 373.192 663.237 352.692 663.237Z" />
                <path d="M380.071 725.999L378.657 727.413L385.163 733.919L386.577 732.504L380.071 725.999Z" />
                <path d="M320.231 666.058L318.816 667.472L325.322 673.978L326.736 672.564L320.231 666.058Z" />
                <path d="M385.142 666.108L378.637 672.613L380.051 674.027L386.556 667.522L385.142 666.108Z" />
                <path d="M325.341 725.948L318.836 732.454L320.25 733.868L326.755 727.362L325.341 725.948Z" />
                <path d="M340.491 676.737H338.491V736.637H340.491V676.737Z" />
                <path d="M383.091 712.537H351.691V686.337H383.091L376.091 699.437L383.091 712.537ZM353.691 710.537H379.691L373.791 699.437L379.691 688.337H353.691V710.537Z" />
                <path d="M342.691 675.737H335.991V677.737H342.691V675.737Z" />
                <path d="M353.691 707.637H338.491V680.237H353.691V707.637ZM340.491 705.637H351.691V682.237H340.491V705.637Z" />
            </motion.g>

            <motion.g ref={scope4} onClick={() => onClickValues(4)} style={{ cursor: 'pointer' }} fill="#F8E9CD">
                <motion.circle ref={scopeCircle4} cx="65.0323" cy="517.023" r="60.7144" fill="black" stroke="#F8E9CD" strokeWidth="0.702146" />
                <path d="M65.2961 564.891C38.6961 564.891 16.9961 543.191 16.9961 516.591C16.9961 489.991 38.6961 468.291 65.2961 468.291C91.8961 468.291 113.596 489.991 113.596 516.591C113.596 543.191 91.8961 564.891 65.2961 564.891ZM65.2961 470.291C39.7961 470.291 18.9961 491.091 18.9961 516.591C18.9961 542.091 39.7961 562.891 65.2961 562.891C90.7961 562.891 111.596 542.091 111.596 516.591C111.596 491.091 90.7961 470.291 65.2961 470.291Z" />
                <path d="M65.2963 555.691C43.6963 555.691 26.1963 538.191 26.1963 516.591C26.1963 494.991 43.6963 477.491 65.2963 477.491C86.8963 477.491 104.396 494.991 104.396 516.591C104.396 538.191 86.8963 555.691 65.2963 555.691ZM65.2963 479.491C44.8963 479.491 28.1963 496.091 28.1963 516.591C28.1963 536.991 44.7963 553.691 65.2963 553.691C85.7963 553.691 102.396 537.091 102.396 516.591C102.396 496.191 85.7963 479.491 65.2963 479.491Z" />
                <path d="M65.2963 555.691C43.6963 555.691 26.1963 538.191 26.1963 516.591C26.1963 494.991 43.6963 477.491 65.2963 477.491C86.8963 477.491 104.396 494.991 104.396 516.591C104.396 538.191 86.8963 555.691 65.2963 555.691ZM65.2963 479.491C44.8963 479.491 28.1963 496.091 28.1963 516.591C28.1963 536.991 44.7963 553.691 65.2963 553.691C85.7963 553.691 102.396 537.091 102.396 516.591C102.396 496.191 85.7963 479.491 65.2963 479.491Z" />
                <path d="M65.2961 564.891C38.6961 564.891 16.9961 543.191 16.9961 516.591C16.9961 489.991 38.6961 468.291 65.2961 468.291C91.8961 468.291 113.596 489.991 113.596 516.591C113.596 543.191 91.8961 564.891 65.2961 564.891ZM65.2961 470.291C39.7961 470.291 18.9961 491.091 18.9961 516.591C18.9961 542.091 39.7961 562.891 65.2961 562.891C90.7961 562.891 111.596 542.091 111.596 516.591C111.596 491.091 90.7961 470.291 65.2961 470.291Z" />
                <path d="M65.2963 555.691C43.6963 555.691 26.1963 538.191 26.1963 516.591C26.1963 494.991 43.6963 477.491 65.2963 477.491C86.8963 477.491 104.396 494.991 104.396 516.591C104.396 538.191 86.8963 555.691 65.2963 555.691ZM65.2963 479.491C44.8963 479.491 28.1963 496.091 28.1963 516.591C28.1963 536.991 44.7963 553.691 65.2963 553.691C85.7963 553.691 102.396 537.091 102.396 516.591C102.396 496.191 85.7963 479.491 65.2963 479.491Z" />
                <path d="M65.2963 555.691C43.6963 555.691 26.1963 538.191 26.1963 516.591C26.1963 494.991 43.6963 477.491 65.2963 477.491C86.8963 477.491 104.396 494.991 104.396 516.591C104.396 538.191 86.8963 555.691 65.2963 555.691ZM65.2963 479.491C44.8963 479.491 28.1963 496.091 28.1963 516.591C28.1963 536.991 44.7963 553.691 65.2963 553.691C85.7963 553.691 102.396 537.091 102.396 516.591C102.396 496.191 85.7963 479.491 65.2963 479.491Z" />
                <path d="M93.5558 542.211L92.1416 543.625L98.6469 550.131L100.061 548.716L93.5558 542.211Z" />
                <path d="M33.6447 482.337L32.2305 483.751L38.7358 490.256L40.15 488.842L33.6447 482.337Z" />
                <path d="M37.8754 543.135L31.3701 549.641L32.7843 551.055L39.2896 544.55L37.8754 543.135Z" />
                <path d="M98.6274 482.316L92.1221 488.821L93.5363 490.235L100.042 483.73L98.6274 482.316Z" />
                <path d="M32.4965 535.391L30.8965 533.691L43.8965 520.791L44.8965 515.891L47.1965 516.391L46.0965 521.991L32.4965 535.391Z" />
                <path d="M47.2967 550.491L45.5967 548.791L59.0967 535.291C60.9967 533.391 63.4967 532.191 66.1967 531.891C68.2967 531.691 70.2967 530.691 71.7967 529.291L85.6967 515.891C85.9967 515.591 86.0967 515.291 86.0967 514.891C86.0967 514.491 85.9967 513.991 85.5967 513.691C85.2967 513.391 84.7967 513.191 84.3967 513.291H84.1967C83.8967 513.291 83.5967 513.491 83.3967 513.691L78.5967 518.491L76.9967 517.091L81.7967 512.291C82.3967 511.691 83.1967 511.291 83.9967 511.191H84.1967C85.2967 510.991 86.4967 511.391 87.2967 512.191C88.0967 512.991 88.5967 514.091 88.4967 515.291C88.3967 516.191 87.9967 517.091 87.2967 517.791L73.4967 531.091C71.5967 532.891 69.0967 534.091 66.4967 534.391C64.3967 534.591 62.3967 535.591 60.7967 537.091L47.2967 550.491Z" />
                <path d="M78.7963 518.391L77.1963 516.691L85.3963 508.691C85.6963 508.391 85.7963 508.091 85.7963 507.691C85.7963 507.191 85.4963 506.591 84.9963 506.191C84.5963 505.791 83.9963 505.491 83.5963 505.591H83.3963C83.0963 505.591 82.7963 505.791 82.5963 505.991L76.8963 511.691L75.1963 509.991L80.8963 504.291C81.4963 503.691 82.2963 503.291 83.0963 503.191H83.2963C84.3963 503.091 85.5963 503.491 86.5963 504.391C87.5963 505.391 88.1963 506.591 88.0963 507.791C87.9963 508.691 87.5963 509.591 86.8963 510.291L78.7963 518.391Z" />
                <path d="M76.6969 511.891L75.0969 510.191L81.4969 504.091C81.7969 503.891 81.8969 503.491 81.8969 503.191C81.8969 502.691 81.5969 502.091 81.0969 501.691C80.6969 501.291 80.0969 500.991 79.6969 501.091H79.4969C79.1969 501.091 78.8969 501.291 78.6969 501.491L72.9969 507.091L71.2969 505.391L76.9969 499.691C77.5969 499.091 78.3969 498.691 79.1969 498.591H79.3969C80.4969 498.491 81.6969 498.891 82.6969 499.791C83.6969 500.791 84.2969 501.991 84.1969 503.191C84.0969 504.091 83.6969 504.991 82.9969 505.691L76.6969 511.891Z" />
                <path d="M72.6961 507.191L71.0961 505.491L77.4961 499.391C77.7961 499.191 77.8961 498.791 77.8961 498.491C77.8961 497.991 77.5961 497.391 77.0961 496.991C76.6961 496.591 76.0961 496.291 75.6961 496.391H75.4961C75.1961 496.391 74.8961 496.591 74.6961 496.791L67.6961 503.791L65.9961 502.091L72.9961 495.091C73.5961 494.491 74.3961 494.091 75.1961 493.991H75.3961C76.4961 493.891 77.6961 494.291 78.6961 495.191C79.6961 496.191 80.2961 497.391 80.1961 498.591C80.0961 499.491 79.6961 500.391 78.9961 501.091L72.6961 507.191Z" />
                <path d="M63.7963 508.591C62.6963 508.591 61.6963 508.191 60.8963 507.391L53.8963 500.391C52.2963 498.791 52.2963 496.191 53.8963 494.591C54.6963 493.791 55.6963 493.391 56.7963 493.391C57.8963 493.391 58.8963 493.791 59.6963 494.591L66.6963 501.591C68.2963 503.191 68.2963 505.791 66.6963 507.391C65.9963 508.191 64.8963 508.591 63.7963 508.591ZM56.7963 495.791C56.2963 495.791 55.8963 495.991 55.5963 496.291C55.2963 496.591 55.0963 497.091 55.0963 497.491C55.0963 497.891 55.2963 498.391 55.5963 498.691L62.5963 505.691C63.2963 506.391 64.3963 506.391 64.9963 505.691C65.2963 505.391 65.4963 504.891 65.4963 504.491C65.4963 504.091 65.2963 503.591 64.9963 503.291L57.9963 496.291C57.6963 495.891 57.2963 495.791 56.7963 495.791Z" />
                <path d="M59.9965 513.491C58.8965 513.491 57.8965 513.091 57.0965 512.291L50.0965 505.291C49.2965 504.491 48.8965 503.491 48.8965 502.391C48.8965 501.291 49.2965 500.291 50.0965 499.491C50.8965 498.691 51.8965 498.291 52.9965 498.291C54.0965 498.291 55.0965 498.691 55.8965 499.491L62.8965 506.491C63.6965 507.291 64.0965 508.291 64.0965 509.391C64.0965 510.491 63.6965 511.491 62.8965 512.291C62.0965 513.091 61.0965 513.491 59.9965 513.491ZM52.8965 500.591C52.3965 500.591 51.9965 500.791 51.6965 501.091C51.3965 501.391 51.1965 501.891 51.1965 502.291C51.1965 502.691 51.3965 503.191 51.6965 503.491L58.6965 510.491C59.3965 511.191 60.4965 511.191 61.0965 510.491C61.3965 510.191 61.5965 509.691 61.5965 509.291C61.5965 508.891 61.3965 508.391 61.0965 508.091L54.0965 501.091C53.7965 500.791 53.3965 500.591 52.8965 500.591Z" />
                <path d="M55.1963 517.291C54.1963 517.291 53.0963 516.891 52.2963 516.091L47.3963 511.191C46.5963 510.391 46.1963 509.391 46.1963 508.291C46.1963 507.191 46.5963 506.191 47.3963 505.391C48.9963 503.791 51.5963 503.791 53.1963 505.391L58.0963 510.291C59.6963 511.891 59.6963 514.491 58.0963 516.091C57.2963 516.891 56.2963 517.291 55.1963 517.291ZM50.2963 506.491C49.8963 506.491 49.3963 506.691 49.0963 506.991C48.7963 507.291 48.5963 507.791 48.5963 508.191C48.5963 508.591 48.7963 509.091 49.0963 509.391L53.9963 514.291C54.6963 514.991 55.7963 514.991 56.3963 514.291C57.0963 513.591 57.0963 512.491 56.3963 511.891L51.4963 506.991C51.1963 506.691 50.7963 506.491 50.2963 506.491Z" />
                <path d="M50.7961 520.991C49.6961 520.991 48.6961 520.591 47.8961 519.791L45.6961 517.591C44.0961 515.991 44.0961 513.391 45.6961 511.791C47.2961 510.191 49.8961 510.191 51.4961 511.791L53.6961 513.991C54.4961 514.791 54.8961 515.791 54.8961 516.891C54.8961 517.991 54.4961 518.991 53.6961 519.791C52.8961 520.591 51.8961 520.991 50.7961 520.991ZM48.5961 512.991C48.1961 512.991 47.6961 513.191 47.3961 513.491C46.6961 514.191 46.6961 515.291 47.3961 515.891L49.5961 518.091C50.2961 518.791 51.3961 518.791 51.9961 518.091C52.2961 517.791 52.4961 517.291 52.4961 516.891C52.4961 516.491 52.2961 515.991 51.9961 515.691L49.7961 513.491C49.4961 513.191 49.0961 512.991 48.5961 512.991Z" />
                <path d="M82.8969 550.491L68.5969 536.191C68.0969 535.691 65.6969 535.291 63.2969 535.191L63.3969 532.891C64.6969 532.891 68.8969 533.191 70.2969 534.591L84.5969 548.891L82.8969 550.491Z" />
                <path d="M83.8959 519.389L82.2695 521.015L97.2601 536.006L98.8864 534.379L83.8959 519.389Z" />
                <path d="M66.5965 502.191C66.5965 502.091 66.5965 501.991 66.5965 501.891C66.3965 501.291 65.7965 500.891 65.0965 500.891H62.9965L59.8965 497.491V492.691H69.8965L73.7965 495.991L72.2965 497.791L68.9965 494.991H62.1965V496.591L63.9965 498.591H64.9965C66.7965 498.591 68.2965 499.691 68.7965 501.391C68.8965 501.591 68.8965 501.791 68.8965 501.991L66.5965 502.191Z" />
            </motion.g>
            <motion.g ref={scope5} onClick={() => onClickValues(5)} style={{ cursor: 'pointer' }} fill="#F8E9CD" >
                <motion.circle ref={scopeCircle5} cx="61.7823" cy="233.319" r="60.7144" fill="black" stroke="#F8E9CD" strokeWidth="0.702146" />
                <path d="M62.2961 281.387C35.6961 281.387 13.9961 259.687 13.9961 233.087C13.9961 206.487 35.6961 184.787 62.2961 184.787C88.8961 184.787 110.596 206.487 110.596 233.087C110.596 259.687 88.8961 281.387 62.2961 281.387ZM62.2961 186.787C36.7961 186.787 15.9961 207.587 15.9961 233.087C15.9961 258.587 36.7961 279.387 62.2961 279.387C87.7961 279.387 108.596 258.587 108.596 233.087C108.596 207.587 87.7961 186.787 62.2961 186.787Z" />
                <path d="M62.2963 272.187C40.6963 272.187 23.1963 254.687 23.1963 233.087C23.1963 211.487 40.6963 193.987 62.2963 193.987C83.8963 193.987 101.396 211.487 101.396 233.087C101.396 254.687 83.8963 272.187 62.2963 272.187ZM62.2963 195.987C41.8963 195.987 25.1963 212.587 25.1963 233.087C25.1963 253.487 41.7963 270.187 62.2963 270.187C82.6963 270.187 99.3963 253.587 99.3963 233.087C99.3963 212.687 82.7963 195.987 62.2963 195.987Z" />
                <path d="M62.2963 272.187C40.6963 272.187 23.1963 254.687 23.1963 233.087C23.1963 211.487 40.6963 193.987 62.2963 193.987C83.8963 193.987 101.396 211.487 101.396 233.087C101.396 254.687 83.8963 272.187 62.2963 272.187ZM62.2963 195.987C41.8963 195.987 25.1963 212.587 25.1963 233.087C25.1963 253.487 41.7963 270.187 62.2963 270.187C82.6963 270.187 99.3963 253.587 99.3963 233.087C99.3963 212.687 82.7963 195.987 62.2963 195.987Z" />
                <path d="M63.2959 185.787H61.2959V194.987H63.2959V185.787Z" />
                <path d="M63.2959 270.487H61.2959V279.687H63.2959V270.487Z" />
                <path d="M109.196 231.687H99.9961V233.687H109.196V231.687Z" />
                <path d="M24.5965 231.687H15.3965V233.687H24.5965V231.687Z" />
                <path d="M80.1961 232.187H45.9961C45.3961 232.187 44.9961 231.787 44.9961 231.187V224.587C44.9961 223.987 45.3961 223.587 45.9961 223.587H80.1961C80.7961 223.587 81.1961 223.987 81.1961 224.587V231.187C81.1961 231.687 80.6961 232.187 80.1961 232.187ZM46.9961 230.187H79.1961V225.587H46.9961V230.187Z" />
                <path d="M62.2959 212.787C59.7959 212.787 57.7959 210.787 57.7959 208.287C57.7959 205.787 59.7959 203.787 62.2959 203.787C64.7959 203.787 66.7959 205.787 66.7959 208.287C66.7959 210.787 64.7959 212.787 62.2959 212.787ZM62.2959 205.787C60.8959 205.787 59.7959 206.887 59.7959 208.287C59.7959 209.687 60.8959 210.787 62.2959 210.787C63.6959 210.787 64.7959 209.687 64.7959 208.287C64.7959 206.887 63.6959 205.787 62.2959 205.787Z" />
                <path d="M80.1961 225.587H45.9961C45.5961 225.587 45.1961 225.287 45.0961 224.887C44.9961 224.487 45.0961 223.987 45.3961 223.787L61.6961 211.087C61.9961 210.787 62.4961 210.787 62.8961 211.087L80.7961 223.787C81.1961 224.087 81.2961 224.487 81.1961 224.887C80.9961 225.287 80.5961 225.587 80.1961 225.587ZM48.8961 223.587H76.9961L62.2961 213.087L48.8961 223.587Z" />
                <path d="M88.4953 253.687H76.8953C76.3953 253.687 75.9953 253.287 75.8953 252.787L73.1953 231.287C73.1953 230.987 73.2953 230.687 73.3953 230.487C73.5953 230.287 73.8953 230.187 74.0953 230.187H79.6953C80.0953 230.187 80.4953 230.387 80.5953 230.787L89.1953 252.287C89.2953 252.587 89.2953 252.987 89.0953 253.187C88.8953 253.387 88.7953 253.687 88.4953 253.687ZM77.7953 251.687H86.9953L79.1953 232.187H75.3953L77.7953 251.687Z" />
                <path d="M82.7949 237.586H75.2949V239.586H82.7949V237.586Z" />
                <path d="M85.7949 244.987H75.2949V246.987H85.7949V244.987Z" />
                <path d="M48.8958 253.687H37.2958C36.9958 253.687 36.6958 253.487 36.4958 253.287C36.2958 253.087 36.2958 252.687 36.3958 252.387L44.9958 230.787C45.1958 230.387 45.4958 230.187 45.8958 230.187H51.4958C51.7958 230.187 52.0958 230.287 52.1958 230.487C52.3958 230.687 52.4958 230.987 52.3958 231.287L49.8958 252.787C49.8958 253.287 49.3958 253.687 48.8958 253.687ZM38.7958 251.687H47.9958L50.3958 232.187H46.5958L38.7958 251.687Z" />
                <path d="M50.4961 237.586H42.9961V239.586H50.4961V237.586Z" />
                <path d="M50.4961 244.987H39.9961V246.987H50.4961V244.987Z" />
            </motion.g>
            <defs>
                <clipPath id="clip0_1829_13943">
                    <rect width="96.6" height="96.6" fill="white" transform="translate(581.773 184.019)" />
                </clipPath>
                <clipPath id="clip1_1829_13943">
                    <rect width="96.6" height="96.6" fill="white" transform="translate(302.392 13.2769)" />
                </clipPath>
                <clipPath id="clip2_1829_13943">
                    <rect width="96.6" height="96.6" fill="white" transform="translate(587.773 468.691)" />
                </clipPath>
                <clipPath id="clip3_1829_13943">
                    <rect width="96.6" height="96.6" fill="white" transform="translate(304.392 652.037)" />
                </clipPath>
                <clipPath id="clip4_1829_13943">
                    <rect width="96.6" height="96.6" fill="white" transform="translate(13.9961 184.787)" />
                </clipPath>
                <clipPath id="clip5_1829_13943">
                    <rect width="96.6" height="96.6" fill="white" transform="translate(16.9961 468.291)" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default CoreValuesSVG;
