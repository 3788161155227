import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';
import UukhaiFlagSVG from '../SVGs/UukhaiFlagSVG';

const WithdrawBPSSuccessModal = ({ open, handleClose,amount }) => {
    
    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ backdropFilter: 'blur(12px)' }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Box sx={{ width: { sm: '400px', xs: '90%' }, maxWidth: '400px', backgroundColor: 'common.white' }}>
                    <Box>
                        <Box sx={{ position: 'relative' }}>
                            <Box sx={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <UukhaiFlagSVG />
                            </Box>
                            <Box sx={{ height: '60px', backgroundColor: '#E7E7E7' }}>

                            </Box>
                            <Box sx={{ height: '40px', backgroundColor: '#fff' }}>

                            </Box>
                        </Box>
                        <Box sx={{ textAlign: 'center', marginBottom: '16px' }}>
                            <Typography variant='inter16Bold'>
                                Withdraw Success
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant='roboto32'>
                                ${amount}
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant='inter14' color='#808080'>
                                Amount withdrawn
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ padding: '15px', textAlign: 'center' }}>
                        <Button variant='contained' color='black' sx={{ width: '100%', marginBottom: '10px' }} onClick={handleClose}>
                            <Typography variant='inter14Bold'>
                                BACK TO DASHBOARD
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default WithdrawBPSSuccessModal;