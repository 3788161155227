import * as React from "react"

const VerifiedCodeSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={23}
        height={22}
        fill="none"
        {...props}
    >
        <path
            stroke="#1BC47D"
            strokeWidth={0.936}
            d="m11.45 1 9.999 10-10 10-10-10z"
        />
        <path
            stroke="#1BC47D"
            strokeLinejoin="round"
            strokeWidth={1.404}
            d="m8 10.907 2.504 2.606 4.398-5.026"
        />
    </svg>
)
export default VerifiedCodeSVG;
