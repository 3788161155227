import React from 'react';
import { Box, Typography } from '@mui/material';
import MenuItemComponent from '../MenuItemComponent/MenuItemComponent';

const MenuComponent = ({ title, items, selectedRoute = '' }) => {
    return (
        <Box>
            <Box sx={{ marginBottom: '8px' }}>
                <Typography variant='roboto12Bold' color='common.grey'>
                    {title}
                </Typography>
            </Box>
            {items.map(({ icon, label, onClick, route, target, soon }, index) =>
                <MenuItemComponent key={index} icon={icon} label={label} href={route} onClick={onClick} isActive={selectedRoute === route} target={target} soon={soon} />
            )}
        </Box>
    )
}

export default MenuComponent