import * as React from "react"
const DocumentationIconSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={24}
        fill="none"
        {...props}
    >
        <g
            stroke="#FF4C00"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
        >
            <path d="M19 1H1v22h18V1Z" />
            <path d="m7 9-3 3 3 3M13 15l3-3-3-3" />
        </g>
    </svg>
)
export default DocumentationIconSVG
