import { Box } from '@mui/material';
import React from 'react';

const MenuIconSVG = ({ width = 32, height = 32, fill = '#FF4C00' }) => (
    <Box width={width} height={height}>
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32" fill="none">
            <g>
                <rect y="2" width="32" height="4" fill={fill} />
                <rect y="26" width="32" height="4" fill={fill} />
                <rect x="14" y="14" width="18" height="4" fill={fill} />
            </g>
            <defs>
                <clipPath id="clip0_739_29291">
                    <rect width="32" height="32" fill="white" transform="translate(32 32) rotate(-180)" />
                </clipPath>
            </defs>
        </svg>
    </Box>
);

export default MenuIconSVG;