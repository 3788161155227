import * as React from "react";

const USDTokenSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#000"
            d="M12 24.03c-6.617 0-12-5.382-12-12 0-6.617 5.383-12 12-12s12 5.383 12 12c0 6.618-5.383 12-12 12Zm0-23c-6.066 0-11 4.935-11 11 0 6.066 4.934 11.002 11 11.002s11-4.936 11-11.001C23 5.965 18.067 1.03 12 1.03Z"
        />
        <path
            fill="#000"
            d="M12 21.79c-5.38 0-9.758-4.38-9.758-9.76s4.379-9.758 9.759-9.758c5.38 0 9.758 4.379 9.758 9.759 0 5.38-4.378 9.758-9.758 9.758ZM12 3.27c-4.83 0-8.759 3.93-8.759 8.76s3.93 8.759 8.76 8.759 8.759-3.929 8.759-8.76c0-4.83-3.929-8.759-8.76-8.759Z"
        />
        <path
            fill="#000"
            d="M12 21.79c-5.38 0-9.758-4.38-9.758-9.76s4.379-9.758 9.759-9.758c5.38 0 9.758 4.379 9.758 9.759 0 5.38-4.378 9.758-9.758 9.758ZM12 3.27c-4.83 0-8.759 3.93-8.759 8.76s3.93 8.759 8.76 8.759 8.759-3.929 8.759-8.76c0-4.83-3.929-8.759-8.76-8.759Z"
        />
        <path
            fill="#000"
            d="M13.142 16.545H9.094v-1h4.048c.86 0 1.561-.7 1.561-1.56 0-.86-.7-1.56-1.56-1.56h-2.357a2.562 2.562 0 0 1-2.56-2.56 2.562 2.562 0 0 1 2.56-2.56h3.606v.999h-3.605a1.563 1.563 0 0 0 0 3.124h2.355a2.56 2.56 0 1 1 0 5.118Z"
        />
        <path
            fill="#000"
            d="M12.546 6.08h-1v1.8h1v-1.8ZM12.546 15.928h-1v1.801h1v-1.801ZM7.377 17.907a7.391 7.391 0 0 1-.075-11.689l.618.785a6.401 6.401 0 0 0-2.446 5.028c0 1.315.396 2.58 1.147 3.654.38.542.837 1.021 1.364 1.425l-.608.797ZM16.779 17.905l-.618-.786a6.401 6.401 0 0 0 2.446-5.027c0-1.315-.396-2.58-1.147-3.654a6.484 6.484 0 0 0-1.364-1.425l.607-.792a7.388 7.388 0 0 1 2.903 5.873c0 1.712-.6 3.382-1.687 4.702-.34.406-.722.78-1.14 1.109ZM18.91 18.004l-.848.847 1.585 1.585.848-.848-1.585-1.584ZM4.351 3.448l-.847.847L5.089 5.88l.847-.847-1.585-1.585ZM19.648 3.448l-1.585 1.585.847.847 1.585-1.585-.848-.847ZM5.089 18.005 3.504 19.59l.847.847 1.585-1.585-.847-.848Z"
        />
    </svg>
)
export default USDTokenSVG;
