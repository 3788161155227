
import { Box } from '@mui/material';
import React from 'react';

const VaultIconSVG = ({ width = 38, height = 34 }) => (
    <Box sx={{ width, height }}>
        <svg xmlns="http://www.w3.org/2000/svg" width='100%' height='100%' viewBox="0 0 38 34" fill="none">
            <g clipPath="url(#clip0_1416_15)">
                <path d="M26.5436 0.588257H11.1582C5.31025 0.588257 0.541504 5.35701 0.541504 11.2049V16.3383V32.8758C0.541504 33.4737 1.03734 33.9695 1.63525 33.9695H36.0519C36.6498 33.9695 37.1457 33.4737 37.1457 32.8758V16.3383V11.2049C37.1457 5.35701 32.3915 0.588257 26.5436 0.588257ZM28.979 31.7674H8.88317C8.40192 28.632 5.89359 26.182 2.729 25.7737V17.432H14.0603H14.4832V22.3028C14.4832 22.9008 14.979 23.3966 15.5769 23.3966H21.7603C22.3582 23.3966 22.854 22.9008 22.854 22.3028V17.432H23.4228H34.9436V25.8028C31.8811 26.2695 29.4603 28.6903 28.979 31.7674ZM16.6853 21.2091V12.7362H20.6811V21.2091H16.6853ZM2.729 11.2049C2.729 6.55284 6.50609 2.77576 11.1582 2.77576H26.5436C31.1957 2.77576 34.9728 6.55284 34.9728 11.2049V15.2445H23.4519H22.8832V11.6424C22.8832 11.0445 22.3873 10.5487 21.7894 10.5487H15.6061C15.0082 10.5487 14.5123 11.0445 14.5123 11.6424V15.2445H14.0603H2.729V11.2049ZM2.729 27.9612C4.68317 28.3258 6.24359 29.8424 6.68109 31.7674H2.729V27.9612ZM31.1957 31.7674C31.6186 29.8862 33.0915 28.4133 34.9728 27.9903V31.7674H31.1957Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_1416_15">
                    <rect width="36.6042" height="33.3667" fill="white" transform="translate(0.541504 0.588257)" />
                </clipPath>
            </defs>
        </svg>
    </Box>
);

export default VaultIconSVG;