import React, { useEffect, useState,useContext } from 'react'
import { Box, Button, ClickAwayListener, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import ArrowRightPrimaryIconSVG from '../../../components/SVGs/ArrowRightPrimaryIconSVG';
import DashboardBarCodeSVG from '../../../components/SVGs/Admin/DashboardBarCodeSVG';
import ToolTipInfoSVG from '../../../components/SVGs/ToolTipInfoSVG';
import CurrencyIconSVG from '../../../components/SVGs/Admin/MenuIcons/CurrencyIconSVG';
import VaultIconSVG from '../../../components/SVGs/Admin/MenuIcons/VaultIconSVG';
import TradingIconSVG from '../../../components/SVGs/Admin/MenuIcons/TradingIconSVG';
import BitCoinTokenSVG from '../../../components/SVGs/BitCoinTokenSVG';
import ETHTokenSVG from '../../../components/SVGs/ETHTokenSVG';
import USDTokenSVG from '../../../components/SVGs/USDTokenSVG';
import CommonContainer from '../../../components/CommonComponents/CommonContainer/CommonContainer';
import LightTooltip from '../../../components/LightTooltip/LightTooltip';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate } from 'react-router-dom';
import { useEthers } from '@usedapp/core';
import DCIJSON from '../../../abis/DualCurrency.json';
import DCIJSONNew from '../../../abis/DCI_New.json';
import Token from '../../../abis/OurToken.json';
import { Contract,utils } from 'ethers';
import dayjs from 'dayjs';
import { ConfigContext,UserContext,connectContractToSigner,convertW3Data,CURRENCY_UNIT,DISPLAY_DECIMALS,formatNumber, convertPercent } from '../../../common';
const DCIABI=DCIJSON.abi;
const tokenABI=Token.abi;

const data1 = {
    label: 'TOTAL VOLUME TRADED',
    value: '$10,495,322.12',
    goToLabel: 'TRANSACTIONS'//fixme to go to below table
};

const data2 = {
    label: 'BPS REWARDS',
    value: '100,000 BPS',
    goToLabel: 'MANAGE'
};

const investmentProducts = [
    {
        icon: <CurrencyIconSVG fill='#000' />,
        label: 'Dual Currency',
        description: 'Earn steady yield (up to 100% APY) whichever way the market goes.'
    },
    {
        icon: <VaultIconSVG fill='#000' />,
        label: 'Arb Yield Vaults',
        description: 'Deposit stables and earn up to 40% APY.'
    },
    {
        icon: <TradingIconSVG fill='#000' />,
        label: 'Risk-free Trading',
        description: 'Description here'
    },
];
const tokenMapping={
    'BTC':{
        icon:<BitCoinTokenSVG />,
        name: 'Bitcoin'
    },
    'WBTC':{
        icon:<BitCoinTokenSVG />,
        name: 'Bitcoin'
    },
    'ETH':{
        icon:<ETHTokenSVG />,
        name: 'Ethereum'
    },
    'WETH':{
        icon:<ETHTokenSVG />,
        name: 'Ethereum'
    },
    'USDC':{
        //fixme
        icon:<USDTokenSVG />,
        name: 'USDC'
    },
    'USDT':{
        icon:<USDTokenSVG />,
        name: 'USDT'
    }

}
const clientTradeState = ['OPEN', 'DECLINED', 'SETTLED'];
const DCIStatus = [
    'INVALID',
    'LOCKED',
    'REQUEST_WITHDRAW',
    'WITHDRAWN',
    'EXERCISED',
    'EXPIRED'
]
const Dashboard = () => {
    const { account, library, chainId } = useEthers();
    const user=useContext(UserContext);
    const {chainConfig,provider,balances}=user;
    const [trades,setTrades]=useState([]);
    const {ttv}=user;
    const bps=formatNumber(balances?.BPS||0,2);
    const navigate=useNavigate();
    const [pageNo,setPageNo]=useState(1);
    const start=(pageNo-1)*10;
    const end=Math.max(0,Math.min(pageNo*10,trades.length));
    const total=trades.length;
    const tradesToDisplay=trades.slice(start,end);
    
    useEffect(()=>{
        console.log(chainConfig);
        if(!chainConfig?.dci){
            return;
        }
        
        const loadTrades=async()=>{
            
            const trades=[];
            let accountLoad=account;
            // let accountLoad='0x2D98c24DfD650323347C3a21df864C951241227a';//testing
            //todo handle native ETH
            if(chainConfig.name.includes('ETH')){
                const trades = await (await fetch('/client_trade')).json();
                console.log('trades',trades);
                let mappedTrades=trades.map((trade)=>{
                    const strikeDecimals= 18+ CURRENCY_UNIT['USDC']-CURRENCY_UNIT[trade.group];
                    let status='-';
                    if (trade.is_verified) {
                        status = 'OPEN';
                        if(dayjs.unix(trade.maturity).isBefore(dayjs())){
                            status='SETTLE';
                        }
                    }

                    return {
                        id: trade.address+'|'+ trade.enterTime,
                        ts: trade.enterTime,
                        token: trade.tokenExercised,
                        amount: formatNumber(Number(utils.formatUnits(trade.amount,CURRENCY_UNIT[trade.investToken])),DISPLAY_DECIMALS[trade.investToken]), 
                        investToken: trade.investToken,
                        expriy: dayjs.unix(trade.maturity).format('DD-MMM-YY'),
                        strike: formatNumber(Number(utils.formatUnits(trade.strike,strikeDecimals)),0), 
                        'yield_client':`${trade.yield_client/100} %`,
                        status: status
                    }
                });
                mappedTrades.sort((a,b)=>b.ts-a.ts);
                setTrades([...mappedTrades]);
            }else{
                if(chainConfig.nft_api){
                    const dciResponse=await fetch(`${chainConfig.nft_api}/getNFTs?owner=${accountLoad}&contractAddresses[]=${chainConfig.dci2}&withMetadata=false`);
                    const dciJson = await dciResponse.json();
                    console.log('nfts dci response',chainConfig.dci2,accountLoad, dciJson);
                    const { ownedNfts:dciNfts } = dciJson;
                    const dciNftIds = dciNfts.map(nft => window.BigInt(nft.id.tokenId));
                    
                    if(dciNftIds.length>0){
                        const dciContract = new Contract(chainConfig.dci2,DCIJSONNew.abi,provider);
                        const dciTrades = await dciContract.getTrades(dciNftIds);
                        console.log('dci trades',dciTrades);
                        dciTrades.forEach((trade)=>{
                            const group=chainConfig.groups[Number(trade.instrumentId)];
                            const base=chainConfig.tokens2[group.base];
                            const contra=chainConfig.tokens2[group.contra];
                            const token=trade.investInBase?base:contra;
                            const exerciseToken=trade.investInBase?contra:base;
                            const apy=convertPercent(trade.apy);
                            trades.push({
                                id: 'w3|'+chainConfig.dci2+'|'+ trade.id,
                                ts: Number(trade.tradeTime),
                                token: exerciseToken.name,
                                amount: formatNumber(Number(convertW3Data(trade.amount,token.decimals,token.displayDecimals)),token.displayDecimals), 
                                investToken: token.name,
                                expriy: dayjs.unix(Number(trade.expiry)).format('DD-MMM-YY'),
                                strike: formatNumber(Number(convertW3Data(trade.strike,18+contra.decimals-base.decimals,0)),0), 
                                'yield_client': `${(apy/100).toFixed(2)} %`,
                                status: DCIStatus[Number(trade.status)]
                            })
                        });
                        trades.sort((a,b)=>b.ts-a.ts);
                        console.log(trades);
                        setTrades([...trades]);
                    }   
                }
                Object.keys(chainConfig.dci).forEach((ccy)=>{
                    const {CALL,PUT,contra}=chainConfig.dci[ccy];
                    const dciContract=new Contract(CALL,DCIABI,provider);
                    dciContract.getTradesFor(accountLoad).then(w3Trades=>{
                        console.log(ccy,contra,'CALL',w3Trades);
                        w3Trades.forEach((w3Trade)=>{
                            trades.push({
                                id: CALL+'|'+ w3Trade.id,
                                ts: w3Trade.enterTime,
                                token: contra,
                                amount: formatNumber(Number(convertW3Data(w3Trade.amount,chainConfig.tokens[ccy].decimals,chainConfig.tokens[ccy].displayDecimals)),chainConfig.tokens[ccy].displayDecimals), 
                                investToken: ccy,
                                expriy: dayjs.unix(w3Trade.maturity).format('DD-MMM-YY'),
                                strike: formatNumber(Number(convertW3Data(w3Trade.strike,18+chainConfig.tokens[contra].decimals-chainConfig.tokens[ccy].decimals,0)),0), 
                                'yield_client':`${w3Trade.yield_client/100} %`,
                                status: clientTradeState[w3Trade.state]
                            })
                        });
                        trades.sort((a,b)=>b.ts-a.ts);
                        setTrades([...trades]);
                    });
                    const dciContract2=new Contract(PUT,DCIABI,provider);
                    dciContract2.getTradesFor(accountLoad).then(w3Trades=>{
                        console.log(ccy,contra,'PUT',w3Trades);
                        w3Trades.forEach((w3Trade)=>{
                            trades.push({
                                id: PUT+'|'+ w3Trade.id,
                                ts: w3Trade.enterTime,
                                token: ccy,
                                amount: formatNumber(Number(convertW3Data(w3Trade.amount,chainConfig.tokens[contra].decimals,chainConfig.tokens[contra].displayDecimals)),chainConfig.tokens[contra].displayDecimals), 
                                investToken: contra,
                                expriy: dayjs.unix(w3Trade.maturity).format('DD-MMM-YY'),
                                strike: formatNumber(Number(convertW3Data(w3Trade.strike,18+chainConfig.tokens[contra].decimals-chainConfig.tokens[ccy].decimals,0)),0), 
                                'yield_client': `${w3Trade.yield_client/100} %`,
                                status: clientTradeState[w3Trade.state]
                            })
                        });
                        trades.sort((a,b)=>b.ts-a.ts);
                        setTrades([...trades]);
                    });
                });
            }
            
            // const dciContract=new Contract('0x7e2Bd3Ee3FbA3bD0aE9BbF5D8B8aE7B6cA4cBb5a',DCIABI);
            // const filter=dciContract.filters.Trade(account);
            // const trades=await dciContract.queryFilter(filter);
            // setTrades(trades);
        };
        if(account){
            loadTrades();
        }
    },[account,chainId,library]);
    const [open, setOpen] = useState(false);

    const [openMobile, setOpenMobile] = useState(false);
    const [selectedTab, setSelectedTab] = useState('currency');

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleMobileTooltipClose = () => {
        setOpenMobile(false);
    };

    const handleMobileTooltipOpen = () => {
        setOpenMobile(true);
    };


    return (
        <Box sx={{ flex: 1, padding: { xl: '40px', lg: '30px', md: '25px', sm: '20px', xs: '15px' }, overflow: 'hidden' }} >
            <Box sx={{ marginBottom: '24px' }}>
                <Typography variant='inter24Bold'>
                    Dashboard
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '24px', marginBottom: '24px', flexDirection: { sm: 'row', xs: 'column' } }}>
                <Box
                    sx={{
                        backgroundColor: 'common.black',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: { lg: '24px', md: '21px', sm: '18px', xs: '12px' },
                        position: 'relative',
                        flex: 1
                    }}>
                    <Box sx={{
                        top: -1,
                        right: -1,
                        position: 'absolute',
                        width: 0,
                        height: 0,
                        borderLeft: { md: '20px solid transparent', xs: '15px solid transparent' },
                        borderTop: { md: '20px solid #fff', xs: '15px solid #fff' }
                    }} />
                    <Box sx={{ position: 'absolute', bottom: '0px', right: '10%', height: { md: '24px', sm: '20px', xs: '16px' } }}>
                        <DashboardBarCodeSVG />
                    </Box>
                    <Box sx={{ marginBottom: '8px', display: 'flex', gap: '8px' }}>
                        <Typography variant='roboto14Bold' color='common.white'>
                            {data1.label}
                        </Typography>

                        <LightTooltip
                            title="This is not the live MTM value."
                            placement="top"
                            arrow
                            onClose={handleTooltipClose}
                            open={open}
                        >
                            <Button sx={{ padding: 0, margin: 0, outline: 0, minWidth: '0px', display: { md: 'block', xs: 'none' } }} onClick={handleTooltipOpen} >
                                <ToolTipInfoSVG fill='#fff' />
                            </Button>
                        </LightTooltip>
                        <ClickAwayListener onClickAway={handleMobileTooltipClose} >
                            <LightTooltip
                                onClose={handleMobileTooltipClose}
                                open={openMobile}
                                title="This is not the live MTM value."
                                placement="top"
                                arrow
                            >
                                <Button sx={{ padding: 0, margin: 0, outline: 0, minWidth: '0px', display: { md: 'none', xs: 'block' } }} onClick={handleMobileTooltipOpen} >
                                    <ToolTipInfoSVG fill='#fff' />
                                </Button>
                            </LightTooltip>
                        </ClickAwayListener>
                    </Box>
                    <Box sx={{ marginBottom: { lg: '24px', md: '20px', sm: '16px', xs: '12px' } }}>
                        <Typography variant='roboto40' color='primary'>
                            {ttv}
                        </Typography>
                    </Box>
                    <Box sx={{ zIndex: 1 }}>
                        <Button sx={{ display: 'flex', alignItems: 'center', gap: '9px', padding: 0, outline: 0 }} color='white' onClick={() => navigate('#my-transactions')}>
                            <Typography variant='inter14Bold' color='common.white'>
                                {data1.goToLabel}
                            </Typography>
                            <ArrowRightPrimaryIconSVG />
                        </Button>
                    </Box>
                </Box>
                {
                    chainConfig?.rewardTokenAddress && <Box
                        sx={{
                            backgroundColor: 'common.black',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding: { lg: '24px', md: '21px', sm: '18px', xs: '12px' },
                            position: 'relative',
                            flex: 1
                        }}>
                        <Box sx={{
                            top: -1,
                            right: -1,
                            position: 'absolute',
                            width: 0,
                            height: 0,
                            borderLeft: { md: '20px solid transparent', xs: '15px solid transparent' },
                            borderTop: { md: '20px solid #fff', xs: '15px solid #fff' }
                        }} />
                        <Box sx={{ position: 'absolute', bottom: '0px', right: '10%', height: { md: '24px', sm: '20px', xs: '16px' } }}>
                            <DashboardBarCodeSVG />
                        </Box>
                        <Box sx={{ marginBottom: '8px', display: 'flex', gap: '8px' }}>
                            <Typography variant='roboto14Bold' color='common.white'>
                                {data2.label}
                            </Typography>
                        </Box>
                        <Box sx={{ marginBottom: { lg: '24px', md: '20px', sm: '16px', xs: '12px' } }}>
                            <Typography variant='roboto40' color='primary'>
                                {bps}
                            </Typography>
                        </Box>
                        <Box sx={{ zIndex: 1 }}>
                            <Button sx={{ display: 'flex', alignItems: 'center', gap: '9px', padding: 0, outline: 0 }} color='white' onClick={() => navigate('/admin/bps-rewards')}>
                                <Typography variant='inter14Bold' color='common.white'>
                                    {data2.goToLabel}
                                </Typography>
                                <ArrowRightPrimaryIconSVG />
                            </Button>
                        </Box>
                    </Box>
                }
                
            </Box>
            <Box sx={{ marginBottom: '16px' }}>
                <Typography variant='roboto16Bold'>
                    INVESTMENT PRODUCTS
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                gap: '24px',
                overflowX: 'scroll',
                maxWidth: { xl: '100%', lg: 'calc(calc(100vw * 0.72) - 60px)', md: 'calc(calc(100vw * 0.72) - 50px)', sm: 'calc(100vw - 40px)', xs: 'calc(100vw - 30px)' },
                paddingBottom: '15px',
                marginBottom: '40px'
            }}>
                {investmentProducts.map(({ icon, label, description }, index) => (
                    <CommonContainer key={index} sx={{ minWidth: { md: '377px', sm: '307px', xs: '237px' }, width: { md: '377px', sm: '307px', xs: '237px' }, display: 'flex', padding: '24px', gap: '16px', alignItems: 'center' }}>
                        <Box sx={{ width: { md: '80px', sm: '70px', xs: '60px' }, height: { md: '80px', sm: '70px', xs: '60px' }, borderRadius: { md: '80px', sm: '70px', xs: '60px' }, backgroundColor: 'primary.main', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box sx={{ width: { md: '32px', sm: '28px', xs: '24px' } }}>
                                {icon}
                            </Box>
                        </Box>
                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='inter20Bold' sx={{ marginbottom: '8px' }}>
                                {label}
                            </Typography>
                            <Typography variant='inter14'>
                                {description}
                            </Typography>
                        </Box>
                    </CommonContainer>
                ))}
            </Box>
            <Box sx={{ marginBottom: '16px' }}>
                <Typography variant='roboto16Bold' id='my-transactions'>
                    ACTIVE TRANSACTIONS
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', marginBottom: '24px', justifyContent: 'space-between', flexDirection: { md: 'row', xs: 'column' } }}>
                <Box sx={{ display: 'flex' }}>
                    <Button color='black' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} onClick={() => setSelectedTab('currency')}>
                        <Typography variant='roboto14500' >
                            Dual Currency
                        </Typography>
                        <svg style={{ visibility: selectedTab === 'currency' ? 'visible' : 'hidden' }} xmlns="http://www.w3.org/2000/svg" width="90" height="14" viewBox="0 0 90 14" fill="none">
                            <path d="M2 14C2 12.3431 3.34315 11 5 11H85C86.6569 11 88 12.3431 88 14V14H2V14Z" fill="black" />
                        </svg>
                    </Button>
                    <Button color='black' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} onClick={() => setSelectedTab('vault')}>
                        <Typography variant='roboto14500'>
                            Arb Yield Vault
                        </Typography>
                        <svg style={{ visibility: selectedTab === 'vault' ? 'visible' : 'hidden' }} xmlns="http://www.w3.org/2000/svg" width="90" height="14" viewBox="0 0 90 14" fill="none">
                            <path d="M2 14C2 12.3431 3.34315 11 5 11H85C86.6569 11 88 12.3431 88 14V14H2V14Z" fill="black" />
                        </svg>
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }} color='black'>
                    <Typography variant='inter14' sx={{ margin: '0px 8px' }}>
                        Showing {start+1}-{end} out of {total} transactions
                    </Typography>
                    <Button size="small" disabled={start===0} onClick={() => {setPageNo(pageNo-1)}} variant='outlined' color='black' sx={{ padding: 0, margin: 0, borderRadius: 0, minWidth: 'fit-content', marginLeft: '8px' }}>
                        <NavigateBeforeIcon size="small" />
                    </Button>
                    <Button size="small" disabled={total<=start+10} onClick={() => {setPageNo(pageNo+1)}} variant='outlined' color='black' sx={{ padding: 0, margin: 0, borderRadius: 0, minWidth: 'fit-content', marginLeft: '8px' }}>
                        <NavigateNextIcon size="small" />
                    </Button>
                </Box>
            </Box>

            {selectedTab === 'currency' && (
                <TableContainer component={Paper}
                    sx={{
                        border: '1px solid #FF7033',
                        overflowX: 'scroll',
                        paddingBottom: '5px',
                        // minWidth: { lg: '900px', md: 'calc(calc(100vw * 0.72) - 50px)', sm: 'calc(100vw - 60px)', xs: 'calc(100vw - 40px)' },
                        maxWidth: { lg: '100%', md: 'calc(calc(100vw * 0.72) - 50px)', sm: 'calc(100vw - 40px)', xs: 'calc(100vw - 30px)' }
                    }}>
                    <Table sx={{ minWidth: 900 }} aria-label="simple table">
                        <TableHead sx={{ borderBottomStyle: 'dotted', borderBottomWidth: '2px', borderBottomColor: '#FF7033' }}>
                            <TableRow>
                                <TableCell>
                                    <Typography variant='roboto12Bold' color='#888071'>
                                        TOKEN
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='roboto12Bold' color='#888071'>
                                        INVESTMENT
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='roboto12Bold' color='#888071'>
                                        TARGET PRICE
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='roboto12Bold' color='#888071'>
                                        YIELD
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='roboto12Bold' color='#888071'>
                                        SETTLEMENT DATE
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='roboto12Bold' color='#888071'>
                                        STATUS
                                    </Typography>
                                </TableCell>
                                {/* <TableCell>
                                    <Typography variant='roboto12Bold' color='#888071'>
                                        ACTIONS
                                    </Typography>
                                </TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            
                            {tradesToDisplay.map((trade,index) => (
                                <TableRow
                                    key={trade.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell scope="row">
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                            {tokenMapping[trade.token].icon}
                                            <Typography variant='inter14'>
                                                {tokenMapping[trade.token].name}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='roboto14500'>
                                            {trade.amount} {tokenMapping[trade.investToken].name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='roboto14500'>
                                            {trade.strike}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ border: '1px solid #037A41', background: '#CFE2D9', width: 'fit-content', padding: '4px 8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Typography variant='inter12' color='#037A41'>
                                                {trade.yield_client}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='inter14' width='fit-content'>
                                            {trade.expriy}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ width: 'fit-content', border: '1px solid #CCC', background: '#E7E7E7', padding: '0px 8px' }}>
                                            <Typography variant='inter12'>
                                                {trade.status}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    {/* <TableCell>
                                        <Box sx={{ width: 'fit-content', border: '1px solid #E7E7E7', padding: '0px 12px' }}>
                                            <Typography variant='inter12Bold'>
                                                DETAILS
                                            </Typography>
                                        </Box>
                                    </TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {selectedTab === 'vault' && (
                <TableContainer component={Paper}
                    sx={{
                        borderRadius: 0,
                        border: '1px solid #FF7033',
                        overflowX: 'scroll',
                        paddingBottom: '5px',
                        // minWidth: { lg: '900px', md: 'calc(calc(100vw * 0.72) - 50px)', sm: 'calc(100vw - 60px)', xs: 'calc(100vw - 40px)' },
                        maxWidth: { lg: '100%', md: 'calc(calc(100vw * 0.72) - 50px)', sm: 'calc(100vw - 40px)', xs: 'calc(100vw - 30px)' }
                    }}>
                    <Table sx={{ minWidth: 900 }} aria-label="simple table">
                        <TableHead sx={{ borderBottomStyle: 'dotted', borderBottomWidth: '2px', borderBottomColor: '#FF7033' }}>
                            <TableRow>
                                <TableCell>
                                    <Typography variant='roboto12Bold' color='#888071'>
                                        TOKEN
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='roboto12Bold' color='#888071'>
                                        TOTAL SUBSCRIBED AMOUNT
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell scope="row" style={{ width: '15%' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <USDTokenSVG />
                                        <Typography variant='inter14'>
                                            iUSD
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='roboto14Bold'>
                                        {formatNumber(balances?.iUSD||0,2)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    )
}

export default Dashboard;