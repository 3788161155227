import { Box, Container, Divider, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import BaljunaMarquee from '../../../components/BaljunaMarquee/BaljunaMarquee';
import HomeHeader from '../../../components/HomeHeader/HomeHeader';
import NavButton from '../../../components/NavButton/NavButton';
const Banner = () => {

    useEffect(() => {
        const laptop = document.getElementById("laptop");
        if (laptop) laptop.style.right = "-60px";
    }, []);
    const [tvl,setTvl]=React.useState('-');
    const [ttv,setTtv]=React.useState('-');
    useEffect(()=>{
        const load=async()=>{
            const tvlData=await (await fetch('/get_tvl_ttv')).json();
            const ttv=tvlData.reduce((acc,cur)=>cur.TTV,0);
            const tvl=tvlData.reduce((acc,cur)=>acc+cur.TVL,0);
            setTtv(ttv.toLocaleString('en-US', { style: 'currency', currency: 'USD' }));
            setTvl(tvl.toLocaleString('en-US', { style: 'currency', currency: 'USD' }));
        };
        load();
    },[]);
    return (
        <Box component='section'
            sx={{
                backgroundColor: 'common.black',
                overflow: 'hidden',
                paddingTop: {
                    xl: '32px',
                    lg: '24px',
                    xs: '16px'
                },
                paddingBottom: '5px',
                paddingLeft: {
                    xl: '32px',
                    lg: '24px',
                    xs: '16px'
                },
                paddingRight: {
                    xl: '32px',
                    lg: '24px',
                    xs: '16px'
                },
            }}>

            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', borderWidth: '0.75px', borderStyle: 'solid', borderColor: 'secondary.main' }}>
                <Container maxWidth='xl' fixed>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                        paddingTop: { xl: '80px', lg: '90px', md: '80px', xs: '70px' },
                        paddingLeft: { lg: '34px', md: '28px', sm: '20px' }
                    }}>
                        <Box sx={{ flex: 1 }}>
                            <HomeHeader
                                sx={{ marginTop: '5%', display: { md: 'block', xs: 'none' } }}
                                title="BUILD WEALTH SMARTER WITH BALJUNA WATERS"
                                titleColor='secondary.main'
                                description="The only smart contract-powered, full custody, DeFi hub where your principal is protected, your losses are rewarded, and your trades earn interest."
                                descriptionColor='common.white'
                            />
                            <HomeHeader
                                sx={{ marginTop: '5%', paddingRight: '20%', display: { md: 'none', xs: 'block' } }}
                                title="SUPERIOR SOLUTIONS TO MODERN DEFI."
                                titleColor='secondary.main'
                                description="The most innovative real yield products built on-chain"
                                descriptionColor='common.white'
                            />
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: 'fit-content',
                                border: '2px solid rgba(255, 255, 255, 0.30)',
                                marginBottom: '24px'
                            }}>
                                <Box sx={{ padding: { md: '10px 16px', xs: '8px 12px' }, borderRight: '2px solid rgba(255, 255, 255, 0.30)' }}>
                                    <Typography color='common.white' variant='currency'>
                                        TTV: {ttv}
                                    </Typography>
                                </Box>
                                <Divider orientation="vertical" flexItem />
                                <Box sx={{ padding: { md: '10px 16px', xs: '8px 12px' } }}>
                                    <Typography color='common.white' variant='currency'>
                                        TVL: {tvl}
                                    </Typography>
                                </Box>
                            </Box>
                            <NavButton
                                variant='contained'
                                color='primary'
                                textColor='common.black'
                                textVariant='button1'
                                label='LAUNCH APP'
                                href='/admin'
                                hoverBackgroundColor='primary'
                            />
                        </Box>
                        <Box sx={{
                            flex: 1,
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            marginTop: { md: '0px', xs: '-20px' }
                        }}>
                            <Box
                                component='img'
                                src="/assets/images/illustrate2.png"
                                alt="Vector 1"
                                sx={{
                                    objectFit: 'contain',
                                    maxWidth: '375px',
                                    width: '55%',
                                    height: 'auto',
                                    transition: '1.5s'
                                }}
                            />

                            <Box
                                id="laptop"
                                component='img'
                                src="/assets/images/laptop.png"
                                alt="Laptop"
                                sx={{
                                    objectFit: 'contain',
                                    width: { md: '100%', sm: '90%', xs: '100%' },
                                    height: 'auto',
                                    position: { md: 'absolute', xs: 'relative' },
                                    marginTop: { md: '0px', sm: '-170px', xs: '-110px' },
                                    right: '-660px',
                                    top: '40%',
                                    transition: '1.5s'
                                }}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '30px', marginTop: { md: '-50px', xs: '-25%' } }}>
                        <Box sx={{ flex: 1, display: { md: 'block', xs: 'none' } }} />
                        <Box sx={{ flex: 1, textAlign: { md: 'center', xs: 'start' } }}>
                            <Box
                                component='img'
                                src="/assets/images/illustrate1.png"
                                alt="Vector 1"
                                sx={{
                                    objectFit: 'contain',
                                    maxWidth: '256px',
                                    width: { md: '55%', xs: '70%' },
                                    height: 'auto',
                                    transition: '1.5s',
                                    zIndex: 1,
                                    position: 'relative'
                                }}
                            />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'flex-end', position: 'relative', zIndex: 1 }}>
                                <Typography variant='audited'>
                                    Audited by
                                </Typography>
                                <Box component='img' src="/assets/images/peckshield-logo.png" alt="Peck Shield Logo"
                                    sx={{
                                        objectFit: 'contain',
                                        maxWidth: '192px',
                                        width: { md: '45%', xs: '55%' },
                                        height: 'auto',
                                    }} />
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <BaljunaMarquee />
            </Box >
        </Box >
    );
};

export default Banner;