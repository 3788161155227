import * as React from "react"
const RewardsIconSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={22}
        fill="none"
        {...props}
    >
        <path
            stroke="#FF4C00"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M2.971 9.101h16.057v11.9H2.971z"
        />
        <path
            stroke="#FF4C00"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M8.709 4.88h4.585v16.119H8.709z"
        />
        <path
            stroke="#FF4C00"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M1.365 9.1h19.268V4.88H1.365zM4.438 1 11 4.88H4.438V1Z"
        />
        <path
            stroke="#FF4C00"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M17.424 1 10.86 4.88h6.564V1Z"
        />
    </svg>
)
export default RewardsIconSVG
