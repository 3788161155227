import * as React from "react"
const ReferralBarCodeSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width='100%'
        height='100%'
        viewBox="0 0 184 16"
        fill="none"
        {...props}
    >
        <path
            stroke="gray"
            strokeWidth={4.35}
            d="M181.229 26V.443M2.175.443V26M133.385 26V.443M50.021.443V26"
        />
        <path
            stroke="gray"
            strokeWidth={1.45}
            d="M174.705 26V.443M8.7.443V26M126.861 26V.443M56.545.443V26M150.781 26V.443M32.622.443V26M102.939 26V.443M80.465.443V26M142.81 26V.443M40.596.443V26M94.964 26V.443M88.44.443V26"
        />
        <path
            stroke="gray"
            strokeWidth={13.051}
            d="M160.929 26V.443M22.475.443V26M113.088 26V.443"
        />
        <path stroke="gray" strokeWidth={8.701} d="M68.145.443V26" />
        <path
            stroke="gray"
            strokeWidth={4.35}
            d="M157.305 26V.443M26.097.443V26M109.463 26V.443M73.941.443V26"
        />
    </svg>
)
export default ReferralBarCodeSVG;
