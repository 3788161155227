import { createContext } from "react";
import { utils } from 'ethers';
import BitCoinTokenSVG from './components/SVGs/BitCoinTokenSVG';
import ETHTokenSVG from './components/SVGs/ETHTokenSVG';
import USDTokenSVG from './components/SVGs/USDTokenSVG';
import {BS,BSHolder} from './bs';
export const UserContext=createContext();
export const ConfigContext=createContext();
export const InstrumentListContext=createContext();
export const ONE_ETHER=10n ** 18n;
export const ONE_YEAR = 365 * 24 * 3600;
const quoteRatio=0.6;
const refQuoteRatio=0.7;
export function connectContractToSigner(contract, options, library) {
    if (contract.signer) {
      return contract;
    }
    if (options === null || options === void 0 ? void 0 : options.signer) {
      return contract.connect(options.signer);
    }
    if (library === null || library === void 0 ? void 0 : library.getSigner()) {
      return contract.connect(library.getSigner());
    }
    throw new TypeError('No signer available in contract, options or library');
}


export const convertW3Data = (data, decimals, displayDecimals) => {
  // console.log('convert', data, decimals, displayDecimals);
  if (data===null||data===void 0) {
      debugger;
  }
  if (decimals >= displayDecimals) {
      decimals = decimals - displayDecimals;
      return Number(data.toBigInt() / (10n ** window.BigInt(decimals))) / Math.pow(10, displayDecimals);
  } else {
      return Number(data.toBigInt() / (10n ** window.BigInt(decimals)));
  }
}

export const convertTokenAmt = (amt, token) => {
  const { decimals, displayDecimals } = token;
  return convertW3Data(amt, decimals, displayDecimals);
}
export const parseTokenAmt=(amt,token)=>{
  const { decimals, displayDecimals } = token;
  if(decimals>-displayDecimals){
      return window.BigInt(Math.round(amt * (10 ** displayDecimals))) * (10n ** window.BigInt(decimals - displayDecimals));
  }else{
      return window.BigInt(Math.round(amt * (10 ** displayDecimals))) / (10n ** window.BigInt(displayDecimals - decimals));
  }
}
export const convertPercent=(p)=>{
  return Number(p.toBigInt()*10000n/ONE_ETHER)/10000;
}
export const parsePercent=(p)=>{
  return window.BigInt(Math.floor(p*10000))*ONE_ETHER/10000n;
}
export const convertW3Price = (p, group,tokens) => {
  const { base, contra, displayDecimals } = group;
  const w3Decimals = 18 + tokens[contra].decimals - tokens[base].decimals;
  return convertW3Data(p, w3Decimals, displayDecimals);
}
export const parseW3Price = (p, group,tokens) => {
  const { base, contra,displayDecimals } = group;
  const w3Decimals = 18 + tokens[contra].decimals - tokens[base].decimals;
  return window.BigInt(Math.floor(p * (10 ** displayDecimals)))* (10n ** window.BigInt(w3Decimals - displayDecimals));
}
export const quoteAPY=(spot,r,vol,strike,now,expiry,inBase)=>{
  const amount=inBase?1:strike;
  const tau=(expiry - now) / ONE_YEAR;
  const bsHolder = new BSHolder(spot, strike, r, vol, tau);
  let pv=inBase?BS.call(bsHolder):BS.put(bsHolder);
  pv=Math.max(pv,0);
  if(!!localStorage.getItem('refcode')){
      pv=pv*refQuoteRatio;
  }else{
      pv=pv*quoteRatio;
  }
  if(inBase){
      pv=pv/spot;
  }
  return pv/(tau*amount);
}

const host =
  window.location.host.indexOf('github.dev') >= 0 || window.location.host.indexOf('localhost') >= 0
    ? 'baljuna.io'
    : window.location.host;
console.log('host', host);
const cache = {};
const subscriber = {};
const WS_CONTAINER={}
function connectWS() {
  const schema = window.location.protocol === 'https:'||window.location.host.indexOf('localhost') >= 0 ? 'wss' : 'ws';
  console.log('connecting to ', `${schema}://${host}/ws`);
  const ws = new WebSocket(`${schema}://${host}/ws`);
  ws.onmessage = (message) => {
    const data = JSON.parse(message.data);
    if (data.instrument) {
      cache[data.instrument] = data;
      if (subscriber[data.instrument]) {
        subscriber[data.instrument].forEach((cb) => cb(data));
      }
    }
  };
  return ws;
}
const keepalive=()=>{
  if (WS_CONTAINER.connect && (!WS_CONTAINER.websocket||WS_CONTAINER.websocket?.readyState == WebSocket.CLOSED)) {
    WS_CONTAINER.websocket = connectWS();
  }
}
export function setConnection(con){
  WS_CONTAINER.connect=con;
  keepalive();
}
setInterval(() => {
  keepalive();
}, 5000);
export function subscribeWS(key, cb) {
  console.log('subscribing ', key);
  if (!subscriber[key]) {
    subscriber[key] = [];
  }
  subscriber[key].push(cb);
  if (cache[key]) {
    console.log('notifying ', key);
    cb(cache[key]);
  }
  return () => {
    subscriber[key] = subscriber[key].filter((icb) => icb !== cb);
  };
}
export function formatNumberRoundDown(value, dp) {
  const options = {
    style: 'decimal', 
    minimumFractionDigits: 0,
    maximumFractionDigits: dp,
  };
  value=Math.floor(value*Math.pow(10,dp))/Math.pow(10,dp);
  return value?.toLocaleString('en-US', options);

}
export function formatNumber(value, dp) {
  const options = {
    style: 'decimal',  // Other options: 'currency', 'percent', etc.
    minimumFractionDigits: 0,
    maximumFractionDigits: dp,
  };
  return value?.toLocaleString('en-US', options);
}

export async function postData(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export const tokenMapping={
  'BTC':{
      icon:<BitCoinTokenSVG />,
      name: 'Bitcoin'
  },
  'ETH':{
      icon:<ETHTokenSVG />,
      name: 'Ethereum'
  },
  'WETH':{
      icon:<ETHTokenSVG />,
      name: 'Ethereum'
  },
  'USDC':{
      icon:<USDTokenSVG />,
      name: 'USDC'
  },
  'USDT':{
      icon:<USDTokenSVG />,
      name: 'USDT'
  }

}
export const CURRENCY_UNIT = {
  USDT: 6,
  USDC: 6,
  ETH: 18,
  WETH: 18,
  BTC: 8,
  WBTC: 8,
  SOL: 18,
};
export const DISPLAY_DECIMALS={
  USDT: 2,
  USDC: 2,
  ETH: 2,
  WETH: 2,
  BTC: 4,
  WBTC: 4,
  SOL: 2,
}
const globalFuncs={};
export const setGlobalAlert=(funcs)=>{
  globalFuncs['_default']=funcs;
}
export const globalAlert=(level,msg)=>{
  globalFuncs['_default']?.(level,msg);
}
export const tryF=(operation,f)=>async (...args)=>{
  try{
      return await f(...args);
  }catch(e){
      globalAlert('error',`Error while ${operation}: ${e.reason||e.message||String(e)||e}`);
      console.error(e);
  }
}