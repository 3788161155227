import React, { useEffect, useState } from 'react';
import CommonModal from '../CommonComponents/CommonModal/CommonModal';
import { Box, Typography, Button, ClickAwayListener } from '@mui/material';
import LightTooltip from '../../components/LightTooltip/LightTooltip';
import ToolTipInfoSVG from '../../components/SVGs/ToolTipInfoSVG';
import BitCoinTokenSVG from '../SVGs/BitCoinTokenSVG';
import OppositeIconSVG from '../SVGs/OppositeIconSVG';
import GiftCircleSVG from '../SVGs/GiftCircleSVG';
import DualCurrencyJSON from '../../abis/DualCurrency.json';
import IERC20JSON from '../../abis/OurToken.json';

import _ from 'lodash';
import dayjs from 'dayjs';
import {formatNumber,postData,connectContractToSigner,tokenMapping,formatNumberRoundDown,tryF} from '../../common';
import { Contract } from 'ethers';
import * as ethers from 'ethers';
import { useEthers,ChainId } from '@usedapp/core';


const BuyTheDipModal = ({ open, handleClose, onClickSuccess, dciItem,account,chainConfig,user }) => {
    const {library,switchNetwork}=useEthers();
    const [investAmount, setInvestAmount] = useState('');
    const [tradeableQuote, setTradeableQuote] = useState();
    const { dci, tokens, chainId, name: chainName } = chainConfig || {};
    const baseToken=tokens?.[dciItem?.base_ccy];
    const contraToken=tokens?.[dciItem?.contra_ccy];
    const minSize=(dci?.[dciItem?.base_ccy]?.minSize * dciItem?.strike).toFixed(contraToken?.displayDecimals);
    const maxSize=(dci?.[dciItem?.base_ccy]?.maxSize * dciItem?.strike).toFixed(contraToken?.displayDecimals);
    const token=tokenMapping[dciItem?.base_ccy];
    let effectiveAmount=minSize;
    const investParsed=parseFloat(investAmount);
    if(!isNaN(investParsed) && investParsed!==0){
        effectiveAmount=investParsed;
    }
    if(maxSize){
        effectiveAmount=Math.min(effectiveAmount,maxSize);
    }
    
    const payoff = effectiveAmount * (1+tradeableQuote?.yield_rate * tradeableQuote?.year_fraction);
    const payOffExercised = payoff / dciItem?.strike;
    const updateInvestAmount=(text)=>{
        const value = parseFloat(text);
        if(isNaN(value) || text.endsWith('.') || value===0){
            setInvestAmount(text);
        }else{
            const scale=Math.pow(10, tokens?.[dciItem?.contra_ccy]?.displayDecimals);
            const roundedValue=Math.floor(value * scale)/scale;
            let finalValue=Math.max(roundedValue,minSize);
            if(maxSize){
                finalValue=Math.min(finalValue,maxSize);
            }
            setInvestAmount(finalValue);
        }
    }
    const ensureBalance=async(tokenName,amount,chainConfig)=>{
        const token=chainConfig.tokens[tokenName];
        const tokenContract = new Contract(
            token.addr,
            IERC20JSON.abi,
            user?.provider
        );
        const balance = await tokenContract.balanceOf(account);
        if(balance.lt(amount)){
            //fixme error notification
            throw new Error('Insufficient balance '+tokenName);
        }
    }
    const ensureAllowance=async(spender,tokenName,amount,chainConfig)=>{
        await ensureBalance(tokenName,amount,chainConfig);
        const token=chainConfig.tokens[tokenName];
        const tokenContract = new Contract(
            token.addr,
            IERC20JSON.abi,
            user?.provider
        );
        const allowance = await tokenContract.allowance(account,spender);
        if(allowance.lt(amount)){
            const tokenSigned = connectContractToSigner(
                tokenContract,
                {
                  transactionName: `Approval Spending of ${tokenName}`,
                },
                library
              );
            const transaction=await tokenSigned.approve(spender,amount);
            await transaction.wait(1);
        }
    }
    const subscribeDCI=async()=>{
        
        const dciAddr=chainConfig?.dci?.[dciItem?.base_ccy]?.PUT;
        const dciContract = new Contract(
            dciAddr,
            DualCurrencyJSON.abi,
            user?.provider
        );
        const rewards = await postData('/compute_rewards', {
            token: tradeableQuote['token'],
            amount: effectiveAmount,
            status: 'processing',
            });
        const reward_data = rewards.map((reward) => [
            reward.address,
            parseInt(reward.amount),
        ]);
        const strikeDecimals= 18 + contraToken?.decimals - baseToken?.decimals;
        const strike = ethers.utils.parseUnits(dciItem?.strike.toString(),strikeDecimals);
        console.log('reward_data',reward_data,'strike',strike);
        const web3Amount=ethers.utils.parseUnits(effectiveAmount.toString(),contraToken?.decimals);
        await ensureAllowance(dciAddr,dciItem?.contra_ccy,web3Amount,chainConfig);
        const signed = connectContractToSigner(
            dciContract,
            {
            transactionName: `Subscribe to buy ${dciItem?.base_ccy}`,
            },
            library
        );
        const yield_rate = parseInt(tradeableQuote['yield_rate'] * 10000);
        const expiry=dciItem.expiry;
        //for testing only, expires in 5 minutes
        // const expiry=Math.round((new Date()).getTime()/1000)+300;
        
        console.log(
            'subscribing, params ',
            web3Amount,
            strike,
            yield_rate,
            expiry,
            tradeableQuote['token'],
            reward_data
        );
        const subscribe = await signed.enterDirect(
            web3Amount,
            strike,
            yield_rate,
            expiry,
            tradeableQuote['token'],
            reward_data
        );
        console.log('subscribing', subscribe);
        const update = postData('/client_trade', {
            chainId: chainId,
            chainName: chainName,
            token: tradeableQuote['token'],
            instrument: dciItem['instrument'],
            contractAddress:dciAddr,
            investToken: dciItem['contra_ccy'],
            tokenExercised: dciItem['base_ccy'],
            group: dciItem['group'],
            amount: effectiveAmount,
            strike: dciItem.strike,
            expiry: dciItem.expiry,
            call_put: dciItem['call_put'],
            yield_rate: yield_rate,
            status: 'processing',
            });
        await subscribe.wait(1);
        await update;
        onClickSuccess(dciItem,effectiveAmount,tradeableQuote['yield_rate']);
    }
    const [openToolTip, setOpenToopTip] = useState(false);
    const [openMobileToolTip, setOpenMobileTooltip] = useState(false);

    const handleTooltipClose = () => {
        setOpenToopTip(false);
    };

    const handleTooltipOpen = () => {
        setOpenToopTip(true);
    };

    const handleMobileTooltipClose = () => {
        setOpenMobileTooltip(false);
    };

    const handleMobileTooltipOpen = () => {
        setOpenMobileTooltip(true);
    };

    const trySubscribe= tryF('Subscribing', subscribeDCI);
    const switchChain=()=>{
        switchNetwork(chainId===ChainId.Mainnet?ChainId.Polygon:ChainId.Mainnet);
    }
    
    useEffect(() => {
        if(!(dciItem && account)){
            return;
        }
        const loadTradeableQuote=async()=>{
            let response = await fetch(
                `/get_tradeable/${dciItem.instrument}/${account}`
            );
            let json = await response.json();
            if (json['token']) {
                console.log('got token', json);
                setTradeableQuote(json);
            } else {
                //fixme error notification
            }
        }
        loadTradeableQuote();
    }, [dciItem,account]);
    const strikeText=formatNumber(dciItem?.strike,0);
    return (
        <CommonModal width={{ md: '801px', xs: 'calc(100vw - 50px)' }} minWidth='400px' title='// SUBSCRIBE TO BUY THE DIP' open={open} handleClose={handleClose}>
            <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column-reverse' } }}>
                <Box sx={{ flex: 1, borderRightColor: '#000', borderRightWidth: '1px', borderRightStyle: 'solid', padding: '24px', minWidth: '400px', boxSizing: 'border-box' }}>
                    <Box sx={{ display: 'flex', gap: '8px', marginBottom: '24px' }}>
                        {token?.icon}
                        <Typography variant='inter16'>
                            {token?.name}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '12px' }}>
                        <Typography variant='inter14'>
                            Target Buy Price
                        </Typography>
                        <Typography variant='inter14Bold'>
                            ${strikeText}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '12px' }}>
                        <Typography variant='inter14'>
                            Settlement Date
                        </Typography>
                        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>

                            <Typography variant='inter14Bold'>
                                {dayjs.unix(dciItem?.expiry).format('DD-MMM-YY')}
                            </Typography>
                            <Typography variant='inter14' color='#808080'>
                                ({dayjs.unix(dciItem?.expiry).fromNow()})
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '24px' }}>
                        <Typography variant='inter14'>
                            % APY
                        </Typography>
                        <Box sx={{ display: 'flex', padding: '0px 8px', border: '1px solid #037A41' }}>
                            <Typography variant='inter14Bold' color='#037A41'>
                                {(tradeableQuote?.yield_rate*100)?.toFixed(0)}%
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ marginBottom: '8px' }}>
                        <Typography variant='inter14'>
                            How much do you want to invest?
                        </Typography>
                    </Box>
                    <Box sx={{ border: '1px solid #B0A592', padding: '20px 16px', backgroundColor: '#FEFDFA', marginBottom: '8px' }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingBottom: '8px',
                            borderBottomColor: '#CCCCCC',
                            borderBottomWidth: '1px',
                            borderBottomStyle: 'solid',
                            marginBottom: '8px',
                            gap: '16px'
                        }}>
                            <Box sx={{ flex: 1 }}>
                                <Box
                                    component={'input'}
                                    placeholder={`Minimum ${minSize} USDC`}
                                    sx={{
                                        background: 'transparent',
                                        width: '100%',
                                        fontFamily: 'Inter',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        outline: 0,
                                        border: 0
                                    }}
                                    value={investAmount}
                                    onChange={(e) => setInvestAmount(e.target.value)} onBlur={(e)=>updateInvestAmount(e.target.value)}/>
                                    
                            </Box>
                            <Box>
                                <Typography variant='inter14'>
                                    USDC | <Typography variant='inter14Bold' sx={{ cursor: 'pointer' }} onClick={() => updateInvestAmount(formatNumberRoundDown(user?.balances?.[dciItem?.contra_ccy],contraToken?.displayDecimals))}>Max</Typography>
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography variant='inter12'>
                                Wallet Balance: {formatNumber(user?.balances?.[dciItem?.contra_ccy],contraToken?.displayDecimals)} USDC
                            </Typography>
                        </Box>
                        {
                            user?.balances?.[dciItem?.contra_ccy] < effectiveAmount &&  <Box sx={{ marginBottom: '8px' }}>
                                <Typography variant='inter12' color='#DC3C3C'>
                                    You don’t have enough balance.
                                </Typography>
                            </Box>
                        }
                       
                    </Box>
                    {
                        !chainName?.includes('Polygon') &&<Box sx={{ backgroundColor: '#FAFAFA', padding: '16px', display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }} onClick={switchChain}>
                            <Typography variant='inter14'>
                                Want a lower gas or minimum investment option?
                            </Typography>
                            <Typography variant='inter14Bold'>
                                SWITCH TO POLYGON NETWORK
                            </Typography>
                        </Box>
                        <OppositeIconSVG />
                        </Box>
                    }
                    
                    <Box sx={{ marginBottom: '16px' }}>
                        <Typography variant='inter14'>
                            Please review and make sure all details are correct before you subscribe. Subscribed amount is locked until the settlement date.
                        </Typography>
                    </Box>
                    <Button variant='contained' color='black' disabled={_.isEmpty(investAmount.toString())} sx={{ width: '100%' }} onClick={trySubscribe}>
                        <Typography variant='inter14Bold'>
                            SUBSCRIBE
                        </Typography>
                    </Button>
                </Box>
                <Box sx={{ flex: 1, padding: '24px', minWidth: '400px', boxSizing: 'border-box' }}>
                    <Box sx={{ marginBottom: '8px' }}>
                        <Typography variant='roboto14Bold'>
                            SETTLEMENT PROJECTION
                        </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '16px' }}>
                        <Typography variant='inter14'>
                            With an investment of <Typography variant='inter14Bold'>${formatNumber(effectiveAmount,contraToken?.displayDecimals)}</Typography>:
                        </Typography>
                    </Box>
                    <Box sx={{ backgroundColor: '#FAFAFA', padding: '16px', display: 'flex', marginBottom: '8px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <Typography variant='inter14'>
                                If price at settlement <Typography variant='inter14Bold'>{`>=$${strikeText}`}   </Typography>,
                            </Typography>
                            <Typography variant='inter14'>
                                you will receive <Typography variant='inter14Bold'>{formatNumber(payoff,contraToken?.displayDecimals)} USDC</Typography>.
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <LightTooltip
                                title="Total of your investment + % APY interest"
                                placement="top"
                                arrow
                                onClose={handleTooltipClose}
                                open={openToolTip}
                            >
                                <Button sx={{ padding: 0, margin: 0, outline: 0, minWidth: '0px', display: { md: 'block', xs: 'none' } }} onClick={handleTooltipOpen} >
                                    <ToolTipInfoSVG />
                                </Button>
                            </LightTooltip>
                            <ClickAwayListener onClickAway={handleMobileTooltipClose} >
                                <LightTooltip
                                    onClose={handleMobileTooltipClose}
                                    open={openMobileToolTip}
                                    title="Total of your investment + % APY interest"
                                    placement="top"
                                    arrow
                                >
                                    <Button sx={{ padding: 0, margin: 0, outline: 0, minWidth: '0px', display: { md: 'none', xs: 'block' } }} onClick={handleMobileTooltipOpen} >
                                        <ToolTipInfoSVG />
                                    </Button>
                                </LightTooltip>
                            </ClickAwayListener>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
                        <Box sx={{ padding: '0px 8px', backgroundColor: '#0B8E4F' }}>
                            <Typography variant='inter14Bold' color='common.white'>
                                TARGET BUY PRICE: ${strikeText}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ backgroundColor: '#c7e0d4', padding: '16px', display: 'flex', flexDirection: 'column', marginBottom: '24px' }}>
                        <Typography variant='inter14'>
                            If price at settlement <Typography variant='inter14Bold'>{`<$${strikeText}`}   </Typography>,
                        </Typography>
                        <Typography variant='inter14'>
                            you will receive <Typography variant='inter14Bold'>{formatNumber(payOffExercised,baseToken?.displayDecimals)} BTC</Typography>.
                        </Typography>
                    </Box>
                    <Box sx={{ padding: '16px', border: '1px solid #000' }}>
                        <Box sx={{ marginBottom: '8px' }}>
                            <Typography variant='roboto14Bold'>
                                TRADING REWARDS
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '12px' }}>
                            <Box sx={{ width: '40px' }}>
                                <GiftCircleSVG />
                            </Box>
                            <Box>
                                <Typography variant='inter14'>
                                    Earn extra BPS tokens when the settlement price is below the target buy price.<br></br><br></br>
                                    <Typography variant='inter14Bold'>
                                        Learn More
                                    </Typography> on how it works.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </CommonModal>
    );
};

export default BuyTheDipModal;