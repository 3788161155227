import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const MenuItemComponent = ({ icon, label, isActive, onClick, href, target, soon }) => {
    if (onClick) {
        return (
            <Button
                onClick={onClick}
                color='white'
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: '8px',
                    padding: '8px',
                    position: 'relative',
                    cursor: 'pointer',
                    backgroundColor: isActive ? '#343434' : 'transparent',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.2)'
                    }
                }}>
                <Box sx={{ width: '24px', display: 'flex', justifyContent: 'center' }}>
                    {icon}
                </Box>
                <Box>
                    <Typography variant='inter14' color='common.white' sx={{ textTransform: 'initial' }}>
                        {label}
                    </Typography>
                </Box>
                {soon === true && (
                    <Box sx={{ padding: '0px 8px', backgroundColor: '#282BF6', marginLeft: 'auto' }}>
                        <Typography variant='inter14Bold' color='common.white'>
                            SOON
                        </Typography>
                    </Box>
                )}
            </Button>
        );
    } else if (icon) {
        return (
            <Link to={href} style={{ textDecoration: 'none' }} target={target ? '_blank' : '_self'}>
                <Button
                    color='white'
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '8px',
                        padding: '8px',
                        position: 'relative',
                        cursor: 'pointer',
                        backgroundColor: isActive ? '#343434' : 'transparent',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.2)'
                        }
                    }}>
                    <Box sx={{ width: '24px', display: 'flex', justifyContent: 'center' }}>
                        {icon}
                    </Box>
                    <Box>
                        <Typography variant='inter14' color='common.white' sx={{ textTransform: 'initial' }}>
                            {label}
                        </Typography>
                    </Box>
                    {soon === true && (
                        <Box sx={{ padding: '0px 8px', backgroundColor: '#282BF6', marginLeft: 'auto' }}>
                            <Typography variant='inter14Bold' color='common.white'>
                                SOON
                            </Typography>
                        </Box>
                    )}
                </Button>
            </Link>
        );
    } else {
        return (
            <Link to={href} style={{ textDecoration: 'none' }} target={target ? '_blank' : '_self'}>
                <Button
                    color='white'
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '6px',
                        padding: '8px',
                        position: 'relative',
                        cursor: 'pointer',
                        backgroundColor: isActive ? '#343434' : 'transparent',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.2)'
                        }
                    }}>
                    <Box sx={{ minWidth: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ position: 'absolute', borderWidth: '0.5px', borderColor: '#fff', borderStyle: 'solid', height: '100%' }} />
                    </Box>
                    <Box>
                        <Typography variant='inter14' color='common.white' sx={{ textTransform: 'initial' }}>
                            {label}
                        </Typography>
                    </Box>
                </Button>
            </Link>
        );
    }
}

export default MenuItemComponent