import { Box, Container } from '@mui/material';
import React from 'react';
import QuarterLineSVG from '../../../components/SVGs/QuarterLineSVG';
import RoadMapSVG from '../../../components/SVGs/RoadMapSVG';

const Mapping = () => {

    return (
        <Box component="section" sx={{ paddingTop: { md: '100px', xs: '50px' }, paddingBottom: { md: '100px', xs: '50px' }, position: 'relative', overflow: 'hidden' }}>

            <Box
                component='img'
                src="/assets/images/illustrate2.png"
                alt="Vector 1"
                sx={{
                    objectFit: 'contain',
                    width: { xl: '500px', lg: '400px', md: '350px', sm: '300px', xs: '250px' },
                    left: { xl: '-100px', lg: '-150px', md: '-150px', sm: '-130px', xs: ' -130px' },
                    height: 'auto',
                    position: 'absolute',
                    top: 0,
                    WebkitTransform: 'scale(-1, 1)',
                    MozTransformStyle: 'scale(-1, 1)',
                    transform: 'scale(-1, 1)',
                    opacity: 0.2
                }}
            />
            <Box
                component='img'
                src="/assets/images/illustrate1.png"
                alt="Vector 1"
                sx={{
                    objectFit: 'contain',
                    width: { xl: '500px', lg: '400px', md: '350px', sm: '300px', xs: '250px' },
                    right: { lg: '-200px', md: '-170px', sm: '-150px', xs: '-150px' },
                    height: 'auto',
                    position: 'absolute',
                    bottom: 0,
                    opacity: 0.2
                }}
            />
            <Box sx={{
                position: 'absolute',
                left: 0,
                top: '5%',
                width: { md: '25%', sm: '30%', xs: '30%' }
            }}>
                <QuarterLineSVG />
            </Box>
            <Box sx={{
                position: 'absolute',
                left: 0,
                top: '35%',
                width: { md: '25%', sm: '30%', xs: '30%' }
            }}>
                <QuarterLineSVG />
            </Box>
            <Container maxWidth='xl' fixed sx={{ paddingTop: { md: '6%', xs: '3%' }, paddingBottom: { md: '6%', xs: '3%' } }}>
                <RoadMapSVG width='100%' height='100%' />
            </Container>
        </Box>
    );
};

export default Mapping;

