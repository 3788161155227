
import { Box } from '@mui/material';
import React from 'react';

const CurrencyBagIconSVG = ({ width = 27, height = 35 }) => (
    <Box sx={{ width, height }}>
        <svg xmlns="http://www.w3.org/2000/svg" width='100%' height='100%' viewBox="0 0 27 35" fill="none">
            <path d="M23.8725 17.8168C22.8769 16.1272 21.6549 14.6034 20.2217 13.2909H21.157C21.685 13.2909 22.1225 12.8534 22.1225 12.3254C22.1225 11.7974 21.685 11.3599 21.157 11.3599H19.4825L24.5363 5.05388C24.7928 4.73707 24.8381 4.29957 24.657 3.9375C24.476 3.57543 24.1139 3.33405 23.7066 3.33405H19.1204L19.8294 1.43319C19.9501 1.11638 19.9049 0.739224 19.7087 0.467672C19.5126 0.165948 19.1807 0 18.8337 0H7.02125C6.65918 0 6.3122 0.196121 6.11608 0.512931C5.93504 0.814655 5.91996 1.2069 6.08591 1.53879L7.00616 3.31897H2.14841C1.74108 3.31897 1.37901 3.54526 1.19798 3.92241C1.03203 4.28448 1.07729 4.72198 1.33375 5.03879L6.38763 11.3448H3.97384C3.44582 11.3448 3.00832 11.7823 3.00832 12.3103C3.00832 12.8384 3.44582 13.2759 3.97384 13.2759H5.64841C4.27556 14.5582 3.09884 16.0065 2.13332 17.6509C-0.0541803 21.3772 -0.582197 25.7974 0.654872 29.7802C1.86177 33.6573 3.97384 34.6228 5.5428 34.7284C7.97168 34.9095 10.5363 35 13.101 35C15.6204 35 18.1398 34.9095 20.5385 34.7435C22.1075 34.6228 24.2346 33.6724 25.4264 29.7651C26.6031 25.8427 26.045 21.4828 23.8725 17.8168ZM8.09237 5.43103L9.84237 8.82543C10.1139 9.33836 10.7475 9.54957 11.2605 9.27802C11.7734 9.00646 11.9846 8.37284 11.7131 7.85991L8.75616 2.11207H17.31L15.1075 7.9806C14.8963 8.52371 15.1829 9.12716 15.726 9.33836C15.8467 9.38362 15.9674 9.39871 16.1031 9.39871C16.5256 9.39871 16.9329 9.14224 17.0988 8.70474L18.3208 5.43103H21.504L16.8424 11.2543H8.99754L4.35099 5.43103H8.09237ZM23.3898 29.1315C22.7109 31.3491 21.7001 32.5259 20.3725 32.6164C15.6355 32.9634 10.4307 32.9634 5.69366 32.6013C4.36608 32.4957 3.3553 31.334 2.66134 29.1315C1.59022 25.722 2.07298 21.9203 3.95875 18.6918C5.15056 16.6552 6.73461 14.9052 8.63547 13.5022L8.84668 13.3513H17.0083L17.4156 13.653C19.3014 15.0711 20.8553 16.8211 22.0622 18.8578C23.9178 22.056 24.4006 25.7823 23.3898 29.1315Z" fill="black" />
            <path d="M12.9353 17.2285C9.43528 17.2285 6.58398 20.0797 6.58398 23.5797C6.58398 27.0797 9.43528 29.931 12.9353 29.931C16.4353 29.931 19.2866 27.0797 19.2866 23.5797C19.2866 20.0647 16.4353 17.2285 12.9353 17.2285ZM12.9353 28C10.4913 28 8.51502 26.0086 8.51502 23.5797C8.51502 21.1509 10.4913 19.1595 12.9353 19.1595C15.3792 19.1595 17.3555 21.1358 17.3555 23.5797C17.3555 26.0237 15.3642 28 12.9353 28Z" fill="black" />
        </svg>
    </Box>
);

export default CurrencyBagIconSVG;