import { createTheme } from "@mui/material";

const CustomTheme = createTheme({
    palette: {
        primary: {
            main: '#FF4C00',
            light: '#FF7033',
            dark: '#E84500',
            contrastText: '#000'
        },
        secondary: {
            main: '#F8E9CD',
            light: '#F9EDD7',
            dark: '#E2D4BB',
            contrastText: '#000'
        },
        tertiary: {
            main: '#1C1FAF',
            light: '#2427E0',
            dark: '#1C1FAF',
            contrastText: '#fff'
        },
        black: {
            main: '#000',
            light: '#555555',
            dark: '#555555',
            contrastText: '#fff'
        },
        white: {
            main: '#fff',
            light: '#fff',
            dark: '#f0f0f0',
            contrastText: '#000'
        },
        red: {
            light: '#ff7961',
            main: '#dc3c3c',
            dark: '#ba000d',
            contrastText: '#fff',
        },
        common: {
            grey: '#808080'
        }
    },
    typography: {
        fontFamily: 'Inter',
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold: 700,
    },
    components: {
        MuiInput: {
            styleOverrides: {
                root: {
                    color: '#FFEDE6',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    width: '100%',
                    paddingBottom: '5px',

                    '&:before': {
                        borderBottom: '2px solid #808080',
                        borderBottomStyle: 'dotted',
                        color: '#fff'
                    },
                    '&:hover:not(.Mui-disabled, .Mui-error):before': {
                        borderBottom: '2px solid #808080',
                        borderBottomStyle: 'dotted',
                        color: '#fff'
                    },
                    '&.Mui-focused:before': {
                        borderBottom: '2px solid #FFEDE6',
                        borderBottomStyle: 'dotted'
                    },
                    '&.Mui-focused:after': {
                        borderBottom: '2px solid #FFEDE6',
                        borderBottomStyle: 'dotted',
                        color: '#fff'
                    },
                    '&.Mui-error:after': {
                        borderBottomColor: '#FF4C00'
                    },
                    '&.Mui-error:before': {
                        borderBottomColor: '#FF4C00'
                    },
                    '&.Mui-focused.MuiAutocomplete-input': {
                        backgroundColor: 'transparent'
                    },
                    "&:has(> input:-webkit-autofill)": {
                        backgroundColor: 'transparent'
                    }
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    paddingTop: '14px',
                    paddingBottom: '14px',
                    // '&:hover': {
                    //     backgroundColor: 'rgba(255, 255, 255, 0.2)'
                    // }
                }
            }
        }
    }
});

CustomTheme.components.MuiInput.styleOverrides.input = {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#FFEDE6',
    '::-webkit-input-placeholder': {
        color: '#CCCCCC'
    },
    '::placeholder': {
        color: '#CCCCCC',
        opacity: 1
    },


    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '12px',
        lineHeight: '20px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '12px',
        lineHeight: '20px'
    },
};

CustomTheme.typography.h1Mb = {
    fontFamily: 'GoshaSansBold',

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '80px',
        lineHeight: '95px',
        marginBottom: '25px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '60px',
        lineHeight: '65px',
        marginBottom: '20px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '45px',
        lineHeight: '50px',
        marginBottom: '15px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '36px',
        lineHeight: '36px',
        marginBottom: '15px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '36px',
        lineHeight: '36px',
        marginBottom: '15px'
    },
};

CustomTheme.typography.h1 = {
    fontFamily: 'GoshaSansBold',

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '80px',
        lineHeight: '95px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '60px',
        lineHeight: '65px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '45px',
        lineHeight: '50px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '36px',
        lineHeight: '36px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '36px',
        lineHeight: '36px'
    },
};

CustomTheme.typography.h2 = {
    fontWeight: 700,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '48px',
        lineHeight: '56px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '44px',
        lineHeight: '51px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '40px',
        lineHeight: '46px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '24px',
        lineHeight: '32px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '22px',
        lineHeight: '28px'
    },
};

CustomTheme.typography.h4 = {
    fontWeight: 700,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '28.892px',
        lineHeight: '38.523px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '26px',
        lineHeight: '36px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '22px',
        lineHeight: '32px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '18px',
        lineHeight: '28px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '16px',
        lineHeight: '24px'
    },
};

CustomTheme.typography.subtitle1 = {
    fontWeight: 600,
    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '24px',
        lineHeight: '32px',
        marginBottom: '30px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '22px',
        lineHeight: '30px',
        marginBottom: '30px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '18px',
        lineHeight: '24px',
        marginBottom: '30px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '16px',
        lineHeight: '22px',
        marginBottom: '25px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '15px',
        lineHeight: '20px',
        marginBottom: '25px'
    },
};

CustomTheme.typography.currency = {
    fontFamily: 'Roboto Mono',
    fontWeight: 700,
    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '22px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '20px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '18px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '16px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '14px'
    },
};

CustomTheme.typography.roboto24 = {
    fontFamily: 'Roboto Mono',
    fontWeight: 400,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '24px',
        lineHeight: '32px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '24px',
        lineHeight: '32px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '22px',
        lineHeight: '30px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '18px',
        lineHeight: '28px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '16px',
        lineHeight: '26px'
    }
};

CustomTheme.typography.body1 = {
    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '20px',
        lineHeight: '36px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '19px',
        lineHeight: '34px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '18px',
        lineHeight: '31px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '16px',
        lineHeight: '28px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '14px',
        lineHeight: '24px'
    },
};

CustomTheme.typography.body2 = {
    fontWeight: 400,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '20px',
        lineHeight: '30px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '18px',
        lineHeight: '26px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '18px',
        lineHeight: '22px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '15px',
        lineHeight: '21px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '14px',
        lineHeight: '20px'
    },
};

CustomTheme.typography.audited = {
    color: '#FFAD8A',
    fontFamily: 'Roboto Mono',
    fontWeight: 700,
    fontSize: '10.423px'
};

CustomTheme.typography.button1 = {
    fontWeight: 700,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '16px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '16px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '14px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '13px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '13px'
    }
};

CustomTheme.typography.inter15 = {
    fontFamily: 'Inter',
    fontWeight: 400,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '15px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '15px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '15px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '14px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '14px'
    }
};

CustomTheme.typography.inter14 = {
    fontFamily: 'Inter',
    fontWeight: 400,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '14px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '14px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '14px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '13px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '13px'
    }
};

CustomTheme.typography.inter24Bold = {
    fontFamily: 'Inter',
    fontWeight: 700,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '24px',
        lineHeight: '32px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '24px',
        lineHeight: '32px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '22px',
        lineHeight: '30px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '18px',
        lineHeight: '28px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '16px',
        lineHeight: '26px'
    }
};

CustomTheme.typography.inter20Bold = {
    fontFamily: 'Inter',
    fontWeight: 700,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '20px',
        lineHeight: '30px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '20px',
        lineHeight: '30px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '18px',
        lineHeight: '28px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '15px',
        lineHeight: '25px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '14px',
        lineHeight: '24px'
    }
};

CustomTheme.typography.inter14SemiBold = {
    fontWeight: 600,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '14px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '14px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '14px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '13px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '12px'
    }
};

CustomTheme.typography.inter16 = {
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '24px',
    fontSize: '16px',

    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '15px',
        lineHeight: '22px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '13px',
        lineHeight: '18px'
    },
}

CustomTheme.typography.inter16SemiBold = {
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: '16px',

    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '15px',
        lineHeight: '22px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '13px',
        lineHeight: '18px'
    },
}

CustomTheme.typography.inter16Bold = {
    fontFamily: 'Inter',
    fontWeight: 700,
    lineHeight: '24px',
    fontSize: '16px',

    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '15px',
        lineHeight: '22px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '13px',
        lineHeight: '18px'
    },
}

CustomTheme.typography.tableHeader = {
    fontFamily: 'Inter',
    fontWeight: 600,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '18px',
        lineHeight: '28px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '16px',
        lineHeight: '24px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '15px',
        lineHeight: '22px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '14px',
        lineHeight: '20px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '14px',
        lineHeight: '20px'
    },
}

CustomTheme.typography.tableSubHeader = {
    fontFamily: 'Roboto Mono',
    fontWeight: 400,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '16px',
        lineHeight: '24px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '14px',
        lineHeight: '20px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '13px',
        lineHeight: '18px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '12px',
        lineHeight: '16px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '12px',
        lineHeight: '16px'
    },
}

CustomTheme.typography.tableItem = {
    fontFamily: 'Inter',
    fontWeight: 600,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '16px',
        lineHeight: '24px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '14px',
        lineHeight: '20px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '13px',
        lineHeight: '18px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '12px',
        lineHeight: '16px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '12px',
        lineHeight: '16px'
    },
}

CustomTheme.typography.tableSubItem = {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '14px',
        lineHeight: '24px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '12px',
        lineHeight: '18px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '11px',
        lineHeight: '16px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '10px',
        lineHeight: '14px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '10px',
        lineHeight: '14px'
    },
}

CustomTheme.typography.footer = {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px'
}

CustomTheme.typography.subject = {
    fontFamily: 'Roboto Mono',
    fontWeight: 700,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '20px',
        lineHeight: '30px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '18px',
        lineHeight: '27px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '16px',
        lineHeight: '24px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '16px',
        lineHeight: '22px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '14px',
        lineHeight: '20px'
    },
}

CustomTheme.typography.sectionTitle = {
    fontFamily: 'Inter',
    fontWeight: 600,
    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '30px',
        lineHeight: '36px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '30px',
        lineHeight: '36px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '27px',
        lineHeight: '33px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '24px',
        lineHeight: '30px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '24px',
        lineHeight: '30px'
    },
};

CustomTheme.typography.sectionBody = {
    fontFamily: 'Inter',
    fontWeight: 400,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '16px',
        lineHeight: '33px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '16px',
        lineHeight: '33px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '15px',
        lineHeight: '30px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '14px',
        lineHeight: '28px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '14px',
        lineHeight: '28px'
    },
};

CustomTheme.typography.coreValues = {
    fontFamily: 'GoshaSansBold',
    textTransform: 'uppercase',
    textAlign: 'center',

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '40px',
        lineHeight: '100px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '40px',
        lineHeight: '90px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '32px',
        lineHeight: '80px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '24px',
        lineHeight: '70px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '23px',
        lineHeight: '60px'
    },
};

CustomTheme.typography.coreValuesDesc = {
    fontFamily: 'Inter',
    textAlign: 'center',

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '16px',
        lineHeight: '30px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '16px',
        lineHeight: '30px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '14px',
        lineHeight: '26px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '13px',
        lineHeight: '22px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '12px',
        lineHeight: '18px'
    },
};

CustomTheme.typography.bannerDesc = {
    fontFamily: 'Inter',
    fontWeight: 600,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '35px',
        lineHeight: '43px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '35px',
        lineHeight: '43px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '28px',
        lineHeight: '38px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '24px',
        lineHeight: '35px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '24px',
        lineHeight: '32px'
    },
};

CustomTheme.typography.name = {
    fontFamily: 'GoshaSansBold',

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '60px',
        lineHeight: '64px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '60px',
        lineHeight: '64px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '50px',
        lineHeight: '56px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '40px',
        lineHeight: '48px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '35px',
        lineHeight: '44px'
    },
};

CustomTheme.typography.form = {
    fontFamily: 'Inter',
    fontWeight: 700,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '16px',
        lineHeight: '24px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '15px',
        lineHeight: '22px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '14px',
        lineHeight: '20px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '13px',
        lineHeight: '19px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '12px',
        lineHeight: '18px'
    },
};

CustomTheme.typography.form2 = {
    fontFamily: 'Inter',
    fontWeight: 400,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '14px',
        lineHeight: '24px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '13px',
        lineHeight: '19px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '12px',
        lineHeight: '18px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '11px',
        lineHeight: '17px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '11px',
        lineHeight: '17px'
    },
};

CustomTheme.typography.inter14Bold = {
    fontFamily: 'Inter',
    fontWeight: 700,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '14px',
        lineHeight: '24px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '13px',
        lineHeight: '19px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '12px',
        lineHeight: '18px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '11px',
        lineHeight: '17px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '11px',
        lineHeight: '17px'
    },
};

CustomTheme.typography.authTitle = {
    fontFamily: 'GoshaSansBold',

    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '36px',
        lineHeight: '48px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '30px',
        lineHeight: '40px'
    },
};

CustomTheme.typography.authDescription = {
    fontFamily: 'GoshaSansBold',

    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '16px',
        lineHeight: '26px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '16px',
        lineHeight: '26px'
    },
};

CustomTheme.typography.inter12 = {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',

    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '11px',
        lineHeight: '16px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '11px',
        lineHeight: '16px'
    },
};

CustomTheme.typography.inter12Bold = {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '18px',

    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '11px',
        lineHeight: '16px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '11px',
        lineHeight: '16px'
    },
};

CustomTheme.typography.error404 = {
    fontFamily: 'Roboto Mono',
    fontWeight: 700,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '24px',
        lineHeight: '30px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '22px',
        lineHeight: '28px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '20px',
        lineHeight: '26px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '18px',
        lineHeight: '24px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '16px',
        lineHeight: '22px'
    },
};

CustomTheme.typography.error404Sub = {
    fontFamily: 'Roboto Mono',
    fontWeight: 700,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '15px',
        lineHeight: '32px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '14px',
        lineHeight: '30px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '13px',
        lineHeight: '28px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '12px',
        lineHeight: '26px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '11px',
        lineHeight: '24px'
    },
};

CustomTheme.typography.roboto5 = {
    fontFamily: 'Roboto Mono',

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '20px',
        lineHeight: '22px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '19px',
        lineHeight: '22px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '18px',
        lineHeight: '20px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '16px',
        lineHeight: '18px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '14px',
        lineHeight: '16px'
    },
};

CustomTheme.typography.roboto14 = {
    fontFamily: 'Roboto Mono',
    fontWeight: 400,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '14px',
        lineHeight: '20px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '14px',
        lineHeight: '20px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '13px',
        lineHeight: '18px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '12px',
        lineHeight: '16px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '11px',
        lineHeight: '15px'
    },
};

CustomTheme.typography.roboto14Bold = {
    fontFamily: 'Roboto Mono',
    fontWeight: 700,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '14px',
        lineHeight: '20px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '14px',
        lineHeight: '20px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '13px',
        lineHeight: '18px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '12px',
        lineHeight: '16px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '11px',
        lineHeight: '15px'
    },
};

CustomTheme.typography.roboto14500 = {
    fontFamily: 'Roboto Mono',
    fontWeight: 500,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '14px',
        lineHeight: '20px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '14px',
        lineHeight: '20px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '13px',
        lineHeight: '18px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '12px',
        lineHeight: '16px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '11px',
        lineHeight: '15px'
    },
};

CustomTheme.typography.roboto16Bold = {
    fontFamily: 'Roboto Mono',
    fontWeight: 700,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '16px',
        lineHeight: '24px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '16px',
        lineHeight: '24px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '15px',
        lineHeight: '22px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '14px',
        lineHeight: '19px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '13px',
        lineHeight: '16px'
    },
};

CustomTheme.typography.roboto32 = {
    fontFamily: 'Roboto Mono',
    fontWeight: 400,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '32px',
        lineHeight: '40px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '30px',
        lineHeight: '38px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '26px',
        lineHeight: '34px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '24px',
        lineHeight: '32px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '22px',
        lineHeight: '30px'
    },
};

CustomTheme.typography.roboto32Amount = {
    fontFamily: 'Roboto Mono',
    fontWeight: 400,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '32px',
        lineHeight: '40px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '24px',
        lineHeight: '34px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '26px',
        lineHeight: '34px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '24px',
        lineHeight: '32px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '22px',
        lineHeight: '30px'
    },
};

CustomTheme.typography.roboto40 = {
    fontFamily: 'Roboto Mono',
    fontWeight: 400,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '40px',
        lineHeight: '40px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '36px',
        lineHeight: '36px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '32px',
        lineHeight: '32px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '28px',
        lineHeight: '28px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '24px',
        lineHeight: '24px'
    },
};

CustomTheme.typography.roboto12 = {
    fontFamily: 'Roboto Mono',
    fontWeight: 400,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '12px',
        lineHeight: '18px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '12px',
        lineHeight: '18px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '11px',
        lineHeight: '16px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '10px',
        lineHeight: '14px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '10px',
        lineHeight: '14px'
    },
};

CustomTheme.typography.roboto12Bold = {
    fontFamily: 'Roboto Mono',
    fontWeight: 700,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '12px',
        lineHeight: '18px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '12px',
        lineHeight: '18px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '11px',
        lineHeight: '16px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '10px',
        lineHeight: '14px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '10px',
        lineHeight: '14px'
    },
};

CustomTheme.typography.referralTitle = {
    fontFamily: 'GoshaSansBold',

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '55px',
        lineHeight: '60px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '45px',
        lineHeight: '52px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '32px',
        lineHeight: '40px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '40px',
        lineHeight: '46px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '35px',
        lineHeight: '42px'
    },
};

CustomTheme.typography.inter32Bold = {
    fontFamily: 'Inter',
    fontWeight: 700,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '32px',
        lineHeight: '40px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '30px',
        lineHeight: '38px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '28px',
        lineHeight: '36px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '26px',
        lineHeight: '34px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '24px',
        lineHeight: '32px'
    },
};

CustomTheme.typography.inter32 = {
    fontFamily: 'Inter',
    fontWeight: 400,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '32px',
        lineHeight: '40px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '30px',
        lineHeight: '38px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '28px',
        lineHeight: '36px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '26px',
        lineHeight: '34px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '24px',
        lineHeight: '32px'
    },
};

CustomTheme.typography.GoshanSans16Bold = {
    fontFamily: 'GoshaSans',
    fontWeight: 700,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '16px',
        lineHeight: '24px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '16px',
        lineHeight: '24px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '14px',
        lineHeight: '20px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '13px',
        lineHeight: '18px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '12px',
        lineHeight: '16px'
    },
};

CustomTheme.typography.GoshanSans24Bold = {
    fontFamily: 'GoshaSans',
    fontWeight: 700,

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '24px',
        lineHeight: '32px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '22px',
        lineHeight: '30px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '20px',
        lineHeight: '28px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '18px',
        lineHeight: '26px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '16px',
        lineHeight: '24px'
    },
};

CustomTheme.typography.marquee = {
    fontFamily: 'GoshaSans',
    fontWeight: 400,
    verticalAlign: 'middle',
    overflow: 'hidden',

    [CustomTheme.breakpoints.only('xl')]: {
        fontSize: '28px',
        lineHeight: '28px'
    },
    [CustomTheme.breakpoints.only('lg')]: {
        fontSize: '24px',
        lineHeight: '24px'
    },
    [CustomTheme.breakpoints.only('md')]: {
        fontSize: '20px',
        lineHeight: '20px'
    },
    [CustomTheme.breakpoints.only('sm')]: {
        fontSize: '20px',
        lineHeight: '28px'
    },
    [CustomTheme.breakpoints.only('xs')]: {
        fontSize: '20px',
        lineHeight: '28px'
    }
}

export default CustomTheme;