import * as React from "react"
const OppositeIconSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill="#000"
            d="M22 16H4.414l3.293-3.293a1 1 0 0 0-1.414-1.414l-5 5a1 1 0 0 0 0 1.414l5 5a1 1 0 1 0 1.414-1.414L4.414 18H22a1 1 0 1 0 0-2ZM17.707 1.293a1 1 0 0 0-1.414 1.414L19.586 6H2a1 1 0 1 0 0 2h17.586l-3.293 3.293a1 1 0 1 0 1.414 1.414l5-5a1 1 0 0 0 0-1.414l-5-5Z"
        />
    </svg>
)
export default OppositeIconSVG
