import * as React from "react";

const DipIconSVG = ({ fill = '#fff' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
    >
        <path
            stroke={fill}
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M2 2h20v20H2z"
        />
        <path
            stroke={fill}
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="m5.277 7.839 3.26 6.019 5.392-2.508 7.774 10.533"
        />
    </svg>
)
export default DipIconSVG;

