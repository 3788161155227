import React, { useContext, useEffect, useState } from 'react';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { Box, Button, ClickAwayListener, Typography,Modal } from '@mui/material';
import DashboardBarCodeSVG from '../../../components/SVGs/Admin/DashboardBarCodeSVG';
import ToolTipInfoSVG from '../../../components/SVGs/ToolTipInfoSVG';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import LightTooltip from '../../../components/LightTooltip/LightTooltip';
import DarkTooltip from '../../../components/DarkTooltip/DarkTooltip';
import DepositUSDModal from '../../../components/DepositUSDModal/DepositUSDModal';
import DepositUSDSuccessModal from '../../../components/DepositUSDSuccessModal/DepositUSDSuccessModal';
import ClaimIUSDModal from '../../../components/ClaimIUSDModal/ClaimIUSDModal';
import ClaimIUSDSuccessModal from '../../../components/ClaimIUSDSuccessModal/ClaimIUSDSuccessModal';
import ArbYieldVaultModal from '../../../components/ArbYieldVaultModal/ArbYieldVaultModal';
import { useEthers } from '@usedapp/core';
import { Contract, utils } from 'ethers';
import InvestVaultJSON from '../../../abis/InvestVault.json'
import IERC20JSON from '../../../abis/OurToken.json';
import { UserContext,formatNumber,connectContractToSigner, tryF } from '../../../common';
import dayjs from 'dayjs';
const investVaultABI=InvestVaultJSON.abi;
const dashboardData = [
    {
        label: 'TOTAL VALUE LOCKED',
        value: '$1,495,322.12',
        goToLabel: 'VIEW PERFORMANCE BREAKDOWN',
        withTooltip: false
    },
    {
        label: 'iUSD PRICE',
        value: '$1.0073',
        goToLabel: 'BUY ON UNISWAP',
        goToLabel2: 'ADD TO METAMASK',
        withTooltip: true
    }
];

const ArbYieldVault = () => {
    const [open, setOpen] = useState(false);
    const {account,library}=useEthers();
    const user=useContext(UserContext);
    const [summary,setSummary]=useState([]);
    const [investData,setInvestData]=useState({});
    const {
        investVaultAddress,
        investTokenPriceDecimals=4
      } = user?.chainConfig||{};
    useEffect(()=>{
        if(user?.chainConfig){
            const load=async()=>{
                const investVaultContract=new Contract(investVaultAddress,investVaultABI,user?.provider);
                let investData = await investVaultContract.getStatusFor(account);
                let {balance,epochEnd,investing,redepmtion,tokenPrice,valuation}=investData;
                console.log('investData',investData);
                balance=formatNumber(Number(utils.formatUnits(balance,6)),2);
                investing=formatNumber(Number(utils.formatUnits(investing,6)),2);
                redepmtion=formatNumber(Number(utils.formatUnits(redepmtion,6)),2);
                epochEnd=dayjs.unix(epochEnd.toNumber()).format('DD-MMM-YY');
                valuation=formatNumber(Number(utils.formatUnits(valuation,6)),2);
                tokenPrice=formatNumber(Number(utils.formatUnits(tokenPrice,18)),investTokenPriceDecimals);
                const asset=formatNumber(Number(utils.formatUnits(investData.tokenPrice,18))*Number(utils.formatUnits(investData.balance,6)),2);
                console.log({balance,epochEnd,investing,redepmtion,tokenPrice,valuation,investTokenPriceDecimals,asset});
                setSummary([
                    {
                        label: 'TOTAL VALUE LOCKED',
                        value: `$${valuation}`,
                        goToLabel: 'VIEW PERFORMANCE BREAKDOWN',
                        withTooltip: false
                    },
                    {
                        label: 'iUSD PRICE',
                        value: `$${tokenPrice}`,
                        goToLabel: 'BUY ON UNISWAP',
                        goToLabel2: 'ADD TO METAMASK',
                        withTooltip: true
                    }
                ]);
                setInvestData({balance,epochEnd,investing,redepmtion,tokenPrice,valuation,asset})
            }
            load();            
        }
    },[user?.chainConfig])

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const [openMobile, setOpenMobile] = useState(false);

    const handleMobileTooltipClose = () => {
        setOpenMobile(false);
    };

    const handleMobileTooltipOpen = () => {
        setOpenMobile(true);
    };


    const [tooltip2, setTooltip2] = useState(false);
    const [tooltip2Mobile, setTooltip2Mobile] = useState(false);
    const [amount, setAmount] = useState('');
    const [tooltip3, setTooltip3] = useState(false);
    const [tooltip3Mobile, setTooltip3Mobile] = useState(false);

    const [tooltip4, setTooltip4] = useState(false);
    const [tooltip4Mobile, setTooltip4Mobile] = useState(false);

    const [depositModal, setDepositModal] = useState(false);
    const toggleDepositModal = () => setDepositModal(!depositModal);

    const [depositSuccessModal, setDepositSuccessModal] = useState(false);
    const toggleDepositSuccessModal = () => setDepositSuccessModal(!depositSuccessModal);
    const [openVideo, setOpenVideo] = useState(false);
    const depositSuccess = (amount) => {
        setDepositModal(false);
        setDepositSuccessModal(true);
        setAmount(amount);
    }

    const [claimModal, setClaimModal] = useState(false);
    const toggleClaimModal = () => setClaimModal(!claimModal);

    const [claimSuccessModal, setClaimSuccessModal] = useState(false);
    const toggleClaimSuccessModal = () => setClaimSuccessModal(!claimSuccessModal);

    const [showVaultReminderModal, setShowVaultReminderModal] = useState(true);

    const claimSuccess = () => {
        setClaimModal(false);
        setClaimSuccessModal(true);
    }
    const {balances,chainConfig,provider}=user||{};
    const ensureBalance=async(tokenName,tokenAddr,amount)=>{
        const tokenContract = new Contract(
            tokenAddr,
            IERC20JSON.abi,
            user?.provider
        );
        const balance = await tokenContract.balanceOf(account);
        if(balance<amount){
            //fixme error notification
            throw new Error('Insufficient balance '+tokenName);
        }
    }
    const ensureAllowance=async(spender,tokenName,tokenAddr,amount)=>{
        await ensureBalance(tokenName,tokenAddr,amount);
        const tokenContract = new Contract(
            tokenAddr,
            IERC20JSON.abi,
            user?.provider
        );
        const allowance = await tokenContract.allowance(account,spender);
        console.log('allowance',allowance,'amount',amount);
        if(allowance.lt(amount)){
            const tokenSigned = connectContractToSigner(
                tokenContract,
                {
                  transactionName: `Approval Spending of ${tokenName}`,
                },
                library
            );
            const transaction2=await tokenSigned.approve(spender,amount);
            await transaction2.wait(1);
        }
    }
    const onClickRedeem = async () => {
        const web3Amount=utils.parseUnits(""+balances?.iUSD,6);
        console.log('web3 amount',web3Amount);
        await ensureAllowance(chainConfig.investVaultAddress,'iUSD',chainConfig?.investTokenAddress,web3Amount);
        const vaultContract = new Contract(
            chainConfig.investVaultAddress,
            InvestVaultJSON.abi,
            user?.provider
        );
        const contractSigned = connectContractToSigner(
            vaultContract,
            {
              transactionName: 'redeem iUSD',
            },
            library
          );
        const transaction=await contractSigned.redeem(web3Amount);
        await transaction.wait(1);
        setAmount(balances?.iUSD);
        toggleClaimSuccessModal();
    }
    const tryRedeem=tryF('redeem iUSD',onClickRedeem);
    return (
        <Box sx={{ flex: 1, padding: { lg: '40px', md: '25px', sm: '20px', xs: '15px' }, overflow: 'hidden' }} >
            <Box sx={{ backgroundColor: '#000', marginBottom: '24px' }}>
                <Box
                    sx={{
                        background: 'url("/assets/svgs/vaults.svg")',
                        padding: '24px',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                    <Box sx={{ paddingRight: '16px' }}>
                        <Box sx={{ marginBottom: '8px' }}>
                            <Typography variant='GoshanSans24Bold' color='secondary.main'>
                                ARB YIELD VAULTS
                            </Typography>
                        </Box>
                        <Box sx={{ marginBottom: '16px' }}>
                            <Typography variant='inter16' color='secondary.main'>
                                Deposit USD and earn up to <Typography variant='inter16Bold' color='secondary.main'>40% APY</Typography>.
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '8px', marginBottom: '24px', alignItems: 'center' }}>
                            <Box sx={{ minWidth: '24px', width: '24px', height: '24px', borderRadius: '24px', backgroundColor: 'primary.main', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                                    <g clip-path="url(#clip0_651_12351)">
                                        <path d="M10.0889 9.0293H0.285156V11.0246H10.0889V9.0293Z" fill="black" />
                                        <path d="M0 11V2.04651L2.02837 0V11H0Z" fill="black" />
                                        <path d="M9.72536 2.02074H4.08233L2.00195 0.0253906H9.72536V2.02074Z" fill="black" />
                                        <path d="M10.0127 6.06246H6.13802L4.75977 4.75781H10.0127V6.06246Z" fill="black" />
                                        <path d="M5.20158 7.34188C6.30746 7.34188 7.20395 6.45998 7.20395 5.37211C7.20395 4.28424 6.30746 3.40234 5.20158 3.40234C4.09571 3.40234 3.19922 4.28424 3.19922 5.37211C3.19922 6.45998 4.09571 7.34188 5.20158 7.34188Z" fill="black" />
                                        <path d="M11.001 7.39258H8.97266V10.9996H11.001V7.39258Z" fill="black" />
                                        <path d="M11.001 0.0253906H8.97266V3.42772H11.001V0.0253906Z" fill="black" />
                                        <path d="M11.001 4.75781H8.97266V6.06246H11.001V4.75781Z" fill="black" />
                                        <path d="M5.20118 6.29265C5.70385 6.29265 6.11135 5.89179 6.11135 5.3973C6.11135 4.90281 5.70385 4.50195 5.20118 4.50195C4.69851 4.50195 4.29102 4.90281 4.29102 5.3973C4.29102 5.89179 4.69851 6.29265 5.20118 6.29265Z" fill="#FF4C00" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_651_12351">
                                            <rect width="11" height="11" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </Box>
                            <Typography variant='inter14' color='#CCCCCC'>
                                No minimum investment required.
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '8px' }}>
                            <Typography variant='inter14' color='#CCCCCC'>
                                Powered by
                            </Typography>
                            <Box component={'img'} src='/assets/svgs/ceffu.svg' sx={{ width: '86.634px', height: '24px' }}>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ backgroundColor: '#1A1A1A', padding: '24px', height: 'fit-content' }}>
                        <Box sx={{ marginBottom: '8px' }}>
                            <Typography variant='inter16Bold' color='primary.main'>
                                Don’t know how to start?
                            </Typography>
                        </Box>
                        <Box sx={{ marginBottom: '20px' }}>
                            <Typography variant='inter12' color='common.white'>
                                Watch our tutorial video to help you get started.
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '8px', cusor:'pointer' }} onClick={()=>setOpenVideo(true)}>
                            <PlayArrowRoundedIcon color='primary' />
                            <Typography variant='inter14Bold' color='common.white'>
                                WATCH TUTORIAL <Typography variant='inter14' color='common.white' sx={{ fontStyle: 'italic' }}>(3 mins)</Typography>
                            </Typography>
                            <Modal open={openVideo} onClose={()=>setOpenVideo(false)}>
                                <div className='video-container' onClick={()=>setOpenVideo(false)}>                                
                                    <iframe width="800" height="560" src="https://www.youtube.com/embed/-86hcYCumxo?&autoplay=1" title="Baljuna Waters - Arb Yield Vault" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen autoplay></iframe>
                                </div>
                            </Modal>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: '24px', marginBottom: '24px', flexDirection: { sm: 'row', xs: 'column' } }}>
                {summary.map(({ label, value, goToLabel, goToLabel2, withTooltip }, index) => (
                    <Box
                        key={index}
                        sx={{
                            backgroundColor: 'common.black',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding: { lg: '24px', md: '21px', sm: '18px', xs: '12px' },
                            position: 'relative',
                            flex: 1
                        }}>
                        <Box sx={{
                            top: -1,
                            right: -1,
                            position: 'absolute',
                            width: 0,
                            height: 0,
                            borderLeft: { md: '20px solid transparent', xs: '15px solid transparent' },
                            borderTop: { md: '20px solid #fff', xs: '15px solid #fff' }
                        }} />
                        <Box sx={{ position: 'absolute', bottom: '0px', right: '10%', height: { md: '24px', sm: '20px', xs: '16px' } }}>
                            <DashboardBarCodeSVG />
                        </Box>
                        <Box sx={{ marginBottom: '8px', display: 'flex', gap: '8px' }}>
                            <Typography variant='roboto14Bold' color='common.white'>
                                {label}
                            </Typography>

                            {withTooltip === true && (
                                <>
                                    <LightTooltip
                                        title="This is not the live MTM value."
                                        placement="top"
                                        arrow
                                    >
                                        <Button sx={{ padding: 0, margin: 0, outline: 0, display: { md: 'block', xs: 'none' }, minWidth: '0px' }} onClick={handleTooltipOpen} >
                                            <Box sx={{ minWidth: '16px' }}>
                                                <ToolTipInfoSVG fill='#fff' />
                                            </Box>
                                        </Button>
                                    </LightTooltip>
                                    <ClickAwayListener onClickAway={handleMobileTooltipClose} >
                                        <LightTooltip
                                            onClose={handleMobileTooltipClose}
                                            open={openMobile}
                                            title="This is not the live MTM value."
                                            placement="top"
                                            arrow
                                        >
                                            <Button sx={{ padding: 0, margin: 0, outline: 0, display: { md: 'none', xs: 'block' }, minWidth: '0px' }} onClick={handleMobileTooltipOpen} >

                                                <Box sx={{ minWidth: '16px' }}>
                                                    <ToolTipInfoSVG fill='#fff' />
                                                </Box>
                                            </Button>
                                        </LightTooltip>
                                    </ClickAwayListener>
                                </>
                            )}
                        </Box>
                        <Box sx={{ marginBottom: { lg: '24px', md: '20px', sm: '16px', xs: '12px' } }}>
                            <Typography variant='roboto40' color='primary'>
                                {value}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '9px', zIndex: 1 }}>
                            <Button color='white'>
                                <Typography variant='inter14Bold' color='common.white'>
                                    {goToLabel}
                                </Typography>
                            </Button>
                            {typeof goToLabel2 !== 'undefined' && (
                                <Button color='primary'>
                                    <Typography variant='inter14Bold' color='primary.main'>
                                        {goToLabel2}
                                    </Typography>
                                </Button>
                            )}
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box sx={{ border: '1px solid #B0A592', padding: '24px', marginBottom: '40px' }}>
                <Box sx={{ display: 'flex', gap: { lg: '48px', md: '36px', sm: '24px', xs: '24px' } }}>
                    <Box>
                        <Box sx={{ display: 'flex', gap: '8px', height: '25px' }}>
                            <Typography variant='roboto12Bold' color='#888071'>
                                Last NAV update date
                            </Typography>
                            {/* <LightTooltip
                                onClose={() => setTooltip2(false)}
                                title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                                placement="top"
                                arrow
                                open={tooltip2}
                            >
                                <Button sx={{ padding: 0, margin: 0, outline: 0, display: { md: 'block', xs: 'none' }, minWidth: '0px' }} onClick={() => setTooltip2(true)} >
                                    <Box sx={{ minWidth: '16px' }}>
                                        <ToolTipInfoSVG />
                                    </Box>
                                </Button>
                            </LightTooltip>
                            <ClickAwayListener onClickAway={() => setTooltip2Mobile(false)} >
                                <LightTooltip
                                    onClose={() => setTooltip2Mobile(false)}
                                    open={tooltip2Mobile}
                                    title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                                    placement="top"
                                    arrow
                                >
                                    <Button sx={{ padding: 0, margin: 0, outline: 0, display: { md: 'none', xs: 'block' }, minWidth: '0px' }} onClick={() => setTooltip2Mobile(true)} >
                                        <Box sx={{ minWidth: '16px' }}>
                                            <ToolTipInfoSVG />
                                        </Box>
                                    </Button>
                                </LightTooltip>
                            </ClickAwayListener> */}
                        </Box>
                        <Typography variant='roboto24' color='primary.main'>
                            {/* {investData?.epochEnd} */}
                            {chainConfig?.updateDate}
                        </Typography>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', gap: '8px', marginBottom: '4px', height: '25px' }}>
                            <Typography variant='roboto12Bold' color='#888071'>
                                APY (PAST 30 DAYS)
                            </Typography>
                            <DarkTooltip
                                title="This APY is just the estimated annual yield. This may vary depending on market conditions."
                                placement="top"
                                arrow
                                open={tooltip3}
                                onClose={() => setTooltip3(false)}
                            >
                                <Button sx={{ padding: 0, margin: 0, outline: 0, display: { md: 'block', xs: 'none' }, minWidth: '0px' }} onClick={() => setTooltip3(true)} >
                                    <Box sx={{ minWidth: '16px' }}>
                                        <ToolTipInfoSVG />
                                    </Box>
                                </Button>
                            </DarkTooltip>
                            <ClickAwayListener onClickAway={() => setTooltip3Mobile(false)} >
                                <DarkTooltip
                                    onClose={() => setTooltip3Mobile(false)}
                                    open={tooltip3Mobile}
                                    title="This APY is just the estimated annual yield. This may vary depending on market conditions."
                                    placement="top"
                                    arrow
                                >
                                    <Button sx={{ padding: 0, margin: 0, outline: 0, display: { md: 'none', xs: 'block' }, minWidth: '0px' }} onClick={() => setTooltip3Mobile(true)} >
                                        <Box sx={{ minWidth: '16px' }}>
                                            <ToolTipInfoSVG />
                                        </Box>
                                    </Button>
                                </DarkTooltip>
                            </ClickAwayListener>
                        </Box>
                        <Typography variant='roboto24' color='common.black'>
                            {chainConfig?.apy?.[0]}
                        </Typography>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', gap: '8px', marginBottom: '4px', height: '25px' }}>
                            <Typography variant='roboto12Bold' color='#888071'>
                                APY (PAST 180 DAYS)
                            </Typography>
                        </Box>
                        <Typography variant='roboto24' color='common.black'>
                            {chainConfig?.apy?.[1]}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: { lg: '48px', md: '36px', sm: '24px', xs: '24px' }, flexDirection: { lg: 'row', xs: 'column' } }}>
                    <Box>
                        <Box sx={{ marginBottom: '4px', height: '25px' }}>
                            <Typography variant='roboto12Bold' color='#888071'>
                                TOTAL INVESTMENT BALANCE
                            </Typography>
                        </Box>
                        <Typography variant='roboto24' color='common.black'>
                            {investData?.asset} USDT ({investData?.balance} iUSD)
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: { lg: '48px', md: '36px', sm: '24px', xs: '24px' } }}>
                        <Box>
                            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', marginBottom: '4px', height: '25px' }}>
                                <Typography variant='roboto12Bold' color='#888071'>
                                    PENDING FOR DEPOSIT
                                </Typography>
                                <DarkTooltip
                                    title="Your funds will be fully deposited in the next investment epoch."
                                    placement="top"
                                    arrow
                                    open={tooltip4}
                                    onClose={() => setTooltip4(false)}
                                >
                                    <Button sx={{ padding: 0, margin: 0, outline: 0, display: { md: 'flex', xs: 'none' }, minWidth: '0px', alignItems: 'center' }} onClick={() => setTooltip4(true)} >
                                        <Box sx={{ minWidth: '16px' }}>
                                            <ToolTipInfoSVG />
                                        </Box>
                                    </Button>
                                </DarkTooltip>
                                <ClickAwayListener onClickAway={() => setTooltip4Mobile(false)} >
                                    <DarkTooltip
                                        onClose={() => setTooltip4Mobile(false)}
                                        open={tooltip4Mobile}
                                        title="Your funds will be fully deposited in the next investment epoch."
                                        placement="top"
                                        arrow
                                    >
                                        <Button sx={{ padding: 0, margin: 0, outline: 0, display: { md: 'none', xs: 'block' }, minWidth: '0px' }} onClick={() => setTooltip4Mobile(true)} >
                                            <Box sx={{ minWidth: '16px' }}>
                                                <ToolTipInfoSVG />
                                            </Box>
                                        </Button>
                                    </DarkTooltip>
                                </ClickAwayListener>
                            </Box>
                            <Typography variant='roboto24' color='common.black'>
                                {investData?.investing} USDT
                            </Typography>
                        </Box>
                        <Box>
                            <Box sx={{ marginBottom: '4px', height: '25px' }}>
                                <Typography variant='roboto12Bold' color='#888071'>
                                    PENDING FOR WITHDRAWAL
                                </Typography>
                            </Box>
                            <Typography variant='roboto24' color='common.black'>
                                {investData?.redepmtion} iUSD
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ height: '1px', margin: { lg: '24px 0px', md: '20px 0px', sm: '16px 0px', xs: '14px 0px' }, backgroundColor: '#B0A592' }} />
                <Box sx={{ display: 'flex', gap: '16px' }}>
                        <Typography variant='inter14' color='primary.main'>
                                Only whitelisted clients are permitted to invest in this vault. Non-whitelisted clients risk losing investment. Apply to be whitelisted client now.
                        </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '16px' }}>
                    <Button variant='contained' color='black' onClick={toggleDepositModal}>
                        <Typography variant='inter14Bold'>
                            DEPOSIT
                        </Typography>
                    </Button>
                    <Button variant='outlined' color='black' onClick={toggleClaimModal}>
                        <Typography variant='inter14Bold' style={{ textTransform: 'none' }}>
                            CLAIM iUSD
                        </Typography>
                    </Button>
                    <Button variant='outlined' color='black' onClick={tryRedeem}>
                        <Typography variant='inter14Bold'>
                            WITHDRAW ALL INVESTMENT
                        </Typography>
                    </Button>
                </Box>
            </Box>
            <Box sx={{ marginBottom: '16px' }}>
                <Typography variant='inter20Bold'>
                    FAQs
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <Accordion
                    sx={{
                        borderColor: '#B0A592',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderRadius: '0px',
                        boxShadow: 'none'
                    }}>
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon color='primary' />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        focusVisibleClassName='activeAccordion'
                        sx={{
                            borderBottomColor: '#B0A592',
                            borderBottomWidth: '1px',
                            borderBottomStyle: 'solid',
                            borderRadius: '0px',
                        }}
                    >
                        <Typography variant='inter14SemiBold'>Strategy</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box component={'ul'}>
                            <Box component={'li'} sx={{ margin: '10px 0' }}>
                                <Typography variant='inter14'>
                                    Baljuna's DeFi Arbitrage Vault is a real yield strategy that utilizes battle-tested bots and algorithms from the top crypto quantitative fund 61 BTC (champion of the prestigious TokenInsight Quantitative Trading Competition 2020). This strategy buys low on the spot market and sells high on the perpetual futures market for minimal risk profits on Binance whenever prices diverge.
                                </Typography>
                            </Box>
                            <Box component={'li'} sx={{ margin: '10px 0' }}>
                                <Typography variant='inter14'>
                                    Baljuna benefits from Binance VIP 9 treatment, resulting in the lowest possible latency and execution costs. It also employs Ceffu Custody (formerly Binance Custody) to ensure that during trading, assets are always locked in fully transparent and verifiable on-chain decentralized MPC wallets, rather than being held on Binance.
                                </Typography>
                            </Box>
                            <Box component={'li'} sx={{ margin: '10px 0' }}>
                                <Typography variant='inter14'>
                                    Historically, similar products offered by top quant funds require a minimum investment ticket size of at least $2 million. Now, anyone can access this top-notch product with full security, with no minimum investment size requirement, and experience real yield returns previously available only to the wealthiest investors.
                                </Typography>
                            </Box>
                            <Box component={'li'} sx={{ margin: '10px 0' }}>
                                <Typography variant='inter14'>
                                    Before depositing, please read the docs and T&C. By depositing you agree to the aforementioned T&C, have read the linked documentation and take responsibility for knowledge of these actions before using Baljuna’s contracts.
                                </Typography>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{
                    borderColor: '#B0A592',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderRadius: '0px',
                    boxShadow: 'none'
                }}>
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon color='primary' />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        sx={{
                            borderBottomColor: '#B0A592',
                            borderBottomWidth: '1px',
                            borderBottomStyle: 'solid',
                            borderRadius: '0px',
                            boxShadow: 'none'
                        }}
                    >
                        <Typography variant='inter14SemiBold'>Strategy Highlights</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box component={'ul'}>
                            <Box component={'li'} sx={{ margin: '10px 0' }}>
                                <Typography variant='inter14'>
                                    <Typography variant='inter14Bold'>Principal safety</Typography> prioritized by trading only in arbitrage opportunities.
                                </Typography>
                            </Box>
                            <Box component={'li'} sx={{ margin: '10px 0' }}>
                                <Typography variant='inter14'>
                                    <Typography variant='inter14Bold'>No market or price risk</Typography> since there is always a matching long and short.
                                </Typography>
                            </Box>
                            <Box component={'li'} sx={{ margin: '10px 0' }}>
                                <Typography variant='inter14'>
                                    Executed by 61 BTC, a <Typography variant='inter14Bold'>well-established quant-fund partner</Typography> with a solid track record managing over $300mm for years and winning the prestigious TokenInsight Quantitative Trading Competition 2020.
                                </Typography>
                            </Box>
                            <Box component={'li'} sx={{ margin: '10px 0' }}>
                                <Typography variant='inter14'>
                                    <Typography variant='inter14Bold'>Solid track Record</Typography> as the strategy has delivered a 33% annualized return during 2020-2023 (through the full bull-bear cycle), with a maximum daily drawdown of only 0.43%. The Sharpe ratio is 9.6, and there has not been a single down month.
                                </Typography>
                            </Box>
                            <Box component={'li'} sx={{ margin: '10px 0' }}>
                                <Typography variant='inter14'>
                                    <Typography variant='inter14Bold'>Fully transparent and secure</Typography> with on-chain smart contracts and assets locked in verifiable on-chain decentralized MPC wallets (Ceffu Custody).
                                </Typography>
                            </Box>
                            <Box component={'li'} sx={{ margin: '10px 0' }}>
                                <Typography variant='inter14'>
                                    <Typography variant='inter14Bold'>Lowest execution fees and latency</Typography> with BinanceVIP 9 access, (typically require $4B monthly spot trading volume).
                                </Typography>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{
                    borderColor: '#B0A592',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderRadius: '0px',
                    boxShadow: 'none'
                }}>
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon color='primary' />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                        sx={{
                            borderBottomColor: '#B0A592',
                            borderBottomWidth: '1px',
                            borderBottomStyle: 'solid',
                            borderRadius: '0px',
                            boxShadow: 'none'
                        }}
                    >
                        <Typography variant='inter14SemiBold'>iUSD</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box component={'ul'}>
                            <Box component={'li'} sx={{ margin: '10px 0' }}>
                                <Typography variant='inter14'>
                                    iUSD is a unique ERC20 token that represents a client's holdings in Baljuna Arb Yield Vault.
                                </Typography>
                            </Box>
                            <Box component={'li'} sx={{ margin: '10px 0' }}>
                                <Typography variant='inter14'>
                                    iUSD is not a stablecoin, but a yield-bearing token that accrues interest from the arbitrage of the same assets on Binance using a proven and reliable trading bot. When clients deposit USDT into the Baljuna Arb Yield Vault, they receive iUSD tokens that reflect their investment in the pool.
                                </Typography>
                            </Box>
                            <Box component={'li'} sx={{ margin: '10px 0' }}>
                                <Typography variant='inter14'>
                                    At the start of first epoch, iUSD's default price is set to 1 USDT. After the first epoch, <Typography variant='inter14Bold'>the value of iUSD is calculated as (Net asset value (NAV) of all assets in the vault)/ (the number of iUSD tokens outstanding)</Typography>.
                                </Typography>
                            </Box>
                            <Box component={'li'} sx={{ margin: '10px 0' }}>
                                <Typography variant='inter14'>
                                    In the future, Baljuna plans to expand its offerings around iUSD, including but not limited to additional services such as swaps and lending. These services will aim to increase flexibility, enhance yields, and improve the overall utility of iUSD for our clients.
                                </Typography>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <DepositUSDModal open={depositModal} handleClose={toggleDepositModal} onClickSuccess={depositSuccess} />
            <DepositUSDSuccessModal open={depositSuccessModal} handleClose={toggleDepositSuccessModal} amount={amount}/>
            <ClaimIUSDModal open={claimModal} handleClose={toggleClaimModal} onClickSuccess={claimSuccess} />
            <ClaimIUSDSuccessModal open={claimSuccessModal} handleClose={toggleClaimSuccessModal} amount={amount}/>

            <ArbYieldVaultModal open={showVaultReminderModal} handleClose={() => setShowVaultReminderModal(false)} />
        </Box >
    );
}

export default ArbYieldVault;