import { Box, Container, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';

const profiles = [
    {
        name: 'George Liu',
        position: 'CEO',
        about: 'A veteran TradFi executive with over 16 years of experience including executive director at JP Morgan, head of China for Noble Group, as well as head of Asian Structuring for Mercuria Group.',
        image: '/assets/images/team/Name1.png',
    },
    {
        name: 'David Xue, PhD',
        position: 'CTO',
        about: 'A veteran quant with over 17 years of experience. Ex-senior quant at the largest Australian bank, led the development and support for the bank’s quant pricing, trading and risk control system for rates, fx and precious metals businesses.',
        image: '/assets/images/team/Name2Temp.png',
    },
    {
        name: 'Robin Shu',
        position: 'Head of Product',
        about: 'After graduating with his MS Finance degree from Columbia University, Robin was a veteran commodities trader with over 15 years of experience working as senior trader at top global banks such as Morgan Stanley and JP Morgan.',
        image: '/assets/images/team/Name3Temp.png',
    },
    {
        name: 'Eva Shi',
        position: 'Head of Business Development',
        about: 'Previously a senior sales executive at Babel Finance, Eva personally raised over $1 billion AUM for Babel within 2.5 years from scratch.',
        image: '/assets/images/team/Name4Temp.png',
    },
    {
        name: 'Xavier Du',
        position: 'Head of Programming',
        about: 'A senior programmer with 10 years of experience. Ex-senior programmer at GSR, responsible for developing GSR’s client products (supplied to Binance as Binance Earn). Ex-eFX system developer at 2nd largest German Bank.',
        image: '/assets/images/team/Name5Temp.png',
    },
]
const Team = () => {
    const [selected, setSelected] = useState(0);
    const selectedProfile = profiles[selected];

    return (
        <Box component="main" sx={{ paddingTop: { xl: '230px', lg: '230px', md: '200px', sm: '150px', xs: '130px' }, paddingBottom: '40px', backgroundColor: 'common.black' }}>
            <Container maxWidth='xl' fixed>
                <Box sx={{ display: 'flex', gap: '5%', marginBottom: '50px', flexDirection: { md: 'row', xs: 'column' } }}>
                    <Box sx={{
                        width: { md: '35%', xs: '100%' },
                        paddingRight: '5%',
                        borderColor: 'primary.main',
                        borderWidth: '1px',
                        borderRightStyle: { md: 'dashed', xs: 'none' },
                        borderBottomStyle: { md: 'none', xs: 'dashed' },
                        paddingBottom: { md: 0, xs: '20px' },
                        marginBottom: { md: 0, xs: '20px' }
                    }}>
                        <Typography variant='bannerDesc' color='secondary'>
                            Baljuna products and smart contracts are developed by a team of top industry veterans.
                        </Typography>
                    </Box>

                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ marginBottom: '14px' }}>
                            <Typography variant='name' color='secondary'>
                                {selectedProfile.name}
                            </Typography>
                        </Box>
                        <Box sx={{ marginBottom: '20px' }}>
                            <Typography variant='tableSubHeader' color='primary'>
                                {'// ' + selectedProfile.position}
                            </Typography>
                        </Box>
                        <Box sx={{ marginBottom: '30px', minHeight: { lg: '136px', md: '155px', xs: '120px' } }}>
                            <Typography variant='body1' color='secondary'>
                                {selectedProfile.about}
                            </Typography>
                        </Box>
                        {/* <Box sx={{ display: 'flex', marginBottom: '20px', flexDirection: { sm: 'row', xs: 'column' }, alignItems: { sm: 'initial', xs: 'center' } }}>
                            <Box sx={{ flex: 1, display: 'flex', gap: { md: '10%', xs: '5%' }, alignItems: 'center', marginBottom: { sm: '0px', xs: '15px' } }}>
                                <Box sx={{ width: '41.8px', height: '41.8px', borderRadius: '41.8px', border: '1px solid #F8E9CD' }} />
                                <MiniBar />
                            </Box>
                            <Box sx={{ flex: 1, display: 'flex', gap: { md: '10%', xs: '5%' }, alignItems: 'center' }}>
                                <Box sx={{ width: '41.8px', height: '41.8px', borderRadius: '41.8px', border: '1px solid #F8E9CD' }} />
                                <MiniBar />
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: { sm: 'row', xs: 'column' }, alignItems: { sm: 'initial', xs: 'center' } }}>
                            <Box sx={{ flex: 1, display: 'flex', gap: { md: '10%', xs: '5%' }, alignItems: 'center', marginBottom: { sm: '0px', xs: '15px' } }}>
                                <Box sx={{ width: '41.8px', height: '41.8px', borderRadius: '41.8px', border: '1px solid #F8E9CD' }} />
                                <MiniBar />
                            </Box>
                            <Box sx={{ flex: 1, display: 'flex', gap: { md: '10%', xs: '5%' }, alignItems: 'center' }}>
                                <Box sx={{ width: '41.8px', height: '41.8px', borderRadius: '41.8px', border: '1px solid #F8E9CD' }} />
                                <MiniBar />
                            </Box>
                        </Box> */}
                    </Box>
                </Box>
                <Grid container spacing={2} columns={{ sm: 10, xs: 6 }}>
                    {profiles.map((profile, index) => (
                        <Grid key={index} item xs={2}>
                            <Box sx={{ position: 'relative', cursor: 'pointer' }} onClick={() => setSelected(index)}>
                                {selected === index && <Box sx={{ backgroundColor: '#282BF6', mixBlendMode: 'color', position: 'absolute', width: '100%', height: '100%', background: '#282BF6', opacity: 0.7 }} />}
                                <Box component='img' src={profile.image} width='100%' height='auto' />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default Team;

