import * as React from "react"

const ShortLogoSVG = ({ fill = '#FF4C00' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={'100%'}
        height={'100%'}
        viewBox="0 0 76 74"
        fill={fill}
    >
        <path d="M50.713 20.652H25.49v-7.43c0-3.334 1.305-6.487 3.66-8.878 4.893-4.965 12.902-4.965 17.83-.037l.037.037a12.65 12.65 0 0 1 3.696 8.915v7.393ZM57.167 45.875H75.94L50.717 20.652v18.772l6.45 6.45ZM19.04 45.875H.268L25.49 20.652v18.772l-6.451 6.45ZM50.713 39.423H25.49v8.916h25.223v-8.916ZM57.167 73.562H75.94L50.717 48.339V67.11l6.45 6.45ZM19.04 73.562H.268L25.49 48.339V67.11l-6.451 6.45Z" />
    </svg>
)
export default ShortLogoSVG;
