import React, { useState } from 'react';
import { useParallax } from 'react-scroll-parallax';
import { Box, Button, Container, Typography, Snackbar, Alert,Modal } from '@mui/material';
import HomeHeader from '../../../components/HomeHeader/HomeHeader';
import CurrencyBagIconSVG from '../../../components/SVGs/CurrencyBagIconSVG';
import ArrowRightIconSVG from '../../../components/SVGs/ArrowRightIconSVG';
import LaptopBar1SVG from '../../../components/SVGs/LaptopBarCode1SVG';
import LaptopBar2SVG from '../../../components/SVGs/LaptopBarCode2SVG';
import VaultIconSVG from '../../../components/SVGs/VaultIconSVG';
import LaptopBarCode3SVG from '../../../components/SVGs/LaptopBarCode3SVG';
import BarCodeSVG from '../../../components/SVGs/BarCodeSVG';
import { useNavigate } from 'react-router-dom';
import NavButton from '../../../components/NavButton/NavButton';

const TTV = () => (
    <Box sx={{
        padding: { md: '10px 16px', xs: '8px 12px' },
        border: '2px solid rgba(0, 0, 0, 0.30)',
        width: 'fit-content',
        marginBottom: '17px',
        zIndex: 1
    }}>
        <Typography variant='currency'>
            TTV: $10,534,495
        </Typography>
    </Box>
);

const Buttons = ({ soon, setSoon,url,title }) => {
    const [openVideo, setOpenVideo] = useState(false);
    return <Box sx={{ display: 'flex', gap: { md: '25px', xs: '15px' } }}>
    <NavButton
        variant='contained'
        color='black'
        textColor='secondary.main'
        textVariant='button1'
        label='GET STARTED'
        href='/signup'
        sx={{ padding: '8px 24px' }}
        hoverBackgroundColor='black'
    />
    {/* <Button
        variant='contained'
        color="black"
        onClick={() => navigate('/signup')}
        sx={{ color: 'secondary.main', padding: '8px 24px' }}>
        <Typography variant='button1'>
            GET STARTED
        </Typography>
    </Button> */}
    <Button
        variant='outlined'
        onClick={()=>setOpenVideo(true)}
        color='black'
        textColor='common.black'
        textVariant='button1'
        label='HOW IT WORKS'
        startIcon={<ArrowRightIconSVG />}
        sx={{ padding: '8px 24px' }}
    >
        <Typography variant='button1'>
            HOW IT WORKS
        </Typography>
    </Button>
    <Modal open={openVideo} onClose={()=>setOpenVideo(false)}>
        <div className='video-container' onClick={()=>setOpenVideo(false)}>
            <iframe width="800" height="560" src={url} title={title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen autoplay></iframe>
        </div>
    </Modal>
    {/* <Button
        onClick={() => setSoon(true)}
        startIcon={<ArrowRightIconSVG />}
        variant='outlined'
        color='black'
        sx={{ padding: '8px 24px' }}>

        <Typography variant='button1'>
            HOW IT WORKS
        </Typography>
    </Button> */}
    {/* <Snackbar
        open={soon}
        autoHideDuration={4000}
        onClose={() => setSoon(false)}

    >
        <Alert severity="info" sx={{ alignItems: 'center' }}>
            <Typography variant='inter14'>
                Coming Soon!
            </Typography>
        </Alert>
    </Snackbar> */}
</Box >

}
    ;

const InvestmentProducts = () => {
    const [soon, setSoon] = useState(false);

    const { ref } = useParallax({
        translateX: [-20, 20]
    });

    const { ref: refBox } = useParallax({
        translateX: [80, -80]
    });

    const { ref: refBar1 } = useParallax({
        translateX: [30, -30]
    });

    const { ref: refBar2 } = useParallax({
        translateY: [80, -80]
    });

    const { ref: ref2 } = useParallax({
        translateX: [15, -20]
    });

    const { ref: ref2Box } = useParallax({
        translateX: [-30, 20]
    });

    const { ref: ref2Bar1 } = useParallax({
        translateX: [55, -80]
    });

    const { ref: ref2Bar2 } = useParallax({
        translateX: [-10, 75]
    });

    return (
        <Box id='investment' component='section' sx={{ backgroundColor: 'secondary.main', overflow: 'hidden' }}>
            <Container maxWidth="xl" fixed>
                <HomeHeader
                    sx={{
                        textAlign: 'center',
                        marginTop: { lg: '110px', md: '85px', xs: '60px' },
                        marginBottom: { lg: '100px', md: '75px', xs: '45px' }
                    }}
                    title=" INNOVATIVE SOLUTIONS"
                    description="Find your trading edge with our revolutionary products"
                />
                <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column-reverse' }, marginBottom: { xl: '100px', lg: '80px', md: '60px', sm: '80px', xs: '60px' } }}>
                    <Box sx={{ flex: 1 }}>
                        <Box sx={{ paddingLeft: { xl: '82px', lg: '70px', md: '54px', sm: '24px', xs: '10px' }, paddingRight: { xl: '78px', lg: '66px', md: '50px', sm: '24px', xs: '10px' }, marginBottom: { sm: '50px', xs: '50px' } }}>
                            <TTV />
                            <Box sx={{ display: { md: 'block', xs: 'none' } }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px', marginBottom: '17px' }}>
                                    <CurrencyBagIconSVG />
                                    <Typography variant='h2'>
                                        Dual Currency
                                    </Typography>
                                </Box>
                                <Box sx={{ marginBottom: { xl: '64px', lg: '56px', md: '52px' } }}>
                                    <Typography variant="body1" sx={{ marginBottom: { xl: '36p', lg: '33px', md: '30px' } }}>
                                        Investors and traders can lose up to 80% (or more) of their capital within a year. With Baljuna, we created a  win-win solution that allows you to collect BPS tokens and receive rewards from unprofitable trades.
                                    </Typography>
                                    <Box component="ul">
                                        <Box component="li">
                                            <Typography variant="body1">
                                                <Box component={"span"} sx={{ fontWeight: 700 }}>Earn Interest</Box> for simply placing an order.
                                            </Typography>
                                        </Box>
                                        <Box component="li">
                                            <Typography variant="body1">
                                                <Box component={"span"} sx={{ fontWeight: 700 }}>Gain profit</Box> for either buying the dip or selling the rip.
                                            </Typography>

                                        </Box>
                                        <Box component="li">
                                            <Typography variant="body1">
                                                <Box component={"span"} sx={{ fontWeight: 700 }}>Receive BPS tokens</Box> when you suffer losses.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px', marginBottom: { sm: '16px', xs: '14px' } }}>
                                    <Typography variant='h2'>
                                        Dual Currency Investment
                                    </Typography>
                                </Box>
                                <Box sx={{ marginBottom: { sm: '24px', xs: '18px' } }}>
                                    <Typography variant="body1">
                                        <Box component={"span"} sx={{ fontWeight: 700 }}>Earn from Trades</Box> while <Box component={"span"} sx={{ fontWeight: 700 }}>Acquiring Yield</Box>.
                                        This win-win solution allows the investor to secure profits for buying the dip or selling the rip. In case of unprofitable trades, collect BPS tokens and receive rewards.
                                    </Typography>
                                </Box>
                            </Box>
                            <Buttons soon={soon} setSoon={setSoon} url='https://www.youtube.com/embed/cTvcRU776Ho?&autoplay=1' title='Baljuna Waters - Dual Yield Currency'/>
                        </Box>
                    </Box>

                    <Box sx={{ flex: 1 }}>
                        <Box ref={ref} sx={{
                            backgroundColor: 'primary.main',
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: 'auto',
                            height: { md: '87%', sm: '360px', xs: '305px' },
                            width: { md: '100%', sm: '80%', xs: '100%' },
                            marginBottom: { md: '0px', sm: '80px', xs: '70px' }
                        }}>
                            <Box sx={{
                                top: -1,
                                left: -1,
                                position: 'absolute',
                                width: 0,
                                height: 0,
                                borderLeft: '0px solid transparent',
                                borderRight: { md: '100px solid transparent', xs: '70px solid transparent' },
                                borderTop: { md: '100px solid #f8e9cd', xs: '70px solid #f8e9cd' }
                            }} />
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Box ref={refBar1} sx={{ zIndex: 1, width: '95%', paddingTop: { md: '30px', xs: '17px' }, paddingBottom: { md: '30px', xs: '17px' }, alignSelf: 'flex-end' }}>
                                    <LaptopBar1SVG width='100%' height='100%' />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box ref={refBar2} sx={{ paddingLeft: '5%', paddingRight: '5%', width: '10%', height: '87%' }}>
                                    <LaptopBar2SVG width='100%' height='100%' />
                                </Box>
                                <Box ref={refBox} sx={{ flex: 1 }}>
                                    <Box
                                        component='img'
                                        src="/assets/images/laptop2.png"
                                        alt="Vector 1"
                                        sx={{
                                            objectFit: 'contain',
                                            maxWidth: '700px',
                                            width: '120%',
                                            height: 'auto'
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, paddingBottom: { lg: '112.82px', md: '85px', sm: '60px' }, alignItems: 'center' }}>
                    <Box sx={{ flex: 1 }}>
                        <Box ref={ref2} sx={{
                            backgroundColor: 'tertiary.main',
                            width: { md: '90%', sm: '80%', xs: '100%' },
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            paddingTop: '25px',
                            marginBottom: { md: '0px', sm: '50px', xs: '35px' }
                        }}>
                            <Box ref={ref2Bar1} sx={{ height: '15%', width: '30%', marginRight: '-7%' }}>
                                <BarCodeSVG width='100%' height='100%' />
                            </Box>
                            <Box
                                ref={ref2Box}
                                component='img'
                                src="/assets/images/laptop3.png"
                                alt="Vector 2"
                                sx={{
                                    objectFit: 'contain',
                                    maxWidth: '830px',
                                    width: '115%',
                                    height: 'auto',
                                    marginRight: { md: '-90px', sm: '-55px', xs: '-45px' },
                                    marginTop: { md: '-100px', sm: '-85px', xs: '-70px' },
                                    marginBottom: '15px'
                                }}
                            />
                            <Box ref={ref2Bar2} sx={{ display: 'flex', width: '100%' }}>
                                <LaptopBarCode3SVG width='80%' height='100%' sx={{ alignSelf: 'flex-start' }} />
                            </Box>
                            <Box sx={{
                                width: 0,
                                height: 0,
                                borderLeft: { md: '80px solid transparent', xs: '60px solid transparent' },
                                borderBottom: { md: '80px solid #f8e9cd', xs: '60px solid #f8e9cd' }
                            }} />
                        </Box>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Box sx={{ paddingLeft: { xl: '97px', lg: '86px', md: '70px', sm: '24px', xs: '10px' }, paddingRight: { xl: '50px', lg: '42px', md: '35px', sm: '24px', xs: '10px' }, marginBottom: { sm: '50px', xs: '50px' } }}>
                            <TTV />
                            <Box sx={{ display: { md: 'block', xs: 'none' } }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px', marginBottom: '17px' }}>
                                    <VaultIconSVG />
                                    <Typography variant='h2'>
                                        Arb Yield Vault
                                    </Typography>
                                </Box>
                                <Box sx={{ marginBottom: { xl: '64px', lg: '56px', md: '52px' } }}>
                                    <Typography variant="body1" sx={{ marginBottom: { xl: '36p', lg: '33px', md: '30px' } }}>
                                        Park stablecoins in a <Box component={"span"} sx={{ fontWeight: 700 }}>principal-protected</Box>, arbitrage strategy with the highest risk-adjusted returns. Partnered with established quant funds, with funds safely stored with institutional custodian.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px', marginBottom: { sm: '16px', xs: '14px' } }}>
                                    <Typography variant='h2'>
                                        Arb Yield Vault
                                    </Typography>
                                </Box>
                                <Box sx={{ marginBottom: { sm: '24px', xs: '18px' } }}>
                                    <Typography variant='body1'>
                                        Park stablecoins in a <Box component={"span"} sx={{ fontWeight: 700 }}>principal-protected</Box>, arbitrage strategy with the highest risk-adjusted returns. Partnered with established quant funds, with funds safely stored with institutional custodian.
                                    </Typography>
                                </Box>
                            </Box>
                            <Buttons soon={soon} setSoon={setSoon} url='https://www.youtube.com/embed/-86hcYCumxo?&autoplay=1' title='Baljuna Waters - Arb Yield Vault'/>
                        </Box>
                    </Box>
                </Box>
            </Container >
        </Box >
    );

};

export default InvestmentProducts;