import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Carousel from 'react-spring-3d-carousel';
import InvestorCard from '../InvestorCard/InvestorCard';
import InvestorTreeSVG from '../../../../components/SVGs/Investor/InvestorTreeSVG';
import InvestorHammerSVG from '../../../../components/SVGs/Investor/InvestorHammerSVG';
import InvestorArrowSVG from '../../../../components/SVGs/Investor/InvestorArrowSVG';
import InvestorTrophySVG from '../../../../components/SVGs/Investor/InvestorTrophySVG';
import InvestorBagSVG from '../../../../components/SVGs/Investor/InvestorBagSVG';
import InvestorCodeSVG from '../../../../components/SVGs/Investor/InvestorCodeSVG';
import SliderTrack from '../../../../components/SliderTrack/SliderTrack';


const Draggable = ({ innerRef, rootClass = "", children }) => {
    const ourRef = useRef(null);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0
    });

    const handleDragStart = (e) => {
        if (!ourRef.current) return
        const slider = ourRef.current.children[0];
        slider.style.scrollBehavior = 'initial';
        const startX = e.pageX - slider.offsetLeft;
        const startY = e.pageY - slider.offsetTop;
        const scrollLeft = slider.scrollLeft;
        const scrollTop = slider.scrollTop;
        mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
        setIsMouseDown(true)
        document.body.style.cursor = "grabbing"
    }
    const handleDragEnd = () => {
        setIsMouseDown(false);
        if (!ourRef.current) return;
        document.body.style.cursor = "default";
        const slider = ourRef.current.children[0];
        slider.style.scrollBehavior = 'smooth';
    }
    const handleDrag = (e) => {
        if (!isMouseDown || !ourRef.current) return;
        e.preventDefault();
        const slider = ourRef.current.children[0];
        const x = e.pageX - slider.offsetLeft;
        const y = e.pageY - slider.offsetTop;
        const walkX = (x - mouseCoords.current.startX) * 1.5;
        const walkY = (y - mouseCoords.current.startY) * 1.5;
        slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
        slider.scrollTop = mouseCoords.current.scrollTop - walkY;
    }

    return (
        <div ref={ourRef} onMouseDown={handleDragStart} onMouseUp={handleDragEnd} onMouseMove={handleDrag} className={rootClass + "flex overflow-x-scroll"}>
            {children}
        </div>
    );
};


const ProductsCarousel = ({ width = '100%', height = '100%', sx }) => {

    const [currentSlide, setCurrentSlide] = useState(0);
    const [isClickSlide, setIsClickSide] = useState(false);
    const sliderRef = useRef();
    const perSlide = 200;

    const onScrollSlider = () => {
        if (!isClickSlide) {
            const left = sliderRef?.current.scrollLeft;
            const nextSlide = Math.floor(left / 100);
            if (currentSlide != nextSlide) {
                setCurrentSlide(nextSlide);
            }
        }
        // if (!isClickSlide) {
        //     const sliderWidth = sliderRef?.current?.scrollWidth;
        //     // const perSlide = 200;

        //     const scrollLeft = sliderRef.current.scrollLeft;
        //     if (sliderWidth === scrollLeft) {
        //         sliderRef.current.scrollLeft = 0;
        //     }


        //     let count = 1;
        //     while (scrollLeft >= perSlide * count) {
        //         count++;
        //     }

        //     setCurrentSlide(count - 1);
        // }

    };


    const scrollToSlide = (slideKey) => {
        setIsClickSide(true);
        setCurrentSlide(slideKey);

        const myTimeout = setTimeout(() => {
            setIsClickSide(false);
            clearTimeout(myTimeout);
        }, 500);
    };

    useEffect(() => {
        sliderRef.current.scrollLeft = currentSlide * 100;
    }, [currentSlide])

    const slides = [
        {
            key: 1,
            content: <InvestorCard
                id={1}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                setIsClickSide={setIsClickSide}
                title='Sustainable Yield'
                description='Enjoy comfort in market volatility'
                icon={<InvestorTreeSVG width='100%' height='100%' />}
            />,
            onClick: () => scrollToSlide(0)
        },
        {
            key: 2,
            content: <InvestorCard
                id={2}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                setIsClickSide={setIsClickSide}
                title='Physically Settled'
                description='The First one on-chain. Conveniently receive coins with ready-to-use optionality.'
                icon={<InvestorHammerSVG width='100%' height='100%' />}
            />,
            onClick: () => scrollToSlide(1)
        },
        {
            key: 3,
            content: <InvestorCard
                id={3}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                setIsClickSide={setIsClickSide}
                title='End-to-End'
                description='One Stop Shop DeFi platform geared towards ease of use. '
                icon={<InvestorArrowSVG width='100%' height='100%' />}
            />,
            onClick: () => scrollToSlide(2)
        },
        {
            key: 4,
            content: <InvestorCard
                id={4}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                setIsClickSide={setIsClickSide}
                title='Participation Rewards'
                description='Investors are compensated for engaging with the platform'
                icon={<InvestorTrophySVG width='100%' height='100%' />}
            />,
            onClick: () => scrollToSlide(3)
        },
        {
            key: 5,
            content: <InvestorCard
                id={5}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                setIsClickSide={setIsClickSide}
                title='CeFi to DeFi products'
                description='Traditional CeFi products directly settled on Ethereum and Polygon.'
                icon={<InvestorBagSVG width='100%' height='100%' />}
            />,
            onClick: () => scrollToSlide(4)
        },
        {
            key: 6,
            content: <InvestorCard
                id={6}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                setIsClickSide={setIsClickSide}
                title='Code-secured transparency'
                description='Assets are kept safe with institutional grade custody'
                icon={<InvestorCodeSVG width='100%' height='100%' />}
            />,
            onClick: () => scrollToSlide(5)
        }
    ];

    return (
        <Box sx={{ width, height, ...sx }}>
            <Box sx={{ width, height: '90%', marginBottom: '30px' }}>
                <Carousel
                    slides={slides}
                    goToSlide={currentSlide}
                />
            </Box>
            <Box sx={{ margin: 'auto', width: '80vw', maxWidth: '80vw', overflow: 'hidden' }}>
                <Draggable innerRef={sliderRef} rootClass={"drag"}>
                    <Box onScroll={onScrollSlider} className="remove-scroll" sx={{
                        overflowX: 'scroll', paddingBottom: '-50px',
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        scrollBehavior: 'smooth'
                    }} ref={sliderRef}>
                        <Box sx={{
                            width: 'calc(80vw + 1000px)',
                            height: '25px',
                            background: 'url("/assets/svgs/compass-full.svg")',
                            backgroundRepeat: 'repeat',
                            backgroundSize: 'contain'
                        }} />
                        {/* <SliderTrack /> */}
                    </Box>
                </Draggable>
            </Box>
        </Box >
    )
};

export default ProductsCarousel;