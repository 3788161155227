import * as React from "react"
const ToolTipInfoSVG = ({ fill = '#000' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
    >
        <g >
            <path
                stroke={fill}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 15.333A7.333 7.333 0 1 0 8 .666a7.333 7.333 0 0 0 0 14.667ZM8 7.333v4"
            />
            <path
                fill={fill}
                d="M7.999 5.333a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333Z"
            />
        </g>
    </svg>
)
export default ToolTipInfoSVG
